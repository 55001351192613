import { useState } from "react";
import { JSONTree } from "react-json-tree";
import { MetamaskConnector } from "./MetamaskConnector";
import { RegisterDidFilteringRequest } from "protobuf/OpenApiServerV3";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function DidFilteringBoard({ metamaskConnector }: BoardProps) {
  const [proposalId, setProposalId] = useState("");
  const [mode, setMode] = useState("blocklist");
  const [didList, setDidList] = useState("");
  const [proposalList, setProposalList] = useState("");
  const [request, setRequest] = useState(RegisterDidFilteringRequest.create());
  const [registeredDidFilterId, setRegisteredDidFilterId] = useState(-1);
  const [didFilterIdToRevoke, setDidFilterIdToRevoke] = useState("");

  return (
    <div>
      <table>
        <thead>
          <tr>
            <td>field name</td>
            <td>value</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>proposalId</td>
            <td>
              {" "}
              <input
                type="text"
                value={proposalId}
                onChange={(e) => {
                  setProposalId(e.target.value);
                  setRequest(metamaskConnector.createDidFilteringRequest(e.target.value, mode, didList, proposalList));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>mode</td>
            <td>
              <input
                id="filter-mode-blocklist"
                value="blocklist"
                type="radio"
                checked={mode === "blocklist"}
                onChange={(e) => {
                  setMode("blocklist");
                  setRequest(
                    metamaskConnector.createDidFilteringRequest(proposalId, "blocklist", didList, proposalList)
                  );
                }}
              />{" "}
              Blocklist{" "}
              <input
                id="filter-mode-allowlist"
                value="allowlist"
                type="radio"
                checked={mode === "allowlist"}
                onChange={(e) => {
                  setMode("allowlist");
                  setRequest(
                    metamaskConnector.createDidFilteringRequest(proposalId, "allowlist", didList, proposalList)
                  );
                }}
              />{" "}
              Allowlist
            </td>
          </tr>
          <tr>
            <td colSpan={2}>DID list (i.e. did:snplab:xxxx, did:snplab:yyyy)</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <textarea
                rows={5}
                cols={160}
                value={didList}
                onChange={(e) => {
                  setDidList(e.target.value);
                  setRequest(
                    metamaskConnector.createDidFilteringRequest(proposalId, mode, e.target.value, proposalList)
                  );
                }}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              Proposal list (i.e. mEtSgzGxZAVVMR3k7eKK9kGFT8S3WgzGimyHAuv2VS8UsD8T4,
              mEtSgzGxZAVVMR3k7eKK9est6Xd65jDgjqnBkRaAi5gspqVxQ)
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <textarea
                rows={5}
                cols={160}
                value={proposalList}
                onChange={(e) => {
                  setProposalList(e.target.value);
                  setRequest(metamaskConnector.createDidFilteringRequest(proposalId, mode, didList, e.target.value));
                }}
              />
            </td>
          </tr>
          <tr />
          <tr>
            <td>register now</td>
            <td>
              {" "}
              <button
                type="button"
                onClick={async () =>
                  setRegisteredDidFilterId((await metamaskConnector.sendDidFilterRequest(request)).filteringId)
                }
              >
                register
              </button>
              &nbsp;&nbsp;
              {registeredDidFilterId}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <button
        onClick={async () => {
          console.log("Trying to apply...");
          let result = await metamaskConnector.applyDidFiltering();
          console.log(`result ${result}`);
        }}
      >
        Apply the did filtering now!
      </button>
      <br />
      <br />

      <input
        type="text"
        value={didFilterIdToRevoke}
        onChange={(e) => {
          setDidFilterIdToRevoke(e.target.value);
        }}
      />
      <button
        onClick={async () => {
          console.log("Trying to revoke...");
          let result = await metamaskConnector.revokeDidFilterRequest(didFilterIdToRevoke);
          console.log(`result ${result}`);
        }}
      >
        Revoke the did filtering now!
      </button>
      <br />
      <JSONTree data={request} />
    </div>
  );
}
