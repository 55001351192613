import { useState } from "react";
import { Proposal, RewardTransactionContentForApp } from "../protobuf/OpenApiServerV3DataTypes";
import ProposalItem from "./ProposalItem";
import { Myd3Blockchain } from "../authconnector/myd3";
import { MockMobileCore } from "./MockMobileCore";

type BoardProps = {
  core: MockMobileCore;
};

export default function GoodsListBoard({ core }: BoardProps) {
  let goodsList = core.goodsList !== undefined ? JSON.parse(JSON.stringify(core.goodsList)) : undefined;

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>Code</td>
            <td>Brand</td>
            <td>Name</td>
            <td>Price</td>
            <td>Purchase</td>
            <td>Like</td>
          </tr>

          {goodsList?.map((p: any) => (
            <tr key={`"${p.goodsCode}"`}>
              <td>{`"${p.goodsCode}"`}</td>
              <td>{`"${p.brandName}"`}</td>
              <td>{`"${p.goodsName}"`}</td>
              <td>{`"${p.salePrice}"`}</td>
              <td>
                <button
                  type="button"
                  onClick={() => {
                    core?.purchaseCoupon(p.goodsCode, p.salePrice).then((resp) => {
                      console.log(resp);
                    });
                  }}
                >
                  purchase
                </button>
                {/* &nbsp;&nbsp;&nbsp;&nbsp; ! caution !
                <button
                  type="button"
                  onClick={() => {
                    core?.purchaseManyCoupons(p.goodsCode, p.salePrice);
                  }}
                >
                  purchase 100 coupons
                </button> */}
              </td>
              <td>
                <button
                  type="button"
                  onClick={() => {
                    core?.likeGoods(p.goodsCode).then((resp) => {
                      console.log(resp);
                    });
                  }}
                >
                  like/unlike
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
