import { ChangeEvent, useState, useEffect } from "react";
import { ClientManager } from "./ClientManager";
import ClientItem from "./ClientItem";
import { MockMobileCore } from "./MockMobileCore";
import MockMobileUi from "./MockMobileUi";
import { ALL_DEVICES_DATA } from "authconnector/constants";
import { MetamaskConnector } from "adminconsole/MetamaskConnector";
import { fetchGMORMintApi, fetchMemberInfoApi } from "api";
import { setServerMode } from "api/base";
import * as CryptoJS from "crypto-js";
import { GMORMemberInfoRequest, GMORMemberInfoResponse } from "protobuf/OpenApiServerV3";

type BoardProps = {};

type GMORSurveyResultReq = {
  member_id: string | undefined;
  point: number | undefined;
  survey_id: number | undefined;
  survey_name: string | undefined;
  grant_times: number | undefined;
  hash: string | undefined;
};

export default function ClientBoard(data: BoardProps) {
  const [metamaskConnector, setMetamaskConnector] = useState(new MetamaskConnector());
  const [loggedIn, setLoggedIn] = useState(false);
  const [clientManager, setClientManager] = useState(new ClientManager());

  const savedAllDevicesData: string | null = localStorage.getItem(ALL_DEVICES_DATA);
  const initialCores: MockMobileCore[] = [];
  if (savedAllDevicesData !== null) {
    const parsedAllDevicesData = JSON.parse(savedAllDevicesData);
    parsedAllDevicesData.forEach((value: string) => initialCores.push(new MockMobileCore(value)));
  }

  const [clientCores, setClientCores] = useState(initialCores);
  const initialCurrentCore: MockMobileCore = new MockMobileCore("empty");
  const [currentCore, setCurrentCore] = useState(initialCurrentCore);
  const [serverModeSelected, setServerModeSelected] = useState("dev");

  const [migrationDid, setMigrationDid] = useState("");
  const [migrationVerkey, setMigrationVerkey] = useState("");

  const [statusCode, setStatusCode] = useState<number>();

  const [memberId, setMemberId] = useState("");
  const [point, setPoint] = useState<number>();
  const [surveyId, setSurveyId] = useState<number>();
  const [surveyName, setSurveyName] = useState("");
  const [grantTimes, setGrantTimes] = useState<number>();
  const [hash, setHash] = useState("");
  const [key, setKey] = useState("");

  const chooseCore = (core: MockMobileCore) => {
    console.log(core.myd3()?.did);
    setCurrentCore(core);
  };

  function handleServerChange(event: ChangeEvent<HTMLSelectElement>): void {
    setServerMode(event.target.value);
    setServerModeSelected(event.target.value);
  }

  function generateRandomMid(length: number) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  function customEncodeURL(str: string): string {
    return encodeURIComponent(str)
      .replace(/[!'()~-]/g, (char) => {
        return `%${char.charCodeAt(0).toString(16).toUpperCase()}`;
      })
      .replace(/%20/g, "+");
  }
  function convertToFormData(val: any): FormData {
    const formData = new FormData();
    Object.keys(val).forEach((keyVal) => {
      formData.append(keyVal, val[keyVal]);
    });
    return formData;
  }

  async function callGMORApi(request: GMORSurveyResultReq) {
    const result = await fetchGMORMintApi(convertToFormData(request), "");
    setStatusCode(Number(result));
  }

  async function callMemberInfoApi(request: GMORMemberInfoRequest) {
    const encodedRequest = GMORMemberInfoRequest.encode(request).finish();
    const result = await fetchMemberInfoApi(encodedRequest, `${currentCore.myd3AuthConnector?.state.accessToken}`);
    const decodedResult = GMORMemberInfoResponse.decode(result);
    setStatusCode(Number(decodedResult.code));
    setMemberId(`${decodedResult.memberInfo?.memberId}`);
  }

  const gmorMemberInfoCell = (memReq: GMORMemberInfoRequest, label: string) => {
    return (
      <td>
        <button
          type="button"
          onClick={async () => {
            callMemberInfoApi(memReq);
          }}
        >
          {label}
        </button>
      </td>
    );
  };

  const gmorReqDataCell = (request: GMORSurveyResultReq, label: string) => {
    return (
      <td>
        <button
          type="button"
          onClick={async () => {
            callGMORApi(request);
          }}
        >
          {label}
        </button>
      </td>
    );
  };

  const calculateHash = () => {
    if (memberId && point && surveyId && surveyName && grantTimes) {
      const encodedSurveyName = customEncodeURL(surveyName);
      console.log(encodedSurveyName);
      const newHash = `${grantTimes}${memberId}${point}${surveyId}${encodedSurveyName}`;

      const encryptedHash = CryptoJS.SHA256(newHash).toString(CryptoJS.enc.Hex);
      setHash(encryptedHash);
    } else {
      setHash("");
    }
  };

  useEffect(() => {
    calculateHash();
  }, [memberId, point, surveyId, surveyName, grantTimes]);

  return (
    <div>
      <select onChange={handleServerChange} value={serverModeSelected}>
        <option value="dev">DEV server</option>
        <option value="stag">STAG server</option>
        <option value="gscert">GS CERT server</option>
        <option value="gscert2">GS CERT 2 server</option>
        <option value="local">LOCAL server</option>
      </select>
      <button
        onClick={async () => {
          console.log("Metamask connecting...");
          await metamaskConnector.connectWallet();
          console.log("Metamask connected");
          console.log("Trying to login...");
          await metamaskConnector.login();
          console.log("Trying to logged in");
          if (metamaskConnector.accessToken !== undefined && metamaskConnector.accessToken !== "") {
            setLoggedIn(true);
          }
        }}
      >
        Metamask login
      </button>
      <br />
      <br />
      <div style={{ display: loggedIn ? "block" : "none" }}>
        <h1>Generated client</h1>
        <button
          type="button"
          onClick={async () => {
            const newCore = await clientManager.addNewClient(false);
            setClientCores([...clientCores, newCore]);
          }}
        >
          Create Myd3 Client
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <button
          type="button"
          onClick={async () => {
            const newCore = await clientManager.addNewClient(true);
            setClientCores([...clientCores, newCore]);
          }}
        >
          Create Myd2 Client
        </button>
        <br />
        <button
          type="button"
          onClick={async () => {
            const dataToStore: String[] = [];
            clientCores.forEach((value) => {
              dataToStore.push(value.getMobileData());
            });
            localStorage.setItem(ALL_DEVICES_DATA, JSON.stringify(dataToStore));
          }}
        >
          Save devices
        </button>
        <br />
        <details>
          <summary>Migration test</summary>
          <input
            type="text"
            value={migrationDid}
            onChange={(e) => {
              setMigrationDid(e.target.value);
            }}
          />
          &nbsp;
          <input
            type="text"
            value={migrationVerkey}
            onChange={(e) => {
              setMigrationVerkey(e.target.value);
            }}
          />
          <br />
          <button
            type="button"
            onClick={async () => {
              const newCore = await clientManager.addNewMigrationClient(migrationDid, migrationVerkey);
              setClientCores([...clientCores, newCore]);
            }}
          >
            create migration device
          </button>
        </details>
        <table>
          <thead>
            <tr>
              <td>Click to choose</td>
              <td>DID</td>
              <td>Key</td>
            </tr>
          </thead>

          <tbody>
            {clientCores.map((core) => (
              <ClientItem key={core.myd3()?.did} core={core} cb={chooseCore} />
            ))}
          </tbody>
        </table>
        <h1>Current client</h1>
        <div className="outdiv">
          DID:
          {currentCore?.myd3()?.did === undefined ? (
            <span className="error">no DID chosen</span>
          ) : (
            <span className="valid">{currentCore?.myd3()?.did}</span>
          )}
          <MockMobileUi core={currentCore} />
        </div>
        <h2>Test for GMOR</h2>
        <tr>
          <td align="right">
            {gmorMemberInfoCell(
              {
                did: `${currentCore.myd3AuthConnector?.state.did}`,
                mid: generateRandomMid(36),
              },
              "Create member"
            )}
          </td>
        </tr>
        <details open>
          <summary>GMOR Mint TEST!!!(token)</summary>
          GMOR Survey 보상 지급 API 테스트 모듈입니다.
          <table>
            <tr>
              <td>
                <span>
                  <summary>member_id</summary>
                  <input type="text" name="member_id" value={memberId} readOnly />
                  &nbsp;
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  <summary>point</summary>
                  <input
                    type="number"
                    name="point"
                    value={point}
                    onChange={(e) => setPoint(e.target.valueAsNumber)}
                  />{" "}
                  &nbsp;
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  <summary>survey_id</summary>
                  <input
                    type="number"
                    name="survey_id"
                    value={surveyId}
                    onChange={(e) => setSurveyId(e.target.valueAsNumber)}
                  />{" "}
                  &nbsp;
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  <summary>survey_name</summary>
                  <input
                    type="text"
                    name="survey_name"
                    value={surveyName}
                    onChange={(e) => setSurveyName(e.target.value)}
                  />{" "}
                  &nbsp;
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  <summary>grant_times</summary>
                  <input
                    type="number"
                    name="grant_times"
                    value={grantTimes}
                    onChange={(e) => setGrantTimes(e.target.valueAsNumber)}
                  />{" "}
                  &nbsp;
                </span>
              </td>
            </tr>
            <tr>
              <td align="right">
                {gmorReqDataCell(
                  {
                    point,
                    survey_id: surveyId,
                    survey_name: surveyName,
                    grant_times: grantTimes,
                    hash,
                    member_id: memberId,
                  },
                  "GMOR TEST"
                )}
              </td>
            </tr>
          </table>
        </details>
        <h3>Result</h3>
        <table>
          <tbody>statusCode</tbody>
          <td>{`"${statusCode}"`}</td>
        </table>
      </div>
    </div>
  );
}
