import { useRef, useState } from "react";
import { INITIALIZE, REQ_ATTESTATION, RES_MYD3_STATE, RES_REGISTERED, invokeInterface } from "./interface";
import { Myd3AuthConnector, Myd3AuthConnectorState } from "authconnector/myd3AuthConnector";

type DeviceRegistrationResult = {
  appKey: string; // base64 encoded; only valid for attestation
  secretKey: string; // base64 encoded
  iv: string; // base64 encoded
};

// const applicationIfnoSchema = z.object({
//   appVersion: z.string(),
//   appKey: z.string(),
// });

// const myd2Schema = z.object({
//   did: z.string(),
//   myd2Verkey: z.string(),
//   myd2PrivateKey: z.string(),
// });

// const myd3Schema = z.object({
//   did: z.string(),
//   myd3PublicKey: z.string(),
//   myd3PrivateKey: z.string(),
//   myd3BesuAddress: z.string(),
//   applicationIfno: applicationIfnoSchema,
//   dataFromMyd2: myd2Schema.nullable(),
// });

export default function Board() {
  const connectorRef = useRef<Myd3AuthConnector | null>(null); // TODO: useState로 변경해보기
  const [appInfo, setAppInfo] = useState<DeviceRegistrationResult>();
  const [authData, setAuthData] = useState<Myd3AuthConnectorState>();

  const handleInit = () => {
    invokeInterface(INITIALIZE, { ready: true }, (appData: string) => {
      console.log(`${INITIALIZE} callback`, appData);
      const result = JSON.parse(appData);

      const applicationInfo = {
        appVersion: result.data.applicationInfo.appVersion,
        appKey: result.data.applicationInfo.appKey,
      };

      // 앱에서 myd3 데이터가 오는 경우(우선순위 1번)
      if (result.data.did) {
        const paramData = {
          did: result.data.did as string,
          myd3PublicKey: result.data.myd3PublicKey as string,
          myd3PrivateKey: result.data.myd3PrivateKey as string,
          myd3BesuAddress: result.data.myd3BesuAddress as string,
        };

        connectorRef.current = new Myd3AuthConnector(
          applicationInfo,
          () => {
            console.log("앱에 myd3 데이터 존재");
            return true;
          },
          JSON.stringify(paramData)
        );

        setAuthData({
          ...connectorRef.current.state,
        });
        return;
      }

      // 앱에서 myd2 데이터가 오는 경우
      if (result.data.dataFromMyd2) {
        const { dataFromMyd2 } = result.data;
        connectorRef.current = new Myd3AuthConnector(
          applicationInfo,
          () => {
            console.log("앱에 myd2 데이터 존재");
            return true;
          },
          undefined,
          dataFromMyd2
        );

        setAuthData({
          ...connectorRef.current.state,
        });

        return;
      }

      // 앱에서 아무런 데이터가 오지 않는 경우
      connectorRef.current = new Myd3AuthConnector(applicationInfo, () => {
        console.log("앱에 데이터가 존재하지 않을 때");
        return true;
      });

      setAuthData({
        ...connectorRef.current.state,
      });
    });
  };

  const handleMyd3State = () => {
    if (!connectorRef.current) {
      console.log("커넥터 인스턴스가 없습니다.");
      return;
    }

    const { state } = connectorRef.current;

    invokeInterface(RES_MYD3_STATE, state, (arg: string) => {
      console.log(`${RES_MYD3_STATE} callback`, arg);
    });
  };

  const handleAttestation = async () => {
    if (!connectorRef.current) {
      console.log("커넥터 인스턴스가 없습니다.");
      return;
    }

    const connector = connectorRef.current;
    const nonce = await connector.getNonce();

    invokeInterface(REQ_ATTESTATION, { nonce }, async (arg: string) => {
      console.log(`${REQ_ATTESTATION} callback`, arg);
      const result = JSON.parse(arg);

      const info = await connector.deviceAttestation(result.data.token, nonce.nonce!);
      console.log("deviceAttestation", info);

      const newInfo = {
        appKey: info.appKey!,
        secretKey: info.secretKey!,
        iv: info.iv!,
      };
      setAppInfo(newInfo);
    });
  };

  const handleRegistered = () => {
    if (!appInfo) {
      console.log("appInfo가 없습니다.");
      return;
    }

    invokeInterface(RES_REGISTERED, appInfo, async (arg: string) => {
      console.log(`${RES_REGISTERED} callback`, arg);
    });
  };

  const btnStyle = {
    fontSize: "20px",
    padding: "10px 20px",
    marginBottom: "30px",
  };

  return (
    <section>
      <h1>JS Bridge Test</h1>
      <button type="button" style={btnStyle} onClick={handleInit}>
        initialize
      </button>
      <br />
      <br />

      <button type="button" style={btnStyle} onClick={handleMyd3State}>
        myd3-state
      </button>
      <br />
      <br />

      <button type="button" style={btnStyle} onClick={handleAttestation}>
        attestation
      </button>
      <br />
      <br />

      <button type="button" style={btnStyle} onClick={handleRegistered}>
        registered
      </button>

      <table>
        <tr>
          <th>이름</th>
          <th>값</th>
        </tr>

        <tr>
          <td>did</td>
          <td>{authData?.did}</td>
        </tr>

        <tr>
          <td>myd3PublicKey</td>
          <td>{authData?.myd3PublicKey}</td>
        </tr>

        <tr>
          <td>myd3PrivateKey</td>
          <td>{authData?.myd3PrivateKey}</td>
        </tr>

        <tr>
          <td>myd3BesuAddress</td>
          <td>{authData?.myd3BesuAddress}</td>
        </tr>

        <tr>
          <td>accessToken</td>
          <td>{authData?.accessToken}</td>
        </tr>

        <tr>
          <td>myd2Did</td>
          <td>{authData?.dataFromMyd2?.did}</td>
        </tr>

        <tr>
          <td>myd2Verkey</td>
          <td>{authData?.dataFromMyd2?.myd2Verkey}</td>
        </tr>

        <tr>
          <td>myd2PrivateKey</td>
          <td>{authData?.dataFromMyd2?.myd2PrivateKey}</td>
        </tr>
      </table>
    </section>
  );
}
