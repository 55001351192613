import { useState } from "react";
import {
  Proposal,
  ProposalActiveGlobal,
  ProposalClientStatus,
  ProposalStatus,
} from "../protobuf/OpenApiServerV3DataTypes";
import { MockMobileCore } from "./MockMobileCore";
import { Gzip, RawDeflate, RawInflate } from "zlibt2";
import { getProtobufEnumKey } from "utils/storage";
import { Purpose } from "protobuf/DwSolidity";
import { JSONTree } from "react-json-tree";

type ItemProps = {
  proposal: Proposal;
  core: MockMobileCore | undefined;
};

const getClientMessage = ({ proposal, core }: ItemProps) => {
  const localProposal = core?.getProposal(proposal.proposalId);

  if (localProposal?.activeClient === undefined) return "";
  return localProposal?.activeClient?.proposalClientStatus === undefined
    ? "not issued"
    : ProposalClientStatus[localProposal?.activeClient?.proposalClientStatus];
};

const getRandomId = (length: number) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export default function ProposalItem({ proposal, core }: ItemProps) {
  const initialClientMessage = getClientMessage({ proposal, core });
  const [clientMessage, setClientMessage] = useState(initialClientMessage);
  const [piAddMessage, setPiAddMessage] = useState("");
  const [piTokenMessage, setPiTokenMessage] = useState("");

  const exampleData = {
    payload: [
      [
        {
          identifier: {
            scheme: "KeywordEngine",
            category: "Life",
          },
          keywords: [
            {
              dateTime: "2023-01-13 22:17:00",
              identifier: {
                scheme: "Keyword",
                category: "Life",
              },
              name: "검색해요",
            },
            {
              dateTime: "2023-01-14 00:38:00",
              identifier: {
                scheme: "Keyword",
                category: "Life",
              },
              name: "가짜검색어",
            },
            {
              dateTime: "2023-01-14 00:38:00",
              identifier: {
                scheme: "Keyword",
                category: "Life",
              },
              name: "가짜검색어2",
            },
          ],
        },
      ],
    ],
  };

  return (
    <>
      <tr>
        <td rowSpan={3}>{`"${proposal.static?.content?.contentDescription?.title}"`}</td>
        <td> {getProtobufEnumKey(Purpose, proposal.static?.purpose) ?? proposal.static?.purpose} </td>
        <td>{proposal.static?.content?.contentDescription?.company?.name}</td>
        <td>
          {proposal.activeGlobal?.proposalStatus === undefined
            ? null
            : ProposalStatus[proposal.activeGlobal?.proposalStatus]}
          <span style={{ color: "red" }}>
            {core?.didFiltering?.visibleProposals.includes(proposal.proposalId) ? " (VISIBLE)" : ""}
            {core?.didFiltering?.forbiddenProposals.includes(proposal.proposalId) ? " (FORBIDDEN)" : ""}
          </span>
        </td>
      </tr>
      <tr>
        <td>
          Client(Ticket){" "}
          <button
            type="button"
            disabled={proposal.activeGlobal?.proposalStatus !== ProposalStatus.PROPOSAL_ACTIVATED}
            onClick={async () => {
              core
                ?.issueTicket(proposal.proposalId)
                .then((result) => {
                  // setTmp(JSON.parse(result));
                  setClientMessage(getClientMessage({ proposal, core }));
                })
                .then(() => {
                  const onetimeIdFromActiveClient = core?.proposalsMap
                    .get(proposal.proposalId)
                    ?.activeClient?.uploadStatus?.at(0)?.oneTimeId;
                  const pdsId = onetimeIdFromActiveClient == null ? getRandomId(5) : onetimeIdFromActiveClient;
                  core?.getPiAccessToken(pdsId).then((result) => {
                    setPiTokenMessage(`token: ${result}`);
                  });
                  const utf8Encode: TextEncoder = new TextEncoder();
                  const exampleString: string = JSON.stringify(exampleData);
                  const gzippedPayload: Uint8Array | any = new Gzip(utf8Encode.encode(exampleString)).compress();

                  core
                    ?.uploadPi(
                      proposal.static?.content?.contentPurchase?.uploadUrl!,
                      pdsId,
                      proposal.proposalId,
                      gzippedPayload
                    )
                    .then((result) => {
                      setPiAddMessage(`uploaded ${result}`);
                    });
                });
            }}
          >
            ticket/issue
          </button>{" "}
        </td>
        <td colSpan={2}>{getProtobufEnumKey(ProposalClientStatus, proposal.activeClient?.proposalClientStatus)}</td>
      </tr>
      <tr>
        <td colSpan={4}>{proposal.proposalId}</td>
      </tr>
      <tr className="greydiv">
        <td colSpan={4}>
          <details>
            <summary>details</summary>
            PDS uploaded : {piAddMessage}
            <JSONTree data={proposal} />
          </details>
        </td>
      </tr>
    </>
  );
}
