import { MetamaskConnector } from "adminconsole/MetamaskConnector";
import { useState } from "react";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function ManualMyd2MigrationBoard({ metamaskConnector }: BoardProps) {
  const [didToMigrateData, setDidToMigrateData] = useState("");
  const [besuAddressToMigrateData, setBesuAddressToMigrateData] = useState("");

  return (
    <div>
      <details open>
        <summary>Manual migration when migration is partially failed</summary>
        DID
        <input
          type="text"
          value={didToMigrateData}
          onChange={(e) => {
            setDidToMigrateData(e.target.value);
          }}
        />
        &nbsp; besu address
        <input
          type="text"
          value={besuAddressToMigrateData}
          onChange={(e) => {
            setBesuAddressToMigrateData(e.target.value);
          }}
        />
        &nbsp;
        <button
          onClick={async () => {
            if (window.confirm("정말 Migration 하겠습니까? - 출석체크")) {
              console.log(`manual migration - attendance ${didToMigrateData}`);
              let result = await metamaskConnector.manualMigration(
                didToMigrateData,
                besuAddressToMigrateData,
                true,
                false,
                false,
                false
              );
              console.log(`result ${result}`);
            }
          }}
        >
          attendance
        </button>
        &nbsp;
        <button
          onClick={async () => {
            if (window.confirm("정말 Migration 하겠습니까? - Wordpress user")) {
              console.log(`manual migration - wordpress ${didToMigrateData}`);
              let result = await metamaskConnector.manualMigration(
                didToMigrateData,
                besuAddressToMigrateData,
                false,
                true,
                false,
                false
              );
              console.log(`result ${result}`);
            }
          }}
        >
          wordpress user
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <button
          onClick={async () => {
            if (window.confirm("**주의** 정말 Migration 하겠습니까? - mint point")) {
              console.log(`manual migration - mint point ${didToMigrateData}`);
              let result = await metamaskConnector.manualMigration(
                didToMigrateData,
                besuAddressToMigrateData,
                false,
                false,
                true,
                false
              );
              console.log(`result ${result}`);
            }
          }}
        >
          mint point
        </button>
        &nbsp;
        <button
          onClick={async () => {
            if (window.confirm("**주의** 정말 Migration 하겠습니까? - reward history")) {
              console.log(`manual migration - reward history ${didToMigrateData}`);
              let result = await metamaskConnector.manualMigration(
                didToMigrateData,
                besuAddressToMigrateData,
                false,
                false,
                false,
                true
              );
              console.log(`result ${result}`);
            }
          }}
        >
          reward history
        </button>
      </details>
    </div>
  );
}
