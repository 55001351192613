import { useState } from "react";

import ProposalBoard from "./ProposalBoard";

import { MockMobileCore } from "./MockMobileCore";
import RewardHistoryBoard from "./RewardHistoryBoard";
import GoodsListBoard from "./GoodsListBoard";
import CouponListBoard from "./CouponListBoard";
import GoodsLikeListBoard from "./GoodsLikeListBoard";
import SignalRequestBoard from "./SignalRequestBoard";
import { ProposalFiltering, VoIPP2PMessageDirection, VoIPSignalAction } from "protobuf/OpenApiServerV3DataTypes";

type MobileUiProps = {
  core: MockMobileCore | undefined;
};

export default function MockMobileUi({ core }: MobileUiProps) {
  let initialFiltering: ProposalFiltering | undefined;
  const [responseMessage, setResponseMessage] = useState("");
  const [invitationCode, setInvitationCode] = useState("");
  const [filteringProposal, setFilteringProposal] = useState(initialFiltering);
  const [receivedInvitationCode, setReceivedInvitationCode] = useState("");
  const [acceptInvitationResult, setAcceptInvitationResult] = useState("");
  const [attendanceResult, setAttendanceResult] = useState("");
  const [callRoomId, setCallRoomId] = useState("");
  const [calleeDid, setCalleeDid] = useState("");
  const [myd2GoodsCode, setMyd2GoodsCode] = useState("G00001981028");
  const [myd2GoodsPrice, setMyd2GoodsPrice] = useState("300");
  const [challengeId, setChallengeId] = useState("");
  const [myd2PublicKey, setMyd2PublicKey] = useState(
    "MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEXGgzdYAYYV9Om3AkCNupb2YYUUvBDwHXKSXRU7gRPGWiH0vt+k8/BsYo8/9CTqxrTzsK2WZtl+/L3MQoK27N1w=="
  );

  return (
    <div>
      <table>
        <thead>
          <tr>
            <td>Category</td>
            <td>Item</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Account</td>
            <td>
              <details open>
                <summary>Account API</summary>
                <table>
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Value</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Device registration (no-attestation case)</td>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            core?.deviceRegistration().then((resp) => {
                              console.log(resp);
                              setResponseMessage(resp);
                            });
                          }}
                        >
                          auth/nonce then auth/device-register
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>Get AccessToken</td>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            core?.getAccessToken(core?.myd3AuthConnector?.myd3.did.substring(11)).then((resp) => {
                              console.log(resp);
                              setResponseMessage(resp);
                            });
                          }}
                        >
                          auth/nonce then auth/token
                        </button>
                        &nbsp;
                        {core?.accessToken === undefined ? (
                          <span className="error">no token</span>
                        ) : (
                          <span className="valid">valid token</span>
                        )}
                        <details>
                          <summary>
                            <span>token</span>
                          </summary>
                          {core?.accessToken()}
                        </details>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </details>
            </td>
          </tr>
          <tr>
            <td>Blockchain keys</td>
            <td>
              <details>
                <summary>Blockchain keys</summary>
                <table>
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Value</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>DID</td>
                      <td>{core?.myd3()?.did}</td>
                    </tr>
                    <tr>
                      <td>Besu address</td>
                      <td>{core?.myd3()?.account.address.toLocaleLowerCase()}</td>
                    </tr>
                    <tr>
                      <td>2.0 Verkey</td>
                      <td>{core?.myd3()?.myd2?.verkey}</td>
                    </tr>
                    <tr>
                      <td>2.0 PrivateKey</td>
                      <td>{core?.myd3()?.myd2?.privateKey}</td>
                    </tr>
                    <tr>
                      <td>3.0 PublicKey</td>
                      <td>{core?.myd3()?.publicKey}</td>
                    </tr>
                    <tr>
                      <td>3.0 PrivateKey</td>
                      <td>{core?.myd3()?.privateKey}</td>
                    </tr>
                  </tbody>
                </table>
              </details>
            </td>
          </tr>
          <tr>
            <td>Proposal</td>
            <td>
              <button
                type="button"
                onClick={() => {
                  core?.listProposals().then((resp) => {
                    console.log(resp);
                    setResponseMessage(resp);
                    console.log(`DID filter ${core?.didFiltering}`);
                    setFilteringProposal(core?.didFiltering);
                  });
                }}
              >
                proposal/list
              </button>
              &nbsp;&nbsp;List proposal since {core?.lastListedTime}
              <div className="rcdiv">
                Visible proposals :
                {filteringProposal?.visibleProposals.map((p) => (
                  <>
                    <span key={p}>{p}</span>
                    <span> </span>
                  </>
                ))}
                <br />
                Forbidden proposals :
                {filteringProposal?.forbiddenProposals.map((p) => (
                  <>
                    <span key={p}>{p}</span>
                    <span> </span>
                  </>
                ))}
              </div>
              <details>
                <summary>Active proposals</summary>
                <div className="rcdiv">
                  <ProposalBoard core={core!} onlyActivated />
                  <br />
                </div>
              </details>
              <details>
                <summary>All proposals</summary>
                <div className="rcdiv">
                  <ProposalBoard core={core!} onlyActivated={false} />
                  <br />
                </div>
              </details>
            </td>
          </tr>
          <tr>
            <td>Reward history</td>
            <td>
              <details>
                <summary>Reward history API</summary>

                <button
                  type="button"
                  onClick={() => {
                    core?.listRewardHistory().then((resp) => {
                      console.log(resp);
                      setResponseMessage(resp);
                    });
                  }}
                >
                  /reward/history
                </button>
                <div className="rcdiv">
                  <RewardHistoryBoard core={core!} />
                  <br />
                </div>
              </details>
            </td>
          </tr>
          <tr>
            <td>Goods list</td>

            <td>
              <details>
                <summary>Goods list API</summary>
                <table>
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Value</td>
                      <td>최신순</td>
                      <td>인기순</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>List</td>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            core?.listGoods().then((resp) => {
                              console.log(resp);
                              setResponseMessage(resp);
                            });
                          }}
                        >
                          /store/goods/list
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            let resp = core!.changeSortType(1);
                            console.log(resp);
                            setResponseMessage(resp);
                          }}
                        >
                          ➡
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            let resp = core!.changeSortType(2);
                            console.log(resp);
                            setResponseMessage(resp);
                          }}
                        >
                          ➡
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="rcdiv">
                  <GoodsListBoard core={core!} />
                </div>
              </details>
            </td>
          </tr>
          <tr>
            <td>Goods like list</td>
            <td>
              <details>
                <summary>Goods like list API</summary>
                <table>
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Value</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>List</td>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            core?.listGoodsLike().then((resp) => {
                              console.log(resp);
                              setResponseMessage(resp);
                            });
                          }}
                        >
                          /store/goods/like/list
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="rcdiv">
                  <GoodsLikeListBoard core={core!} />
                  <br />
                </div>
              </details>
            </td>
          </tr>
          <tr>
            <td>Coupon list</td>
            <td>
              <details>
                <summary>Coupon list API</summary>
                <table>
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Value</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>List</td>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            core?.listCoupon().then((resp) => {
                              console.log(resp);
                              setResponseMessage(resp);
                            });
                          }}
                        >
                          /store/coupon/list
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="rcdiv">
                  <CouponListBoard core={core!} />
                  <br />
                </div>
              </details>
            </td>
          </tr>
          <tr>
            <td>Myd2 Coupon list</td>
            <td>
              <details>
                <summary>Myd2 Coupon list API</summary>
                <table>
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Value</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>List</td>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            core?.listMyd2Coupon().then((resp) => {
                              console.log(resp);
                              setResponseMessage(resp);
                            });
                          }}
                        >
                          /store/coupon/list-myd2
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="rcdiv">
                  <CouponListBoard core={core!} />
                  <br />
                </div>
              </details>
            </td>
          </tr>
          <tr>
            <td>Invite friend</td>
            <td>
              <details>
                <summary>Host mode</summary>
                <button
                  type="button"
                  onClick={async () => {
                    const code = await core?.generateInvitationCode();
                    setInvitationCode(code!);
                  }}
                >
                  Get a invitation code
                </button>
                &nbsp;&nbsp;{invitationCode}
              </details>
              <details>
                <summary>Guest mode</summary>
                <input
                  type="text"
                  value={receivedInvitationCode}
                  onChange={(e) => {
                    setReceivedInvitationCode(e.target.value);
                  }}
                />
                <button
                  type="button"
                  onClick={async () => {
                    const code = await core?.acceptInvitationCode(receivedInvitationCode);
                    setAcceptInvitationResult(code!);
                  }}
                >
                  Accept invitation
                </button>
                &nbsp;&nbsp;{acceptInvitationResult}
              </details>
            </td>
          </tr>
          <tr>
            <td>Attendance</td>
            <td>
              <button
                type="button"
                onClick={async () => {
                  const result = await core?.attendanceRollCall();
                  setAttendanceResult(result!);
                }}
              >
                Roll-call
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                onClick={async () => {
                  const result = await core?.attendanceSubmit();
                  setAttendanceResult(result!);
                }}
              >
                Submit
              </button>
              <br />
              {attendanceResult}
            </td>
          </tr>
          <tr>
            <td>VoIP</td>
            <td>
              <details>
                <summary>caller</summary>
                <table>
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Value</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            core?.startSignal(calleeDid).then((resp) => {
                              console.log(resp);
                              setResponseMessage(resp);
                              setCallRoomId(resp);
                            });
                          }}
                        >
                          call
                        </button>
                      </td>
                      <td>
                        call who:{" "}
                        <input
                          type="text"
                          name="calleeDid"
                          value={calleeDid}
                          onChange={(e) => setCalleeDid(e.target.value)}
                        />
                        <br />
                        room id :{callRoomId}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            core?.fetchSignal(callRoomId).then((resp) => {
                              console.log(resp);
                              setResponseMessage(resp);
                            });
                          }}
                        >
                          fetch
                        </button>
                      </td>
                      <td>
                        <div className="rcdiv">
                          <SignalRequestBoard core={core!} />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            core?.sendP2pSignal(callRoomId, VoIPP2PMessageDirection.TO_CALLEE).then((resp) => {
                              console.log(resp);
                              setResponseMessage(resp);
                            });
                          }}
                        >
                          open for caller
                        </button>
                      </td>
                      <td>{core?.socketState}</td>
                    </tr>
                    <tr>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            core?.hangUpSignal(callRoomId).then((resp) => {
                              console.log(resp);
                              setResponseMessage(resp);
                            });
                          }}
                        >
                          hang up
                        </button>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>

                {/* <div className="rcdiv">
                  <CouponListBoard core={core!} />
                  <br />
                </div> */}
              </details>

              <details>
                <summary>callee</summary>
                <table>
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Value</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            core?.answerSignal(callRoomId).then((resp) => {
                              console.log(resp);
                              setResponseMessage(resp);
                            });
                          }}
                        >
                          answer
                        </button>
                      </td>
                      <td>
                        room id{" "}
                        <input
                          type="text"
                          name="callRoomId"
                          value={callRoomId}
                          onChange={(e) => setCallRoomId(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            core?.fetchSignal(callRoomId).then((resp) => {
                              console.log(resp);
                              setResponseMessage(resp);
                            });
                          }}
                        >
                          fetch
                        </button>
                      </td>
                      <td>
                        <div className="rcdiv">
                          <SignalRequestBoard core={core!} />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            core?.sendP2pSignal(callRoomId, VoIPP2PMessageDirection.TO_CALLER).then((resp) => {
                              console.log(resp);
                              setResponseMessage(resp);
                            });
                          }}
                        >
                          open for callee
                        </button>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            core?.hangUpSignal(callRoomId).then((resp) => {
                              console.log(resp);
                              setResponseMessage(resp);
                            });
                          }}
                        >
                          hang up
                        </button>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>

                {/* <div className="rcdiv">
                  <CouponListBoard core={core!} />
                  <br />
                </div> */}
              </details>
            </td>
          </tr>
          <tr>
            <td>Challenge</td>
            <td>
              challengeId
              <input
                type="text"
                value={challengeId}
                onChange={(e) => {
                  setChallengeId(e.target.value);
                }}
              />
              <button
                type="button"
                onClick={() => {
                  core?.requestChallenge(Number(challengeId)).then((resp) => {
                    console.log(resp);
                  });
                }}
              >
                join
              </button>
              <br />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      Myd2 coupon test board
      <table>
        <thead>
          <tr>
            <td>Category</td>
            <td>Item</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Purchase</td>
            <td>
              <details open>
                <summary>Coupon API</summary>
                {/* <button
                  type="button"
                  onClick={() => {
                    core?.listMyd2Rewards().then((resp) => {
                      console.log(resp);
                      setResponseMessage(resp);
                    });
                  }}
                >
                  listToken
                </button>
                <br /> */}
                GoodsCode
                <input
                  type="text"
                  value={myd2GoodsCode}
                  onChange={(e) => {
                    setMyd2GoodsCode(e.target.value);
                  }}
                />
                Price
                <input
                  type="text"
                  value={myd2GoodsPrice}
                  onChange={(e) => {
                    setMyd2GoodsPrice(e.target.value);
                  }}
                />
                Public key
                <input
                  type="text"
                  value={myd2PublicKey}
                  onChange={(e) => {
                    setMyd2PublicKey(e.target.value);
                  }}
                />
                <br />
                <button
                  type="button"
                  onClick={() => {
                    core?.purchaseMyd2Coupon(myd2GoodsCode, Number(myd2GoodsPrice), myd2PublicKey).then((resp) => {
                      console.log(resp);
                      setResponseMessage(resp);
                    });
                  }}
                >
                  Purchase
                </button>
              </details>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div className="rcdiv">{responseMessage}</div>
    </div>
  );
}
