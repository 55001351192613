import { Purpose } from "protobuf/DwSolidity";
import {
  AttendanceContent,
  Content,
  ContentDescription,
  ProposalStatic,
  RewardPolicy,
} from "protobuf/OpenApiServerV3DataTypes";
import { useState } from "react";
import { JSONTree } from "react-json-tree";
import { MetamaskConnector } from "./MetamaskConnector";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function RegisterAttendanceProposal({ metamaskConnector }: BoardProps) {
  const newProposalStatic = ProposalStatic.create();
  newProposalStatic.purpose = Purpose.PURPOSE_ATTENDANCE;
  const newAttendanceContent = AttendanceContent.create();
  newProposalStatic.attendanceContent = newAttendanceContent;
  newProposalStatic.attendanceContent.dailyMissionReward = 10;
  newProposalStatic.attendanceContent.weeklyMissionReward = 230;
  newProposalStatic.content = Content.create();
  newProposalStatic.content.contentDescription = ContentDescription.create();

  const now: string = new Date().toISOString();
  newProposalStatic.notBefore = now;
  newProposalStatic.notAfter = now;
  newProposalStatic.rewardPolicy = RewardPolicy.create();

  const [proposalStatic, setProposalStatic] = useState(newProposalStatic);

  const [notBefore, setNotBefore] = useState(now);
  const [notAfter, setNotAfter] = useState(now);
  const [imageUrl, setImageUrl] = useState("");
  const [landingUrl, setLandingUrl] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dailyMissionReward, setDailyMissionReward] = useState(newProposalStatic.attendanceContent.dailyMissionReward);
  const [weeklyMissionReward, setWeeklyMissionReward] = useState(
    newProposalStatic.attendanceContent.weeklyMissionReward
  );

  return (
    <div>
      <table className="bluetable">
        <thead>
          <tr>
            <th>field name</th>
            <th>value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>purpose</td>
            <td>{proposalStatic.purpose}</td>
          </tr>
          <tr>
            <td>notBefore</td>
            <td>
              <input
                type="text"
                value={notBefore}
                onChange={(e) => {
                  proposalStatic.notAfter = e.target.value;
                  setNotBefore(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>notAfter</td>
            <td>
              <input
                type="text"
                value={notAfter}
                onChange={(e) => {
                  proposalStatic.notAfter = e.target.value;
                  setNotAfter(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>attendanceContent.imageUrl</td>
            <td>
              <input
                type="text"
                value={imageUrl}
                onChange={(e) => {
                  proposalStatic.attendanceContent!.imageUrl = e.target.value;
                  setImageUrl(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>attendanceContent.landingUrl</td>
            <td>
              <input
                type="text"
                value={landingUrl}
                onChange={(e) => {
                  proposalStatic.attendanceContent!.landingUrl = e.target.value;
                  setLandingUrl(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>attendanceContent.title</td>
            <td>
              <input
                type="text"
                value={title}
                onChange={(e) => {
                  proposalStatic.attendanceContent!.title = e.target.value;
                  proposalStatic.content!.contentDescription!.title = e.target.value;
                  setTitle(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>attendanceContent.description</td>
            <td>
              <input
                type="text"
                value={description}
                onChange={(e) => {
                  proposalStatic.attendanceContent!.description = e.target.value;
                  setDescription(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>attendanceContent.dailyMissionReward</td>
            <td>
              <input
                type="text"
                value={dailyMissionReward}
                onChange={(e) => {
                  proposalStatic.rewardPolicy!.amount = Number(e.target.value);
                  proposalStatic.attendanceContent!.dailyMissionReward = Number(e.target.value);
                  setDailyMissionReward(Number(e.target.value));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>attendanceContent.weeklyMissionReward</td>
            <td>
              <input
                type="text"
                value={weeklyMissionReward}
                onChange={(e) => {
                  proposalStatic.attendanceContent!.weeklyMissionReward = Number(e.target.value);
                  setWeeklyMissionReward(Number(e.target.value));
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
        onClick={async () => {
          if (window.confirm("정말 등록 하시겠습니까?")) {
            console.log("Trying to register...");
            let result = await metamaskConnector.registerSpecialProposal(proposalStatic);
            console.log(`result ${result}`);
          }
        }}
      >
        Register the proposal now!
      </button>
      <br />
      <JSONTree data={proposalStatic} />
      <br />
    </div>
  );
}
