import { useState } from "react";
import { MetamaskConnector } from "./MetamaskConnector";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function RevokeSpecialProposal({ metamaskConnector }: BoardProps) {
  const [proposalIdToRevoke, setProposalIdToRevoke] = useState("");

  return (
    <div>
      <input
        type="text"
        value={proposalIdToRevoke}
        onChange={(e) => {
          setProposalIdToRevoke(e.target.value);
        }}
      />
      <button
        onClick={async () => {
          console.log("Trying to revoke...");
          let result = await metamaskConnector.revokeSpecialProposal(proposalIdToRevoke);
          console.log(`result ${result}`);
        }}
      >
        Revoke the proposal now!
      </button>
      <br />
      <br />
    </div>
  );
}
