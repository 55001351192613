import { MockMobileCore } from "./MockMobileCore";

type BoardProps = {
  core: MockMobileCore;
};

export default function GoodsListBoard({ core }: BoardProps) {
  let likeList = core.likeList !== undefined ? JSON.parse(JSON.stringify(core.likeList)) : undefined;

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>Code</td>
          </tr>

          {likeList?.map((p: any) => (
            <tr key={`"${p}"`}>
              <td>{`"${p}"`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
