import sodium from "libsodium-wrappers";
import bs58 from "bs58";
import { toHexString, fromHexString } from "./blockchainutil";

export class Myd2Blockchain {
  DID_PREFIX: string = "did:snplab:" as const;

  document = {};

  constructor(public did: string = "", public verkey: string = "", public privateKey: string = "") {
    // do nothing.
  }

  public generateIfNeeded(): Promise<string> {
    if (this.did === "" || this.verkey === "" || this.privateKey === "") {
      return this.generateDid();
    }
    this.setDocument();
    return new Promise<string>((resolve) => {
      resolve("OK");
    });
  }

  public async generateDid(): Promise<string> {
    await sodium.ready;

    return new Promise<string>((resolve) => {
      const pair = sodium.crypto_sign_keypair();
      this.did = this.DID_PREFIX + bs58.encode(pair.publicKey.slice(0, 16));
      this.verkey = bs58.encode(pair.publicKey);
      this.privateKey = bs58.encode(pair.privateKey);

      console.log(`DID: ${this.did}`);
      console.log(`Verkey: ${this.verkey}`);
      this.setDocument();
      resolve("OK");
    });
  }

  setDocument() {
    this.document = {
      "@context": "https://www.w3.org/ns/did/v1",
      id: this.did,
      verificationMethod: {
        id: `${this.did}#verkey`,
        type: "Ed25519VerificationKey2018",
        publicKeyBase58: this.verkey,
        controller: this.did,
      },
      authentication: `${this.did}#verkey`,
    };
  }
}
