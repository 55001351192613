import {
  fetchAllManagedAddress,
  fetchAllSelfManagedAddress,
  fetchNonce,
  fetchRewardDeclare,
  fetchWalletBasedToken,
  fetchCompanies,
  fetchKeyPair,
  fetchNavigator,
  fetchProposalList,
  fetchRegisterSpecialProposal,
  fetchRevokeSpecialProposal,
  fetchRegisterDidFiltering,
  fetchRevokeDidFiltering,
  fetchApplyDidFilteringAddress,
  fetchLookupUser,
  fetchBulkMint,
  fetchHealthCheck,
  fetchSaveBuildableResource,
  fetchListBuildableResource,
  fetchPublishBuildableResource,
  fetchBurnPointList,
  fetchQueryBurnPointList,
  fetchGrantSelfManagedAddress,
  fetchRevokeSelfManagedAddress,
  fetchRefundSpecialProposal,
  fetchUploadBuildableResource,
  fetchCouponTask,
  fetchElasticSearchQueryEvaluation,
  fetchDailyReport,
  fetchProposalReport,
  fetchProposalCleanRedis,
  fetchDidBesuAddressInfo,
  fetchBreakAwayDid,
  fetchManagerQueryRewardHistory,
  fetchManagerMyd2CouponList,
  fetchDidReport,
  fetchAdminProposalUpdate,
  fetchManualMyd2Migration,
  fetchUserMigrationReport,
  compensationForMissingPoints,
} from "api";
import {
  DeclareRewardTransactionContentRequest,
  DeclareRewardTransactionContentResponse,
  GetAllManagedAddressesResponse,
  GetAllSelfManagedAddressesResponse,
  GetAuthNonceRequest,
  GetAuthNonceResponse,
  GetAuthWalletBasedAccessTokenRequest,
  GetAuthWalletBasedAccessTokenResponse,
  CreateWalletKeyPairRequest,
  CreateWalletKeyPairResponse,
  GetCompaniesResponse,
  Companies,
  ManagedAddress,
  SelfManagedAddress,
  ListProposalRequest,
  ListProposalResponse,
  BlockchainRole,
  CreateSpecialProposalRequest,
  CreateSpecialProposalResponse,
  RegisterDidFilteringRequest,
  DidFilteringMode,
  RegisterDidFilteringResponse,
  LookupUsersRequest,
  LookupUsersResponse,
  SimpleUserInfo,
  BulkMintRequest,
  BulkMintResponse,
  HomeBannerConfig,
  AppVersionConfig,
  BurnPointResponse,
  QueryBurnPointCandidatesResponse,
  BurnPointRequest,
  QueryBurnPointsCandidatesRequest,
  CreateOrUpdateSelfManagedWalletRequest,
  CreateOrUpdateSelfManagedWalletResponse,
  BuildableResourceListReqeust,
  BuildableResourceListResponse,
  BuildableResourcePublishResponse,
  BuildableResourcePublishRequest,
  BuildableResourceSaveResponse,
  BuildableResourceSaveRequest,
  BuildableResourceType,
  CouponManagerRequest,
  CouponManagerRequestType,
  ApplicationResource,
  ElasticSearchQueryRunnerRequest,
  ElasticSearchQueryTemplate,
  CouponRequestParameterType,
  CouponManagerResponse,
  ElasticSearchQueryRunnerResponse,
  DailyReportRequest,
  DailyReportResponse,
  ProposalReport,
  ProposalReportRequest,
  ProposalReportResponse,
  TicketReportInDb,
  ProposalManagementRedisCleanupRequest,
  ProposalManagementRedisCleanupResponse,
  DidBesuAddressInfoRequest,
  DidBesuAddressInfoResponse,
  BreakAwayDidRequest,
  BreakAwayDidResponse,
  ListRewardHistoryResponse,
  ListRewardHistoryRequest,
  ListRewardHistoryRequestType,
  ListMyd2CouponRequest,
  ListCouponRequest,
  ListMyd2CouponResponse,
  DidReportResponse,
  DidReportRequest,
  AdminProposalUpdateRequest,
  AdminProposalUpdateResponse,
  ManualMyd2MigrationResponse,
  ManualMyd2MigrationRequest,
  UserMigrationReportResponse,
  UserMigrationReportRequest,
} from "protobuf/OpenApiServerV3";
import {
  Proposal,
  ProposalDataUploadStatus,
  ProposalStatic,
  ProposalStatus,
  RewardTransactionContentForApp,
} from "protobuf/OpenApiServerV3DataTypes";
import {
  RewardTransactionContent,
  RewardTransactionContentFromPlatformOperator,
  RewardTransactionOperatorActionType,
} from "protobuf/OpenApiServerV3Middleware";
import { ethers, isAddress } from "ethers";
import { Purpose } from "protobuf/DwSolidity";
import { CommonStatusCode } from "protobuf/CommonStatusCode";
import { rewardContractAbi } from "contracts/RewardContractAbi";
import { navigatorContractAbi } from "contracts/NavigatorContractAbi";
import { getProtobufEnumKey } from "utils/storage";
import { rbacContractAbi } from "contracts/RbacContractAbi";
import { integratedLogicContractAbi } from "contracts/IntegratedLogicContractAbi";
import { didStorageContractAbi } from "contracts/DidStorageContractAbi";
import { proposalStorageContractAbi } from "contracts/ProposalStorageContractAbi";
import { toHexString, uint8ArrayToBase64 } from "authconnector/blockchainutil";

export type OverviewItemProps = {
  role: BlockchainRole;
  title: string;
  subTitle: string;
  itemId: string;
  itemAddress: string;
  ownerId: string;
  ownerAddress: string;
  ownerName: string;
  netBalance: number;
  refundableDeposit: number;
  allowance: bigint;
  coinBalance: bigint;
  notBefore: string;
  notAfter: string;
  status: ProposalStatus;
  proposal?: Proposal;
  isDeleted?: Boolean;
};

export type SmartContractItemLink = {
  leftContract: string;
  leftContractAddress: string;
  leftContractDeployedDate: string;
  relationTitle: string;
  rightContract: string;
  rightContractAddress: string;
  rightContractDeployedDate: string;
  verification: string;
};

export type SummarizedProposalReport = {
  proposalReport: ProposalReport;
  issuedButNotUploded: TicketReportInDb[];
  uploadedButNotRewarded: TicketReportInDb[];
  countIssued?: number;
  countUploaded?: number;
  countRewarded?: number;
  statAvgRedisTime: number;
  statAvgIssueToUpload: number;
  statAvgIssueToReward: number;
  statRedisTime: number[];
  statIssueToReward: number[];
  statIssueToUpload: number[];
};

function toIsoString(date: Date) {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";
  const pad = (num: number) => (num < 10 ? "0" : "") + num;

  let output = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
  output += `T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
  output += `${dif}${pad(Math.floor(Math.abs(tzo) / 60))}:${pad(Math.abs(tzo) % 60)}`;
  return output;
}

export class MetamaskConnector {
  accounts: any;
  walletAddress: string = "";
  challengePrefix: string = "Login myd.world operator; nonce: ";
  accessToken: string = "";

  // source data
  navigatorAddress: string | undefined;
  selfManagedAddresses: SelfManagedAddress[] = [];
  managedAddresses: ManagedAddress[] = [];
  companies: Companies[] = [];
  proposals: Proposal[] = [];
  companyIdCompanyMap: Map<string, Companies> = new Map<string, Companies>();
  proposalIdProposalMap: Map<string, Proposal> = new Map<string, Proposal>();

  // overview
  specialUsersOverviewItems: OverviewItemProps[] = [];
  companyOverviewItems: OverviewItemProps[] = [];
  proposalOverviewItems: OverviewItemProps[] = [];

  // smart contract data
  smartContractItemLinks: SmartContractItemLink[] = [];

  async connectWallet() {
    if (window.ethereum) {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      this.walletAddress = accounts[0].toLowerCase();
    }
  }

  async getNonce(): Promise<string> {
    const getAuthNonceRequest: GetAuthNonceRequest = GetAuthNonceRequest.create();
    getAuthNonceRequest.besuAddress = this.walletAddress;

    const paramBuf = GetAuthNonceRequest.encode(getAuthNonceRequest).finish();
    let res = await fetchNonce(paramBuf);
    const nonceResponse = GetAuthNonceResponse.decode(res);

    return nonceResponse.nonce;
  }

  async login(): Promise<string> {
    if (window.ethereum) {
      let nonce = await this.getNonce();

      let challenge = this.challengePrefix + nonce;
      const signature = await window.ethereum.request({
        method: "personal_sign",
        params: [`${challenge}`, `${this.walletAddress}`],
      });

      const getAuthWalletBasedAccessTokenRequest: GetAuthWalletBasedAccessTokenRequest =
        GetAuthWalletBasedAccessTokenRequest.create();
      getAuthWalletBasedAccessTokenRequest.besuAddress = this.walletAddress;
      getAuthWalletBasedAccessTokenRequest.nonce = nonce;
      getAuthWalletBasedAccessTokenRequest.signature = signature;

      const paramBuf = GetAuthWalletBasedAccessTokenRequest.encode(getAuthWalletBasedAccessTokenRequest).finish();
      let res = await fetchWalletBasedToken(paramBuf);
      const tokenResponse = GetAuthWalletBasedAccessTokenResponse.decode(res);
      this.accessToken = tokenResponse.accessToken;

      console.log(tokenResponse);

      const navigatorRes = await fetchNavigator(new Uint8Array(0), this.accessToken);
      this.navigatorAddress = new TextDecoder().decode(navigatorRes);
      console.log(this.navigatorAddress);

      return tokenResponse.accessToken;
    }
    return "";
  }

  async getSelfManagedAddress(): Promise<string> {
    let res = await fetchAllSelfManagedAddress(new Uint8Array(0), this.accessToken);
    let response = GetAllSelfManagedAddressesResponse.decode(res);

    this.selfManagedAddresses = response.selfManagedAddresses;
    return "";
  }

  async getManagedAddress(): Promise<string> {
    let res = await fetchAllManagedAddress(new Uint8Array(0), this.accessToken);
    let response = GetAllManagedAddressesResponse.decode(res);

    this.managedAddresses = response.managedAddresses;
    return "";
  }

  async getCompanies(): Promise<string> {
    let res = await fetchCompanies(new Uint8Array(0), this.accessToken);
    let response = GetCompaniesResponse.decode(res);

    this.companies = response.companies;
    return "";
  }

  async createKeyPair(did: string): Promise<string> {
    const createWalletKeyPairRequest = CreateWalletKeyPairRequest.create();
    createWalletKeyPairRequest.did = did;

    const paramBuf = CreateWalletKeyPairRequest.encode(createWalletKeyPairRequest).finish();

    let response = await fetchKeyPair(paramBuf, this.accessToken);
    const decodedRes = CreateWalletKeyPairResponse.decode(response);

    if (decodedRes.isCreated) {
      return decodedRes.address;
    }
    return "";
  }

  async manualMint(recipeint: string, amount: number, title: string): Promise<string> {
    const declareRewardTransactionContentRequest: DeclareRewardTransactionContentRequest =
      DeclareRewardTransactionContentRequest.create();

    declareRewardTransactionContentRequest.blockchainRequestId = this.timestampToUint8Array();

    declareRewardTransactionContentRequest.content = RewardTransactionContent.create();
    const rewardTransactionContent: RewardTransactionContent = RewardTransactionContent.create();
    const rewardTransactionContentForApp: RewardTransactionContentForApp = RewardTransactionContentForApp.create();
    rewardTransactionContentForApp.amount = amount;
    rewardTransactionContentForApp.category = "VOC";
    rewardTransactionContentForApp.title = title;
    rewardTransactionContentForApp.createdAt = new Date().toISOString();

    const rewardTransactionContentFromPlatformOperator: RewardTransactionContentFromPlatformOperator =
      RewardTransactionContentFromPlatformOperator.create();
    rewardTransactionContentFromPlatformOperator.action =
      RewardTransactionOperatorActionType.REWARD_TRANSACTION_OPERATOR_ACTION_MINT;
    rewardTransactionContentFromPlatformOperator.amountReceiverBesuAddress = recipeint;
    rewardTransactionContentFromPlatformOperator.transactionOwnerBesuAddress = this.walletAddress;

    rewardTransactionContent.app = rewardTransactionContentForApp;
    rewardTransactionContent.operator = rewardTransactionContentFromPlatformOperator;

    declareRewardTransactionContentRequest.content = rewardTransactionContent;

    let res = await fetchRewardDeclare(
      DeclareRewardTransactionContentRequest.encode(declareRewardTransactionContentRequest).finish(),
      this.accessToken
    );

    let response = DeclareRewardTransactionContentResponse.decode(res);
    console.log(response);
    if (response.code !== CommonStatusCode.STATUS_SUCCESS) {
      return "failed to declare";
    }

    // mint
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(await this.getRewardContractAddress(), rewardContractAbi, signer);
    const txn = await contract.mint(recipeint, amount, declareRewardTransactionContentRequest.blockchainRequestId);
    txn.wait();
    console.log(txn.hash);

    const value = await contract.balanceOf(recipeint);
    console.log(value);
    return value;
  }

  async manualBurn(sender: string, amount: number, title: string): Promise<string> {
    const declareRewardTransactionContentRequest: DeclareRewardTransactionContentRequest =
      DeclareRewardTransactionContentRequest.create();

    declareRewardTransactionContentRequest.blockchainRequestId = this.timestampToUint8Array();
    declareRewardTransactionContentRequest.content = RewardTransactionContent.create();
    const rewardTransactionContent: RewardTransactionContent = RewardTransactionContent.create();
    const rewardTransactionContentForApp: RewardTransactionContentForApp = RewardTransactionContentForApp.create();
    rewardTransactionContentForApp.amount = -amount;
    rewardTransactionContentForApp.category = "VOC";
    rewardTransactionContentForApp.title = title;
    rewardTransactionContentForApp.createdAt = new Date().toISOString();

    let snpalbBisAddress = "";
    await this.getManagedAddress();
    const managedAddressPromises = this.managedAddresses.map(async (managedAddress, index) => {
      if (managedAddress.role === BlockchainRole.SNPLAB_BIS) {
        snpalbBisAddress = managedAddress.address;
      }
    });

    const rewardTransactionContentFromPlatformOperator: RewardTransactionContentFromPlatformOperator =
      RewardTransactionContentFromPlatformOperator.create();
    rewardTransactionContentFromPlatformOperator.action =
      RewardTransactionOperatorActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_BURN;
    rewardTransactionContentFromPlatformOperator.amountReceiverBesuAddress = snpalbBisAddress;
    rewardTransactionContentFromPlatformOperator.transactionOwnerBesuAddress = this.walletAddress;
    rewardTransactionContentFromPlatformOperator.amountSenderBesuAddress = sender;

    rewardTransactionContentFromPlatformOperator.transactionOwnerBesuAddress = this.walletAddress;

    rewardTransactionContent.app = rewardTransactionContentForApp;
    rewardTransactionContent.operator = rewardTransactionContentFromPlatformOperator;

    declareRewardTransactionContentRequest.content = rewardTransactionContent;

    let res = await fetchRewardDeclare(
      DeclareRewardTransactionContentRequest.encode(declareRewardTransactionContentRequest).finish(),
      this.accessToken
    );

    let response = DeclareRewardTransactionContentResponse.decode(res);
    console.log(response);
    if (response.code !== CommonStatusCode.STATUS_SUCCESS) {
      return "failed to declare";
    }

    // burn
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(await this.getRewardContractAddress(), rewardContractAbi, signer);
    const txn = await contract.operatorTransfer(
      sender,
      snpalbBisAddress,
      amount,
      declareRewardTransactionContentRequest.blockchainRequestId
    );
    txn.wait();
    console.log(txn.hash);

    const value = await contract.balanceOf(sender);
    console.log(value);
    return value;
  }

  splitUsers(wholeDidList: string): string[] {
    let result: string[] = [];
    const didArray = this.splitTextList(wholeDidList);

    didArray.forEach((v) => {
      if (v !== "") {
        result.push(v);
      }
    });
    return result;
  }

  async lookupUsers(didList: string[]): Promise<SimpleUserInfo[]> {
    let lookupUsersRequest: LookupUsersRequest = LookupUsersRequest.create();
    lookupUsersRequest.dids = didList;

    let res = await fetchLookupUser(LookupUsersRequest.encode(lookupUsersRequest).finish(), this.accessToken);
    return LookupUsersResponse.decode(res).users;
  }

  async bulkMint(userInfo: SimpleUserInfo[], example: RewardTransactionContent): Promise<BulkMintResponse> {
    const bulkMintRequest: BulkMintRequest = BulkMintRequest.create();

    userInfo.forEach((u) => {
      if (example.app === undefined) {
        throw Error("rewardTransactionContentForApp must not be undefined");
      }
      if (u.besuAddress === undefined) {
        throw Error("rewardTransactionContentFromPlatformOperator.amountReceiverBesuAddress must not be undefined");
      }

      const declareRewardTransactionContentRequest: DeclareRewardTransactionContentRequest =
        DeclareRewardTransactionContentRequest.create();

      declareRewardTransactionContentRequest.blockchainRequestId = this.timestampToUint8Array();

      declareRewardTransactionContentRequest.content = structuredClone(example);
      if (declareRewardTransactionContentRequest.content === undefined) {
        throw Error("declareRewardTransactionContentRequest.content must not be undefined");
      }
      const rewardTransactionContentFromPlatformOperator: RewardTransactionContentFromPlatformOperator =
        RewardTransactionContentFromPlatformOperator.create();
      rewardTransactionContentFromPlatformOperator.action =
        RewardTransactionOperatorActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_BULK_MINT;
      rewardTransactionContentFromPlatformOperator.amountReceiverBesuAddress = u.besuAddress!;
      rewardTransactionContentFromPlatformOperator.transactionOwnerBesuAddress = this.walletAddress;
      declareRewardTransactionContentRequest.content.operator = rewardTransactionContentFromPlatformOperator;
      declareRewardTransactionContentRequest.content.did = u.did;
      bulkMintRequest.reqs.push(declareRewardTransactionContentRequest);
    });

    let res = await fetchBulkMint(BulkMintRequest.encode(bulkMintRequest).finish(), this.accessToken);

    return BulkMintResponse.decode(res);
  }

  async getRewardContractAddress(): Promise<string> {
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    console.log(`navigator ${this.navigatorAddress!}`);
    const contract = new ethers.Contract(this.navigatorAddress!, navigatorContractAbi, signer);
    const value = await contract.getContract(Purpose.PURPOSE_REWARD);

    return value;
  }

  async getRbacContractAddress(): Promise<string> {
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(this.navigatorAddress!, navigatorContractAbi, signer);
    const value = await contract.getContract(Purpose.PURPOSE_RBAC);

    return value;
  }

  async getDidStorageAddress(): Promise<string> {
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    console.log(`navigator ${this.navigatorAddress!}`);
    const contract = new ethers.Contract(this.navigatorAddress!, navigatorContractAbi, signer);
    const logicContractAddress = await contract.getContract(Purpose.PURPOSE_LOGIC);
    console.log(`logic ${logicContractAddress}`);
    const logicContract = new ethers.Contract(logicContractAddress, integratedLogicContractAbi, signer);
    return logicContract.didStorage();
  }

  async getProposalStorageAddress(): Promise<string> {
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    console.log(`navigator ${this.navigatorAddress!}`);
    const contract = new ethers.Contract(this.navigatorAddress!, navigatorContractAbi, signer);
    const logicContractAddress = await contract.getContract(Purpose.PURPOSE_LOGIC);
    console.log(`logic ${logicContractAddress}`);
    const logicContract = new ethers.Contract(logicContractAddress, integratedLogicContractAbi, signer);
    return logicContract.proposalStorage();
  }

  timestampToUint8Array(): Uint8Array {
    let randomArray = new Uint8Array(24);
    crypto.getRandomValues(randomArray);

    const buffer = new ArrayBuffer(8);
    const view = new DataView(buffer);
    view.setBigUint64(0, BigInt(new Date().valueOf()), false);

    const timestampArray = new Uint8Array(buffer);
    return this.concatTypedArrays2(timestampArray, randomArray);
  }

  private concatTypedArrays2(a: Uint8Array, b: Uint8Array) {
    const r = new Uint8Array(a.length + b.length);
    r.set(a, 0);
    r.set(b, a.length);
    return r;
  }

  async getPoint(owner: string): Promise<string> {
    if (isAddress(owner)) {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(await this.getRewardContractAddress(), rewardContractAbi, signer);
        const value = await contract.balanceOf(owner);
        // console.log(value);
        return value;
      } catch (error) {
        console.log("Probably Metamask-network is not proper...");
        console.log(error);
        return "0";
      }
    }
    return "0";
  }

  async getBlockchainNonce(owner: string): Promise<number> {
    if (isAddress(owner)) {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.BrowserProvider(window.ethereum);
        const nonce = await provider.getTransactionCount(owner);
        return nonce;
      } catch (error) {
        console.log("Probably Metamask-network is not proper...");
        console.log(error);
        return 0;
      }
    }
    return 0;
  }

  async getApprovedTotalAllowance(owner: string): Promise<string> {
    if (isAddress(owner)) {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(await this.getRewardContractAddress(), rewardContractAbi, signer);
      const value = await contract.totalAllowance(owner);
      // console.log(value);
      return value;
    }
    return "0";
  }

  async getApprovedAllowance(owner: string, spender: string): Promise<bigint> {
    if (isAddress(owner) && isAddress(spender)) {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(await this.getRewardContractAddress(), rewardContractAbi, signer);
      const value = await contract.allowance(owner, spender);
      console.log(value);
      return value;
    }
    return BigInt(0);
  }

  async operatorTransferFrom(
    operatorTransferFromSender: string,
    operatorTransferFromReceiver: string,
    amount: number
  ): Promise<string> {
    if (isAddress(operatorTransferFromSender) && isAddress(operatorTransferFromReceiver)) {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(await this.getRewardContractAddress(), rewardContractAbi, signer);
      const blockchainRequestId = this.timestampToUint8Array();
      const value = await contract.operatorTransfer(
        operatorTransferFromSender,
        operatorTransferFromReceiver,
        amount,
        blockchainRequestId
      );
      console.log(value);
      return value;
    }
    return "invalid addresses";
  }

  async getDid(didToRetrieve: string): Promise<string> {
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(await this.getDidStorageAddress(), didStorageContractAbi, signer);
    const value = await contract.didBesuAddressMap(didToRetrieve);
    console.log(value);
    return value;
  }

  async getCoinBalance(owner: string): Promise<bigint> {
    if (isAddress(owner)) {
      // console.log(`getCoinBalance ${owner}`);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.BrowserProvider(window.ethereum);
      return provider.getBalance(owner);
    }
    return BigInt(0);
  }

  async hasRole(owner: string, role: number): Promise<boolean> {
    if (isAddress(owner)) {
      // console.log(`getCoinBalance ${owner}`);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(await this.getRbacContractAddress(), rbacContractAbi, signer);
      const value = await contract.hasRole(role, owner);
      return value;
    }
    return false;
  }

  async grantRole(owner: string, role: number): Promise<boolean> {
    if (isAddress(owner)) {
      // console.log(`getCoinBalance ${owner}`);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(await this.getRbacContractAddress(), rbacContractAbi, signer);
      const value = await contract.grantRole(role, owner);
      return value;
    }
    return false;
  }

  async revokeRole(owner: string, role: number): Promise<boolean> {
    if (isAddress(owner)) {
      // console.log(`getCoinBalance ${owner}`);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(await this.getRbacContractAddress(), rbacContractAbi, signer);
      const value = await contract.revokeRole(role, owner);
      return value;
    }
    return false;
  }

  async grantMiddlewareRole(owner: string, role: number, username: string, team: string): Promise<boolean> {
    if (isAddress(owner) || username.length === 0 || team.length === 0) {
      let createOrUpdateSelfManagedWalletRequest: CreateOrUpdateSelfManagedWalletRequest =
        CreateOrUpdateSelfManagedWalletRequest.create();

      createOrUpdateSelfManagedWalletRequest.wallet = SelfManagedAddress.create();
      createOrUpdateSelfManagedWalletRequest.wallet.address = owner;
      createOrUpdateSelfManagedWalletRequest.wallet.role = role;
      createOrUpdateSelfManagedWalletRequest.wallet.createdAt = new Date().toISOString();
      createOrUpdateSelfManagedWalletRequest.wallet.team = team;
      createOrUpdateSelfManagedWalletRequest.wallet.username = username;

      let paramBuf = CreateOrUpdateSelfManagedWalletRequest.encode(createOrUpdateSelfManagedWalletRequest).finish();
      let res = await fetchGrantSelfManagedAddress(paramBuf, this.accessToken);

      let response = CreateOrUpdateSelfManagedWalletResponse.decode(res);
      console.log(response);
      return response.code === CommonStatusCode.STATUS_SUCCESS;
    }
    return false;
  }

  async revokeMiddlewareRole(owner: string): Promise<boolean> {
    if (isAddress(owner)) {
      let res = await fetchRevokeSelfManagedAddress(owner, new Uint8Array(0), this.accessToken);
      console.log(res);
      return true;
    }
    return false;
  }

  async loadProposals() {
    const listProposalRequest: ListProposalRequest = ListProposalRequest.create();
    listProposalRequest.fetchStatic = true;
    listProposalRequest.fetchActiveGlobal = true;
    listProposalRequest.fetchActiveClient = false;

    const paramBuf = ListProposalRequest.encode(listProposalRequest).finish();
    let res = await fetchProposalList(paramBuf, this.accessToken);
    const decodedRes = ListProposalResponse.decode(res);
    this.proposals = decodedRes.proposals;

    await this.proposals.map((proposal, index) => {
      this.proposalIdProposalMap.set(proposal.proposalId, proposal);
    });
  }

  async loadSpecialUsersOverview() {
    await this.getSelfManagedAddress();
    await this.getManagedAddress();

    this.specialUsersOverviewItems = [];

    const selfManagedAddressPromises = this.selfManagedAddresses.map(async (selfManagedAddress, index) => {
      if (
        selfManagedAddress.role === BlockchainRole.ADMIN ||
        selfManagedAddress.role === BlockchainRole.PLATFORM_MANAGER ||
        selfManagedAddress.role === BlockchainRole.PLATFORM_OPERATOR
      ) {
        // recognized item
        const netBalance = Number(await this.getPoint(selfManagedAddress.address));
        const refundableDeposit = 0; // Number(await this.getApprovedTotalAllowance(selfManagedAddress.address));
        const coinBalance = await this.getCoinBalance(selfManagedAddress.address);
        const newItem: OverviewItemProps = {
          role: selfManagedAddress.role,
          title: selfManagedAddress.username,
          subTitle: selfManagedAddress.team,
          itemId: "",
          itemAddress: selfManagedAddress.address,
          ownerId: "",
          ownerAddress: "",
          ownerName: "",
          netBalance,
          refundableDeposit,
          coinBalance,
          allowance: BigInt(0),
          notBefore: "",
          notAfter: "",
          status: ProposalStatus.PROPOSAL_UNKNOWN,
          isDeleted: selfManagedAddress.isDeleted,
        };
        this.specialUsersOverviewItems.push(newItem);
      }
    });

    const managedAddressPromises = this.managedAddresses.map(async (managedAddress, index) => {
      if (
        managedAddress.role === BlockchainRole.ROLE_MAINTAINER ||
        managedAddress.role === BlockchainRole.GAS_STATIONER ||
        managedAddress.role === BlockchainRole.ENDORSER ||
        managedAddress.role === BlockchainRole.SNPLAB_BIS ||
        managedAddress.role === BlockchainRole.BIS_MINTER ||
        managedAddress.role === BlockchainRole.BIS_COUPON
      ) {
        // recognized item
        const netBalance = Number(await this.getPoint(managedAddress.address));
        const refundableDeposit = 0; // Number(await this.getApprovedTotalAllowance(managedAddress.address));
        const coinBalance = await this.getCoinBalance(managedAddress.address);
        const newItem: OverviewItemProps = {
          role: managedAddress.role,
          title: "",
          subTitle: managedAddress.hostname,
          itemId: "",
          itemAddress: managedAddress.address,
          ownerId: "",
          ownerAddress: "",
          ownerName: "",
          netBalance,
          refundableDeposit,
          coinBalance,
          allowance: BigInt(0),
          notBefore: "",
          notAfter: "",
          status: ProposalStatus.PROPOSAL_UNKNOWN,
        };
        this.specialUsersOverviewItems.push(newItem);
      }
    });

    await Promise.all(selfManagedAddressPromises);
    await Promise.all(managedAddressPromises);
    this.specialUsersOverviewItems.sort((n1, n2) => n1.role - n2.role);
  }

  async loadCompanyProposalOverview() {
    await this.getCompanies();
    await this.loadProposals();

    // clear old data
    this.companyOverviewItems = [];
    this.proposalOverviewItems = [];
    this.companyIdCompanyMap = new Map<string, Companies>();
    this.proposalIdProposalMap = new Map<string, Proposal>();

    const companiesPromises = this.companies.map(async (company, index) => {
      this.companyIdCompanyMap.set(company.did, company);
      const title = company.name;
      const netBalance = Number(await this.getPoint(company.address));
      const refundableDeposit = 0;
      const coinBalance = await this.getCoinBalance(company.address);
      const newItem: OverviewItemProps = {
        role: BlockchainRole.COMPANY,
        title,
        subTitle: "",
        itemId: company.did,
        itemAddress: company.address,
        ownerId: "",
        ownerAddress: "",
        ownerName: "",
        netBalance,
        refundableDeposit,
        coinBalance,
        allowance: BigInt(0),
        notBefore: "",
        notAfter: "",
        status: ProposalStatus.PROPOSAL_UNKNOWN,
      };
      this.companyOverviewItems.push(newItem);
    });

    const proposalPromises = this.proposals.map(async (proposal, index) => {
      this.proposalIdProposalMap.set(proposal.proposalId, proposal);
      let title =
        proposal.static?.content?.contentDescription?.title === undefined
          ? ""
          : proposal.static?.content?.contentDescription?.title;
      const subTitle = getProtobufEnumKey(Purpose, proposal.static?.purpose) ?? "";
      const itemAddress = proposal.static?.proposalAddress;
      const netBalance =
        proposal.static?.proposalAddress !== undefined
          ? Number(await this.getPoint(proposal.static?.proposalAddress))
          : 0;
      const refundableDeposit = 0; // proposal 은 deposit 이 없음
      const coinBalance = itemAddress === undefined ? BigInt(0) : await this.getCoinBalance(itemAddress);
      const company =
        proposal.static?.owner === undefined ? undefined : this.companyIdCompanyMap.get(proposal.static?.owner);
      const companyAddress = company === undefined ? undefined : company.address;
      const allowance: bigint =
        companyAddress === undefined || itemAddress === undefined
          ? BigInt(0)
          : await this.getApprovedAllowance(companyAddress, itemAddress); // proposal에 남겨진 이체 한도

      if (proposal.static?.purpose === Purpose.PURPOSE_INVITE_FRIEND) {
        title =
          proposal.static?.inviteFriendContent?.title === undefined ? "" : proposal.static?.inviteFriendContent?.title;
      }

      const newItem: OverviewItemProps = {
        role: BlockchainRole.PROPOSAL,
        title,
        subTitle,
        itemId: proposal.static?.proposalId === undefined ? "" : proposal.static?.proposalId,
        itemAddress: itemAddress === undefined ? "" : itemAddress,
        ownerId: proposal.static?.owner === undefined ? "" : proposal.static?.owner,
        ownerAddress: companyAddress === undefined ? "" : companyAddress,
        ownerName: company === undefined || company.name === undefined ? "" : company.name,
        netBalance,
        refundableDeposit,
        coinBalance,
        allowance,
        notBefore: proposal.static?.notBefore === undefined ? "" : proposal.static?.notBefore,
        notAfter: proposal.static?.notAfter === undefined ? "" : proposal.static?.notAfter,
        status:
          proposal.activeGlobal?.proposalStatus === undefined
            ? ProposalStatus.PROPOSAL_UNKNOWN
            : proposal.activeGlobal?.proposalStatus,
        proposal,
      };
      this.proposalOverviewItems.push(newItem);
    });

    await Promise.all(companiesPromises);
    await Promise.all(proposalPromises);
  }

  async loadContractRelations() {
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const navigatorContract = new ethers.Contract(this.navigatorAddress!, navigatorContractAbi, signer);

    this.smartContractItemLinks = [];

    const logicContractAddress = await navigatorContract.getContract(Purpose.PURPOSE_LOGIC);
    console.log(logicContractAddress);
    const rewardContractAddress = await navigatorContract.getContract(Purpose.PURPOSE_REWARD);
    const rbacContractAddress = await navigatorContract.getContract(Purpose.PURPOSE_RBAC);

    this.smartContractItemLinks.push({
      leftContract: "Navigator",
      leftContractAddress: this.navigatorAddress!,
      leftContractDeployedDate: await this.getDeployedDate(this.navigatorAddress!),
      relationTitle: "getContract(PURPOSE_REWARD)",
      rightContract: "RewardContract",
      rightContractAddress: rewardContractAddress,
      rightContractDeployedDate: await this.getDeployedDate(rewardContractAddress),
      verification: rewardContractAddress === undefined ? "reward not ready" : "OK",
    });

    this.smartContractItemLinks.push({
      leftContract: "Navigator",
      leftContractAddress: this.navigatorAddress!,
      leftContractDeployedDate: await this.getDeployedDate(this.navigatorAddress!),
      relationTitle: "getContract(PURPOSE_LOGIC)",
      rightContract: "IntegratedLogicContract",
      rightContractAddress: logicContractAddress,
      rightContractDeployedDate: await this.getDeployedDate(logicContractAddress),
      verification: logicContractAddress === undefined ? "logic not ready" : "OK",
    });

    this.smartContractItemLinks.push({
      leftContract: "Navigator",
      leftContractAddress: this.navigatorAddress!,
      leftContractDeployedDate: await this.getDeployedDate(this.navigatorAddress!),
      relationTitle: "getContract(PURPOSE_RBAC)",
      rightContract: "RBACLogicContract",
      rightContractAddress: rbacContractAddress,
      rightContractDeployedDate: await this.getDeployedDate(rbacContractAddress),
      verification: rbacContractAddress === undefined ? "rbac not ready" : "OK",
    });

    const logicContract = new ethers.Contract(logicContractAddress, integratedLogicContractAbi, signer);

    const didContractAddressFromLogic = await logicContract.didStorage();
    const proposalContractAddressFromLogic = await logicContract.proposalStorage();
    const rewardContractAddressFromLogic = await logicContract.rewardStorage();
    const rbacContractAddressFromLogic = await logicContract.rbacContract();

    this.smartContractItemLinks.push({
      leftContract: "IntegratedLogicContract",
      leftContractAddress: logicContractAddress,
      leftContractDeployedDate: await this.getDeployedDate(logicContractAddress),
      relationTitle: "didStorage()",
      rightContract: "DidLogicContract",
      rightContractAddress: didContractAddressFromLogic,
      rightContractDeployedDate: await this.getDeployedDate(didContractAddressFromLogic),
      verification: didContractAddressFromLogic === undefined ? "did not ready" : "OK",
    });

    this.smartContractItemLinks.push({
      leftContract: "IntegratedLogicContract",
      leftContractAddress: logicContractAddress,
      leftContractDeployedDate: await this.getDeployedDate(logicContractAddress),
      relationTitle: "proposalStorage()",
      rightContract: "ProposalStorageContract",
      rightContractAddress: proposalContractAddressFromLogic,
      rightContractDeployedDate: await this.getDeployedDate(proposalContractAddressFromLogic),
      verification: proposalContractAddressFromLogic === undefined ? "proposal not ready" : "OK",
    });

    this.smartContractItemLinks.push({
      leftContract: "IntegratedLogicContract",
      leftContractAddress: logicContractAddress,
      leftContractDeployedDate: await this.getDeployedDate(logicContractAddress),
      relationTitle: "rewardStorage()",
      rightContract: "RewardContract",
      rightContractAddress: rewardContractAddressFromLogic,
      rightContractDeployedDate: await this.getDeployedDate(rewardContractAddressFromLogic),
      verification: rewardContractAddressFromLogic !== rewardContractAddress ? "reward not correct" : "OK",
    });

    this.smartContractItemLinks.push({
      leftContract: "IntegratedLogicContract",
      leftContractAddress: logicContractAddress,
      leftContractDeployedDate: await this.getDeployedDate(logicContractAddress),
      relationTitle: "rbacContract()",
      rightContract: "RBACContract",
      rightContractAddress: rbacContractAddressFromLogic,
      rightContractDeployedDate: await this.getDeployedDate(rbacContractAddressFromLogic),
      verification: rbacContractAddressFromLogic !== rbacContractAddress ? "RBAC not correct" : "OK",
    });

    const rewardContract = new ethers.Contract(rewardContractAddress, rewardContractAbi, signer);

    const rbacAddressForRewardContract = await rewardContract.rbacAddress();

    this.smartContractItemLinks.push({
      leftContract: "RewardContract",
      leftContractAddress: rewardContractAddress,
      leftContractDeployedDate: await this.getDeployedDate(rewardContractAddress),
      relationTitle: "rbacAddress()",
      rightContract: "RBACContract",
      rightContractAddress: rbacAddressForRewardContract,
      rightContractDeployedDate: await this.getDeployedDate(rbacAddressForRewardContract),
      verification: rbacAddressForRewardContract !== rbacContractAddress ? "rbac not correct" : "OK",
    });

    const didStorageContract = new ethers.Contract(didContractAddressFromLogic, didStorageContractAbi, signer);
    const rbacAddressForDidStorageContract = await didStorageContract.rbacAddress();

    this.smartContractItemLinks.push({
      leftContract: "DidStorageContract",
      leftContractAddress: didContractAddressFromLogic,
      leftContractDeployedDate: await this.getDeployedDate(didContractAddressFromLogic),
      relationTitle: "rbacAddress()",
      rightContract: "RBACContract",
      rightContractAddress: rbacAddressForDidStorageContract,
      rightContractDeployedDate: await this.getDeployedDate(rbacAddressForDidStorageContract),
      verification: rbacAddressForDidStorageContract !== rbacContractAddress ? "rbac not correct" : "OK",
    });

    const proposalStorageContract = new ethers.Contract(
      proposalContractAddressFromLogic,
      proposalStorageContractAbi,
      signer
    );
    const rbacAddressForProposalStorageContract = await proposalStorageContract.rbacAddress();

    this.smartContractItemLinks.push({
      leftContract: "ProposalStorageContract",
      leftContractAddress: proposalContractAddressFromLogic,
      leftContractDeployedDate: await this.getDeployedDate(proposalContractAddressFromLogic),
      relationTitle: "rbacAddress()",
      rightContract: "RBACContract",
      rightContractAddress: rbacAddressForProposalStorageContract,
      rightContractDeployedDate: await this.getDeployedDate(rbacAddressForProposalStorageContract),
      verification: rbacAddressForProposalStorageContract !== rbacContractAddress ? "rbac not correct" : "OK",
    });

    const logicContractForDidStorageContract = await didStorageContract.logicContractAddress();

    this.smartContractItemLinks.push({
      leftContract: "DidStorageContract",
      leftContractAddress: didContractAddressFromLogic,
      leftContractDeployedDate: await this.getDeployedDate(didContractAddressFromLogic),
      relationTitle: "logicContractAddress()",
      rightContract: "IntegratedLogicContract",
      rightContractAddress: logicContractForDidStorageContract,
      rightContractDeployedDate: await this.getDeployedDate(logicContractForDidStorageContract),
      verification: logicContractForDidStorageContract !== logicContractAddress ? "logic not correct" : "OK",
    });

    const logicContractForProposalStorageContract = await proposalStorageContract.logicContractAddress();

    this.smartContractItemLinks.push({
      leftContract: "ProposalStorageContract",
      leftContractAddress: proposalContractAddressFromLogic,
      leftContractDeployedDate: await this.getDeployedDate(proposalContractAddressFromLogic),
      relationTitle: "logicContractAddress()",
      rightContract: "IntegratedLogicContract",
      rightContractAddress: logicContractForProposalStorageContract,
      rightContractDeployedDate: await this.getDeployedDate(logicContractForProposalStorageContract),
      verification: logicContractForProposalStorageContract !== logicContractAddress ? "logic not correct" : "OK",
    });
  }

  async registerSpecialProposal(proposalStatic: ProposalStatic): Promise<string> {
    const createSpecialProposalRequest: CreateSpecialProposalRequest = CreateSpecialProposalRequest.create();
    createSpecialProposalRequest.proposal = proposalStatic;

    const paramBuf = CreateSpecialProposalRequest.encode(createSpecialProposalRequest).finish();
    let res = await fetchRegisterSpecialProposal(paramBuf, this.accessToken);
    let response = CreateSpecialProposalResponse.decode(res);

    return JSON.stringify(response);
  }

  createDidFilteringRequest(
    proposalId: string,
    mode: string,
    didList: string,
    proposalList: string
  ): RegisterDidFilteringRequest {
    let registerDidFilteringRequest: RegisterDidFilteringRequest = RegisterDidFilteringRequest.create();

    registerDidFilteringRequest.proposalId = proposalId;
    if (mode === "blocklist") {
      registerDidFilteringRequest.mode = DidFilteringMode.FILTER_BLOCKLIST;
    } else {
      registerDidFilteringRequest.mode = DidFilteringMode.FILTER_ALLOWLIST;
    }

    const didArray = this.splitTextList(didList);
    const proposalArray = this.splitTextList(proposalList);

    didArray.forEach((v) => {
      if (v !== "") {
        registerDidFilteringRequest.didFilter.push(v);
      }
    });

    proposalArray.forEach((v) => {
      if (v !== "") {
        registerDidFilteringRequest.proposalFilter.push(v);
      }
    });

    // registerDidFilteringRequest.didFilter = didArray;
    // registerDidFilteringRequest.proposalFilter = proposalArray;

    return registerDidFilteringRequest;
  }

  async sendDidFilterRequest(request: RegisterDidFilteringRequest): Promise<RegisterDidFilteringResponse> {
    const paramBuf = RegisterDidFilteringRequest.encode(request).finish();
    let res = await fetchRegisterDidFiltering(paramBuf, this.accessToken);
    let response = RegisterDidFilteringResponse.decode(res);

    console.log(JSON.stringify(response));

    return response;
  }

  async revokeDidFilterRequest(didFilterId: string): Promise<boolean> {
    let res = await fetchRevokeDidFiltering(didFilterId, new Uint8Array(0), this.accessToken);
    console.log(res);

    return true;
  }

  async applyDidFiltering(): Promise<boolean> {
    let res = await fetchApplyDidFilteringAddress(new Uint8Array(0), this.accessToken);
    console.log(res);
    return true;
  }

  async getProposalInSmartContract(proposalAddress: string): Promise<string> {
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    let proposalStorageAddress = await this.getProposalStorageAddress();
    const contract = new ethers.Contract(proposalStorageAddress, proposalStorageContractAbi, signer);
    let encodedProposalInfo = await contract.proposalIdDataMap(proposalAddress);
    console.log(encodedProposalInfo);

    // TODO: decoding failed
    // let abiCoder: AbiCoder = AbiCoder.defaultAbiCoder();
    // let result: Result = abiCoder.decode(
    //   ["bytes", "bytes", "uint64", "uint64", "uint64", "uint64", "bytes", "bytes"],
    //   encodedProposalInfo
    // );
    // console.log(result);
    // return JSON.stringify(result);
    return encodedProposalInfo;
  }

  splitTextList(str: string): string[] {
    const arr = str.split(",");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].replace(/^\s*/, "").replace(/\s*$/, "");
    }
    return arr;
  }

  async revokeSpecialProposal(proposalIdToRevoke: string): Promise<string> {
    let res = await fetchRevokeSpecialProposal(proposalIdToRevoke, new Uint8Array(0), this.accessToken);
    return JSON.stringify(res);
  }

  async refundSpecialProposal(proposalIdToRefund: string): Promise<string> {
    let res = await fetchRefundSpecialProposal(proposalIdToRefund, new Uint8Array(0), this.accessToken);
    return JSON.stringify(res);
  }

  async getDeployedDate(contractAddress: string): Promise<string> {
    return "";
  }

  async healthCheck(): Promise<any> {
    return JSON.parse(new TextDecoder().decode(await fetchHealthCheck(new Uint8Array(0), this.accessToken)));
  }

  async saveHomeBanner(
    index: number,
    homeBannerConfig: HomeBannerConfig,
    setHidden: boolean,
    setReadOnly: boolean
  ): Promise<BuildableResourceSaveResponse> {
    let request = BuildableResourceSaveRequest.create();
    if (index >= 0) {
      request.resourceNo = index;
    }
    request.homeBanner = homeBannerConfig;
    request.isHidden = setHidden;
    request.isFinal = setReadOnly;
    request.title = "1111";
    request.destinationPath = "/homebanner";
    request.resourceType = BuildableResourceType.HOME_BANNER;

    const paramBuf = BuildableResourceSaveRequest.encode(request).finish();
    let res = await fetchSaveBuildableResource(paramBuf, this.accessToken);
    let response = BuildableResourceSaveResponse.decode(res);

    console.log(response);
    return response;
  }

  async listHomeBanner(): Promise<BuildableResourceListResponse> {
    let request = BuildableResourceListReqeust.create();
    request.pageNum = 0;
    request.pageSize = 100;
    request.buildableResourceType.push(BuildableResourceType.HOME_BANNER);
    const paramBuf = BuildableResourceListReqeust.encode(request).finish();
    let res = await fetchListBuildableResource(paramBuf, this.accessToken);
    let response = BuildableResourceListResponse.decode(res);

    console.log(response);
    return response;
  }

  async publishBuildableResource(resourceNo: number): Promise<BuildableResourcePublishResponse> {
    let request = BuildableResourcePublishRequest.create();
    request.resourceNo = resourceNo;
    const paramBuf = BuildableResourcePublishRequest.encode(request).finish();
    let res = await fetchPublishBuildableResource(paramBuf, this.accessToken);
    let response = BuildableResourcePublishResponse.decode(res);

    console.log(response);
    return response;
  }

  async listAppVersionConfig(): Promise<BuildableResourceListResponse> {
    let request = BuildableResourceListReqeust.create();
    request.pageNum = 0;
    request.pageSize = 100;
    request.buildableResourceType.push(BuildableResourceType.APP_VERSION);
    const paramBuf = BuildableResourceListReqeust.encode(request).finish();
    let res = await fetchListBuildableResource(paramBuf, this.accessToken);
    let response = BuildableResourceListResponse.decode(res);

    console.log(response);
    return response;
  }

  async saveAppVersionConfig(
    index: number,
    appVersionConfig: AppVersionConfig,
    setHidden: boolean,
    setReadOnly: boolean
  ): Promise<BuildableResourceSaveResponse> {
    let request = BuildableResourceSaveRequest.create();
    if (index >= 0) {
      request.resourceNo = index;
    }
    request.appVersion = appVersionConfig;
    request.isHidden = setHidden;
    request.isFinal = setReadOnly;
    request.title = "1111";
    request.destinationPath = "/appversion";
    request.resourceType = BuildableResourceType.APP_VERSION;

    const paramBuf = BuildableResourceSaveRequest.encode(request).finish();
    let res = await fetchSaveBuildableResource(paramBuf, this.accessToken);
    let response = BuildableResourceSaveResponse.decode(res);

    console.log(response);
    return response;
  }

  async saveApplicationResource(
    index: number,
    applicationResource: ApplicationResource,
    setHidden: boolean,
    setReadOnly: boolean,
    title: string
  ): Promise<BuildableResourceSaveResponse> {
    let request = BuildableResourceSaveRequest.create();
    if (index >= 0) {
      request.resourceNo = index;
    }
    request.applicationResource = applicationResource;
    request.isHidden = setHidden;
    request.isFinal = setReadOnly;
    request.title = title;
    request.destinationPath = "";
    request.resourceType = BuildableResourceType.APPLICATION_RESOURCE;

    const paramBuf = BuildableResourceSaveRequest.encode(request).finish();
    let res = await fetchSaveBuildableResource(paramBuf, this.accessToken);
    let response = BuildableResourceSaveResponse.decode(res);

    console.log(response);
    return response;
  }

  async listWebResources(): Promise<BuildableResourceListResponse> {
    let request = BuildableResourceListReqeust.create();
    request.pageNum = 0;
    request.pageSize = 100;
    request.buildableResourceType.push(BuildableResourceType.WEB_RESOURCE);
    const paramBuf = BuildableResourceListReqeust.encode(request).finish();
    let res = await fetchListBuildableResource(paramBuf, this.accessToken);
    let response = BuildableResourceListResponse.decode(res);

    console.log(response);
    return response;
  }

  async saveWebResource(
    index: number,
    setHidden: boolean,
    setReadOnly: boolean,
    title: string,
    destinationPath: string
  ): Promise<BuildableResourceSaveResponse> {
    let request = BuildableResourceSaveRequest.create();
    if (index >= 0) {
      request.resourceNo = index;
    }
    request.isHidden = setHidden;
    request.isFinal = setReadOnly;
    request.title = title;
    request.destinationPath = destinationPath === "" ? "webresource_default" : destinationPath;
    request.resourceType = BuildableResourceType.WEB_RESOURCE;

    const paramBuf = BuildableResourceSaveRequest.encode(request).finish();
    let res = await fetchSaveBuildableResource(paramBuf, this.accessToken);
    let response = BuildableResourceSaveResponse.decode(res);

    console.log(response);
    return response;
  }

  async uploadWebResource(resourceId: string, fileList: FileList) {
    let formData = new FormData();
    formData.append("resourceId", resourceId);
    for (let i = 0; i < fileList.length; i++) {
      formData.append(`file-${i}`, fileList[i]);
    }
    let res = await fetchUploadBuildableResource(new Uint8Array(0), formData, this.accessToken);
    let response = BuildableResourceSaveResponse.decode(res);

    console.log(res);
    return response;
  }

  async listApplicationResources(): Promise<BuildableResourceListResponse> {
    let request = BuildableResourceListReqeust.create();
    request.pageNum = 0;
    request.pageSize = 100;
    request.buildableResourceType.push(BuildableResourceType.APPLICATION_RESOURCE);
    const paramBuf = BuildableResourceListReqeust.encode(request).finish();
    let res = await fetchListBuildableResource(paramBuf, this.accessToken);
    let response = BuildableResourceListResponse.decode(res);

    console.log(response);
    return response;
  }

  async runElasticSearchEvaluation(
    elasticSearchQueryTemplate: ElasticSearchQueryTemplate,
    esqtEvaluationMaterial: string
  ): Promise<string> {
    let request = ElasticSearchQueryRunnerRequest.create();
    request.template = elasticSearchQueryTemplate;
    request.dataInJson = esqtEvaluationMaterial;
    const paramBuf = ElasticSearchQueryRunnerRequest.encode(request).finish();
    let res = await fetchElasticSearchQueryEvaluation(paramBuf, this.accessToken);
    let response = ElasticSearchQueryRunnerResponse.decode(res);

    console.log(response);
    return JSON.stringify(response);
  }

  // async listCouponsForDid(couponOwnerDid: string) {
  //   let request = CouponManagerRequest.create();
  //   request.couponRequestType = CouponManagerRequestType.LIST;
  //   request.couponRequestInfo = CouponManagerRequestInfo.create();
  //   request.couponRequestInfo.couponListType = CouponManagerListType.COUPON_LIST_DID;
  //   request.couponRequestInfo.typeList
  //   const paramBuf = BuildableResourceListReqeust.encode(request).finish();
  //   let res = await fetchListBuildableResource(paramBuf, this.accessToken);
  //   let response = BuildableResourceListResponse.decode(res);

  //   console.log(response);
  //   return response;
  // }

  async getBurnCandidates(): Promise<QueryBurnPointCandidatesResponse> {
    let request = QueryBurnPointsCandidatesRequest.create();
    request.queryCandidates = true;
    request.queryCandidatesInMyd2 = true;
    const paramBuf = QueryBurnPointsCandidatesRequest.encode(request).finish();
    let res = await fetchQueryBurnPointList(paramBuf, this.accessToken);
    let response = QueryBurnPointCandidatesResponse.decode(res);
    console.log(response);
    return response;
  }

  async burnPoints(request: BurnPointRequest): Promise<BurnPointResponse> {
    const paramBuf = BurnPointRequest.encode(request).finish();
    let res = await fetchBurnPointList(paramBuf, this.accessToken);
    let response = BurnPointResponse.decode(res);
    console.log(response);
    return response;
  }

  async consumeCheck(consumeCheckProposalId: string, consumeCheckTicketId: string) {
    if (this.proposalIdProposalMap.size === 0) {
      this.loadProposals();
    }

    await window.ethereum.request({ method: "eth_requestAccounts" });
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const navigatorContract = new ethers.Contract(this.navigatorAddress!, navigatorContractAbi, signer);
    const logicContractAddress = await navigatorContract.getContract(Purpose.PURPOSE_LOGIC);
    const logicContract = new ethers.Contract(logicContractAddress, integratedLogicContractAbi, signer);
    const proposalContractAddressFromLogic = await logicContract.proposalStorage();
    const proposalStorageContract = new ethers.Contract(
      proposalContractAddressFromLogic,
      proposalStorageContractAbi,
      signer
    );

    const ticketIdInBytes: Uint8Array = new TextEncoder().encode(consumeCheckTicketId);
    let res = await proposalStorageContract.isTicketConsumed(
      this.proposalIdProposalMap.get(consumeCheckProposalId)?.static?.proposalAddress,
      ticketIdInBytes
    );
    console.log(res);
  }

  async getCouponsByTransactionId(couponTransactionId: string): Promise<CouponManagerResponse> {
    let request = CouponManagerRequest.create();
    request.couponRequestType = CouponManagerRequestType.COUPON_REQUEST_TYPE_INFO;
    request.couponRequestParameterType = CouponRequestParameterType.COUPON_REQUEST_PARAMETER_TRANSACTION_ID;
    request.parameterList.push(couponTransactionId);

    const paramBuf = CouponManagerRequest.encode(request).finish();
    let res = await fetchCouponTask(paramBuf, this.accessToken);
    let response = CouponManagerResponse.decode(res);
    console.log(response);
    return response;
  }

  async listCouponsByDid(couponOwnerDid: string): Promise<CouponManagerResponse> {
    let request = CouponManagerRequest.create();
    request.couponRequestType = CouponManagerRequestType.COUPON_REQUEST_TYPE_LIST;
    request.couponRequestParameterType = CouponRequestParameterType.COUPON_REQUEST_PARAMETER_DID;
    request.parameterList.push(couponOwnerDid);

    const paramBuf = CouponManagerRequest.encode(request).finish();
    let res = await fetchCouponTask(paramBuf, this.accessToken);
    let response = CouponManagerResponse.decode(res);
    console.log(response);
    return response;
  }

  async listMyd2CouponsByDid(couponOwnerDid: string): Promise<ListMyd2CouponResponse> {
    const listCouponRequest: ListMyd2CouponRequest = ListMyd2CouponRequest.create();
    listCouponRequest.did = couponOwnerDid;
    // listCouponRequest.couponTransactionIds = ?
    const paramBuf = ListCouponRequest.encode(listCouponRequest).finish();
    let res = await fetchManagerMyd2CouponList(paramBuf, this.accessToken);

    const listCouponResponse = ListMyd2CouponResponse.decode(res);
    if (listCouponResponse.code === CommonStatusCode.STATUS_SUCCESS && listCouponResponse.coupons !== undefined) {
      for (let coupon of listCouponResponse.coupons) {
        console.log(
          `${coupon.couponTransactionId} : ${toHexString(coupon.encryptedInfo)} : ${uint8ArrayToBase64(
            coupon.encryptedInfo
          )}`
        );
      }
    }

    return listCouponResponse;
  }

  async revokeCouponsByTransactionId(couponTransactionId: string): Promise<CouponManagerResponse> {
    let request = CouponManagerRequest.create();
    request.couponRequestType = CouponManagerRequestType.COUPON_REQUEST_TYPE_REVOKE;
    request.couponRequestParameterType = CouponRequestParameterType.COUPON_REQUEST_PARAMETER_TRANSACTION_ID;
    request.parameterList.push(couponTransactionId);

    const paramBuf = CouponManagerRequest.encode(request).finish();
    let res = await fetchCouponTask(paramBuf, this.accessToken);
    let response = CouponManagerResponse.decode(res);
    console.log(response);
    return response;
  }

  async getDailyReport(days: number): Promise<SummarizedProposalReport[]> {
    let request = DailyReportRequest.create();

    let notBefore = new Date();
    notBefore.setDate(notBefore.getDate() - days);
    let notAfter = new Date();
    notAfter.setDate(notAfter.getDate());
    request.notBefore = notBefore.toISOString();
    request.notAfter = notAfter.toISOString();

    const paramBuf = DailyReportRequest.encode(request).finish();
    let res = await fetchDailyReport(paramBuf, this.accessToken);
    let response = DailyReportResponse.decode(res);
    console.log(response);

    if (response.code === CommonStatusCode.STATUS_SUCCESS) {
      if (response.proposalIdList.length >= 1) {
        return this.getProposalReport(response.proposalIdList);
      }
    }

    console.log(`No changes while ${days} days`);

    // failed to get list
    return [];
  }

  async cleanProposalRedis(proposalId: string): Promise<ProposalManagementRedisCleanupResponse> {
    let request = ProposalManagementRedisCleanupRequest.create();
    request.proposalId = proposalId;

    const paramBuf = ProposalManagementRedisCleanupRequest.encode(request).finish();
    let res = await fetchProposalCleanRedis(paramBuf, this.accessToken);
    let response = ProposalManagementRedisCleanupResponse.decode(res);
    console.log(response);

    return response;
  }

  async getProposalReport(proposalIds: string[]): Promise<SummarizedProposalReport[]> {
    let request = ProposalReportRequest.create();
    request.proposalIds = proposalIds;

    const paramBuf = ProposalReportRequest.encode(request).finish();
    let res = await fetchProposalReport(paramBuf, this.accessToken);
    let response = ProposalReportResponse.decode(res);
    console.log(response);

    if (response.code === CommonStatusCode.STATUS_SUCCESS) {
      const summarizedProposalReports = [];
      for (let i = 0; i < response.proposalReport.length; i++) {
        summarizedProposalReports.push(this.getSummairzedProposalReport(response.proposalReport[i]));
      }
      return summarizedProposalReports;
    }

    return [];
  }

  getSummairzedProposalReport(proposalReport: ProposalReport): SummarizedProposalReport {
    const report: SummarizedProposalReport = {
      proposalReport,
      issuedButNotUploded: [],
      uploadedButNotRewarded: [],
      statAvgRedisTime: 0,
      statAvgIssueToUpload: 0,
      statAvgIssueToReward: 0,
      statRedisTime: [],
      statIssueToReward: [],
      statIssueToUpload: [],
    };

    report.countIssued = 0;
    report.countUploaded = 0;
    report.countRewarded = 0;

    for (let i = 0; i < proposalReport.ticketReportInDb.length; i++) {
      // flag
      let clientIssued = false;
      let clientUploaded = false;
      let clientRewarded = false;
      // date
      let issueRequestedAt;
      let issuedAt;
      let uploadedAt;
      let rewardedAt;

      // issued check
      if (proposalReport.ticketReportInDb[i].activeClient?.issueRequestedAt !== undefined) {
        issueRequestedAt = proposalReport.ticketReportInDb[i].activeClient?.issueRequestedAt;
      }
      // issued check
      if (proposalReport.ticketReportInDb[i].activeClient?.issuedAt !== undefined) {
        report.countIssued += 1;
        clientIssued = true;
        issuedAt = proposalReport.ticketReportInDb[i].activeClient?.issuedAt;
      }
      // uploaded check
      if (proposalReport.ticketReportInDb[i].activeClient?.uploadStatus !== undefined) {
        const uploadStatus: ProposalDataUploadStatus[] =
          proposalReport.ticketReportInDb[i].activeClient?.uploadStatus ?? [];
        if (uploadStatus.length > 0 && uploadStatus[0].dataUploadedAt !== undefined) {
          report.countUploaded += 1;
          clientUploaded = true;
          uploadedAt = uploadStatus[0].dataUploadedAt;
        }
      }
      // rewarded check
      if (proposalReport.ticketReportInDb[i].activeClient?.rewardedCompletelyAt !== undefined) {
        report.countRewarded += 1;
        clientRewarded = true;
        rewardedAt = proposalReport.ticketReportInDb[i].activeClient?.rewardedCompletelyAt;
      }
      // stat redis-time
      if (issuedAt !== undefined && issueRequestedAt !== undefined) {
        const issuedAtEpoch = new Date(issuedAt).getTime();
        const issuedRequestedAtEpoch = new Date(issueRequestedAt).getTime();
        const diff: number = issuedAtEpoch - issuedRequestedAtEpoch;
        report.statRedisTime.push(diff);
      }
      // stat issue to upload time
      if (issuedAt !== undefined && uploadedAt !== undefined) {
        const issuedAtEpoch = new Date(issuedAt).getTime();
        const uploadedAtEpoch = new Date(uploadedAt).getTime();
        console.log(`i ${issuedAt} u ${uploadedAt}`);
        const diff: number = uploadedAtEpoch - issuedAtEpoch;
        report.statIssueToUpload.push(diff);
      }
      // stat issue to reward time
      if (issuedAt !== undefined && rewardedAt !== undefined) {
        const issuedAtEpoch = new Date(issuedAt).getTime();
        const rewardedAtEpoch = new Date(rewardedAt).getTime();
        const diff: number = rewardedAtEpoch - issuedAtEpoch;
        report.statIssueToReward.push(diff);
      }
      // uploaded but not rewarded
      if (clientIssued && !clientUploaded) {
        report.issuedButNotUploded.push(proposalReport.ticketReportInDb[i]);
      }
      // uploaded but not rewarded
      if (clientUploaded && !clientRewarded) {
        report.uploadedButNotRewarded.push(proposalReport.ticketReportInDb[i]);
      }
    }
    report.statAvgRedisTime = report.statRedisTime.reduce((a, b) => a + b, 0) / report.statRedisTime.length || 0;
    report.statAvgIssueToUpload =
      report.statIssueToUpload.reduce((a, b) => a + b, 0) / report.statIssueToUpload.length || 0;
    report.statAvgIssueToReward =
      report.statIssueToReward.reduce((a, b) => a + b, 0) / report.statIssueToReward.length || 0;
    return report;
  }

  async getDidReport(dids: string[]): Promise<DidReportResponse> {
    let request = DidReportRequest.create();
    request.dids = dids;
    request.listOption = ListProposalRequest.create();
    request.listOption.fetchStatic = true;
    request.listOption.fetchActiveGlobal = true;
    request.listOption.fetchActiveClient = true;

    const paramBuf = DidReportRequest.encode(request).finish();
    let res = await fetchDidReport(paramBuf, this.accessToken);
    let response = DidReportResponse.decode(res);
    console.log(response);

    return response;
  }

  async searchForDidAddressProposalId(didOrBesuAddressOrProposalId: string): Promise<any> {
    let result;
    if (didOrBesuAddressOrProposalId.startsWith("orPxmv1") || didOrBesuAddressOrProposalId.startsWith("mEtSgz")) {
      // proposal id
      const listProposalRequest: ListProposalRequest = ListProposalRequest.create();
      listProposalRequest.fetchStatic = true;
      listProposalRequest.fetchActiveGlobal = true;
      listProposalRequest.fetchActiveClient = false;
      listProposalRequest.proposalIds.push(didOrBesuAddressOrProposalId);

      const paramBuf = ListProposalRequest.encode(listProposalRequest).finish();
      let res = await fetchProposalList(paramBuf, this.accessToken);
      const decodedRes = ListProposalResponse.decode(res);
      if (decodedRes.proposals.length >= 1) {
        return {
          proposalId: decodedRes.proposals[0].proposalId,
          besuAddress: decodedRes.proposals[0].static?.proposalAddress,
          name: decodedRes.proposals[0].static?.content?.contentDescription?.title,
          purpose: decodedRes.proposals[0].static?.purpose,
          role: BlockchainRole.PROPOSAL,
          proposals: decodedRes.proposals,
        };
      }
      return {
        did: undefined,
        proposalId: didOrBesuAddressOrProposalId,
        besuAddress: "Not found",
        name: undefined,
        role: BlockchainRole.UNKNOWN,
        response: undefined,
      };
    }
    // besu address
    let request: DidBesuAddressInfoRequest = DidBesuAddressInfoRequest.create();
    if (isAddress(didOrBesuAddressOrProposalId)) {
      request.besuAddresses.push(didOrBesuAddressOrProposalId);
    } else {
      request.dids.push(didOrBesuAddressOrProposalId);
    }
    const paramBuf = DidBesuAddressInfoRequest.encode(request).finish();
    let res = await fetchDidBesuAddressInfo(paramBuf, this.accessToken);
    let response = DidBesuAddressInfoResponse.decode(res);
    if (response.code === CommonStatusCode.STATUS_SUCCESS && response.results.length > 0) {
      return {
        did: response.results[0].did,
        proposalId: response.results[0].proposalId,
        besuAddress: response.results[0].besuAddress,
        name: response.results[0].name,
        role: response.results[0].role,
        response,
      };
    }
    result = response;

    return result;
  }

  async breakAwayDid(didToBreakAway: string) {
    let request = BreakAwayDidRequest.create();
    request.dids.push(didToBreakAway);

    const paramBuf = BreakAwayDidRequest.encode(request).finish();
    let res = await fetchBreakAwayDid(paramBuf, this.accessToken);
    let response = BreakAwayDidResponse.decode(res);
    console.log(response);
  }

  async listRewardHistory(did: string, monthsToQuery: number): Promise<ListRewardHistoryResponse> {
    const now = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - monthsToQuery);

    const listRewardHistoryRequest: ListRewardHistoryRequest = ListRewardHistoryRequest.create();
    listRewardHistoryRequest.did = did;
    listRewardHistoryRequest.requestType = ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_ALL;
    listRewardHistoryRequest.startAt = toIsoString(lastMonth);
    listRewardHistoryRequest.endAt = toIsoString(now);
    listRewardHistoryRequest.pageSize = 500;
    listRewardHistoryRequest.pageNum = 0;

    const paramBuf = ListRewardHistoryRequest.encode(listRewardHistoryRequest).finish();
    let res = await fetchManagerQueryRewardHistory(paramBuf, this.accessToken);
    return ListRewardHistoryResponse.decode(res);
  }

  async setProposalHidden(proposalIdToSetHidden: string, setHidden: boolean) {
    const listProposalRequest: ListProposalRequest = ListProposalRequest.create();
    listProposalRequest.fetchStatic = true;
    listProposalRequest.fetchActiveGlobal = true;
    listProposalRequest.fetchActiveClient = false;
    listProposalRequest.proposalIds = [proposalIdToSetHidden];

    const paramBuf = ListProposalRequest.encode(listProposalRequest).finish();
    let res = await fetchProposalList(paramBuf, this.accessToken);
    const decodedRes = ListProposalResponse.decode(res);

    if (decodedRes.proposals.length !== 1) {
      console.log("nothing changed. Invalid proposal ID");
    } else if (decodedRes.proposals[0].static?.hidden === undefined) {
      console.log(`nothing changed. Proposal data is invalid ${decodedRes}`);
    } else if (decodedRes.proposals[0].static?.hidden === setHidden) {
      console.log("no need to set ${decodedRes}");
    } else {
      let proposalStatic = decodedRes.proposals[0].static;
      proposalStatic.hidden = setHidden;
      const adminProposalUpdateRequest: AdminProposalUpdateRequest = AdminProposalUpdateRequest.create();
      adminProposalUpdateRequest.proposalStatic = proposalStatic;
      console.log(adminProposalUpdateRequest);
      const updateParamBuf = AdminProposalUpdateRequest.encode(adminProposalUpdateRequest).finish();
      const updateRes = await fetchAdminProposalUpdate(updateParamBuf, this.accessToken);
      const updateDecodedRes = AdminProposalUpdateResponse.decode(updateRes);
      console.log(updateDecodedRes);
      if (updateDecodedRes.statusCode === CommonStatusCode.STATUS_SUCCESS) {
        console.log(`Set ${proposalIdToSetHidden} hidden ${setHidden} successful`);
      }
    }
  }

  async manualMigration(
    did: string,
    besuAddress: string,
    attendance: boolean,
    wordpressUser: boolean,
    mintPoint: boolean,
    rewardHistory: boolean
  ): Promise<ManualMyd2MigrationResponse> {
    const manualMyd2MigrationRequest: ManualMyd2MigrationRequest = ManualMyd2MigrationRequest.create();
    manualMyd2MigrationRequest.did = did;
    manualMyd2MigrationRequest.besuAddress = besuAddress;
    manualMyd2MigrationRequest.wordressUser = wordpressUser;
    manualMyd2MigrationRequest.attendance = attendance;
    manualMyd2MigrationRequest.mintPoint = mintPoint;
    manualMyd2MigrationRequest.rewardHistory = rewardHistory;

    const paramBuf = ManualMyd2MigrationRequest.encode(manualMyd2MigrationRequest).finish();
    let res = await fetchManualMyd2Migration(paramBuf, this.accessToken);
    return ManualMyd2MigrationResponse.decode(res);
  }

  async migrationReport(daysToQuery: number, activeUserCriteria: number): Promise<UserMigrationReportResponse> {
    let d = new Date();
    d.setDate(d.getDate() - daysToQuery + 1);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);

    const userMigrationReportRequest: UserMigrationReportRequest = UserMigrationReportRequest.create();
    userMigrationReportRequest.histogramUnit = "P1D";
    userMigrationReportRequest.activeUserJudgementCriteria = `P${activeUserCriteria}D`;
    userMigrationReportRequest.createdAtGreaterThan = toIsoString(d);

    const paramBuf = UserMigrationReportRequest.encode(userMigrationReportRequest).finish();
    let res = await fetchUserMigrationReport(paramBuf, this.accessToken);
    return UserMigrationReportResponse.decode(res);
  }

  async compensationForMissingPoints(prevFewDay: number): Promise<string> {
    let res = await compensationForMissingPoints(prevFewDay, new Uint8Array(0), this.accessToken);
    return JSON.stringify(res);
  }
}
