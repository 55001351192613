import { MetamaskConnector } from "adminconsole/MetamaskConnector";
import { useState } from "react";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function SmartContractDirectCallBoard({ metamaskConnector }: BoardProps) {
  const [didToRetrieve, setDidToRetrieve] = useState("");

  const [consumeCheckProposalId, setConsumeCheckProposalId] = useState("");
  const [consumeCheckTicketId, setConsumeCheckTicketId] = useState("");

  const [operatorTransferFromSender, setOperatorTransferFromSender] = useState("");
  const [operatorTransferFromReceiver, setOperatorTransferFromReceiver] = useState("");
  const [operatorTransferFromAmount, setOperatorTransferFromAmount] = useState("10");

  return (
    <div>
      <details open>
        <summary>Check if a did is registered</summary>
        DID
        <input
          type="text"
          value={didToRetrieve}
          onChange={(e) => {
            setDidToRetrieve(e.target.value);
          }}
        />
        &nbsp;
        <button
          onClick={async () => {
            console.log("Trying to check...");
            const result = await metamaskConnector.getDid(didToRetrieve);
            console.log(`result ${result}`);
          }}
        >
          Check DID
        </button>
      </details>
      <br />
      <br />
      <details open>
        <summary>Check if a ticket is consumed</summary>
        Proposal ID
        <input
          type="text"
          value={consumeCheckProposalId}
          onChange={(e) => {
            setConsumeCheckProposalId(e.target.value);
          }}
        />
        Ticket ID
        <input
          type="text"
          value={consumeCheckTicketId}
          onChange={(e) => {
            setConsumeCheckTicketId(e.target.value);
          }}
        />
        &nbsp;
        <button
          onClick={async () => {
            console.log("Trying to check...");
            const result = await metamaskConnector.consumeCheck(consumeCheckProposalId, consumeCheckTicketId);
            console.log(`result ${result}`);
          }}
        >
          Check ticket
        </button>
      </details>
      <br />
      <br />
      <details open>
        <summary>Operator transfer from</summary>
        Sender
        <input
          type="text"
          value={operatorTransferFromSender}
          onChange={(e) => {
            setOperatorTransferFromSender(e.target.value);
          }}
        />
        Receiver
        <input
          type="text"
          value={operatorTransferFromReceiver}
          onChange={(e) => {
            setOperatorTransferFromReceiver(e.target.value);
          }}
        />
        Amount
        <input
          type="text"
          value={operatorTransferFromAmount}
          onChange={(e) => {
            setOperatorTransferFromAmount(e.target.value);
          }}
        />
        &nbsp;
        <button
          onClick={async () => {
            console.log("Trying to operator transfer from...");
            const result = await metamaskConnector.operatorTransferFrom(
              operatorTransferFromSender,
              operatorTransferFromReceiver,
              Number(operatorTransferFromAmount)
            );
            console.log(`result ${result}`);
          }}
        >
          transfer
        </button>
      </details>
    </div>
  );
}
