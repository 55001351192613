import { Purpose } from "protobuf/DwSolidity";
import { Proposal, ProposalClientStatus, ProposalStatus } from "protobuf/OpenApiServerV3DataTypes";
import { useState } from "react";
import { JSONTree } from "react-json-tree";
import { MetamaskConnector } from "./MetamaskConnector";
import { BlockchainRole, DidReport, ListRewardHistoryResponse } from "protobuf/OpenApiServerV3";
import { copyToClipboard, getProtobufEnumKey } from "utils/storage";
import DailyReportBoard from "./DailyReportBoard";
import { CSVLink } from "react-csv";
import { Data } from "react-csv/lib/core";
import CouponManagerBoard from "./CouponManagerBoard";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function QueryBoard({ metamaskConnector }: BoardProps) {
  const [didOrBesuAddressOrProposalId, setDidOrBesuAddressOrProposalId] = useState("");
  const [didBesuAddressInfo, setDidBesuAddressInfo] = useState<any>({});

  const [didForReport, setDidForReport] = useState("");
  const [didReport, setDidReport] = useState(DidReport.create());

  const [didToQueryHistory, setDidToQueryHistory] = useState("");
  const [monthsToQueryHistory, setMonthsToQueryHistory] = useState("3");
  const [didRewardHistory, setDidRewardHistory] = useState<ListRewardHistoryResponse>(
    ListRewardHistoryResponse.create()
  );
  const [didRewardHistoryCsvData, setDidRewardHistoryCsvData] = useState<string | Data | (() => string | Data)>("");

  return (
    <div>
      <details open>
        <summary>Search for DID / besu address</summary>
        <div className="rcdiv">
          DID or besu address or Proposal ID
          <input
            type="text"
            name="search-id"
            value={didOrBesuAddressOrProposalId}
            onChange={(e) => setDidOrBesuAddressOrProposalId(e.target.value)}
          />{" "}
          &nbsp;{" "}
          <button
            onClick={async () => {
              setDidBesuAddressInfo(
                await metamaskConnector.searchForDidAddressProposalId(didOrBesuAddressOrProposalId.replace(/\s/g, ""))
              );
            }}
          >
            Search
          </button>
          <br />
          <table className="bluetable">
            <tbody>
              <tr>
                <th>Role</th>
                <th>DID / Proposal ID / Name</th>
                <th>Purpose</th>
                <th>Besu address</th>
              </tr>
              <tr>
                <td>{getProtobufEnumKey(BlockchainRole, didBesuAddressInfo.role)}</td>
                <td>
                  {didBesuAddressInfo.did ?? didBesuAddressInfo.proposalId ?? didBesuAddressInfo.name}{" "}
                  <button
                    type="button"
                    onClick={() =>
                      copyToClipboard(
                        didBesuAddressInfo.did ?? didBesuAddressInfo.proposalId ?? didBesuAddressInfo.name
                      )
                    }
                  >
                    복사
                  </button>
                </td>
                <td>
                  {didBesuAddressInfo.purpose !== undefined
                    ? getProtobufEnumKey(Purpose, didBesuAddressInfo.purpose)
                    : ""}
                </td>
                <td>
                  {didBesuAddressInfo.besuAddress}{" "}
                  <button type="button" onClick={() => copyToClipboard(didBesuAddressInfo.besuAddress)}>
                    복사
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <details>
            <summary>raw</summary>
            <JSONTree
              data={didBesuAddressInfo}
              shouldExpandNodeInitially={(keyName, data, level) => {
                if (level === 0) {
                  return true;
                }
                if (level === 1) {
                  return true;
                }
                return false;
              }}
            />
          </details>
        </div>
      </details>
      <br />
      <br />
      <details open>
        <summary>Proposal</summary>
        <DailyReportBoard metamaskConnector={metamaskConnector} queryOnly />
      </details>
      <br />
      <br />
      <details open>
        <summary>DID report</summary>
        <div className="rcdiv">
          Owner (DID){" "}
          <input
            type="text"
            name="did-for-report"
            value={didForReport}
            onChange={(e) => setDidForReport(e.target.value)}
          />
          &nbsp;
          <button
            onClick={async () => {
              const result = await metamaskConnector.getDidReport([didForReport]);
              console.log(result);
              if (result !== undefined && result.didReports !== undefined && result.didReports.length > 0) {
                if (result.didReports[0].userStatInJson === '{"_id":"no data""}') {
                  // wrong data
                  result.didReports[0].userStatInJson = '{"id":"no data"}';
                }
                setDidReport(result.didReports[0]);
              }
            }}
          >
            get
          </button>
          &nbsp;&nbsp;
          <table className="bluetable">
            <tbody>
              <tr>
                <th>Field name</th>
                <th colSpan={3}>Value</th>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>DID</td>
                <td colSpan={3}>{didReport.did}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td colSpan={3}>
                  {didReport.userStatInJson !== "" &&
                  JSON.parse(didReport.userStatInJson) !== undefined &&
                  JSON.parse(didReport.userStatInJson).userInfo !== undefined
                    ? JSON.parse(didReport.userStatInJson).userInfo.gender
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Nick</td>
                <td colSpan={3}>
                  {didReport.userStatInJson !== "" &&
                  JSON.parse(didReport.userStatInJson) !== undefined &&
                  JSON.parse(didReport.userStatInJson).userInfo !== undefined
                    ? JSON.parse(didReport.userStatInJson).userInfo.nickName
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Birth</td>
                <td colSpan={3}>
                  {didReport.userStatInJson !== "" &&
                  JSON.parse(didReport.userStatInJson) !== undefined &&
                  JSON.parse(didReport.userStatInJson).userInfo !== undefined
                    ? JSON.parse(didReport.userStatInJson).userInfo.birth
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Registered at</td>
                <td colSpan={3}>
                  {didReport.userStatInJson !== "" &&
                  JSON.parse(didReport.userStatInJson) !== undefined &&
                  JSON.parse(didReport.userStatInJson).userInfo !== undefined
                    ? JSON.parse(didReport.userStatInJson).userInfo.createdAt
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Last updated at</td>
                <td colSpan={3}>
                  {didReport.userStatInJson !== "" && JSON.parse(didReport.userStatInJson) !== undefined
                    ? JSON.parse(didReport.userStatInJson).updatedAt
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Besu address</td>
                <td colSpan={3}>{didReport.member?.besuAddress}</td>
              </tr>
              <tr>
                <td>User agent</td>
                <td colSpan={3}>{didReport.member?.userAgent}</td>
              </tr>
              <tr>
                <td>Mid</td>
                <td colSpan={3}>{didReport.member?.mid}</td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <details>
                    <summary>member raw</summary>
                    <JSONTree data={didReport.member} />
                  </details>
                </td>
              </tr>
              <tr>
                <td>WordpressID (MYD3) - newly creatd or migrated</td>
                <td colSpan={3}>{didReport.wordpressUserDbId}</td>
              </tr>
              <tr>
                <td>Attendance (MYD3) - newly creatd or migrated</td>
                <td colSpan={3}>{didReport.attendance === undefined ? " " : JSON.stringify(didReport.attendance)}</td>
              </tr>
              <tr>
                <th colSpan={4}> from v2</th>
              </tr>
              <tr>
                <td>Point (MYD2 / Fabric)</td>
                <td colSpan={3}>{didReport.myd2Point}</td>
              </tr>
              <tr>
                <td>WordpressID (MYD2) - old db</td>
                <td colSpan={3}>{didReport.myd2WordpressUserDbId}</td>
              </tr>
              <tr>
                <td>Attendance (MYD2 by DID) - old db</td>
                <td colSpan={3}>
                  {didReport.myd2AttendanceByDid === undefined ? " " : JSON.stringify(didReport.myd2AttendanceByDid)}
                </td>
              </tr>
              <tr>
                <td>Attendance (MYD2 by MID) - old db</td>
                <td colSpan={3}>
                  {didReport.myd2AttendanceByMid === undefined ? " " : JSON.stringify(didReport.myd2AttendanceByMid)}
                </td>
              </tr>
            </tbody>
          </table>
          <details open>
            <summary>active proposals</summary>
            <table className="bluetable">
              <tbody>
                <tr>
                  <th colSpan={5}>activated proposal</th>
                </tr>
              </tbody>
              {didReport.listResponse?.proposals
                .filter((value: Proposal) => value.activeGlobal?.proposalStatus === ProposalStatus.PROPOSAL_ACTIVATED)
                .map((item, index) => (
                  <tbody key={`rew-${index}`}>
                    <tr>
                      <td>{getProtobufEnumKey(Purpose, item.static?.purpose) ?? item.static?.purpose}</td>
                      <td>{item.static?.content?.contentDescription?.title}</td>
                      <td>{item.activeClient?.issueRequestedAt?.substring(0, 19)}</td>
                      <td>
                        {item.static?.proposalId.substring(0, 10)}
                        {item.static?.proposalId !== undefined && item.static?.proposalId.length > 10 ? "..." : ""}{" "}
                        <button type="button" onClick={() => copyToClipboard(item.static?.proposalId ?? "")}>
                          복사
                        </button>
                      </td>
                      <td>
                        {getProtobufEnumKey(ProposalStatus, item.activeGlobal?.proposalStatus)} /{" "}
                        {item.activeClient !== undefined
                          ? getProtobufEnumKey(ProposalClientStatus, item.activeClient?.proposalClientStatus)
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5}>
                        <details>
                          <summary>open proposal details</summary>
                          <JSONTree data={item} />
                        </details>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </details>
          <details>
            <summary>deactivated proposals</summary>
            <table className="bluetable">
              <tbody>
                <tr>
                  <th colSpan={5}>deactivated proposal</th>
                </tr>
              </tbody>
              {didReport.listResponse?.proposals
                .filter((value: Proposal) => value.activeGlobal?.proposalStatus !== ProposalStatus.PROPOSAL_ACTIVATED)
                .map((item, index) => (
                  <tbody key={`rew-${index}`}>
                    <tr>
                      <td>{getProtobufEnumKey(Purpose, item.static?.purpose) ?? item.static?.purpose}</td>
                      <td>{item.static?.content?.contentDescription?.title}</td>
                      <td>{item.activeClient?.issueRequestedAt?.substring(0, 19)}</td>
                      <td>
                        {item.static?.proposalId.substring(0, 10)}
                        {item.static?.proposalId !== undefined && item.static?.proposalId.length > 10 ? "..." : ""}{" "}
                        <button type="button" onClick={() => copyToClipboard(item.static?.proposalId ?? "")}>
                          복사
                        </button>
                      </td>
                      <td>
                        {getProtobufEnumKey(ProposalStatus, item.activeGlobal?.proposalStatus)} /{" "}
                        {item.activeClient !== undefined
                          ? getProtobufEnumKey(ProposalClientStatus, item.activeClient?.proposalClientStatus)
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5}>
                        <details>
                          <summary>open proposal details</summary>
                          <JSONTree data={item} />
                        </details>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </details>
        </div>
      </details>
      <br />
      <br />
      <details open>
        <summary>DID reward history</summary>
        <div className="rcdiv">
          Owner (DID){" "}
          <input
            type="text"
            name="reward-owner"
            value={didToQueryHistory}
            onChange={(e) => setDidToQueryHistory(e.target.value)}
          />
          &nbsp; Months to query &nbsp;
          <input
            type="text"
            name="reward-duration"
            value={monthsToQueryHistory}
            onChange={(e) => setMonthsToQueryHistory(e.target.value)}
          />
          <button
            onClick={async () => {
              const result = await metamaskConnector.listRewardHistory(didToQueryHistory, Number(monthsToQueryHistory));
              console.log(result);
              if (result !== undefined) {
                setDidRewardHistory(result);
                let buffer = `Total, ${result.balance}\nExpires within 1month, ${result.amountExpiresWithin1m}\n\nDate, Category, Title, Amount`;
                for (const item of result.contents) {
                  buffer += `\n${item.createdAt}, ${item.category}, ${item.title}, ${item.amount}`;
                }
                setDidRewardHistoryCsvData(buffer);
              }
            }}
          >
            Query balance
          </button>
          &nbsp;&nbsp;
          <CSVLink data={didRewardHistoryCsvData} filename="users.csv" target="_blank">
            Export Excel
          </CSVLink>
          <br />
          <br />
          Total balance: {didRewardHistory.balance}
          <br />
          Expires within 1month: {didRewardHistory.amountExpiresWithin1m}
          <br />
          <table className="bluetable">
            <tbody>
              <tr>
                <th>Date</th>
                <th>Category</th>
                <th>Title</th>
                <th>Amount</th>
              </tr>
            </tbody>
            <tbody>
              {didRewardHistory.contents.map((item, index) => (
                <tr key={`rew-${index}`}>
                  <td>{item.createdAt}</td>
                  <td>{item.category}</td>
                  <td>{item.title}</td>
                  <td>{item.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </details>
      <br />
      <br />
      <details>
        <summary>Coupons</summary>
        <div className="rcdiv">
          <CouponManagerBoard metamaskConnector={metamaskConnector} queryOnly />
        </div>
      </details>
    </div>
  );
}
