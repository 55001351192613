import { useState } from "react";
import { MetamaskConnector } from "./MetamaskConnector";
import { AppVersionConfig, SavedBuilableResource } from "protobuf/OpenApiServerV3";
import { CommonStatusCode } from "protobuf/CommonStatusCode";
import { getServerMode } from "api/base";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function AppVersionBoard({ metamaskConnector }: BoardProps) {
  const [savedResources, setSavedResources] = useState<SavedBuilableResource[]>([]);
  const [selectedResourceIndex, setSelectedResourceIndex] = useState<number>(0);

  const [androidVersion, setAndroidVersion] = useState<string>("");
  const [androidForcedUpdate, setAndroidForcedUpdate] = useState(false);
  const [iosVersion, setIosVersion] = useState<string>("");
  const [iosForcedUpdate, setIosForcedUpdate] = useState(false);
  const [needNotification, setNeedNotification] = useState(false);

  const [output, setOutput] = useState<string>("");

  return (
    <div>
      <button
        onClick={async () => {
          let response = await metamaskConnector.listAppVersionConfig();
          setSavedResources(response.savedBuilableResource ?? []);
        }}
      >
        list app versions
      </button>
      <br />
      final version :
      <a href={`http://cdn.v3.${getServerMode()}.myd.world/appversion/pub.json`} target="_blank" rel="noreferrer">
        JSON
      </a>
      &nbsp;
      <a href={`http://cdn.v3.${getServerMode()}.myd.world/appversion/pub.pb`} target="_blank" rel="noreferrer">
        PROTOBUF
      </a>
      <br />
      <table>
        <thead>
          <tr>
            <td>resource id</td>
            <td>created by</td>
            <td>links</td>
            <td>is final version?</td>
            <td>load this version</td>
            <td>just copy</td>
            <td>make it final</td>
            <td>publish this</td>
            <td>published at</td>
          </tr>
        </thead>
        <tbody>
          {savedResources.map((saved, index) => (
            <tr key={`saved-${saved.resourceNo}`}>
              <td>{saved.resourceNo}</td>
              <td>{saved.createdBy}</td>
              <td>
                <a
                  href={`http://cdn.v3.${getServerMode()}.myd.world/template/appversion/${saved.resourceId}/pub.json`}
                  target="_blank"
                  rel="noreferrer"
                >
                  JSON
                </a>
                &nbsp;
                <a
                  href={`http://cdn.v3.${getServerMode()}.myd.world/template/appversion/${saved.resourceId}/pub.pb`}
                  target="_blank"
                  rel="noreferrer"
                >
                  PROTOBUF
                </a>
              </td>
              <td>{saved.isFinal ? "final version" : "working"}</td>
              <td>
                <button
                  onClick={async () => {
                    if (saved.appVersion !== undefined) {
                      setAndroidVersion(saved.appVersion?.androidVersion);
                      setAndroidForcedUpdate(saved.appVersion?.androidForcedUpdate);
                      setIosVersion(saved.appVersion?.iosVersion);
                      setIosForcedUpdate(saved.appVersion?.iosForcedUpdate);
                      setNeedNotification(saved.appVersion.needNotification);
                      setSelectedResourceIndex(saved.resourceNo);
                    }
                  }}
                >
                  load this config
                </button>
              </td>
              <td>
                <button
                  onClick={async () => {
                    if (saved.appVersion !== undefined) {
                      setAndroidVersion(saved.appVersion?.androidVersion);
                      setAndroidForcedUpdate(saved.appVersion?.androidForcedUpdate);
                      setIosVersion(saved.appVersion?.iosVersion);
                      setIosForcedUpdate(saved.appVersion?.iosForcedUpdate);
                      setNeedNotification(saved.appVersion.needNotification);
                      setSelectedResourceIndex(-1);
                    }
                  }}
                >
                  copy to new one
                </button>
              </td>
              <td>
                <button
                  onClick={async () => {
                    if (saved.appVersion !== undefined) {
                      let response = await metamaskConnector.saveAppVersionConfig(
                        saved.resourceNo,
                        saved.appVersion,
                        false,
                        true
                      );
                      setOutput(JSON.stringify(response));
                    }
                  }}
                >
                  make it final (readonly)
                </button>
              </td>
              <td>
                <button
                  onClick={async () => {
                    if (saved.isFinal) {
                      if (window.confirm("정말 publish 하시겠습니까?")) {
                        let response = await metamaskConnector.publishBuildableResource(saved.resourceNo);
                        setOutput(JSON.stringify(response));
                      }
                    } else {
                      alert("only final version can be published");
                    }
                  }}
                >
                  publish this
                </button>
              </td>
              <td>{saved.publishedAt}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="rcdiv">{output}</div>
      <div>
        App version config
        <br />
        <br />
        <table>
          <thead>
            <tr>
              <td>item</td>
              <td>value</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>android version</td>
              <td>
                <input
                  type="text"
                  value={androidVersion}
                  onChange={(e) => {
                    setAndroidVersion(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>android forced update</td>
              <td>
                <input
                  type="checkbox"
                  checked={androidForcedUpdate}
                  onChange={(checked) => {
                    setAndroidForcedUpdate(!androidForcedUpdate);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>ios version</td>
              <td>
                <input
                  type="text"
                  value={iosVersion}
                  onChange={(e) => {
                    setIosVersion(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>ios forced update</td>
              <td>
                <input
                  type="checkbox"
                  checked={iosForcedUpdate}
                  onChange={(checked) => {
                    setIosForcedUpdate(!iosForcedUpdate);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>need notification</td>
              <td>
                <input
                  type="checkbox"
                  checked={needNotification}
                  onChange={(checked) => {
                    setNeedNotification(!needNotification);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <button
                  onClick={async () => {
                    const appVersionConfig = AppVersionConfig.create();
                    appVersionConfig.androidVersion = androidVersion;
                    appVersionConfig.androidForcedUpdate = androidForcedUpdate;
                    appVersionConfig.iosVersion = iosVersion;
                    appVersionConfig.iosForcedUpdate = iosForcedUpdate;
                    appVersionConfig.needNotification = needNotification;
                    let response = await metamaskConnector.saveAppVersionConfig(
                      selectedResourceIndex,
                      appVersionConfig,
                      false,
                      false
                    );
                    if (response.statusCode === CommonStatusCode.STATUS_SUCCESS) {
                      setSelectedResourceIndex(response.savedBuilableResource!.resourceNo);
                      let responselist = await metamaskConnector.listAppVersionConfig();
                      setSavedResources(responselist.savedBuilableResource ?? []);
                    }
                  }}
                >
                  Save this app version {selectedResourceIndex !== -1 ? `resource-no:${selectedResourceIndex}` : ""}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="rcdiv">{output}</div>
      </div>
    </div>
  );
}
