import { ChangeEvent, useState } from "react";
import { MetamaskConnector } from "./MetamaskConnector";
import { SavedBuilableResource } from "protobuf/OpenApiServerV3";
import { getServerMode } from "api/base";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function WebResourceUploadBoard({ metamaskConnector }: BoardProps) {
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>();

  const [savedResources, setSavedResources] = useState<SavedBuilableResource[]>([]);
  const [destinationPath, setDestinationPath] = useState("webresource_default");
  const [selectedResourceIndex, setSelectedResourceIndex] = useState<number>(-1);

  const [output, setOutput] = useState<string>("");

  function handleFileChange(e: ChangeEvent<HTMLInputElement>): void {
    setSelectedFiles(e.target.files);
  }

  return (
    <div>
      <button
        onClick={async () => {
          let response = await metamaskConnector.listWebResources();
          setSavedResources(response.savedBuilableResource ?? []);
        }}
      >
        list web resources
      </button>{" "}
      &nbsp;&nbsp;
      <button
        onClick={async () => {
          let response = await metamaskConnector.saveWebResource(0, false, false, "", "");
          let responseList = await metamaskConnector.listWebResources();
          setSavedResources(responseList.savedBuilableResource ?? []);
        }}
      >
        create new web resource
      </button>
      <br />
      final version :
      <a href={`http://cdn.v3.${getServerMode()}.myd.world/appversion/pub.json`} target="_blank" rel="noreferrer">
        JSON
      </a>
      &nbsp;
      <a href={`http://cdn.v3.${getServerMode()}.myd.world/appversion/pub.pb`} target="_blank" rel="noreferrer">
        PROTOBUF
      </a>
      <br />
      <table>
        <thead>
          <tr>
            <td>resource id</td>
            <td>created by</td>
            <td>make it final</td>
            <td>publish this</td>
            <td>published at</td>
          </tr>
        </thead>
        {savedResources.map((saved, index) => (
          <tbody key={`saved-${saved.resourceNo}`}>
            <tr>
              <td rowSpan={3}>
                {saved.resourceNo}{" "}
                <button
                  onClick={() => {
                    if (selectedResourceIndex === saved.resourceNo) {
                      setSelectedResourceIndex(-1);
                      setSelectedFiles(undefined);
                    } else {
                      setSelectedResourceIndex(saved.resourceNo);
                      setSelectedFiles(undefined);
                    }
                  }}
                >
                  edit
                </button>
              </td>
              <td>{saved.createdBy}</td>
              <td>
                <button
                  onClick={async () => {
                    let response = await metamaskConnector.saveWebResource(
                      saved.resourceNo,
                      false,
                      true,
                      saved.title,
                      saved.destinationPath ?? "default"
                    );
                    setOutput(JSON.stringify(response));
                    let responseList = await metamaskConnector.listWebResources();
                    setSavedResources(responseList.savedBuilableResource ?? []);
                  }}
                >
                  make it final (readonly)
                </button>
              </td>
              <td>
                <button
                  onClick={async () => {
                    if (saved.isFinal) {
                      if (
                        window.confirm("정말 publish 하시겠습니까? (Destination 경로에 있는 파일들이 삭제됩니다!!)")
                      ) {
                        let response = await metamaskConnector.publishBuildableResource(saved.resourceNo);
                        setOutput(JSON.stringify(response));
                        let responseList = await metamaskConnector.listWebResources();
                        setSavedResources(responseList.savedBuilableResource ?? []);
                      }
                    } else {
                      alert("only final version can be published");
                    }
                  }}
                >
                  publish this
                </button>
              </td>
              <td>{saved.publishedAt}</td>
            </tr>
            <tr>
              <td colSpan={4}>
                Destination: http://cdn.v3.{getServerMode()}.myd.world/w/{saved.destinationPath} <br />
                <div
                  style={{ display: selectedResourceIndex === saved.resourceNo && !saved.isFinal ? "block" : "none" }}
                >
                  http://cdn.v3.{getServerMode()}.myd.world/w/
                  <input
                    type="text"
                    name="destination"
                    value={destinationPath}
                    onChange={(e) => setDestinationPath(e.target.value)}
                  />
                  <button
                    onClick={async () => {
                      let response = await metamaskConnector.saveWebResource(
                        saved.resourceNo,
                        false,
                        false,
                        "",
                        destinationPath
                      );
                      setDestinationPath("");
                      setSelectedResourceIndex(-1);
                      let responseList = await metamaskConnector.listWebResources();
                      setSavedResources(responseList.savedBuilableResource ?? []);
                    }}
                  >
                    update path
                  </button>
                </div>
                <div style={{ display: saved.publishedAt !== undefined ? "block" : "none" }}>
                  {saved.webResource?.fileUrlPath?.map((urlPath, urlIndex) => (
                    <>
                      <a
                        href={urlPath.replace(`template/w/${saved.resourceId}/`, `w/${saved.destinationPath}/`)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {urlPath.replace(`template/w/${saved.resourceId}/`, `w/${saved.destinationPath}/`)}
                      </a>
                      <br />
                    </>
                  ))}
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                {saved.isFinal ? "final version" : "files"}
                <br />
                {saved.webResource?.fileUrlPath?.map((urlPath, urlIndex) => (
                  <>
                    <a href={urlPath} target="_blank" rel="noreferrer">
                      {urlPath}
                    </a>
                    <br />
                  </>
                ))}
                <span
                  style={{ display: selectedResourceIndex === saved.resourceNo && !saved.isFinal ? "block" : "none" }}
                >
                  여러 파일을 선택하기 (폴더 제외)
                </span>
                <input
                  type="file"
                  onChange={handleFileChange}
                  multiple
                  style={{ display: selectedResourceIndex === saved.resourceNo && !saved.isFinal ? "block" : "none" }}
                />
                <span
                  style={{ display: selectedResourceIndex === saved.resourceNo && !saved.isFinal ? "block" : "none" }}
                >
                  폴더채로 선택하기
                </span>
                <input
                  type="file"
                  onChange={handleFileChange}
                  /* eslint-disable react/no-unknown-property */
                  /* @ts-expect-error */
                  directory=""
                  webkitdirectory=""
                  style={{ display: selectedResourceIndex === saved.resourceNo && !saved.isFinal ? "block" : "none" }}
                />
                <button
                  onClick={async () => {
                    if (selectedFiles !== undefined && selectedFiles !== null && selectedFiles.length > 0) {
                      if (
                        window.confirm(
                          "template directory 의 파일들을 삭제하고, 새로운 파일들을 업로드합니다. 정말 업로드 하시겠습니까? (Publish된 파일은 삭제되지 않습니다.)"
                        )
                      ) {
                        let response = await metamaskConnector.uploadWebResource(saved.resourceId, selectedFiles);
                        let responseList = await metamaskConnector.listWebResources();
                        setSavedResources(responseList.savedBuilableResource ?? []);
                      }
                    } else {
                      console.log("File is not selected");
                    }
                  }}
                  style={{ display: selectedResourceIndex === saved.resourceNo && !saved.isFinal ? "block" : "none" }}
                >
                  upload the files
                </button>
              </td>
            </tr>
          </tbody>
        ))}
      </table>
      <div className="rcdiv">{output}</div>
    </div>
  );
}
