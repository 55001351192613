import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { Account } from "web3-core";
import bs58 from "bs58";
import sodium from "libsodium-wrappers";
import secp256k1 from "secp256k1";
import CryptoJS from "crypto-js";

import * as DwSolidity from "../protobuf/DwSolidity";
import * as SolidityTypes from "../protobuf/SolidityTypes";
import {
  toHexString,
  fromHexString,
  convertUint8ArrayToWordArray,
  convertWordArrayToUint8Array,
} from "./blockchainutil";
import { createDecipheriv, createECDH, randomBytes } from "crypto-browserify";
import { Myd2Blockchain } from "./myd2";
import { buffer } from "stream/consumers";
import { fromHex } from "bytebuffer";

export class Myd3Blockchain {
  DID_PREFIX: string = "did:snplab:";
  NAVIGATOR_ABI: AbiItem[] = [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
      inputs: [],
      name: "ADMIN_ROLE",
      outputs: [{ internalType: "uint64", name: "", type: "uint64" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint32", name: "purposeCode", type: "uint32" }],
      name: "getContract",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint64", name: "role", type: "uint64" },
        { internalType: "address", name: "account", type: "address" },
      ],
      name: "hasRole",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "initialAdminAddress",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint32", name: "purposeCode", type: "uint32" },
        { internalType: "bytes", name: "encodedProtobufFunctionCall", type: "bytes" },
      ],
      name: "process",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint32", name: "", type: "uint32" }],
      name: "purposeContractMapping",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "purposeContractMappingKeys",
      outputs: [{ internalType: "uint32", name: "", type: "uint32" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint32", name: "purposeCode", type: "uint32" },
        { internalType: "bytes", name: "encodedProtobufFunctionCall", type: "bytes" },
      ],
      name: "query",
      outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "rbacAddress",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "rewardAddress",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint32", name: "purposeCode", type: "uint32" },
        { internalType: "address", name: "contractAddr", type: "address" },
      ],
      name: "setContract",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ];

  CHAIN_ID: number = 1337;
  GAS_FEE: string = "30000000";
  HEX_PREFIX: string = "0x";
  VERIFY_SIGNATURE_PREFIX: string = "world.myd.login.";

  DID_CONTRACT_PURPOSE: number = 303;
  DATA_MARKET_CONTRACT_PURPOSE: number = 100;

  web3 = new Web3();
  myd2: Myd2Blockchain | undefined = undefined;
  account: Account;
  indyPrivateKey: string = "";
  publicKey: string = "";
  document = {};

  constructor(
    public entropy?: string,
    public did: string = "",
    public privateKey: string = "",
    private nonce: number = 0,
    private navigatorAddress: string = "0x98b5a654a702b06f5960ec0593f34b3d9bb27ac0"
  ) {
    if (did === "" || privateKey === "") {
      // create a new besu account and did
      this.account = this.web3.eth.accounts.create(entropy);
      this.privateKey = bs58.encode(fromHexString(this.account.privateKey.slice(2)));
      const privateKeyUint8Array: Uint8Array = bs58.decode(this.privateKey);
      const publicKeyUint8Array: Uint8Array = secp256k1.publicKeyCreate(privateKeyUint8Array);
      this.publicKey = bs58.encode(publicKeyUint8Array);
      // for myd2 to myd3 migration, the old did should be used.
      if (this.did === "") {
        this.did = this.DID_PREFIX + bs58.encode(publicKeyUint8Array.slice(16));
      }
    } else {
      // load besu account and did
      this.account = this.web3.eth.accounts.privateKeyToAccount(toHexString(bs58.decode(privateKey)));
      this.privateKey = bs58.encode(fromHexString(this.account.privateKey.slice(2)));
      const privateKeyUint8Array: Uint8Array = bs58.decode(this.privateKey);
      const publicKeyUint8Array: Uint8Array = secp256k1.publicKeyCreate(privateKeyUint8Array);
      this.publicKey = bs58.encode(publicKeyUint8Array);
    }

    this.document = {
      "@context": "https://www.w3.org/ns/did/v1",
      id: this.did,
      verificationMethod: {
        id: `${this.did}#verkey`,
        type: "EcdsaSecp256k1VerificationKey2019", // "Ed25519VerificationKey2018"
        publicKeyBase58: this.publicKey,
        controller: this.did,
      },
      authentication: `${this.did}#verkey`,
    };
  }

  updateNonce(nonce: number) {
    this.nonce = nonce;
  }

  getNonce() {
    return this.nonce;
  }

  setMyd2(myd2: Myd2Blockchain) {
    this.myd2 = myd2;
  }

  private getTimestampBytes(timestamp: number) {
    const res = new Uint8Array(8);
    res[0] = timestamp >> 56;
    res[1] = (timestamp >> 48) & 0xff;
    res[2] = (timestamp >> 40) & 0xff;
    res[3] = (timestamp >> 32) & 0xff;
    res[4] = (timestamp >> 24) & 0xff;
    res[5] = (timestamp >> 16) & 0xff;
    res[6] = (timestamp >> 8) & 0xff;
    res[7] = timestamp & 0xff;
    return res;
  }

  private concatTypedArrays2(a: Uint8Array, b: Uint8Array) {
    const r = new Uint8Array(a.length + b.length);
    r.set(a, 0);
    r.set(b, a.length);
    return r;
  }

  private concatTypedArrays3(a: Uint8Array, b: Uint8Array, c: Uint8Array) {
    const r = new Uint8Array(a.length + b.length + c.length);
    r.set(a, 0);
    r.set(b, a.length);
    r.set(c, a.length + b.length);
    return r;
  }

  private concatTypedArrays4(a: Uint8Array, b: Uint8Array, c: Uint8Array, d: Uint8Array) {
    const r = new Uint8Array(a.length + b.length + c.length + d.length);
    r.set(a, 0);
    r.set(b, a.length);
    r.set(c, a.length + b.length);
    r.set(d, a.length + b.length + c.length);
    return r;
  }

  private randomBytes(count: number): Uint8Array {
    const result = new Uint8Array(count);
    for (let i = 0; i < count; ++i) {
      result[i] = Math.floor(256 * Math.random());
    }
    return result;
  }

  generateMyd3Signature(message: Uint8Array): Uint8Array {
    const messageDigest: Uint8Array = fromHexString(this.web3.utils.sha3(toHexString(message))?.substring(2));
    console.log(`generateMyd3Signature messageDigest ${messageDigest.length}`);
    const privateKeyUint8Array: Uint8Array = bs58.decode(this.privateKey);
    const signaturePackage: { signature: Uint8Array; recid: number } = secp256k1.ecdsaSign(
      messageDigest,
      privateKeyUint8Array
    );

    const recidInBytes = new Uint8Array(1);
    recidInBytes[0] = (signaturePackage.recid & 0xff) + 27; // 27 is the real recId base

    return this.concatTypedArrays2(signaturePackage.signature, recidInBytes);
  }

  generateMyd2Signature(message: Uint8Array): Uint8Array {
    const messageDigest: Uint8Array = fromHexString(this.web3.utils.sha3(toHexString(message)));
    if (this.myd2!.privateKey.length >= 32) {
      return sodium.crypto_sign(messageDigest, bs58.decode(this.myd2!.privateKey), "uint8array");
    }
    return messageDigest;
  }

  generateAuthVerifyCode(appKey: string, appVersion: string, nonce: string): { verifyCode: string; signature: string } {
    const verifyCode: string = CryptoJS.SHA256(appKey + this.did + appVersion + nonce).toString();

    const utf8Encode = new TextEncoder();
    const verifyCodeBytes: Uint8Array = this.concatTypedArrays2(
      utf8Encode.encode(this.VERIFY_SIGNATURE_PREFIX),
      fromHexString(verifyCode)
    );
    const verifyCodeDigest: Uint8Array = convertWordArrayToUint8Array(
      CryptoJS.SHA256(convertUint8ArrayToWordArray(verifyCodeBytes))
    );
    const privateKeyUint8Array: Uint8Array = bs58.decode(this.privateKey);
    const signaturePackage: { signature: Uint8Array; recid: number } = secp256k1.ecdsaSign(
      verifyCodeDigest,
      privateKeyUint8Array
    );

    const recidInBytes = new Uint8Array(1);
    recidInBytes[0] = (signaturePackage.recid & 0xff) + 27; // 27 is the real recId base

    const signature: string = toHexString(this.concatTypedArrays2(signaturePackage.signature, recidInBytes));

    return { verifyCode, signature };
  }

  generateSharedKey(
    ephemeralPublicKey: Uint8Array,
    clientKeyMaterial: Uint8Array,
    serverKeyMaterial: Uint8Array
  ): Uint8Array {
    console.log(ephemeralPublicKey);

    let ecdh = createECDH("secp256k1");
    ecdh.setPrivateKey(toHexString(bs58.decode(this.privateKey)), "hex");
    let sharedKey = ecdh.computeSecret(toHexString(ephemeralPublicKey), "hex");

    // console.log(`private key ${toHexString(bs58.decode(this.privateKey))}`);

    // remove leading 0s
    let sharedKeyInHex = toHexString(sharedKey);
    // console.log(
    //   `sharedKeyInHex(org) ${sharedKeyInHex} ${sharedKeyInHex.length} ${Number(`0x${sharedKeyInHex.substring(2, 4)}`)}`
    // );
    while (
      sharedKeyInHex.startsWith("00") &&
      sharedKeyInHex.length > 2 &&
      Number(`0x${sharedKeyInHex.substring(2, 4)}`) < 128
    ) {
      // console.log(`remove leading 00 !!!`);
      sharedKeyInHex = sharedKeyInHex.substring(2);
    }
    // while (sharedKeyInHex.startsWith("00") && sharedKeyInHex.length > 2) {
    //   sharedKeyInHex = sharedKeyInHex.substring(2);
    // }

    // console.log(`ephemeral public key ${toHexString(ephemeralPublicKey)}`);
    // console.log(`ecdh shared key ${sharedKeyInHex}`);
    // console.log(`ecdh shared key length ${sharedKey.length}`);
    let allKeyMaterials: Uint8Array = this.concatTypedArrays3(
      fromHexString(sharedKeyInHex),
      clientKeyMaterial,
      serverKeyMaterial
    );
    // console.log(`clientKeyMaterial ${toHexString(clientKeyMaterial)}`);
    // console.log(`serverKeyMaterial ${toHexString(serverKeyMaterial)}`);
    // console.log(`allKeyMaterials ${toHexString(allKeyMaterials)}`);
    // console.log(`key+iv ${CryptoJS.SHA384(toHexString(allKeyMaterials)).toString()}`);
    return fromHexString(CryptoJS.SHA384(toHexString(allKeyMaterials)).toString());
  }

  decryptAes(key: Uint8Array, iv: Uint8Array, encryptedData: Uint8Array): Uint8Array {
    let GCM_AUTH_TAG_LENGTH = 16;
    let encryptedDataHex: string = toHexString(encryptedData);
    let encryptedBufferPart: string = encryptedDataHex.substring(0, encryptedDataHex.length - GCM_AUTH_TAG_LENGTH * 2);
    let authTagBufferPart: string = encryptedDataHex.substring(encryptedDataHex.length - GCM_AUTH_TAG_LENGTH * 2);
    let encryptedBuffer: Buffer = Buffer.from(encryptedBufferPart, "hex");
    let authTagBuffer: Buffer = Buffer.from(authTagBufferPart, "hex");

    const decipher = createDecipheriv("aes-256-gcm", key, iv);
    decipher.setAuthTag(authTagBuffer);
    const decrypted = Buffer.concat([decipher.update(encryptedBuffer), decipher.final()]).buffer;

    return new Uint8Array(decrypted);
  }
}
