import { OverviewItemProps } from "adminconsole/MetamaskConnector";

type BoardProps = {
  items: OverviewItemProps[];
};

export default function CompanyOverviewBoard({ items }: BoardProps) {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <td colSpan={2}>DID</td>
            <td>회사명</td>
            <td>주소</td>
            <td>잔고</td>
            <td>Coin (gas)</td>
          </tr>
        </thead>
        <tbody>
          {items?.map((c) => (
            <tr key={c.itemId}>
              <td>{c.itemId}</td>
              <td>
                <button type="button" onClick={() => copyToClipboard(c.itemId)}>
                  DID 복사
                </button>
              </td>
              <td>{c.title}</td>
              <td>{c.itemAddress}</td>
              <td>{c.netBalance}</td>
              <td>{c.coinBalance.toString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export async function copyToClipboard(text: string): Promise<void> {
  try {
    await navigator.clipboard.writeText(text);
    console.log(`copied ${text}`);
  } catch (err) {
    console.error(`failed to copy ${text}`);
  }
}
