import { setServerMode } from "api/base";
import {
  base64ToUint8Array,
  fromHexString,
  stringToUint8Array,
  toHexString,
  uint8ArrayToBase64,
  uint8ArrayToString,
} from "authconnector/blockchainutil";
import { MockMobileCore } from "clientsample/MockMobileCore";
import { log } from "console";
import { SolidityRequest, SolidityResponse } from "protobuf/DwSolidity";
import { ProposalActiveClient, ProposalActiveGlobal, ProposalStatic } from "protobuf/OpenApiServerV3DataTypes";
import { ChangeEvent, useState } from "react";
import { GetItemString, JSONTree } from "react-json-tree";
import { Gzip } from "zlibt2";

type BoardProps = {};

export default function ProtobufViewer(data: BoardProps) {
  const [hexString, setHexString] = useState("");
  const [protobufType, setProtobufType] = useState("ProposalStatic");
  const [protobufObject, setProtobufObject] = useState({});

  const [requestRaw, setRequestRaw] = useState("");
  const [purpose, setPurpose] = useState("");
  const [requestObject, setRequestObject] = useState({});

  const [convertHexSource, setConvertHexSource] = useState("");
  const [convertBase64Source, setConvertBase64Source] = useState("");
  const [convertBinarySource, setConvertBinarySource] = useState("");

  function handleProtobufTypeChange(event: ChangeEvent<HTMLSelectElement>): void {
    setProtobufType(event.target.value);
  }

  function convertHexToJson() {
    const binary = fromHexString(hexString);
    let object = {};
    switch (protobufType) {
      case "ProposalStatic":
        object = ProposalStatic.decode(binary);
        break;
      case "ProposalActiveGlobal":
        object = ProposalActiveGlobal.decode(binary);
        break;
      case "ProposalActiveClient":
        object = ProposalActiveClient.decode(binary);
        break;
      case "SolidityRequest":
        object = SolidityRequest.decode(binary);
        break;
      case "SolidityResponse":
        object = SolidityResponse.decode(binary);
        break;
    }
    setProtobufObject(object);
  }

  function convertSolidityRequestAbi() {
    if (requestRaw.startsWith("9e0ee74c")) {
      setPurpose(parseInt(requestRaw.substring(8, 8 + 64), 16).toString());
      const requestLengthHex: string = requestRaw.substring(8 + 64 * 2, 8 + 64 * 3);
      const requestLength: number = parseInt(requestLengthHex, 16);
      const requestInHex: string = requestRaw.substring(8 + 64 * 3, 8 + 64 * 3 + requestLength * 2);
      console.log(requestInHex);
      setRequestObject(SolidityRequest.decode(fromHexString(requestInHex)));
    }
    // 9e0ee74c
    // 0000000000000000000000000000000000000000000000000000000000000136
    // 0000000000000000000000000000000000000000000000000000000000000040
    // 0000000000000000000000000000000000000000000000000000000000000043
    // 080cb2013e0a3c0a3a12226469643a736e706c61623a6f6b666543486d574e3362436952734c6432763936674c22146d39180ed73a15ca8a281cc9432ec34ec37de2b50000000000000000000000000000000000000000000000000000000000
  }

  function processValue(value: unknown): unknown {
    if (value instanceof Uint8Array) {
      return toHexString(value as Uint8Array);
    }
    return value;
  }

  function convertSource(input: string) {
    let source: Uint8Array | undefined;
    if (input === "base64") {
      console.log(convertBase64Source.length);
      source = base64ToUint8Array(convertBase64Source);
      console.log(source.length);
      setConvertHexSource(toHexString(source));
      setConvertBinarySource(uint8ArrayToString(source));
    } else if (input === "binary") {
      source = stringToUint8Array(convertBinarySource);
      setConvertHexSource(toHexString(source));
      setConvertBase64Source(uint8ArrayToBase64(source));
    } else if (input === "hex") {
      if (convertHexSource.startsWith("0x")) {
        source = fromHexString(convertHexSource.substring(2));
      } else {
        source = fromHexString(convertHexSource);
      }
      console.log(convertHexSource.length);
      console.log(uint8ArrayToBase64(source).length);
      console.log(uint8ArrayToString(source).length);
      setConvertBase64Source(uint8ArrayToBase64(source));
      setConvertBinarySource(uint8ArrayToString(source));
    }
  }

  return (
    <div>
      <br />
      Protobuf viewer
      <br />
      <br />
      <select onChange={handleProtobufTypeChange} value={protobufType}>
        <option value="ProposalStatic">ProposalStatic</option>
        <option value="ProposalActiveGlobal">ProposalActiveGlobal</option>
        <option value="ProposalActiveClient">ProposalActiveClient</option>
        <option value="SolidityRequest">SolidityRequest</option>
        <option value="SolidityResponse">SolidityResponse</option>
      </select>
      <br />
      <br />
      <textarea
        rows={10}
        cols={120}
        value={hexString}
        onChange={(e) => {
          setHexString(e.target.value);
        }}
      />
      <br />
      <button type="button" onClick={convertHexToJson}>
        start
      </button>
      <br />
      <br />
      <JSONTree data={protobufObject} />
      <br />
      <br />
      Raw transaction input (Navigator.process)
      <br />
      <br />
      <textarea
        rows={10}
        cols={120}
        value={requestRaw}
        onChange={(e) => {
          setRequestRaw(e.target.value);
        }}
      />
      <br />
      <button type="button" onClick={convertSolidityRequestAbi}>
        start
      </button>
      <br />
      <br />
      Purpose: <input type="text" value={purpose} />
      <br />
      <JSONTree data={requestObject} postprocessValue={(value) => processValue(value)} />
      <br />
      <br />
      Encoder and decoder
      <br />
      <table className="bluetable">
        <tr>
          <td>HEX</td>
          <td>
            <textarea
              rows={5}
              cols={120}
              value={convertHexSource}
              onChange={(e) => {
                setConvertHexSource(e.target.value);
              }}
            />
          </td>
          <td>
            <button type="button" onClick={(e) => convertSource("hex")}>
              convert
            </button>
          </td>
        </tr>
        <tr>
          <td>Base64</td>
          <td>
            <textarea
              rows={5}
              cols={120}
              value={convertBase64Source}
              onChange={(e) => {
                setConvertBase64Source(e.target.value);
              }}
            />
          </td>
          <td>
            <button type="button" onClick={(e) => convertSource("base64")}>
              convert
            </button>
          </td>
        </tr>
        <tr>
          <td>Binary</td>
          <td>
            <textarea
              rows={5}
              cols={120}
              value={convertBinarySource}
              onChange={(e) => {
                setConvertBinarySource(e.target.value);
              }}
            />
          </td>
          <td>
            <button type="button" onClick={(e) => convertSource("binary")}>
              convert
            </button>
          </td>
        </tr>
      </table>
    </div>
  );
}
