import { useState } from "react";
import { MetamaskConnector } from "./MetamaskConnector";
import { ApplicationResource, ElasticSearchQueryTemplate, SavedBuilableResource } from "protobuf/OpenApiServerV3";
import { JSONTree } from "react-json-tree";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

type ArgumentItem = {
  key: string;
  value: string;
};

export default function ApplicationResourceBoard({ metamaskConnector }: BoardProps) {
  const [savedResources, setSavedResources] = useState<SavedBuilableResource[]>([]);
  const [selectedResourceIndex, setSelectedResourceIndex] = useState<number>(-1);

  // esqt - ElasticSearchQueryTemplate
  const [esqtIndex, setEsqtIndex] = useState<string>("");
  const [esqtBaseQuery, setEsqtBaseQuery] = useState<string>("");
  const [esqtArguments, setEsqtArguments] = useState<ArgumentItem[]>([]);
  const [esqtResultJsonPath, setEsqtResultJsonPath] = useState<string>("");
  const [esqtResultType, setEsqtResultType] = useState<string>("long");
  const [resourceForJsonTree, setResourceForJsonTree] = useState<ApplicationResource>(ApplicationResource.create());

  const [esqtEvaluationMaterial, setEsqtEvaluationMaterial] = useState<string>(
    '{ "proposal": { "static": {"purpose": "100"} }, "did": "did:snplab:WCtPNhC87J5dB4HpdyudPo9"}'
  );

  const [output, setOutput] = useState<string>("");

  function convertArguments(inArguments: { [key: string]: string }): ArgumentItem[] {
    const outArguments: ArgumentItem[] = [];
    for (let i = 0; i < Object.keys(inArguments).length; i++) {
      outArguments.push({ key: Object.keys(inArguments)[i], value: inArguments[Object.keys(inArguments)[i]] });
    }
    return outArguments;
  }

  function buildApplicationResource(): ApplicationResource {
    const applicationResource: ApplicationResource = ApplicationResource.create();
    applicationResource.elasticSearchQueryTemplate = ElasticSearchQueryTemplate.create();
    applicationResource.elasticSearchQueryTemplate.elasticSearchIndex = esqtIndex;
    applicationResource.elasticSearchQueryTemplate.baseQuery = esqtBaseQuery;
    for (let i = 0; i < esqtArguments.length; i++) {
      applicationResource.elasticSearchQueryTemplate.arguments[esqtArguments[i].key] = esqtArguments[i].value;
    }
    applicationResource.elasticSearchQueryTemplate.resultJsonPath = esqtResultJsonPath;
    applicationResource.elasticSearchQueryTemplate.resultType = esqtResultType;
    return applicationResource;
  }

  return (
    <div>
      <button
        onClick={async () => {
          let response = await metamaskConnector.listApplicationResources();
          setSavedResources(response.savedBuilableResource ?? []);
        }}
      >
        list elastic search query templates
      </button>
      <br />
      <br />
      <table>
        <thead>
          <tr>
            <td>resource no</td>
            <td>created by</td>
            <td>resource id</td>
            <td>is final version?</td>
            <td>load this version</td>
            <td>just copy</td>
            <td>make it final</td>
          </tr>
        </thead>
        <tbody>
          {savedResources.map((saved, index) => (
            <tr key={`saved-${saved.resourceNo}`}>
              <td>{saved.resourceNo}</td>
              <td>{saved.createdBy}</td>
              <td>{saved.resourceId}</td>
              <td>{saved.isFinal ? "final version" : "working"}</td>
              <td>
                <button
                  onClick={async () => {
                    if (saved.applicationResource !== undefined) {
                      if (saved.applicationResource.elasticSearchQueryTemplate !== undefined) {
                        setEsqtIndex(saved.applicationResource.elasticSearchQueryTemplate.elasticSearchIndex);
                        setEsqtBaseQuery(saved.applicationResource.elasticSearchQueryTemplate.baseQuery);
                        setEsqtArguments(
                          convertArguments(saved.applicationResource.elasticSearchQueryTemplate.arguments)
                        );
                        setEsqtResultJsonPath(saved.applicationResource.elasticSearchQueryTemplate.resultJsonPath);
                        setEsqtResultType(saved.applicationResource.elasticSearchQueryTemplate.resultType);
                        setResourceForJsonTree(saved.applicationResource);
                      } else {
                        setEsqtIndex("");
                        setEsqtBaseQuery("");
                        setEsqtArguments([]);
                        setEsqtResultJsonPath("");
                        setEsqtResultType("");
                      }
                    }
                    setSelectedResourceIndex(saved.resourceNo);
                  }}
                >
                  load this version
                </button>
              </td>
              <td>
                <button
                  onClick={async () => {
                    if (saved.applicationResource !== undefined) {
                      if (saved.applicationResource.elasticSearchQueryTemplate !== undefined) {
                        setEsqtIndex(saved.applicationResource.elasticSearchQueryTemplate.elasticSearchIndex);
                        setEsqtBaseQuery(saved.applicationResource.elasticSearchQueryTemplate.baseQuery);
                        setEsqtArguments(
                          convertArguments(saved.applicationResource.elasticSearchQueryTemplate.arguments)
                        );
                        setEsqtResultJsonPath(saved.applicationResource.elasticSearchQueryTemplate.resultJsonPath);
                        setEsqtResultType(saved.applicationResource.elasticSearchQueryTemplate.resultType);
                      } else {
                        setEsqtIndex("");
                        setEsqtBaseQuery("");
                        setEsqtArguments([]);
                        setEsqtResultJsonPath("");
                        setEsqtResultType("");
                      }
                      setSelectedResourceIndex(-1);
                    }
                  }}
                >
                  copy to new one
                </button>
              </td>
              <td>
                <button
                  onClick={async () => {
                    if (saved.applicationResource !== undefined) {
                      let response = await metamaskConnector.saveApplicationResource(
                        saved.resourceNo,
                        saved.applicationResource,
                        false,
                        true,
                        ""
                      );
                      setOutput(JSON.stringify(response));
                      let responseList = await metamaskConnector.listApplicationResources();
                      setSavedResources(responseList.savedBuilableResource ?? []);
                    }
                  }}
                >
                  make it final (readonly)
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <br />
        <br />
        <table>
          <thead>
            <tr>
              <td>item</td>
              <td>value</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Elastic search index</td>
              <td>
                <input
                  type="text"
                  value={esqtIndex}
                  onChange={(e) => {
                    setEsqtIndex(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Elastic search base query</td>
              <td>
                <textarea
                  rows={5}
                  cols={80}
                  value={esqtBaseQuery}
                  onChange={(e) => {
                    setEsqtBaseQuery(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Query arguments replacement</td>
              <td>
                <table>
                  <thead>
                    <tr>
                      <td>pattern</td>
                      <td>json-path of input</td>
                      <td>delete</td>
                    </tr>
                  </thead>
                  <tbody>
                    {esqtArguments.map((unit, index) => (
                      <tr key={`esqta${index}`}>
                        <td>
                          <input
                            type="text"
                            value={unit.key}
                            onChange={(e) => {
                              let newArguments = [];
                              for (let i = 0; i < esqtArguments.length; i++) {
                                if (esqtArguments[i] !== unit) {
                                  newArguments.push(esqtArguments[i]);
                                } else {
                                  newArguments.push({ key: e.target.value, value: unit.value });
                                }
                              }
                              setEsqtArguments(newArguments);
                            }}
                          />
                        </td>
                        <td>
                          {" "}
                          <input
                            type="text"
                            value={unit.value}
                            onChange={(e) => {
                              let newArguments = [];
                              for (let i = 0; i < esqtArguments.length; i++) {
                                if (esqtArguments[i] !== unit) {
                                  newArguments.push(esqtArguments[i]);
                                } else {
                                  newArguments.push({ key: unit.key, value: e.target.value });
                                }
                              }
                              setEsqtArguments(newArguments);
                            }}
                          />
                        </td>
                        <td>
                          {" "}
                          <button
                            onClick={async () => {
                              let newArguments = [];
                              for (let i = 0; i < esqtArguments.length; i++) {
                                if (esqtArguments[i] !== unit) {
                                  newArguments.push(esqtArguments[i]);
                                }
                              }
                              setEsqtArguments(newArguments);
                            }}
                          >
                            remove
                          </button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={3}>
                        {" "}
                        <button
                          onClick={async () => {
                            let newArguments = [];
                            for (let i = 0; i < esqtArguments.length; i++) {
                              newArguments.push(esqtArguments[i]);
                            }
                            newArguments.push({ key: "", value: "" });
                            setEsqtArguments(newArguments);
                          }}
                        >
                          add new pattern
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>Elastic search result json-path</td>
              <td>
                <input
                  type="text"
                  value={esqtResultJsonPath}
                  onChange={(e) => {
                    setEsqtResultJsonPath(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Elastic search result type</td>
              <td>
                <select onChange={(e) => setEsqtResultType(e.target.value)} value={esqtResultType}>
                  <option value="long">long</option>
                  <option value="string">string</option>
                  <option value="boolean">boolean</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>save</td>
              <td>
                {" "}
                <button
                  onClick={async () => {
                    const applicationResource = buildApplicationResource();
                    setResourceForJsonTree(applicationResource);
                    let response = await metamaskConnector.saveApplicationResource(
                      selectedResourceIndex,
                      applicationResource,
                      false,
                      false,
                      ""
                    );
                    setOutput(JSON.stringify(response));
                    let responseList = await metamaskConnector.listApplicationResources();
                    setSavedResources(responseList.savedBuilableResource ?? []);
                  }}
                >
                  save to {selectedResourceIndex < 0 ? "new" : `${selectedResourceIndex}`}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <JSONTree data={resourceForJsonTree} />
        <br />
        <textarea
          rows={5}
          cols={80}
          value={esqtEvaluationMaterial}
          onChange={(e) => {
            setEsqtEvaluationMaterial(e.target.value);
          }}
        />
        <br />
        <button
          onClick={async () => {
            let esqt = buildApplicationResource();

            if (esqt.elasticSearchQueryTemplate !== undefined) {
              let response = await metamaskConnector.runElasticSearchEvaluation(
                esqt.elasticSearchQueryTemplate,
                esqtEvaluationMaterial
              );
              setOutput(JSON.stringify(response));
            }
          }}
        >
          evaluate
        </button>
        <br />
        <div className="rcdiv">{output}</div>
      </div>
    </div>
  );
}
