import { fetchApi, fetchJsonApi, fetchJsonRawApi, fetchMyd2JsonApi, fetchPiApi } from "./base";

export const fetchNonce = (bodyData: Uint8Array) => fetchApi({ method: "POST", path: "v3/auth/nonce", bodyData });
export const fetchToken = (bodyData: Uint8Array) => fetchApi({ method: "POST", path: "v3/auth/token", bodyData });
export const fetchWalletBasedToken = (bodyData: Uint8Array) =>
  fetchApi({ method: "POST", path: "v3/auth/wallet-based-token", bodyData });
export const fetchPiToken = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/auth/pi-token", bodyData, token });
export const fetchProposalList = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/proposal/list", bodyData, token });
export const fetchIssue = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/ticket/issue", bodyData, token });
export const fetchRegister = (bodyData: Uint8Array) =>
  fetchApi({ method: "POST", path: "v3/account/register", bodyData });
export const fetchAttestation = (bodyData: Uint8Array) =>
  fetchApi({ method: "POST", path: "v3/account/attestation", bodyData });
export const fetchRewardHistory = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/reward/history", bodyData, token });
export const fetchAllSelfManagedAddress = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "GET", path: "v3/management/all-self-managed-address", bodyData, token });
export const fetchGrantSelfManagedAddress = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/self-managed-address", bodyData, token });
export const fetchRevokeSelfManagedAddress = (besuAddress: string, bodyData: Uint8Array, token: string) =>
  fetchApi({
    method: "DELETE",
    path: `v3/management/self-managed-address?besuAddress=${besuAddress}`,
    bodyData,
    token,
    ignoreAccept: true,
  });
export const fetchAllManagedAddress = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "GET", path: "v3/management/all-managed-address", bodyData, token });
export const fetchRewardDeclare = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/reward/declare", bodyData, token });
export const fetchGoodsList = (bodyData: string, token: string) =>
  fetchJsonApi({ method: "POST", path: "store/goods/list", bodyData, token });
export const fetchCouponId = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/store/coupon/id", bodyData, token });
export const fetchCouponIssue = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/store/coupon/issue", bodyData, token });
export const fetchCouponList = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/store/coupon/list", bodyData, token });
export const fetchMyd2CouponList = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/store/coupon/list-myd2", bodyData, token });
export const fetchCouponInfo = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/store/coupon/info", bodyData, token });
export const fetchGoodsLike = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/store/goods/like", bodyData, token });
export const fetchListGoodsLike = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/store/goods/like/list", bodyData, token });
export const fetchRegisterSpecialProposal = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/special-proposal", bodyData, token });
export const fetchRevokeSpecialProposal = (proposalId: string, bodyData: Uint8Array, token: string) =>
  fetchApi({
    method: "DELETE",
    path: `v3/management/special-proposal?proposalId=${proposalId}`,
    bodyData,
    token,
    ignoreAccept: true,
  });
export const fetchRefundSpecialProposal = (proposalId: string, bodyData: Uint8Array, token: string) =>
  fetchApi({
    method: "GET",
    path: `v3/management/refund-proposal?proposalId=${proposalId}`,
    bodyData,
    token,
    ignoreAccept: true,
  });
export const fetchRegisterDidFiltering = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/did-filtering", bodyData, token });
export const fetchRevokeDidFiltering = (didFilterId: string, bodyData: Uint8Array, token: string) =>
  fetchApi({
    method: "DELETE",
    path: `v3/management/did-filtering?didFilterId=${didFilterId}`,
    bodyData,
    token,
    ignoreAccept: true,
  });
export const fetchApplyDidFilteringAddress = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "GET", path: "v3/management/apply-did-filtering", bodyData, token, ignoreAccept: true });
export const fetchInviteFriendIssueCode = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/invite-friend/issue-code", bodyData, token });
export const fetchInviteFriendAcceptInvitationCode = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/invite-friend/accept-invitation-code", bodyData, token });
export const fetchAttendanceRollCall = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/attendance/roll-call", bodyData, token });
export const fetchAttendanceSubmit = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/attendance/submit", bodyData, token });
export const fetchLookupUser = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/lookup-user", bodyData, token });
export const fetchBulkMint = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/bulk-mint", bodyData, token });
export const fetchHealthCheck = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "GET", path: "v3/management/health-check", bodyData, token, ignoreAccept: true });
export const fetchSaveBuildableResource = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/save-buildable-resource", bodyData, token });
export const fetchListBuildableResource = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/list-buildable-resource", bodyData, token });
export const fetchPublishBuildableResource = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/publish-buildable-resource", bodyData, token });
export const fetchUploadBuildableResource = (bodyData: Uint8Array, formData: FormData, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/upload-buildable-resource", bodyData, token, formData });
export const fetchElasticSearchQueryEvaluation = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/elastic-search-query-evaluation", bodyData, token });
export const fetchBurnPointList = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/burn-point", bodyData, token });
export const fetchQueryBurnPointList = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/query-burn-point", bodyData, token });
export const fetchCouponTask = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/coupon-task", bodyData, token });
export const fetchDailyReport = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/daily-report", bodyData, token });
export const fetchProposalReport = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/proposal-report", bodyData, token });
export const fetchDidReport = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/did-report", bodyData, token });
export const fetchProposalCleanRedis = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/proposal-clean-redis", bodyData, token });
export const fetchDidBesuAddressInfo = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/did-besu-address-info", bodyData, token });
export const fetchBreakAwayDid = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/break-away-did", bodyData, token });
export const fetchManagerQueryRewardHistory = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/query-reward-history", bodyData, token });
export const fetchManagerMyd2CouponList = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/list-myd2-coupon", bodyData, token });
export const fetchAdminProposalUpdate = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/admin-proposal-update", bodyData, token });
export const fetchManualMyd2Migration = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/manual-myd2-migration", bodyData, token });
export const fetchUserMigrationReport = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/user-migration-statistics", bodyData, token });

export const fetchLastRequestId = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "GET", path: "v3/account/last-request-id", bodyData, token });
export const fetchUpload = (piServerUrl: string, bodyData: Uint8Array, token: string) =>
  fetchPiApi({ baseUrl: piServerUrl, method: "POST", path: "pi/upload", bodyData, token });

export const fetchCompanies = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "GET", path: "v3/management/managed-companies", bodyData, token });
export const fetchKeyPair = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/management/managed-key", bodyData, token });
export const fetchNavigator = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "GET", path: "v3/management/navigator-address", bodyData, token });

export const fetchVoipCall = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "v3/voip/call", bodyData, token });

export const fetchMyd2RewardList = (bodyData: string, token: string) =>
  fetchMyd2JsonApi({ method: "POST", path: "reward/list", bodyData, token });
export const fetchMyd2CouponPurchase = (bodyData: string, token: string) =>
  fetchMyd2JsonApi({ method: "POST", path: "store/coupon/issue", bodyData, token });

export const fetchGMORMintApi = (bodyData: any, token: string) =>
  fetchJsonRawApi({ method: "POST", path: "gmor/survey/result", bodyData, token });
export const fetchMemberInfoApi = (bodyData: Uint8Array, token: string) =>
  fetchApi({ method: "POST", path: "gmor/member-info", bodyData, token });
export const compensationForMissingPoints = (prevFewDay: number, bodyData: Uint8Array, token: string) =>
  fetchApi({
    method: "POST",
    path: `v3/management/missing-points?prevFewDay=${prevFewDay}`,
    bodyData,
    token,
    ignoreAccept: true,
  });

export const fetchChallengeDetails = (bodyData: string, token: string, challengeId: number) =>
  fetchJsonApi({ method: "GET", path: `v3/challenge/challenges/${challengeId}`, bodyData, token });
export const fetchChallengeId = (bodyData: string, token: string, challengeId: number) =>
  fetchJsonApi({ method: "GET", path: `v3/challenge/challenges/${challengeId}/blockchains`, bodyData, token });
export const fetchChallenges = (bodyData: string, token: string, challengeId: number) =>
  fetchJsonApi({ method: "POST", path: `v3/challenge/challenges/${challengeId}/participations`, bodyData, token });
