import { useState } from "react";
import { Proposal, RewardTransactionContentForApp } from "../protobuf/OpenApiServerV3DataTypes";
import ProposalItem from "./ProposalItem";
import { Myd3Blockchain } from "../authconnector/myd3";
import { MockMobileCore } from "./MockMobileCore";

type BoardProps = {
  core: MockMobileCore;
};

export default function RewardHistoryBoard({ core }: BoardProps) {
  const rewardResponse = core.rewardHistoryList;

  return (
    <div>
      <div>Balance: {`"${rewardResponse?.balance}"`}</div>
      <div>Amount expires within 1m: {`"${rewardResponse?.amountExpiresWithin1m}"`}</div>
      <table>
        <tbody>
          <tr>
            <td>Title</td>
            <td>Category</td>
            <td>Amount</td>
            <td>Created at</td>
          </tr>
          {rewardResponse?.contents.map((p) => (
            <tr key={p.createdAt}>
              <td>{`"${p.title}"`}</td>
              <td>{`"${p.category}"`}</td>
              <td>{`"${p.amount}"`}</td>
              <td>{`"${p.createdAt}"`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
