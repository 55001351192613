import { ProposalStatus } from "protobuf/OpenApiServerV3DataTypes";
import { useState } from "react";
import { JSONTree } from "react-json-tree";
import { MetamaskConnector, OverviewItemProps, SummarizedProposalReport } from "./MetamaskConnector";
import { BlockchainRole } from "protobuf/OpenApiServerV3";
import { copyToClipboard, getProtobufEnumKey } from "utils/storage";
import { CommonStatusCode } from "protobuf/CommonStatusCode";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
  queryOnly: boolean;
};

type HealthCheckProps = {
  SNPLAB_BIS_BALANCE: number;
  CERT_VALIDITY: any[];
};

export default function DailyReportBoard({ metamaskConnector, queryOnly }: BoardProps) {
  const [loaded, setLoaded] = useState(false);
  const [daysToQuery, setDaysToQuery] = useState("1");
  const [proposalId, setProposalId] = useState("");
  const [proposalReportList, setProposalReportList] = useState<SummarizedProposalReport[]>([]);
  const [healthCheckOutput, setHealthCheckOutput] = useState<HealthCheckProps>({
    SNPLAB_BIS_BALANCE: 0,
    CERT_VALIDITY: [],
  });
  const [specialUsersOverviewItems, setSpecialUsersOverviewItems] = useState(
    metamaskConnector.specialUsersOverviewItems
  );

  async function queryDaily(): Promise<void> {
    setLoaded(true);
    console.log("Trying to get report...");
    setHealthCheckOutput(await metamaskConnector.healthCheck());
    let result = await metamaskConnector.getDailyReport(Number(daysToQuery));
    setProposalReportList(result);
    console.log(`proposal report result ${result}`);

    await metamaskConnector.loadSpecialUsersOverview();
    setSpecialUsersOverviewItems(metamaskConnector.specialUsersOverviewItems);
  }

  async function querySpecificProposal(): Promise<void> {
    setLoaded(true);
    console.log("Trying to get report...");
    setHealthCheckOutput(await metamaskConnector.healthCheck());
    let result = await metamaskConnector.getProposalReport([proposalId]);
    console.log(`proposal report result ${result}`);
    setProposalReportList(result);

    await metamaskConnector.loadSpecialUsersOverview();
    setSpecialUsersOverviewItems(metamaskConnector.specialUsersOverviewItems);
  }

  return (
    <div className="rcdiv">
      <div style={{ display: queryOnly === false ? "block" : "none" }}>
        <input
          type="text"
          value={daysToQuery}
          onChange={(e) => {
            setDaysToQuery(e.target.value);
          }}
        />
        days &nbsp;
        <button
          onClick={async () => {
            queryDaily();
          }}
        >
          make a daily report
        </button>{" "}
        or make a report for a specific proposal &nbsp;
        <input
          type="text"
          value={proposalId}
          onChange={(e) => {
            setProposalId(e.target.value);
          }}
        />
        <button
          onClick={async () => {
            querySpecificProposal();
          }}
        >
          make a proposal report
        </button>
      </div>
      <div style={{ display: queryOnly ? "block" : "none" }}>
        <input
          type="text"
          value={proposalId}
          onChange={(e) => {
            setProposalId(e.target.value);
          }}
        />
        &nbsp;
        <button
          onClick={async () => {
            querySpecificProposal();
          }}
        >
          make a proposal report
        </button>
      </div>
      <br />
      <br />
      <div
        style={{
          display: loaded ? "block" : "none",
        }}
      >
        <div
          style={{
            display: queryOnly === false ? "block" : "none",
          }}
        >
          TLS certificate renewal check
          <table className="bluetable">
            <thead>
              <tr>
                <th>Domain</th>
                <th>Link</th>
                <th>Expires within</th>
              </tr>
            </thead>
            <tbody>
              {healthCheckOutput.CERT_VALIDITY.map((entity, index) => (
                <tr key={`key${index}`}>
                  <td>{entity.domain}</td>
                  <td>
                    <a href={`https://${entity.domain}`} target="_blank" rel="noreferrer">
                      link
                    </a>
                  </td>
                  <td
                    className={
                      Math.floor(
                        (new Date(entity.validUntil.replace(/KST /gi, "")).getTime() - new Date().getTime()) /
                          (24 * 60 * 60 * 1000)
                      ) <= 7
                        ? "red-alert"
                        : ""
                    }
                  >
                    {Math.floor(
                      (new Date(entity.validUntil.replace(/KST /gi, "")).getTime() - new Date().getTime()) /
                        (24 * 60 * 60 * 1000)
                    )}
                    &nbsp;days later
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
        </div>
        Data market 제안서의 redis / blockchain 연동현황 확인을 위한 테이블입니다.
        <table className="bluetable">
          <thead>
            <tr>
              <th>Proposal ID</th>
              <th>Title</th>
              <th>Status</th>
              <th>NotBefore</th>
              <th>Max</th>
              <th>Redis</th>
              <th>Issued</th>
              <th>Uploaded</th>
              <th>Rewarded</th>
            </tr>
          </thead>
          {proposalReportList.map((summarizedProposalReport, index) => (
            <tbody key={`prop${summarizedProposalReport.proposalReport.proposalId}${index}`}>
              <tr>
                <td>
                  {summarizedProposalReport.proposalReport.proposalId.substring(0, 10)}
                  {summarizedProposalReport.proposalReport.proposalId !== undefined &&
                  summarizedProposalReport.proposalReport.proposalId.length > 10
                    ? "..."
                    : ""}{" "}
                  <button
                    type="button"
                    onClick={() => copyToClipboard(summarizedProposalReport.proposalReport.proposalId)}
                  >
                    복사
                  </button>
                </td>
                <td>{summarizedProposalReport.proposalReport.proposalStatic?.content?.contentDescription?.title}</td>
                <td>
                  {getProtobufEnumKey(
                    ProposalStatus,
                    summarizedProposalReport.proposalReport.proposalActivateGlobal?.proposalStatus
                  )}
                </td>
                <td>{summarizedProposalReport.proposalReport.proposalStatic?.notBefore.substring(0, 10)}</td>
                <td>{summarizedProposalReport.proposalReport.proposalStatic?.maxIssuable}</td>
                <td
                  className={
                    summarizedProposalReport.proposalReport.ticketReportInRedis?.redisTicketCount !==
                    summarizedProposalReport.countIssued
                      ? "red-alert"
                      : ""
                  }
                >
                  {summarizedProposalReport.proposalReport.ticketReportInRedis?.redisTicketCount}
                  <span>
                    <br />
                    <button
                      style={{
                        display:
                          summarizedProposalReport.proposalReport.ticketReportInRedis?.redisTicketCount !==
                          summarizedProposalReport.countIssued
                            ? "block"
                            : "none",
                      }}
                      onClick={async () => {
                        if (
                          window.confirm(
                            "정말 해당 제안서에 대해서 clean redis 하시겠습니까? Clean 후에는 report 를 다시 로드해야합니다."
                          )
                        ) {
                          let result = await metamaskConnector.cleanProposalRedis(
                            summarizedProposalReport.proposalReport.proposalId
                          );
                          if (result.code === CommonStatusCode.STATUS_SUCCESS) {
                            summarizedProposalReport.proposalReport.ticketReportInRedis = result.ticketReportInRedis;
                          }
                        }
                      }}
                    >
                      clean redis
                    </button>
                  </span>
                </td>
                <td>{summarizedProposalReport.countIssued}</td>
                <td
                  className={
                    summarizedProposalReport.countIssued !== undefined &&
                    summarizedProposalReport.countUploaded !== undefined &&
                    summarizedProposalReport.countIssued > summarizedProposalReport.countUploaded
                      ? "red-alert"
                      : ""
                  }
                >
                  {summarizedProposalReport.countUploaded}
                </td>
                <td
                  className={
                    summarizedProposalReport.countUploaded !== undefined &&
                    summarizedProposalReport.countRewarded !== undefined &&
                    summarizedProposalReport.countUploaded > summarizedProposalReport.countRewarded
                      ? "red-alert"
                      : ""
                  }
                >
                  {summarizedProposalReport.countRewarded}
                </td>
              </tr>
              <tr>
                <td colSpan={6}> Average elapsed time (redis / upload / reward) </td>
                <td>{`${summarizedProposalReport.statAvgRedisTime.toFixed(3)}ms`}</td>
                <td>{`${summarizedProposalReport.statAvgIssueToUpload.toFixed(3)}ms`}</td>
                <td>{`${(
                  summarizedProposalReport.statAvgIssueToReward - summarizedProposalReport.statAvgIssueToUpload
                ).toFixed(3)}ms`}</td>
              </tr>
              <tr>
                <td colSpan={9} style={{ background: "lightgray" }}>
                  <details>
                    <summary>raw data</summary>
                    <JSONTree data={summarizedProposalReport} />
                  </details>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        <br />
        <br />
        <div
          style={{
            display: queryOnly === false ? "block" : "none",
          }}
        >
          필수 point 및 gas 현황입니다. Point 는 blockchain admin 권한을 가진 사용자가 point menu 에서 mint할 수 있으며,
          이력이 남습니다.
          <br />
          Gas는 자동충전되며, 위험수치 이하로 떨어질 경우 자동충전 로직에 문제가 있다고 판단해야하며, 블록체인
          개발자에게 확인을 요청해야합니다.
          <br />
          Metamask 가 해당 blockchain 에 연결되지 않았다면, Gas는 0으로 출력되는것이 정상동작입니다.
          <table className="bluetable">
            <tbody>
              <tr>
                <th>역할</th>
                <th>이름</th>
                <th>분류</th>
                <th>주소</th>
                <th>Point(잔고)</th>
                <th>Coin(Gas/SNP)</th>
              </tr>
              {specialUsersOverviewItems
                ?.filter((value: OverviewItemProps) => value.role === BlockchainRole.SNPLAB_BIS)
                .map((p) => (
                  <tr key={`snplab_bis${p.itemAddress}`}>
                    <td>{getProtobufEnumKey(BlockchainRole, p.role)}</td>
                    <td colSpan={2}>
                      기업 충전, 투표등 각종 보상을 위한 포인트 출처입니다. 10,000,000 이상 유지가 권장됩니다.
                      <br />
                      Gas 는 0.5 SNP이상이 권장됩니다.
                    </td>
                    <td>{p.itemAddress}</td>
                    <td className={healthCheckOutput.SNPLAB_BIS_BALANCE < 10000000 ? "red-alert" : ""}>
                      {healthCheckOutput.SNPLAB_BIS_BALANCE}
                    </td>
                    <td className={Number(p.coinBalance / BigInt(1000000000)) / 1000000000 <= 0.5 ? "red-alert" : ""}>
                      {Number(p.coinBalance / BigInt(1000000000)) / 1000000000}
                    </td>
                  </tr>
                ))}
              {specialUsersOverviewItems
                ?.filter((value: OverviewItemProps) => value.role === BlockchainRole.GAS_STATIONER)
                .map((p) => (
                  <tr key={`gas${p.itemAddress}`}>
                    <td>{getProtobufEnumKey(BlockchainRole, p.role)}</td>
                    <td colSpan={2}>블록체인 사용을 위한 Gas공급자 입니다. 100 SNP 이상이 권장됩니다.</td>
                    <td>{p.itemAddress}</td>
                    <td>-</td>
                    <td className={Number(p.coinBalance / BigInt(1000000000)) / 1000000000 <= 100 ? "red-alert" : ""}>
                      {Number(p.coinBalance / BigInt(1000000000)) / 1000000000}
                    </td>
                  </tr>
                ))}
              <tr>
                <th colSpan={6}>
                  BIS_MINTER: 각 docker container 에서 SNPLAB -&gt; USER 나 PROPOSAL -&gt; USER 자금이체를 위한
                  transaction 을 발급해주는 계정입니다. 0.5 SNP 이상이 권장됩니다.
                </th>
              </tr>
              {specialUsersOverviewItems
                ?.filter((value: OverviewItemProps) => value.role === BlockchainRole.BIS_MINTER)
                .map((p) => (
                  <tr key={`bisminter${p.itemAddress}`}>
                    <td>{getProtobufEnumKey(BlockchainRole, p.role)}</td>
                    <td>{p.title}</td>
                    <td>{p.subTitle}</td>
                    <td>{p.itemAddress}</td>
                    <td>-</td>
                    <td className={Number(p.coinBalance / BigInt(1000000000)) / 1000000000 <= 0.5 ? "red-alert" : ""}>
                      {Number(p.coinBalance / BigInt(1000000000)) / 1000000000}
                    </td>
                  </tr>
                ))}
              <tr>
                <th colSpan={6}>
                  ENDORSER: 각 docker container 에서 DID등록을 대행해주는 계정입니다. 0.5 SNP이상이 권장됩니다.
                </th>
              </tr>
              {specialUsersOverviewItems
                ?.filter((value: OverviewItemProps) => value.role === BlockchainRole.ENDORSER)
                .map((p) => (
                  <tr key={`endorser${p.itemAddress}`}>
                    <td>{getProtobufEnumKey(BlockchainRole, p.role)}</td>
                    <td>{p.title}</td>
                    <td>{p.subTitle}</td>
                    <td>{p.itemAddress}</td>
                    <td>-</td>
                    <td className={Number(p.coinBalance / BigInt(1000000000)) / 1000000000 <= 0.5 ? "red-alert" : ""}>
                      {Number(p.coinBalance / BigInt(1000000000)) / 1000000000}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <br />
          <details>
            <summary>Health check raw data (JSON)</summary>
            <JSONTree
              data={healthCheckOutput}
              shouldExpandNodeInitially={(keyName, data, level) => {
                if (level === 0) {
                  return true;
                }
                return false;
              }}
            />
          </details>
          <br />
          <details>
            <summary>Proposal report raw data (JSON)</summary>
            <JSONTree data={proposalReportList} />
          </details>
        </div>
      </div>
    </div>
  );
}
