import { SetStateAction, useState } from "react";
import {
  Proposal,
  ProposalActiveGlobal,
  ProposalClientStatus,
  ProposalStatus,
} from "../protobuf/OpenApiServerV3DataTypes";
import { Myd3Blockchain } from "../authconnector/myd3";
import restApiCall from "./RestApiCall";
import { IssueTicketRequest, IssueTicketResponse } from "../protobuf/OpenApiServerV3";
import { MockMobileCore } from "./MockMobileCore";

type MobileSelectedFunction = (x: MockMobileCore) => void;

type ItemProps = {
  core: MockMobileCore;
  cb: MobileSelectedFunction;
};

export default function ClientItem({ core, cb }: ItemProps) {
  // let initiallyIssuable: boolean = props.proposal.activeClient === undefined && props.proposal.activeGlobal?.proposalStatus == ProposalStatus.PROPOSAL_ACTIVATED;
  // const [issuable, setIssuable] = useState(initiallyIssuable);

  return (
    <tr>
      <td>
        <button
          type="button"
          onClick={async () => {
            cb(core);
          }}
        >
          Choose
        </button>
      </td>
      <td>{core.myd3()?.did}</td>
      <td>
        {core.myd3()?.myd2 === undefined ? "" : "2.0"} {core.myd3()?.privateKey === undefined ? "" : "3.0"}
      </td>
    </tr>
  );
}
