import CryptoJS from "crypto-js";

export const fromHexString = (hexString: any) =>
  Uint8Array.from(hexString.match(/.{1,2}/g).map((byte: any) => parseInt(byte, 16)));
export const toHexString = (bytes: any) =>
  bytes.reduce((str: any, byte: any) => str + byte.toString(16).padStart(2, "0"), "");
export const byteArrayToHexString = (byteArray: number[]) =>
  Array.from(byteArray, (byte) => byte.toString(16).padStart(2, "0")).join("");

export function convertWordArrayToUint8Array(wordArray: CryptoJS.lib.WordArray): Uint8Array {
  const len = wordArray.words.length;
  const u8Array = new Uint8Array(len << 2);
  let offset = 0;
  let word;
  let i;
  for (i = 0; i < len; i++) {
    word = wordArray.words[i];
    u8Array[offset++] = word >> 24;
    u8Array[offset++] = (word >> 16) & 0xff;
    u8Array[offset++] = (word >> 8) & 0xff;
    u8Array[offset++] = word & 0xff;
  }
  return u8Array;
}

export function convertUint8ArrayToWordArray(u8Array: Uint8Array): CryptoJS.lib.WordArray {
  const words = [];
  let i = 0;
  const len = u8Array.length;

  while (i < len) {
    words.push((u8Array[i++] << 24) | (u8Array[i++] << 16) | (u8Array[i++] << 8) | u8Array[i++]);
  }

  return CryptoJS.lib.WordArray.create(words, words.length * 4);
}

export const uint8ArrayToBase64 = (bytes: Uint8Array) => {
  return Buffer.from(bytes).toString("base64");
};

export function base64ToUint8Array(b64Data: string): Uint8Array {
  return Buffer.from(b64Data, "base64");
}

export function stringToUint8Array(str: string): Uint8Array {
  const enc = new TextEncoder();
  return enc.encode(str);
}

export function uint8ArrayToString(input: Uint8Array): string {
  const dec = new TextDecoder();
  return dec.decode(input);
}
