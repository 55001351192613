import { useState } from "react";
import { MetamaskConnector } from "./MetamaskConnector";
import { BurnPointRequest } from "protobuf/OpenApiServerV3";
import { CommonStatusCode } from "protobuf/CommonStatusCode";
import { getProtobufEnumKey } from "utils/storage";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

type BurnUnit = {
  did: string;
  besuAddress?: string;
  amount: number;
  version: string;
  burned: boolean;
  statusCode?: string;
};

export default function PointExpiryBoard({ metamaskConnector }: BoardProps) {
  const [burnUnits, setBurnUnits] = useState<BurnUnit[]>([]);
  const [expiredPointMap, setExpiredPointMap] = useState<{ [key: string]: number }>({});
  const [expiredMyd2PointMap, setExpiredMyd2PointMap] = useState<{ [key: string]: number }>({});
  const [burnedPointMap, setBurnedPointMap] = useState<{ [key: string]: number }>({});
  const [burnedMyd2PointMap, setBurnedMyd2PointMap] = useState<{ [key: string]: number }>({});

  return (
    <div>
      <button
        onClick={async () => {
          if (burnUnits.length === 0) {
            let data = await metamaskConnector.getBurnCandidates();
            setExpiredPointMap(data.candidates);
            setExpiredMyd2PointMap(data.candidatesInMyd2);

            const newBurnUnits: BurnUnit[] = [];
            const candidateKeys = Object.keys(data.candidates);
            candidateKeys.forEach((value) => {
              const newUnit: BurnUnit = { did: value, amount: data.candidates[value], version: "3.0", burned: false };
              newBurnUnits.push(newUnit);
            });

            const candidateKeysInMyd2 = Object.keys(data.candidatesInMyd2);
            candidateKeysInMyd2.forEach((value) => {
              const newUnit: BurnUnit = {
                did: value,
                amount: data.candidatesInMyd2[value],
                version: "2.0",
                burned: false,
              };
              newBurnUnits.push(newUnit);
            });

            setBurnUnits(newBurnUnits);
          }
        }}
      >
        Collect candidates
      </button>
      &nbsp;&nbsp;&nbsp;
      <button
        onClick={async () => {
          if (window.confirm("정말 burn 하시겠습니까?")) {
            let burnPointRequest = BurnPointRequest.create();
            burnPointRequest.candidates = expiredPointMap;
            burnPointRequest.candidatesInMyd2 = expiredMyd2PointMap;
            burnPointRequest.encryptedOperatorKeyInBase64 =
              "WMTnAlxD9lvcBablJ5gyWWDvSstgFfXYJq9w8u0o8gOEuC/byK47R0pV7Nyw9ESgVYnxpkPhztcxKaniAO78W+dQZwmSmhRzb61KwqzprHE=";
            let data = await metamaskConnector.burnPoints(burnPointRequest);
            setBurnedPointMap(data.processed);
            setBurnedMyd2PointMap(data.processedInMyd2);

            const processedItemsLength = Object.keys(data.processed).length;

            const expiredPointKeys = Object.keys(expiredPointMap);
            const processedItemsKeys = Object.keys(data.processed);
            expiredPointKeys.forEach((value, index) => {
              burnUnits[index].besuAddress = processedItemsKeys[index];
              burnUnits[index].statusCode = getProtobufEnumKey(
                CommonStatusCode,
                data.processed[processedItemsKeys[index]]
              );
              if (expiredPointMap[value] === CommonStatusCode.STATUS_SUCCESS) {
                burnUnits[index].burned = true;
              }
            });

            const expiredMyd2PointKeys = Object.keys(expiredMyd2PointMap);
            const processedMyd2ItemsKeys = Object.keys(data.processedInMyd2);
            expiredMyd2PointKeys.forEach((value, index) => {
              burnUnits[processedItemsLength + index].statusCode = getProtobufEnumKey(
                CommonStatusCode,
                data.processedInMyd2[processedMyd2ItemsKeys[index]]
              );
              if (data.processedInMyd2[processedMyd2ItemsKeys[index]] === CommonStatusCode.STATUS_SUCCESS) {
                burnUnits[processedItemsLength + index].burned = true;
              }
            });

            const newBurnUnits = [...burnUnits];
            setBurnUnits(newBurnUnits);
          }
        }}
      >
        Burn points
      </button>
      <br />
      Candidates
      <br />
      <table>
        <thead>
          <tr>
            <td>DID</td>
            <td>Besu address</td>
            <td>Point</td>
            <td>Version</td>
            <td>Status</td>
            <td>Burned</td>
          </tr>
        </thead>
        <tbody>
          {burnUnits.map((unit) => (
            <tr key={unit.did}>
              <td>{unit.did}</td>
              <td>{unit.besuAddress}</td>
              <td>{unit.amount}</td>
              <td>{unit.version}</td>
              <td>{unit.statusCode}</td>
              <td>{unit.burned}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
