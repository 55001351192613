import { mode } from "crypto-js";

// enable to test local PI server (8091)
const useLocalPi = false;

let serverMode = "dev";
let serverUrl = "https://api.v3.dev.myd.world/";

const checkStatus = (response: any) => {
  if (!response.ok) {
    throw new Error(`HTTP ${response.status} - ${response.statusText}`);
  }
  return response;
};

type TFetchParams = {
  method: "GET" | "POST" | "DELETE";
  path: string;
  bodyData: Uint8Array;
  token?: string;
  baseUrl?: string; // only for PI upload
  ignoreAccept?: boolean;
  formData?: FormData; // for file upload
};

type TJsonFetchParams = {
  method: "GET" | "POST";
  path: string;
  bodyData: string;
  token?: string;
  baseUrl?: string; // only for PI upload
  serverMode?: string;
};

const fetchApiInternal = async (
  url: string,
  { method, path, bodyData, token, ignoreAccept, formData }: TFetchParams
): Promise<Uint8Array> => {
  const headers: HeadersInit = {};

  if (!ignoreAccept) {
    headers["Content-Type"] = "application/x-protobuf";
    headers.Accept = "application/x-protobuf";
  }

  if (typeof token === "string" && token !== "") {
    headers["X-Auth-Token"] = token;
  }

  try {
    let params = null;
    if (formData !== undefined) {
      // file upload content-type will be filled by web browser
      delete headers["Content-Type"];
      params = {
        method,
        body: formData,
        headers,
      };
    } else if (bodyData.length > 0) {
      params = {
        method,
        body: bodyData,
        headers,
      };
    } else {
      params = {
        method,
        headers,
      };
    }
    const result = await fetch(url + path, params)
      .then((response) => checkStatus(response) && response.arrayBuffer())
      .then((buffer) => new Uint8Array(buffer));

    console.log(`Downloaded size ${result.length}`);
    return result;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }

    throw new Error("undefined");
  }
};

const fetchJsonApiInternal = async (
  url: string,
  { method, path, bodyData, token }: TJsonFetchParams
): Promise<object> => {
  const headers: HeadersInit = {};

  if (typeof token === "string" && token !== "") {
    headers["X-Auth-Token"] = token;
    headers["Content-Type"] = "application/json";
  }

  try {
    let result;
    if (bodyData.length > 0) {
      result = await fetch(url + path, {
        method,
        body: bodyData,
        headers,
      }).then((response) => checkStatus(response) && response.json());
    } else {
      result = await fetch(url + path, {
        method,
        headers,
      }).then((response) => checkStatus(response) && response.json());
    }

    console.log(`Downloaded size ${result.length}`);
    return result;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }

    throw new Error("undefined");
  }
};

const fetchJsonRawApiInternal = async (
  url: string,
  { method, path, bodyData, token }: TJsonFetchParams
): Promise<string> => {
  const headers: HeadersInit = {};

  if (typeof token === "string" && token !== "") {
    headers["X-Auth-Token"] = token;
  }

  try {
    const result = await fetch(url + path, {
      method,
      body: bodyData,
      headers,
    }).then((response) => checkStatus(response) && response.text());

    console.log(`Downloaded size ${result.length}`);
    return result;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }

    throw new Error("undefined");
  }
};

export const fetchApi = async (params: TFetchParams): Promise<Uint8Array> => {
  return fetchApiInternal(serverUrl, params);
};

export const fetchJsonApi = async (params: TJsonFetchParams): Promise<Record<string, any>> => {
  return fetchJsonApiInternal(serverUrl, params);
};

export const fetchJsonRawApi = async (params: TJsonFetchParams): Promise<string> => {
  return fetchJsonRawApiInternal(serverUrl, params);
};

export const fetchPiApi = async (params: TFetchParams): Promise<Uint8Array> => {
  return fetchApiInternal(useLocalPi ? "http://localhost:8091/" : params.baseUrl!, params);
};

export const fetchMyd2JsonApi = async (params: TJsonFetchParams): Promise<object> => {
  return fetchJsonApiInternal(serverUrl.replace(/v3\./gi, ""), params);
};

export const setServerMode = (modeIn: string) => {
  switch (modeIn) {
    case "local":
      console.log("changed to local");
      serverUrl = "http://localhost:8080/";
      serverMode = modeIn;
      break;
    case "dev":
      console.log("changed to dev");
      serverUrl = "https://api.v3.dev.myd.world/";
      serverMode = modeIn;
      break;
    case "stag":
      console.log("changed to stag");
      serverUrl = "https://api.v3.stag.myd.world/";
      serverMode = modeIn;
      break;
    case "prod":
      console.log("changed to prod");
      serverUrl = "https://api.v3.prod.myd.world/";
      serverMode = modeIn;
      break;
    case "gscert":
      console.log("changed to gs");
      serverUrl = "https://api.v3.gs.myd.world/";
      serverMode = modeIn;
      break;
    case "gscert2":
      console.log("changed to gs 2");
      serverUrl = "https://api.v3.gs2.myd.world/";
      serverMode = modeIn;
      break;
  }
};

export const getServerMode = (): string => {
  return serverMode;
};
