import { SmartContractItemLink } from "adminconsole/MetamaskConnector";

type BoardProps = {
  items: SmartContractItemLink[];
};

export default function SmartContractOverviewBoard({ items }: BoardProps) {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <td>Owner contract</td>
            <td>Address</td>
            <td colSpan={2}>Relation</td>
            <td>Contract</td>
            <td>Address</td>
            <td>Verification</td>
          </tr>
        </thead>
        <tbody>
          {items?.map((p) => (
            <tr key={p.leftContract + p.relationTitle}>
              <td>{p.leftContract}</td>
              <td>{p.leftContractAddress}</td>
              <td>.{p.relationTitle}</td>
              <td>=</td>
              <td>{p.rightContract}</td>
              <td>{p.rightContractAddress}</td>
              <td>{p.verification}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export async function copyToClipboard(text: string): Promise<void> {
  try {
    await navigator.clipboard.writeText(text);
    console.log(`copied ${text}`);
  } catch (err) {
    console.error(`failed to copy ${text}`);
  }
}
