import { Purpose } from "protobuf/DwSolidity";
import { ProposalStatic } from "protobuf/OpenApiServerV3DataTypes";
import { useState } from "react";
import { JSONTree } from "react-json-tree";
import { MetamaskConnector } from "./MetamaskConnector";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function RegisterDidFilteringProposal({ metamaskConnector }: BoardProps) {
  const newProposalStatic = ProposalStatic.create();
  newProposalStatic.purpose = Purpose.PURPOSE_DID_FILTERING;

  const now: string = new Date().toISOString();
  const farDate: string = new Date("2100-01-01T00:00:00").toISOString();
  newProposalStatic.notBefore = now;
  newProposalStatic.notAfter = farDate;

  const [proposalStatic, setProposalStatic] = useState(newProposalStatic);

  const [notBefore, setNotBefore] = useState(now);
  const [notAfter, setNotAfter] = useState(farDate);

  return (
    <div>
      <table>
        <thead>
          <tr>
            <td>field name</td>
            <td>value</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>purpose</td>
            <td>{proposalStatic.purpose}</td>
          </tr>
          <tr>
            <td>notBefore</td>
            <td>
              <input
                type="text"
                value={notBefore}
                onChange={(e) => {
                  proposalStatic.notAfter = e.target.value;
                  setNotBefore(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>notAfter</td>
            <td>
              <input
                type="text"
                value={notAfter}
                onChange={(e) => {
                  proposalStatic.notAfter = e.target.value;
                  setNotAfter(e.target.value);
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
        onClick={async () => {
          console.log("Trying to register...");
          let result = await metamaskConnector.registerSpecialProposal(proposalStatic);
          console.log(`result ${result}`);
        }}
      >
        Register the proposal now!
      </button>
      <br />
      <JSONTree data={proposalStatic} />
      <br />
    </div>
  );
}
