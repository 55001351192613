export const setItem = <T extends {}>(key: string, item: T) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(item));
  } catch (err) {
    throw new Error("session storage set error");
  }
};

export const getItem = <T>(key: string) => {
  try {
    const item = sessionStorage.getItem(key);
    if (item) {
      return JSON.parse(item) as T;
    }
    return null;
  } catch (err) {
    throw new Error("session storage get error");
  }
};

export const removeItem = (key: string) => {
  try {
    sessionStorage.removeItem(key);
  } catch (err) {
    throw new Error("session storage remove error");
  }
};

export const checkStorageInterval = (key: string, cb: any) => {
  const id = setInterval(() => {
    const item = getItem(key);
    if (item) {
      cb(item);
      clearInterval(id);
    }
  }, 100);
};

export function getProtobufEnumKey(map: any, val: any) {
  return Object.keys(map).find((key) => map[key] === val);
}

export async function copyToClipboard(text: string): Promise<void> {
  try {
    await navigator.clipboard.writeText(text);
    console.log(`copied ${text}`);
  } catch (err) {
    console.error(`failed to copy ${text}`);
  }
}
