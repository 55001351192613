import { Purpose } from "protobuf/DwSolidity";
import {
  Content,
  ContentDescription,
  InviteFriendContent,
  ProposalStatic,
  RewardPolicy,
} from "protobuf/OpenApiServerV3DataTypes";
import { useState } from "react";
import { JSONTree } from "react-json-tree";
import { MetamaskConnector } from "./MetamaskConnector";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function RegisterInviteFriendProposal({ metamaskConnector }: BoardProps) {
  const newProposalStatic = ProposalStatic.create();
  newProposalStatic.purpose = Purpose.PURPOSE_INVITE_FRIEND;
  const newInviteFriendContent = InviteFriendContent.create();
  newProposalStatic.inviteFriendContent = newInviteFriendContent;
  newProposalStatic.maxIssuable = 3;
  newProposalStatic.content = Content.create();
  newProposalStatic.content.contentDescription = ContentDescription.create();

  const now: string = new Date().toISOString();
  newProposalStatic.notBefore = now;
  newProposalStatic.notAfter = now;

  newProposalStatic.rewardPolicy = RewardPolicy.create();
  newProposalStatic.rewardPolicy!.amount = 100;

  newInviteFriendContent.giveRewardToHost = true;

  const [proposalStatic, setProposalStatic] = useState(newProposalStatic);

  const [notBefore, setNotBefore] = useState(now);
  const [notAfter, setNotAfter] = useState(now);
  const [amount, setAmount] = useState(100);
  const [imageUrl, setImageUrl] = useState("");
  const [landingUrl, setLandingUrl] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [maxCountPerInvitationCode, setMaxCountPerInvitationCode] = useState(3);
  const [unlimitedCodeList, setUnlimitedCodeList] = useState("");
  const [maxIssuableCode, setMaxIssuableCode] = useState(100);
  const [giveRewardToHost, setGiveRewardToHost] = useState(true);

  return (
    <div>
      <table className="bluetable">
        <thead>
          <tr>
            <th>field name</th>
            <th>value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>purpose</td>
            <td>{proposalStatic.purpose}</td>
          </tr>
          <tr>
            <td>notBefore</td>
            <td>
              <input
                type="text"
                value={notBefore}
                onChange={(e) => {
                  proposalStatic.notAfter = e.target.value;
                  setNotBefore(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>notAfter</td>
            <td>
              <input
                type="text"
                value={notAfter}
                onChange={(e) => {
                  proposalStatic.notAfter = e.target.value;
                  setNotAfter(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>amount</td>
            <td>
              <input
                type="text"
                value={amount}
                onChange={(e) => {
                  proposalStatic.rewardPolicy!.amount = Number(e.target.value);
                  setAmount(Number(e.target.value));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>maxIssuable</td>
            <td>
              <input
                type="text"
                value={maxIssuableCode}
                onChange={(e) => {
                  proposalStatic.maxIssuable = Number(e.target.value);
                  setMaxIssuableCode(Number(e.target.value));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>inviteFriendContent.imageUrl</td>
            <td>
              <input
                type="text"
                value={imageUrl}
                onChange={(e) => {
                  proposalStatic.inviteFriendContent!.imageUrl = e.target.value;
                  setImageUrl(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>inviteFriendContent.landingUrl</td>
            <td>
              <input
                type="text"
                value={landingUrl}
                onChange={(e) => {
                  proposalStatic.inviteFriendContent!.landingUrl = e.target.value;
                  setLandingUrl(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>inviteFriendContent.title</td>
            <td>
              <input
                type="text"
                value={title}
                onChange={(e) => {
                  proposalStatic.inviteFriendContent!.title = e.target.value;
                  proposalStatic.content!.contentDescription!.title = e.target.value;
                  setTitle(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>inviteFriendContent.description</td>
            <td>
              <input
                type="text"
                value={description}
                onChange={(e) => {
                  proposalStatic.inviteFriendContent!.description = e.target.value;
                  setDescription(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>inviteFriendContent.maxCountPerInvitationCode</td>
            <td>
              <input
                type="text"
                value={maxCountPerInvitationCode}
                onChange={(e) => {
                  proposalStatic.inviteFriendContent!.maxCountPerInvitationCode = Number(e.target.value);
                  setMaxCountPerInvitationCode(Number(e.target.value));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>inviteFriendContent.giveRewardToHost</td>
            <td>
              <input
                type="text"
                checked={giveRewardToHost}
                onChange={(e) => {
                  proposalStatic.inviteFriendContent!.giveRewardToHost = e.target.checked;
                  setGiveRewardToHost(e.target.checked);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>inviteFriendContent.unlimitedCodeList</td>
            <td>
              <textarea
                rows={5}
                cols={80}
                value={unlimitedCodeList}
                onChange={(e) => {
                  setUnlimitedCodeList(e.target.value);
                  const codeList = metamaskConnector.splitTextList(e.target.value);
                  proposalStatic.inviteFriendContent!.unlimitedCodeList = codeList;
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
        onClick={async () => {
          if (window.confirm("정말 등록 하시겠습니까?")) {
            console.log("Trying to register...");
            let result = await metamaskConnector.registerSpecialProposal(proposalStatic);
            console.log(`result ${result}`);
          }
        }}
      >
        Register the proposal now!
      </button>
      <br />
      <JSONTree data={proposalStatic} />
      <br />
    </div>
  );
}
