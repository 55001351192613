import { useState } from "react";
import {
  Proposal,
  ProposalActiveGlobal,
  ProposalClientStatus,
  ProposalStatus,
} from "../protobuf/OpenApiServerV3DataTypes";
import { Myd3Blockchain } from "../authconnector/myd3";
import { Myd2Blockchain } from "../authconnector/myd2";
import { MockMobileCore } from "./MockMobileCore";

function delay1Sec() {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, 200));
}

export class ClientManager {
  clientCores: MockMobileCore[] = [];

  public async addNewClient(includesMyd2: boolean) {
    let newCore;
    if (includesMyd2) {
      let myd2 = new Myd2Blockchain();
      await myd2.generateIfNeeded();
      newCore = new MockMobileCore(undefined, myd2);
    } else {
      newCore = new MockMobileCore(undefined);
    }
    this.clientCores.push(newCore);

    await delay1Sec();

    return newCore;
  }

  public async addNewMigrationClient(migrationDid: string, migrationVerkey: string) {
    let myd2 = new Myd2Blockchain(migrationDid, migrationVerkey, "wzd2LjfN");
    let newCore = new MockMobileCore(undefined, myd2);
    this.clientCores.push(newCore);

    await delay1Sec();

    return newCore;
  }
}
