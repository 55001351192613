import { ChangeEvent, useState } from "react";
import { JSONTree } from "react-json-tree";
import { MetamaskConnector } from "./MetamaskConnector";
import {
  HomeBannerCard,
  HomeBannerCardType,
  HomeBannerConfig,
  HomeBannerSupportedPlatform,
  SavedBuilableResource,
  homeBannerCardTypeFromJSON,
  homeBannerCardTypeToJSON,
  homeBannerSupportedPlatformFromJSON,
  homeBannerSupportedPlatformToJSON,
} from "protobuf/OpenApiServerV3";
import { getProtobufEnumKey } from "utils/storage";
import { Purpose } from "protobuf/DwSolidity";
import { CommonStatusCode } from "protobuf/CommonStatusCode";
import { getServerMode } from "api/base";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function HomeBannerBoard({ metamaskConnector }: BoardProps) {
  const [savedHomeBanners, setSavedHomeBanners] = useState<SavedBuilableResource[]>([]);
  const [homeBannerCards, setHomeBannerCards] = useState<HomeBannerCard[]>([]);
  const [cardIndex, setCardIndex] = useState(-1);
  const [configIndex, setConfigIndex] = useState(-1);

  const [title, setTitle] = useState<string>("");
  const [enabled, setEnabled] = useState(true);
  const [cardType, setCardType] = useState(
    getProtobufEnumKey(HomeBannerCardType, HomeBannerCardType.SIMPLE_BANNER_LANDING)
  );
  const [supportedPlatforms, setSupportedPlatforms] = useState(
    getProtobufEnumKey(HomeBannerSupportedPlatform, HomeBannerSupportedPlatform.SUPPORT_ALL)
  );
  const [bannerImageUrl, setBannerImageUrl] = useState<string>("");
  const [landingPageUrl, setLandingPageUrl] = useState<string>("");
  const [notBefore, setNotBefore] = useState("");
  const [notAfter, setNotAfter] = useState("");
  const [requiresAccessTokenInGetMethod, setRequiresAccessTokenInGetMethod] = useState(true);

  const [proposalId, setProposalId] = useState<string>("");
  const [purpose, setPurpose] = useState<string>("");
  const [output, setOutput] = useState<string>("");

  function handleCardTypeChange(event: ChangeEvent<HTMLSelectElement>): void {
    setCardType(event.target.value);
    updateHomeBannerCardContent();
  }

  function handleSupportedPlatformChange(event: ChangeEvent<HTMLSelectElement>): void {
    setSupportedPlatforms(event.target.value);
    updateHomeBannerCardContent();
  }

  function chooseCardContent(index: number): void {
    if (homeBannerCards.length <= index) {
      console.log("ERROR");
      return;
    }
    let homeBannerCard: HomeBannerCard = homeBannerCards[index];
    console.log(homeBannerCard);
    setBannerImageUrl(homeBannerCard.bannerImageUrl ?? "");
    setLandingPageUrl(homeBannerCard.landingPageUrl ?? "");
    setNotAfter(homeBannerCard.notAfter ?? "2023-12-01T00:00:00+09:00");
    setNotBefore(homeBannerCard.notBefore ?? "2023-08-01T00:00:00+09:00");
    setRequiresAccessTokenInGetMethod(homeBannerCard.requiresAccessTokenInGetMethod ?? true);
    setProposalId(homeBannerCard.proposalId ?? "");
    setPurpose(
      homeBannerCard.purpose === undefined ? Purpose.PURPOSE_DATA_MARKET.toString() : homeBannerCard.purpose.toString()
    );
    setTitle(homeBannerCard.title);
    setCardType(homeBannerCardTypeToJSON(homeBannerCard.type));
    setSupportedPlatforms(homeBannerSupportedPlatformToJSON(homeBannerCard.supportedPlatforms));
  }

  function updateHomeBannerCardContent(): void {
    console.log(`update ${cardIndex}`);
    let homeBannerCard: HomeBannerCard = homeBannerCards[cardIndex];
    homeBannerCard.bannerImageUrl = bannerImageUrl.length === 0 ? undefined : bannerImageUrl;
    homeBannerCard.landingPageUrl = landingPageUrl.length === 0 ? undefined : landingPageUrl;
    homeBannerCard.notAfter = notAfter.length === 0 ? undefined : notAfter;
    homeBannerCard.notBefore = notBefore.length === 0 ? undefined : notBefore;
    homeBannerCard.requiresAccessTokenInGetMethod = requiresAccessTokenInGetMethod;
    homeBannerCard.proposalId = proposalId.length === 0 ? undefined : proposalId;
    homeBannerCard.purpose = purpose.length === 0 ? undefined : Number(purpose);
    homeBannerCard.title = title;
    homeBannerCard.type = homeBannerCardTypeFromJSON(cardType);
    homeBannerCard.supportedPlatforms = homeBannerSupportedPlatformFromJSON(supportedPlatforms);

    let newHomeBannerCardsArray = [...homeBannerCards];
    setHomeBannerCards(newHomeBannerCardsArray);
  }

  const getBannerUrl = (proposalIdIn: string | undefined): string | undefined => {
    if (proposalIdIn === undefined) {
      return undefined;
    }
    let proposal = metamaskConnector.proposalIdProposalMap.get(proposalIdIn);
    if (proposal !== undefined) {
      if (proposal.static?.purpose === Purpose.PURPOSE_DATA_MARKET) {
        return proposal.static?.content?.contentImageUrl?.banner;
      }
      if (proposal.static?.purpose === Purpose.PURPOSE_INVITE_FRIEND) {
        return proposal.static?.inviteFriendContent?.imageUrl;
      }
      if (proposal.static?.purpose === Purpose.PURPOSE_ATTENDANCE) {
        return proposal.static?.attendanceContent?.imageUrl;
      }
    }
    return undefined;
  };

  const getLandingUrl = (proposalIdIn: string | undefined): string | undefined => {
    if (proposalIdIn === undefined) {
      return undefined;
    }
    let proposal = metamaskConnector.proposalIdProposalMap.get(proposalIdIn);
    if (proposal !== undefined) {
      if (proposal.static?.purpose === Purpose.PURPOSE_DATA_MARKET) {
        return proposal.static?.content?.contentImageUrl?.landing;
      }
      if (proposal.static?.purpose === Purpose.PURPOSE_INVITE_FRIEND) {
        return proposal.static?.inviteFriendContent?.landingUrl;
      }
      if (proposal.static?.purpose === Purpose.PURPOSE_ATTENDANCE) {
        return proposal.static?.attendanceContent?.landingUrl;
      }
    }
    return undefined;
  };

  return (
    <div>
      <div>
        Home banner config : an entire set of cards
        <br />
        Home banner card : one slide of the screen
        <br />
        Home banner config creation &gt; Create cards and fill the content &gt; Make it to read-only &gt; Publish
        <br />
        final version :
        <a href={`http://cdn.v3.${getServerMode()}.myd.world/homebanner/pub.json`} target="_blank" rel="noreferrer">
          JSON
        </a>
        &nbsp;
        <a href={`http://cdn.v3.${getServerMode()}.myd.world/homebanner/pub.pb`} target="_blank" rel="noreferrer">
          PROTOBUF
        </a>
        <br />
        <button
          onClick={async () => {
            await metamaskConnector.loadProposals();
            let response = await metamaskConnector.listHomeBanner();
            setSavedHomeBanners(response.savedBuilableResource ?? []);
          }}
        >
          list home banners
        </button>
        <br />
        <table>
          <thead>
            <tr>
              <td>resource id</td>
              <td>created by</td>
              <td>links</td>
              <td>is final version?</td>
              <td>load this version</td>
              <td>just copy</td>
              <td>make it final</td>
              <td>publish this</td>
              <td>published at</td>
            </tr>
          </thead>
          <tbody>
            {savedHomeBanners.map((saved, index) => (
              <tr key={`saved-${saved.resourceNo}`}>
                <td>{saved.resourceNo}</td>
                <td>{saved.createdBy}</td>
                <td>
                  <a
                    href={`http://cdn.v3.${getServerMode()}.myd.world/template/homebanner/${saved.resourceId}/pub.json`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    JSON
                  </a>
                  &nbsp;
                  <a
                    href={`http://cdn.v3.${getServerMode()}.myd.world/template/homebanner/${saved.resourceId}/pub.pb`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    PROTOBUF
                  </a>
                </td>
                <td>{saved.isFinal ? "final version" : "working"}</td>
                <td>
                  <button
                    onClick={async () => {
                      if (saved.homeBanner?.cards !== undefined) {
                        setHomeBannerCards(saved.homeBanner?.cards);
                        setConfigIndex(saved.resourceNo);
                        setCardIndex(0);
                        chooseCardContent(0);
                      }
                    }}
                  >
                    load this banner
                  </button>
                </td>
                <td>
                  <button
                    onClick={async () => {
                      if (saved.homeBanner?.cards !== undefined) {
                        setHomeBannerCards(saved.homeBanner?.cards);
                        setConfigIndex(-1);
                        setCardIndex(0);
                        chooseCardContent(0);
                      }
                    }}
                  >
                    copy to new one
                  </button>
                </td>
                <td>
                  <button
                    onClick={async () => {
                      if (saved.homeBanner !== undefined) {
                        let response = await metamaskConnector.saveHomeBanner(
                          saved.resourceNo,
                          saved.homeBanner,
                          false,
                          true
                        );
                        setOutput(JSON.stringify(response));
                      }
                    }}
                  >
                    make it final (readonly)
                  </button>
                </td>
                <td>
                  <button
                    onClick={async () => {
                      if (saved.isFinal) {
                        if (window.confirm("정말 publish 하시겠습니까?")) {
                          let response = await metamaskConnector.publishBuildableResource(configIndex);
                          setOutput(JSON.stringify(response));
                        }
                      } else {
                        alert("only final version can be published");
                      }
                    }}
                  >
                    publish this
                  </button>
                </td>
                <td>{saved.publishedAt}</td>
              </tr>
            ))}

            <tr>
              <td colSpan={7}>
                <button
                  onClick={async () => {
                    setConfigIndex(0);
                    setHomeBannerCards([]);
                    setCardIndex(0);
                  }}
                >
                  Add new config (new home banner version)
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="rcdiv">{output}</div>
        <div className="rcdiv">
          <button
            onClick={async () => {
              console.log("Trying to add...");
              let newCard = HomeBannerCard.create();
              newCard.type = HomeBannerCardType.SIMPLE_BANNER_LANDING;
              newCard.supportedPlatforms = HomeBannerSupportedPlatform.SUPPORT_ALL;
              newCard.enabled = true;
              newCard.notBefore = "2023-08-01T00:00:00+09:00";
              newCard.notAfter = "2023-12-01T00:00:00+09:00";
              newCard.requiresAccessTokenInGetMethod = true;
              let newHomeBannerCardsArray = [...homeBannerCards, newCard];
              setHomeBannerCards(newHomeBannerCardsArray);
            }}
          >
            Add new card
          </button>
          <br />
          <br />
          <div
            style={{
              display: cardIndex !== -1 ? "block" : "none",
            }}
          >
            <table>
              <thead>
                <tr>
                  <td>field name</td>
                  <td>value</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>current index</td>
                  <td>{cardIndex}</td>
                </tr>
                <tr>
                  <td>type</td>
                  <td>
                    <select onChange={handleCardTypeChange} value={cardType}>
                      <option value={getProtobufEnumKey(HomeBannerCardType, HomeBannerCardType.SIMPLE_BANNER_LANDING)}>
                        Simple banner & landing
                      </option>
                      <option value={getProtobufEnumKey(HomeBannerCardType, HomeBannerCardType.SINGLE_PROPOSAL)}>
                        Single proposal
                      </option>
                      <option
                        value={getProtobufEnumKey(HomeBannerCardType, HomeBannerCardType.CATEGORY_PROPOSAL_PURPOSE)}
                      >
                        Category by proposal purpose
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>title</td>
                  <td>
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>enabled</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={enabled}
                      onChange={(checked) => {
                        setEnabled(!enabled);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>supported platforms</td>
                  <td>
                    <select onChange={handleSupportedPlatformChange} value={supportedPlatforms}>
                      <option
                        value={getProtobufEnumKey(HomeBannerSupportedPlatform, HomeBannerSupportedPlatform.SUPPORT_ALL)}
                      >
                        All
                      </option>
                      <option
                        value={getProtobufEnumKey(
                          HomeBannerSupportedPlatform,
                          HomeBannerSupportedPlatform.SUPPORT_ANDROID
                        )}
                      >
                        Android
                      </option>
                      <option
                        value={getProtobufEnumKey(HomeBannerSupportedPlatform, HomeBannerSupportedPlatform.SUPPORT_IOS)}
                      >
                        iOS
                      </option>
                    </select>
                  </td>
                </tr>
                <tr
                  style={{
                    display:
                      cardType === homeBannerCardTypeToJSON(HomeBannerCardType.SIMPLE_BANNER_LANDING)
                        ? "block"
                        : "none",
                  }}
                >
                  <td>banner image URL</td>
                  <td>
                    <input
                      type="text"
                      value={bannerImageUrl}
                      onChange={(e) => {
                        setBannerImageUrl(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr
                  style={{
                    display:
                      cardType === homeBannerCardTypeToJSON(HomeBannerCardType.SIMPLE_BANNER_LANDING)
                        ? "block"
                        : "none",
                  }}
                >
                  <td>landing page URL</td>
                  <td>
                    <input
                      type="text"
                      value={landingPageUrl}
                      onChange={(e) => {
                        setLandingPageUrl(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr
                  style={{
                    display:
                      cardType === homeBannerCardTypeToJSON(HomeBannerCardType.SIMPLE_BANNER_LANDING)
                        ? "block"
                        : "none",
                  }}
                >
                  <td>Not before (Start time)</td>
                  <td>
                    <input
                      type="text"
                      value={notBefore}
                      onChange={(e) => {
                        setNotBefore(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr
                  style={{
                    display:
                      cardType === homeBannerCardTypeToJSON(HomeBannerCardType.SIMPLE_BANNER_LANDING)
                        ? "block"
                        : "none",
                  }}
                >
                  <td>Not after (end time)</td>
                  <td>
                    <input
                      type="text"
                      value={notAfter}
                      onChange={(e) => {
                        setNotAfter(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr
                  style={{
                    display:
                      cardType === homeBannerCardTypeToJSON(HomeBannerCardType.SIMPLE_BANNER_LANDING)
                        ? "block"
                        : "none",
                  }}
                >
                  <td>require access token (GET)</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={requiresAccessTokenInGetMethod}
                      onChange={(checked) => {
                        setRequiresAccessTokenInGetMethod(!requiresAccessTokenInGetMethod);
                      }}
                    />
                  </td>
                </tr>
                <tr
                  style={{
                    display:
                      cardType === homeBannerCardTypeToJSON(HomeBannerCardType.SINGLE_PROPOSAL) ? "block" : "none",
                  }}
                >
                  <td>Proposal ID</td>
                  <td>
                    <input
                      type="text"
                      value={proposalId}
                      onChange={(e) => {
                        setProposalId(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr
                  style={{
                    display:
                      cardType === homeBannerCardTypeToJSON(HomeBannerCardType.CATEGORY_PROPOSAL_PURPOSE)
                        ? "block"
                        : "none",
                  }}
                >
                  <td>Purpose (100 for Data market)</td>
                  <td>
                    <span>
                      <input
                        type="text"
                        value={purpose}
                        onChange={(e) => {
                          setPurpose(e.target.value);
                        }}
                      />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>update to list</td>
                  <td>
                    <button
                      onClick={async () => {
                        updateHomeBannerCardContent();
                      }}
                    >
                      update
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
          </div>
          <br />
          <br />
          <div
            style={{
              display: homeBannerCards.length > 0 ? "block" : "none",
            }}
          >
            <table>
              <thead>
                <tr>
                  {homeBannerCards.map((card, index) => (
                    <td key={index}>
                      {index}{" "}
                      <button
                        onClick={async () => {
                          setCardIndex(index);
                          chooseCardContent(index);
                        }}
                      >
                        choose
                      </button>
                      &nbsp;
                      <button
                        onClick={async () => {
                          if (index > 0) {
                            let tmp = homeBannerCards[index];
                            homeBannerCards[index] = homeBannerCards[index - 1];
                            homeBannerCards[index - 1] = tmp;
                            setCardIndex(index - 1);
                            chooseCardContent(index - 1);
                          }
                        }}
                      >
                        &lt;&lt;
                      </button>
                      &nbsp;&nbsp;{" "}
                      <button
                        onClick={async () => {
                          if (index < homeBannerCards.length - 1) {
                            let tmp = homeBannerCards[index];
                            homeBannerCards[index] = homeBannerCards[index + 1];
                            homeBannerCards[index + 1] = tmp;
                            setCardIndex(index + 1);
                            chooseCardContent(index + 1);
                          }
                        }}
                      >
                        &gt;&gt;
                      </button>
                      &nbsp;&nbsp;
                      <button
                        onClick={async () => {
                          let newHomeBannerCardsArray = [...homeBannerCards];
                          newHomeBannerCardsArray.splice(index, 1);
                          setHomeBannerCards(newHomeBannerCardsArray);
                          setCardIndex(0);
                          chooseCardContent(0);
                        }}
                      >
                        remove
                      </button>
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {homeBannerCards.map((card, index) => (
                    <td
                      key={index}
                      style={{
                        width: "20%",
                        textAlign: "center",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        verticalAlign: "top",
                      }}
                    >
                      Type: {getProtobufEnumKey(HomeBannerCardType, card.type)}
                      <br />
                      Title: {card.title}
                      <br />
                      Enabled: {card.enabled}
                      <br />
                      Supports: {card.supportedPlatforms}
                      <br />
                      <div
                        style={{
                          display: card.type === HomeBannerCardType.SIMPLE_BANNER_LANDING ? "block" : "none",
                        }}
                      >
                        Banner image URL: <a href={card.bannerImageUrl}> {card.bannerImageUrl}</a> <br />
                        <img src={card.bannerImageUrl} />
                        <br />
                        Landing page URL: <a href={card.landingPageUrl}> {card.landingPageUrl} </a> <br />
                        <br />
                        {card.landingPageUrl}
                        <br />
                        NotBefore (Start time): {card.notBefore}
                        <br />
                        NotAfter (End time): {card.notAfter}
                        <br />
                        Require access token: {card.requiresAccessTokenInGetMethod}
                      </div>
                      <div
                        style={{
                          display: card.type === HomeBannerCardType.SINGLE_PROPOSAL ? "block" : "none",
                        }}
                      >
                        Proposal ID: {card.proposalId}
                        <br />
                        <img src={getBannerUrl(card.proposalId)} width={300} />
                        <br />
                        banner: {getBannerUrl(card.proposalId)}
                        <br />
                        landing: {getLandingUrl(card.proposalId)} <br />
                        <a href={getLandingUrl(card.proposalId)}>link</a>
                      </div>
                      <div
                        style={{
                          display: card.type === HomeBannerCardType.CATEGORY_PROPOSAL_PURPOSE ? "block" : "none",
                        }}
                      >
                        Proposal Purpose: {card.purpose}
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
            <br />
            <button
              onClick={async () => {
                let homeBannerConfig = HomeBannerConfig.create();
                homeBannerConfig.cards = homeBannerCards;
                let response = await metamaskConnector.saveHomeBanner(configIndex, homeBannerConfig, false, false);
                if (response.statusCode === CommonStatusCode.STATUS_SUCCESS) {
                  setConfigIndex(response.savedBuilableResource!.resourceNo);
                  let responselist = await metamaskConnector.listHomeBanner();
                  setSavedHomeBanners(responselist.savedBuilableResource ?? []);
                }
              }}
            >
              Save this banner {configIndex !== -1 ? `config-index:${configIndex}` : ""}
            </button>
          </div>
          <JSONTree data={homeBannerCards} />
          <br />
        </div>
      </div>
    </div>
  );
}
