import { MetamaskConnector } from "adminconsole/MetamaskConnector";
import { useState } from "react";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function AdminProposalUpdate({ metamaskConnector }: BoardProps) {
  const [proposalIdToSetHidden, setProposalIdToSetHidden] = useState("");

  return (
    <div>
      <details open>
        <summary>Hiding proposal (DID filtering - hidden)</summary>
        Proposal ID
        <input
          type="text"
          value={proposalIdToSetHidden}
          onChange={(e) => {
            setProposalIdToSetHidden(e.target.value);
          }}
        />
        &nbsp;
        <button
          onClick={async () => {
            if (window.confirm("정말 제안서를 변경하시겠습니까?")) {
              console.log("Trying to check...");
              let result = await metamaskConnector.setProposalHidden(proposalIdToSetHidden, true);
              console.log(`result ${result}`);
            }
          }}
        >
          Hide
        </button>
        &nbsp;
        <button
          onClick={async () => {
            if (window.confirm("정말 제안서를 변경하시겠습니까?")) {
              console.log("Trying to check...");
              let result = await metamaskConnector.setProposalHidden(proposalIdToSetHidden, false);
              console.log(`result ${result}`);
            }
          }}
        >
          Reveal
        </button>
      </details>
    </div>
  );
}
