import { RewardTransactionContentForApp } from "protobuf/OpenApiServerV3DataTypes";
import { useState } from "react";
import { MetamaskConnector } from "./MetamaskConnector";
import { SimpleUserInfo } from "protobuf/OpenApiServerV3";
import { RewardTransactionContent } from "protobuf/OpenApiServerV3Middleware";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function DidPointAllowanceBoard({ metamaskConnector }: BoardProps) {
  const [manualMintRecipient, setManualMintRecipient] = useState("");
  const [manualBurnTarget, setManualBurnTarget] = useState("");
  const [manualMintTitle, setManualMintTitle] = useState("");
  const [manualBurnTitle, setManualBurnTitle] = useState("");
  const [bulkMintTitle, setBulkMintTitle] = useState("");
  const [manualBurnAmount, setManualBurnAmount] = useState(100);
  const [manualMintAmount, setManualMintAmount] = useState(100);
  const [bulkMintRecipient, setBulkMintRecipient] = useState("");
  const [bulkMintAmount, setBulkMintAmount] = useState(100);
  const [manualPrevFewDay, setPrevFewDay] = useState(3);

  const [allowanceOwnerAddress, setAllowanceOwnerAddress] = useState("");
  const [allowanceSpenderAddress, setAllowanceSpenderAddress] = useState("");
  const [allowancePoint, setAllowancePoint] = useState("");

  const [pointOwnerAddress, setPointOwnerAddress] = useState("");
  const [ownedPointBalance, setOwnedPointBalance] = useState("");
  const [ownedCoin, setOwnedCoin] = useState(BigInt(0));

  return (
    <div>
      <details open>
        <summary>Balance</summary>
        Address{" "}
        <input
          type="text"
          name="owner"
          value={pointOwnerAddress}
          onChange={(e) => setPointOwnerAddress(e.target.value)}
        />
        <button
          onClick={async () => {
            let pointBalance = await metamaskConnector.getPoint(pointOwnerAddress);
            let coinBalance = await metamaskConnector.getCoinBalance(pointOwnerAddress);
            setOwnedPointBalance(pointBalance.toString());
            setOwnedCoin(coinBalance);
          }}
        >
          Get point and coin balance
        </button>
        <br />
        <table className="bluetable">
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Point (Token) balance</td>
              <td>User (balance), Company (balance), Proposal (balance)</td>
              <td>{ownedPointBalance}</td>
            </tr>
            <tr>
              <td>Coin (Gas) balance</td>
              <td>Wei</td>
              <td>{ownedCoin.toString()}</td>
            </tr>
            <tr>
              <td>Coin (Gas) balance</td>
              <td>Gwei</td>
              <td>{(ownedCoin / BigInt(1000000000)).toString()}</td>
            </tr>
            <tr>
              <td>Coin (Gas) balance</td>
              <td>ETH (SNP)</td>
              <td>{(ownedCoin / BigInt(1000000000000000000)).toString()}</td>
            </tr>
          </tbody>
        </table>
      </details>
      <br />
      <br />

      <br />
      <br />
      <details open>
        <summary>Allowance (BIS MINTER transfer allowance)</summary>
        Owner (Proposal){" "}
        <input
          type="text"
          name="allowance-owner"
          value={allowanceOwnerAddress}
          onChange={(e) => setAllowanceOwnerAddress(e.target.value)}
        />
        <br />
        Spender (BIS_MINTER) &nbsp;
        <input
          type="text"
          name="allowance-spender"
          value={allowanceSpenderAddress}
          onChange={(e) => setAllowanceSpenderAddress(e.target.value)}
        />
        <button
          onClick={async () => {
            let allowance = await metamaskConnector.getApprovedAllowance(
              allowanceOwnerAddress,
              allowanceSpenderAddress
            );
            setAllowancePoint(allowance.toString());
          }}
        >
          Get allowance balance
        </button>
        <br />
        <table>
          <thead>
            <tr>
              <td>Title</td>
              <td>Description</td>
              <td>Value</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Allowance point balance</td>
              <td>Company (refundable deposit)</td>
              <td>{allowancePoint}</td>
            </tr>
          </tbody>
        </table>
      </details>
      <br />
      <br />
      <details open>
        <summary>Manual mint to USER or COMPANY (token)</summary>
        기업이나 사용자, SNPLAB_BIS등 입력된 주소에 고객센터 명의로 포인트를 추가합니다.
        <br />
        Recipient{" "}
        <input
          type="text"
          name="recipient"
          value={manualMintRecipient}
          onChange={(e) => setManualMintRecipient(e.target.value)}
        />{" "}
        &nbsp; Title{" "}
        <input type="text" name="title" value={manualMintTitle} onChange={(e) => setManualMintTitle(e.target.value)} />{" "}
        &nbsp; Amount{" "}
        <input
          type="text"
          name="amount"
          value={manualMintAmount}
          onChange={(e) => setManualMintAmount(Number(e.target.value))}
        />
        <button
          onClick={async () => {
            await metamaskConnector.manualMint(manualMintRecipient, manualMintAmount, manualMintTitle);
          }}
        >
          Manual mint
        </button>
      </details>
      <br />
      <br />
      <details open>
        <summary>Manual burn point of USER or COMPANY (token)</summary>
        기업이나 사용자, SNPLAB_BIS등 입력된 주소의 포인트를 제거합니다.
        <br />
        TargetBesuAddress{" "}
        <input
          type="text"
          name="targetDid"
          value={manualBurnTarget}
          onChange={(e) => setManualBurnTarget(e.target.value)}
        />{" "}
        &nbsp; Title{" "}
        <input type="text" name="title" value={manualBurnTitle} onChange={(e) => setManualBurnTitle(e.target.value)} />{" "}
        &nbsp; Amount{" "}
        <input
          type="text"
          name="amount"
          value={manualBurnAmount}
          onChange={(e) => setManualBurnAmount(Number(e.target.value))}
        />
        <button
          onClick={async () => {
            await metamaskConnector.manualBurn(manualBurnTarget, manualBurnAmount, manualBurnTitle);
          }}
        >
          Manual burn
        </button>
      </details>
      <br />
      <br />
      <details open>
        <summary>Bulk mint to USERs</summary>
        여러 사용자에게 소량의 금액을 일괄 지급합니다. SNPLAB_BIS계정에서 차감됩니다
        <br />
        Recipients <br />
        <textarea
          rows={5}
          cols={80}
          value={bulkMintRecipient}
          onChange={(e) => {
            setBulkMintRecipient(e.target.value);
          }}
        />
        &nbsp; Title{" "}
        <input type="text" name="title" value={bulkMintTitle} onChange={(e) => setBulkMintTitle(e.target.value)} />{" "}
        <br /> Amount{" "}
        <input
          type="text"
          name="amount"
          value={bulkMintAmount}
          onChange={(e) => setBulkMintAmount(Number(e.target.value))}
        />
        <button
          onClick={async () => {
            let dids = metamaskConnector.splitUsers(bulkMintRecipient);
            let userInfos: SimpleUserInfo[] = await metamaskConnector.lookupUsers(dids);

            // example content
            const rewardTransactionContent: RewardTransactionContent = RewardTransactionContent.create();
            const rewardTransactionContentForApp: RewardTransactionContentForApp =
              RewardTransactionContentForApp.create();
            rewardTransactionContentForApp.amount = bulkMintAmount;
            rewardTransactionContentForApp.category = "VOC";
            rewardTransactionContentForApp.title = bulkMintTitle;
            rewardTransactionContentForApp.createdAt = new Date().toISOString();
            rewardTransactionContent.app = rewardTransactionContentForApp;

            await metamaskConnector.bulkMint(userInfos, rewardTransactionContent);
          }}
        >
          Bulk mint
        </button>
      </details>
      <br />
      <br />
      <details open>
        <summary>Unpaid point compensation</summary>
        블록체인 장애 등으로 사용자에게 미지급된 포인트에 대한 보상을 진행합니다.
        <br />
        (reward_transaction_content 테이블에 is_tx_completed가 false 이고, 출석체크, 투표, GMOR, TNK 미지급 건 한정)
        <br />
        지급 보상일(숫자만 입력 (ex: 3 입력 시 현재일로부터 3일전의 미지급건까지만 보상)):&nbsp;
        <input
          type="number"
          name="prevFewDay"
          value={manualPrevFewDay}
          onChange={(e) => setPrevFewDay(Number(e.target.value))}
        />
        &nbsp;
        <button
          onClick={async () => {
            if (manualBurnAmount > 0) {
              let result = await metamaskConnector.compensationForMissingPoints(manualPrevFewDay);
              if (result) {
                alert("보상이 완료되었습니다.");
              } else {
                alert("보상이 실패되었습니다.");
              }
              console.log(`result ${result}`);
            } else {
              alert("0이상의 숫자값을 입력하세요.");
            }
          }}
        >
          Points paid
        </button>
      </details>
    </div>
  );
}
