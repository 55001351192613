import bs58 from "bs58";

import {
  Proposal,
  ProposalFiltering,
  ProposalStatus,
  RewardTransactionContentForApp,
  VoIPP2PMessageDirection,
  VoIPP2PMessageType,
  VoIPSignalAction,
  VoIPSignalRequest,
  VoIPSignalResponse,
} from "../protobuf/OpenApiServerV3DataTypes";
import { Myd3Blockchain } from "../authconnector/myd3";
import { Myd2Blockchain } from "../authconnector/myd2";
import {
  AttendanceRollCallRequest,
  AttendanceRollCallResponse,
  AttendanceSubmitRequest,
  AttendanceSubmitResponse,
  CouponInfo,
  GetLastRequestIdResponse,
  InviteFriendGuestRequest,
  InviteFriendGuestResponse,
  InviteFriendHostRequest,
  InviteFriendHostResponse,
  LikeGoodsRequest,
  LikeGoodsResponse,
  ListCouponDetailsRequest,
  ListCouponDetailsResponse,
  ListCouponRequest,
  ListCouponResponse,
  ListGoodsLikeRequest,
  ListGoodsLikeResponse,
  ListProposalRequest,
  ListProposalResponse,
  ListRewardHistoryRequest,
  ListRewardHistoryRequestType,
  ListRewardHistoryResponse,
  UploadPiRequest,
  UploadPiResponse,
} from "../protobuf/OpenApiServerV3";
import {
  fetchAttendanceRollCall,
  fetchAttendanceSubmit,
  fetchCouponInfo,
  fetchCouponList,
  fetchGoodsLike,
  fetchGoodsList,
  fetchInviteFriendAcceptInvitationCode,
  fetchInviteFriendIssueCode,
  fetchIssue,
  fetchLastRequestId,
  fetchListGoodsLike,
  fetchMyd2CouponPurchase,
  fetchMyd2RewardList,
  fetchNonce,
  fetchPiToken,
  fetchProposalList,
  fetchRegister,
  fetchRewardHistory,
  fetchToken,
  fetchUpload,
  fetchVoipCall,
} from "../api";
import { GetAccessTokenResult, GetPiAccessTokenResult, Myd3AuthConnector } from "authconnector/myd3AuthConnector";
import { CommonStatusCode } from "protobuf/CommonStatusCode";
import { Purpose } from "protobuf/DwSolidity";
import { getProtobufEnumKey } from "utils/storage";

function delay1Sec() {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, 1000));
}

function toIsoString(date: Date) {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";
  const pad = (num: number) => (num < 10 ? "0" : "") + num;

  let output = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
  output += `T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
  output += `${dif}${pad(Math.floor(Math.abs(tzo) / 60))}:${pad(Math.abs(tzo) % 60)}`;
  return output;
}

type MockMobileData = {
  lastRequestId: number;
  requestIdMapping: string[] | undefined;
  lastListedTime: string | undefined;
  jsonProposals: string[] | undefined;

  myd3AuthConnectorState: string | undefined;
};

type Myd2RewardPayload = {
  data: string;
  message: string;
  status: number;
};

const defaultApplicaitonInfo = { appVersion: "Test/1.0", appKey: "1234567890abcdefghijklmnopqrstuv" };

export class MockMobileCore {
  myd3AuthConnector: Myd3AuthConnector | undefined;
  myd3AuthConnectorState: string | undefined;
  lastRequestId: number = 0;
  nextRequestId: number = 1;
  lastListedTime: string | undefined;
  proposalsMap: Map<string, Proposal> = new Map<string, Proposal>();
  didFiltering?: ProposalFiltering;
  issueRequestIdMap: Map<string, number> = new Map<string, number>();
  rewardHistoryList: ListRewardHistoryResponse | undefined;
  couponList: any;
  goodsList: any;
  likeList: any;
  sortType: number = 0;
  roomId: string | undefined;
  requestList: any;
  room: any;
  socket!: WebSocket;
  socketState: string | undefined;
  registerRequestPrefix: string = "world.myd.didregistration";
  issueTicketPrefix: string = "world.myd.ticket-issue";

  constructor(data?: string, myd2?: Myd2Blockchain) {
    let saveMyd3AuthConnectorState = (arg: string) => {
      this.myd3AuthConnectorState = arg;
      return true;
    };

    if (data === "empty") {
      // empty object
    } else if (data !== undefined) {
      const storedData: MockMobileData = JSON.parse(data);

      if (storedData.jsonProposals !== undefined) {
        storedData.jsonProposals.forEach((val) => {
          const p = Proposal.fromJSON(JSON.parse(val));
          this.proposalsMap.set(p.proposalId, p);
          if (p.static?.purpose === Purpose.PURPOSE_DID_FILTERING) {
            this.didFiltering = p.activeClient?.filtering;
          }
        });
      }

      if (storedData.lastListedTime !== undefined) {
        this.lastListedTime = storedData.lastListedTime;
      }

      this.lastRequestId = storedData.lastRequestId;

      if (storedData.myd3AuthConnectorState !== undefined) {
        this.myd3AuthConnectorState = JSON.parse(storedData.myd3AuthConnectorState);
      }

      this.myd3AuthConnector = new Myd3AuthConnector(
        defaultApplicaitonInfo,
        saveMyd3AuthConnectorState,
        JSON.stringify(this.myd3AuthConnectorState)
      );
    } else if (myd2) {
      // do not create myd3 wallet
      let dataFromMyd2 = {
        did: myd2.did,
        myd2Verkey: myd2.verkey, // base58
        myd2PrivateKey: myd2.privateKey, // base58
      };
      this.myd3AuthConnector = new Myd3AuthConnector(
        defaultApplicaitonInfo,
        saveMyd3AuthConnectorState,
        undefined,
        dataFromMyd2
      );
    } else {
      this.myd3AuthConnector = new Myd3AuthConnector(defaultApplicaitonInfo, saveMyd3AuthConnectorState);
    }
  }

  saveState(arg: string) {
    this.myd3AuthConnectorState = arg;
  }

  getMobileData(): string {
    const jsonProposals: string[] = [];
    console.log(`getMobileData length of list ${this.proposalsMap.keys.length}`);
    this.proposalsMap.forEach((value, key, obj) => {
      const json = JSON.stringify(Proposal.toJSON(value));
      jsonProposals.push(json);
    });

    const proposalRequestIdMapping: string[] = [];
    this.issueRequestIdMap.forEach((value, key, obj) => {
      const json = JSON.stringify({ key, value });
      jsonProposals.push(json);
    });

    const mobileData: MockMobileData = {
      lastRequestId: this.lastRequestId,
      requestIdMapping: proposalRequestIdMapping,
      lastListedTime: this.lastListedTime,
      jsonProposals,
      myd3AuthConnectorState: this.myd3AuthConnectorState,
    };

    return JSON.stringify(mobileData);
  }

  myd3(): Myd3Blockchain | undefined {
    return this.myd3AuthConnector?.myd3;
  }

  accessToken(): string | undefined {
    return this.myd3AuthConnector?.state.accessToken;
  }

  getProposal(proposalId: string): Proposal | undefined {
    return this.proposalsMap.get(proposalId);
  }

  private randomBytes(count: number): Uint8Array {
    const result = new Uint8Array(count);
    for (let i = 0; i < count; ++i) {
      result[i] = Math.floor(256 * Math.random());
    }
    return result;
  }

  async getAccessToken(mid: string | undefined): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    const result: GetAccessTokenResult = await this.myd3AuthConnector.getAccessToken(mid);
    console.log(`access token result ${JSON.stringify(result)}`);
    return result.accessToken!;
  }

  async getPiAccessToken(pdsId: string): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    const result: GetPiAccessTokenResult = await this.myd3AuthConnector.getPiAccessToken(pdsId);
    return result.piAccessToken!;
  }

  async getLastRequestId(): Promise<string> {
    if (!this.accessToken()) {
      throw new Error("accessToken is not initialized");
    }

    const res = await fetchLastRequestId(new Uint8Array(0), this.accessToken()!);
    const decodedRes = GetLastRequestIdResponse.decode(res);

    this.lastRequestId = decodedRes.lastRequestId;
    this.nextRequestId = this.lastRequestId + 1;

    return JSON.stringify(decodedRes);
  }

  increaseNextRequestId() {
    this.nextRequestId++;
  }

  async listProposals(
    proposalIds: string[] | undefined = undefined,
    purposes: number[] | undefined = undefined,
    fetchStatic: boolean = true,
    fetchActiveGlobal: boolean = true,
    fetchActiveClient: boolean = true,
    lastListedTime: string | undefined = this.lastListedTime
  ): Promise<string> {
    if (!this.accessToken) {
      throw new Error("accessToken is not initialized");
    }

    const requestedDate = new Date();
    const listProposalRequest: ListProposalRequest = ListProposalRequest.create();
    listProposalRequest.fetchStatic = fetchStatic;
    listProposalRequest.fetchActiveGlobal = fetchActiveGlobal;
    listProposalRequest.fetchActiveClient = fetchActiveClient;

    if (purposes !== undefined) {
      listProposalRequest.purposes = purposes;
    }

    if (proposalIds !== undefined) {
      listProposalRequest.proposalIds = proposalIds;
    }

    if (lastListedTime !== undefined) {
      listProposalRequest.updatedAfter = lastListedTime;
    }
    const paramBuf = ListProposalRequest.encode(listProposalRequest).finish();
    const res = await fetchProposalList(paramBuf, this.accessToken()!);
    const decodedRes = ListProposalResponse.decode(res);

    decodedRes.proposals.forEach((val) => {
      console.log(val);
      const proposalInState = this.proposalsMap.get(val.proposalId);
      if (proposalInState === undefined) {
        // new proposal
        this.proposalsMap.set(val.proposalId, val);
        if (val.static?.purpose === Purpose.PURPOSE_DID_FILTERING) {
          this.didFiltering = val.activeClient?.filtering;
        }
      } else {
        if (val.static !== undefined) {
          proposalInState.static = val.static;
        }
        if (val.activeGlobal !== undefined) {
          proposalInState.activeGlobal = val.activeGlobal;
        }
        if (val.activeClient !== undefined) {
          proposalInState.activeClient = val.activeClient;
          if (proposalInState.static?.purpose === Purpose.PURPOSE_DID_FILTERING) {
            this.didFiltering = val.activeClient?.filtering;
          }
        }
        this.proposalsMap.set(val.proposalId, proposalInState);
      }
      console.log(val.proposalId);
    });
    this.lastListedTime = toIsoString(requestedDate).replace(/\n/g, "");
    console.log(this.lastListedTime);

    return `lastListedTime ${this.lastListedTime} \n Downloaded ${decodedRes.proposals.length} proposals`;
  }

  async issueTicket(proposalId: string): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    let issueRes = await this.myd3AuthConnector.issueTicket(proposalId);
    if (issueRes.status !== CommonStatusCode.STATUS_SUCCESS) {
      throw new Error(`myd3AuthConnector issue failed ${getProtobufEnumKey(CommonStatusCode, issueRes.status)}`);
    }
    let result = issueRes.issueTicketResponse!;

    if (result.proposalId === proposalId && result.proposalActiveClient !== undefined) {
      const proposalInState = this.proposalsMap.get(result.proposalId);
      if (proposalInState !== undefined) {
        proposalInState.activeClient = result.proposalActiveClient;
      }
    }

    return JSON.stringify(issueRes);
  }

  async uploadPi(uploadUrl: string, pdsId: string, proposalId: string, gzippedPayload: Uint8Array): Promise<string> {
    const ticketId = pdsId;
    const piAccessToken = await this.getPiAccessToken(ticketId);

    console.log(`ticketId ${ticketId}`);
    const uploadPiRequest: UploadPiRequest = UploadPiRequest.create();
    uploadPiRequest.pdsId = pdsId;
    uploadPiRequest.proposalId = proposalId;
    uploadPiRequest.gzippedPayload = gzippedPayload;

    console.log(UploadPiRequest.toJSON(uploadPiRequest));

    const paramBuf = UploadPiRequest.encode(uploadPiRequest).finish();
    const res = await fetchUpload(`${uploadUrl}/`, paramBuf, piAccessToken);
    const decodedRes = UploadPiResponse.decode(res);

    return JSON.stringify(UploadPiResponse.toJSON(decodedRes));
  }

  async deviceRegistration(): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    return JSON.stringify(await this.myd3AuthConnector.deviceRegistration());
  }

  async listMyd2Rewards(): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }
    if (!this.accessToken) {
      throw new Error("access token is not initialized");
    }
    let request = {
      did: this.myd3AuthConnector.myd3.did,
      payload: {
        Index: "OwnerAndStatus",
        Value: this.myd3AuthConnector.myd3.did,
        Subvalue: "false",
      },
    };
    const res: Myd2RewardPayload = (await fetchMyd2RewardList(
      JSON.stringify(request),
      this.accessToken()!
    )) as Myd2RewardPayload;
    console.log(res);
    let payload = JSON.parse(res.data);
    console.log(payload);
    // return JSON.stringify(await this.myd3AuthConnector.deviceRegistration());
    return "ok";
  }

  async purchaseMyd2Coupon(goodsCode: string, goodsPrice: number, myd2PublicKey: string): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }
    if (!this.accessToken) {
      throw new Error("access token is not initialized");
    }

    // list
    let listRequest = {
      did: this.myd3AuthConnector.myd3.did,
      payload: {
        Index: "OwnerAndStatus",
        Value: this.myd3AuthConnector.myd3.did,
        Subvalue: "false",
      },
    };
    const res: Myd2RewardPayload = (await fetchMyd2RewardList(
      JSON.stringify(listRequest),
      this.accessToken()!
    )) as Myd2RewardPayload;
    let payload = JSON.parse(res.data);
    console.log(payload);

    // token list
    let tokenList: string[] = [];
    payload.payload.Payload.forEach(function (obj: { [x: string]: any }) {
      console.log(obj.TokenID);
      tokenList.push(obj.TokenID);
    });

    // coupon id
    // CB20230914xS1iukdqh40cqbd
    let hourmap = "abcdefghijklmnopqrstuvwxyz";
    let minutemap = "ABCDEFGHIJLKMNOPQRSTUVWXYZ[\\]^-`abcdefghijklmnopqrstuvwxyz{|}";
    let date = new Date();
    let transactionId = `CB${date.getFullYear()}${this.leftpad(date.getMonth() + 1, 2)}${this.leftpad(
      date.getDate(),
      2
    )}${hourmap.at(date.getHours())}${minutemap.at(date.getMinutes())}1iukdqh`;

    // purchase
    let purchaseRequest = {
      did: this.myd3AuthConnector.myd3.did,
      payload: {
        Price: goodsPrice,
        GoodsCode: goodsCode,
        Input: tokenList,
        TransactionId: transactionId,
        PublicKey: myd2PublicKey,
      },
    };
    console.log(purchaseRequest);

    let purchaseRes = await fetchMyd2CouponPurchase(JSON.stringify(purchaseRequest), this.accessToken()!);
    console.log(purchaseRes);

    return "ok";
  }

  leftpad(val: any, resultLength = 2, leftpadChar = "0"): string {
    return (String(leftpadChar).repeat(resultLength) + String(val)).slice(String(val).length);
  }

  async listRewardHistory(): Promise<string> {
    const now = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 2);

    const listRewardHistoryRequest: ListRewardHistoryRequest = ListRewardHistoryRequest.create();
    listRewardHistoryRequest.did = this.myd3AuthConnector?.myd3.did!;
    listRewardHistoryRequest.requestType = ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_ALL;
    listRewardHistoryRequest.startAt = toIsoString(lastMonth);
    listRewardHistoryRequest.endAt = toIsoString(now);
    listRewardHistoryRequest.pageSize = 10;
    listRewardHistoryRequest.pageNum = 0;

    const paramBuf = ListRewardHistoryRequest.encode(listRewardHistoryRequest).finish();
    const res = await fetchRewardHistory(paramBuf, this.accessToken()!);
    this.rewardHistoryList = ListRewardHistoryResponse.decode(res);

    return JSON.stringify(ListRewardHistoryResponse.toJSON(this.rewardHistoryList));
  }

  async listGoods(): Promise<string> {
    const now = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    const request = {
      did: this.myd3AuthConnector?.myd3.did,
      payload: { Version: "" },
    };

    const res = await fetchGoodsList(JSON.stringify(request), this.accessToken()!);
    const stringData = JSON.stringify(res);
    const jsonData = JSON.parse(stringData);
    this.goodsList = JSON.parse(jsonData.data).payload.result.goodsList;
    return JSON.stringify(this.goodsList);
  }

  changeSortType(sortType: number): string {
    if (sortType === 1) {
      this.sortType = 1;
      this.goodsList = this.goodsList.sort(
        (a: any, b: any) => new Date(b.newest).getTime() - new Date(a.newest).getTime()
      );
      return JSON.stringify(this.goodsList);
    }
    if (sortType === 2) {
      this.sortType = 2;
      this.goodsList = this.goodsList.sort((a: any, b: any) => b.downloads - a.downloads);
      return JSON.stringify(this.goodsList);
    }
    this.sortType = 0;
    return JSON.stringify(this.goodsList);
  }

  async purchaseManyCoupons(goodsCode: string, salePrice: number) {
    for (let i = 0; i < 100; i++) {
      // eslint-disable-next-line no-await-in-loop
      await this.purchaseCoupon(goodsCode, salePrice);
      // eslint-disable-next-line no-await-in-loop
      await delay1Sec();
      // eslint-disable-next-line no-await-in-loop
      await delay1Sec();
    }
  }

  async purchaseCoupon(goodsCode: string, salePrice: number): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }
    let issueRes = await this.myd3AuthConnector.issueCoupon(goodsCode, salePrice);

    return JSON.stringify(issueRes);
  }

  async requestChallenge(challengeId: number): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    let challengeDetails = await this.myd3AuthConnector.getChallenge(challengeId);

    let issueRes = await this.myd3AuthConnector.requestChallenge(
      challengeId,
      challengeDetails.data.applyPointPerPerson
    );

    return JSON.stringify(issueRes);
  }

  async likeGoods(goodsCode: string): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    const likeGoodsRequest: LikeGoodsRequest = LikeGoodsRequest.create();
    likeGoodsRequest.did = this.myd3AuthConnector?.myd3.did!;
    likeGoodsRequest.goodsCodes = [goodsCode];
    const paramBuf = LikeGoodsRequest.encode(likeGoodsRequest).finish();

    let res = await fetchGoodsLike(paramBuf, this.accessToken()!);

    return JSON.stringify(LikeGoodsResponse.decode(res));
  }

  async listGoodsLike(): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    const listGoodsLikeRequest: ListGoodsLikeRequest = ListGoodsLikeRequest.create();
    listGoodsLikeRequest.did = this.myd3AuthConnector?.myd3.did!;
    const paramBuf = ListGoodsLikeRequest.encode(listGoodsLikeRequest).finish();

    let res = await fetchListGoodsLike(paramBuf, this.accessToken()!);

    const decodedRes = ListGoodsLikeResponse.decode(res);

    this.likeList = decodedRes.goodsCodes;

    return JSON.stringify(this.likeList);
  }

  async listCoupon(): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }
    let res = await this.myd3AuthConnector.listCoupon();

    if (res.status !== CommonStatusCode.STATUS_SUCCESS) {
      throw new Error("myd3AuthConnector list coupon failed");
    }

    this.couponList = res.coupons;

    return JSON.stringify(this.couponList);
  }

  async listMyd2Coupon(): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }
    let res = await this.myd3AuthConnector.listMyd2Coupon();

    if (res.status !== CommonStatusCode.STATUS_SUCCESS) {
      throw new Error("myd3AuthConnector list coupon failed");
    }

    this.couponList = res.coupons;

    return JSON.stringify(this.couponList);
  }

  async infoCoupon(couponTransactionId: string): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    const listCouponDetailsRequest: ListCouponDetailsRequest = ListCouponDetailsRequest.create();
    listCouponDetailsRequest.did = this.myd3AuthConnector?.myd3.did!;
    listCouponDetailsRequest.couponTransactionIds = [couponTransactionId];
    const paramBuf = ListCouponDetailsRequest.encode(listCouponDetailsRequest).finish();
    let res = await fetchCouponInfo(paramBuf, this.accessToken()!);
    const decodedRes = ListCouponDetailsResponse.decode(res);

    return JSON.stringify(ListCouponDetailsResponse.toJSON(decodedRes));
  }

  async generateInvitationCode(): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }
    const inviteFriendHostRequest: InviteFriendHostRequest = InviteFriendHostRequest.create();
    inviteFriendHostRequest.did = this.myd3AuthConnector.myd3.did;
    inviteFriendHostRequest.mid = this.myd3AuthConnector.myd3.did.substring(10);
    const paramBuf = InviteFriendHostRequest.encode(inviteFriendHostRequest).finish();
    let res = await fetchInviteFriendIssueCode(paramBuf, this.accessToken()!);
    const decodedRes = InviteFriendHostResponse.decode(res);

    console.log(decodedRes);
    if (decodedRes.statusCode === CommonStatusCode.STATUS_SUCCESS) {
      return decodedRes.invitationCode!;
    }
    throw new Error(`issue-code (invite-friend) failed ${decodedRes.statusCode}`);
  }

  async acceptInvitationCode(invitationCode: string): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    let inviteFriendProposal: Proposal | undefined;
    this.proposalsMap.forEach((proposal: Proposal, key: string) => {
      if (
        proposal.static?.purpose === Purpose.PURPOSE_INVITE_FRIEND &&
        proposal.activeGlobal?.proposalStatus === ProposalStatus.PROPOSAL_ACTIVATED
      ) {
        inviteFriendProposal = proposal;
      }
    });

    if (inviteFriendProposal === undefined) {
      throw new Error("InviteFriend proposal not found");
    }

    const inviteFriendGuestRequest: InviteFriendGuestRequest = InviteFriendGuestRequest.create();
    inviteFriendGuestRequest.did = this.myd3AuthConnector.myd3.did;
    inviteFriendGuestRequest.mid = this.myd3AuthConnector.myd3.did.substring(10);
    inviteFriendGuestRequest.invitationCode = invitationCode;
    inviteFriendGuestRequest.proposalId = inviteFriendProposal.proposalId;
    console.log(inviteFriendGuestRequest);
    const paramBuf = InviteFriendGuestRequest.encode(inviteFriendGuestRequest).finish();
    let res = await fetchInviteFriendAcceptInvitationCode(paramBuf, this.accessToken()!);
    const decodedRes = InviteFriendGuestResponse.decode(res);

    console.log(decodedRes);
    if (decodedRes.statusCode === CommonStatusCode.STATUS_SUCCESS) {
      return JSON.stringify(decodedRes);
    }
    throw new Error(`accept-issue-code (invite-friend) failed ${decodedRes.statusCode}`);
  }

  async attendanceRollCall(): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    let attendanceProposal: Proposal | undefined;
    this.proposalsMap.forEach((proposal: Proposal, key: string) => {
      if (
        proposal.static?.purpose === Purpose.PURPOSE_ATTENDANCE &&
        proposal.activeGlobal?.proposalStatus === ProposalStatus.PROPOSAL_ACTIVATED
      ) {
        attendanceProposal = proposal;
      }
    });

    if (attendanceProposal === undefined) {
      throw new Error("Attendance proposal not found");
    }

    const attendanceRollCallRequest: AttendanceRollCallRequest = AttendanceRollCallRequest.create();
    attendanceRollCallRequest.did = this.myd3AuthConnector.myd3.did;
    attendanceRollCallRequest.mid = this.myd3AuthConnector.myd3.did.substring(10);
    attendanceRollCallRequest.proposalId = attendanceProposal.proposalId;

    const paramBuf = AttendanceRollCallRequest.encode(attendanceRollCallRequest).finish();
    let res = await fetchAttendanceRollCall(paramBuf, this.accessToken()!);
    const decodedRes = AttendanceRollCallResponse.decode(res);

    console.log(decodedRes);
    return JSON.stringify(decodedRes);
  }

  async attendanceSubmit(): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    let attendanceProposal: Proposal | undefined;
    this.proposalsMap.forEach((proposal: Proposal, key: string) => {
      if (
        proposal.static?.purpose === Purpose.PURPOSE_ATTENDANCE &&
        proposal.activeGlobal?.proposalStatus === ProposalStatus.PROPOSAL_ACTIVATED
      ) {
        attendanceProposal = proposal;
      }
    });

    if (attendanceProposal === undefined) {
      throw new Error("Attendance proposal not found");
    }

    const attendanceSubmitRequest: AttendanceSubmitRequest = AttendanceSubmitRequest.create();
    attendanceSubmitRequest.did = this.myd3AuthConnector.myd3.did;
    attendanceSubmitRequest.mid = this.myd3AuthConnector.myd3.did.substring(10);
    attendanceSubmitRequest.proposalId = attendanceProposal.proposalId;

    const paramBuf = AttendanceSubmitRequest.encode(attendanceSubmitRequest).finish();
    let res = await fetchAttendanceSubmit(paramBuf, this.accessToken()!);
    const decodedRes = AttendanceSubmitResponse.decode(res);

    console.log(decodedRes);
    return JSON.stringify(decodedRes);
  }

  async startSignal(calleeDid: string): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    const voipSignalRequest: VoIPSignalRequest = VoIPSignalRequest.create();
    voipSignalRequest.did = this.myd3AuthConnector.myd3.did;
    voipSignalRequest.callerDid = this.myd3AuthConnector.myd3.did;
    voipSignalRequest.action = VoIPSignalAction.ACTION_START;
    voipSignalRequest.calleeDid = calleeDid;

    const paramBuf = VoIPSignalRequest.encode(voipSignalRequest).finish();
    let res = await fetchVoipCall(paramBuf, this.accessToken()!);
    const decodedRes = VoIPSignalResponse.decode(res);

    return decodedRes.room?.roomId!;
  }

  async blobToUint8Array(blob: Blob) {
    return new Promise<Uint8Array>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          const uint8Array = new Uint8Array(reader.result);
          resolve(uint8Array);
        } else {
          reject(new Error("Failed to convert Blob to Uint8Array."));
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  }

  async answerSignal(callRoomId: string): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    const voipSignalRequest: VoIPSignalRequest = VoIPSignalRequest.create();
    voipSignalRequest.did = this.myd3AuthConnector.myd3.did;
    voipSignalRequest.callRoomId = callRoomId;
    voipSignalRequest.callerDid = "";
    voipSignalRequest.action = VoIPSignalAction.ACTION_ANSWER;
    voipSignalRequest.calleeDid = this.myd3AuthConnector.myd3.did;

    const paramBuf = VoIPSignalRequest.encode(voipSignalRequest).finish();
    let res = await fetchVoipCall(paramBuf, this.accessToken()!);
    const decodedRes = VoIPSignalResponse.decode(res);
    return JSON.stringify(decodedRes);
  }

  async fetchSignal(callRoomId: string): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    const voipSignalRequest: VoIPSignalRequest = VoIPSignalRequest.create();
    voipSignalRequest.did = this.myd3AuthConnector.myd3.did;
    voipSignalRequest.callRoomId = callRoomId;
    voipSignalRequest.callerDid = this.myd3AuthConnector.myd3.did;
    voipSignalRequest.action = VoIPSignalAction.ACTION_FETCH_MESSAGES;

    const paramBuf = VoIPSignalRequest.encode(voipSignalRequest).finish();
    let res = await fetchVoipCall(paramBuf, this.accessToken()!);
    const decodedRes = VoIPSignalResponse.decode(res);

    this.requestList = decodedRes.requests;
    this.room = decodedRes.room;
    return JSON.stringify(decodedRes);
  }

  async sendP2pSignal(callRoomId: string, toWho: number): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    const voipSignalRequest: VoIPSignalRequest = VoIPSignalRequest.create();
    voipSignalRequest.did = this.myd3AuthConnector.myd3.did;
    voipSignalRequest.action = VoIPSignalAction.ACTION_SEND_P2P;
    voipSignalRequest.callRoomId = callRoomId;
    if (toWho === VoIPP2PMessageDirection.TO_CALLEE) {
      voipSignalRequest.callerDid = this.myd3AuthConnector.myd3.did;
    } else if (toWho === VoIPP2PMessageDirection.TO_CALLER) {
      voipSignalRequest.calleeDid = this.myd3AuthConnector.myd3.did;
    }
    voipSignalRequest.direction = toWho;
    voipSignalRequest.p2pMessageType = VoIPP2PMessageType.WEBRTC_OFFER;
    // voipSignalRequest.p2pMessage = "";

    const paramBuf = VoIPSignalRequest.encode(voipSignalRequest).finish();
    let res = await fetchVoipCall(paramBuf, this.accessToken()!);
    const decodedRes = VoIPSignalResponse.decode(res);

    this.requestList = decodedRes.requests;
    this.room = decodedRes.room;
    return JSON.stringify(decodedRes);
  }

  openWebRTC(callRoomId: string) {
    // 웹소켓 연결을 생성
    this.socket = new WebSocket(`ws://localhost:8080/socket/${callRoomId}`);

    // 서버로부터 메시지를 받았을 때 실행될 함수
    this.socket.onmessage = async (event) => {
      if (event.data instanceof Blob) {
        const uint8Array = await this.blobToUint8Array(event.data);
        const res = VoIPSignalResponse.decode(uint8Array);
        console.log("Received message from server:", res);
        if (res.requests[res.requests.length - 1].action === VoIPSignalAction.ACTION_ANSWER) {
          return;
        }
        let voipSignalRequest: VoIPSignalRequest = VoIPSignalRequest.create(res.requests[res.requests.length - 1]);
        voipSignalRequest.action = VoIPSignalAction.ACTION_ANSWER;
        voipSignalRequest.lastSeq = voipSignalRequest.seq!;
        voipSignalRequest.seq = voipSignalRequest.lastSeq + 1;
        // voipSignalRequest.p2pMessageType = VoIPP2PMessageType.WEBRTC_OFFER;
        const paramBuf = VoIPSignalRequest.encode(voipSignalRequest).finish();
        this.socket.send(paramBuf);
      }
    };
    // 연결이 닫혔을 때 실행될 함수
    this.socket.onclose = async (event) => {
      this.socketState = "Closed";
      console.log("WebSocket connection closed.");
    };
    // 에러 발생 시 실행될 함수
    this.socket.onerror = (event) => {
      console.error("Webthis.socket error:", event);
    };

    // 연결이 열렸을 때 실행될 함수
    this.socket.onopen = async (event) => {
      this.socketState = "Open";
      console.log("WebSocket connection opened.");
      const voipSignalRequest: VoIPSignalRequest = VoIPSignalRequest.create();
      voipSignalRequest.action = VoIPSignalAction.ACTION_SEND_P2P;
      voipSignalRequest.callRoomId = callRoomId;
      voipSignalRequest.callerDid = this.myd3AuthConnector!.myd3.did;
      voipSignalRequest.calleeDid = "";
      voipSignalRequest.direction = VoIPP2PMessageDirection.TO_CALLEE;
      voipSignalRequest.p2pMessageType = VoIPP2PMessageType.WEBRTC_OFFER;
      const paramBuf = VoIPSignalRequest.encode(voipSignalRequest).finish();

      // 연결이 열리면 서버로 메시지 전송
      this.socket.send(paramBuf);
    };
  }

  openWebRTCForCallee(callRoomId: string) {
    // 웹소켓 연결을 생성
    this.socket = new WebSocket(`ws://localhost:8080/socket/${callRoomId}`);

    // 서버로부터 메시지를 받았을 때 실행될 함수
    this.socket.onmessage = async (event) => {
      console.log("Received message from server:", event.data);
    };
    // 연결이 닫혔을 때 실행될 함수
    this.socket.onclose = async (event) => {
      console.log("WebSocket connection closed.");
    };
    // 에러 발생 시 실행될 함수
    this.socket.onerror = (event) => {
      console.error("Webthis.socket error:", event);
    };

    // 연결이 열렸을 때 실행될 함수
    this.socket.onopen = async (event) => {
      console.log("WebSocket connection opened.");
    };
  }

  async hangUpSignal(callRoomId: string): Promise<string> {
    if (!this.myd3AuthConnector) {
      throw new Error("myd3AuthConnector is not initialized");
    }

    const voipSignalRequest: VoIPSignalRequest = VoIPSignalRequest.create();
    voipSignalRequest.did = this.myd3AuthConnector.myd3.did;
    voipSignalRequest.callRoomId = callRoomId;
    voipSignalRequest.callerDid = this.myd3AuthConnector.myd3.did;
    voipSignalRequest.action = VoIPSignalAction.ACTION_HANGUP;

    const paramBuf = VoIPSignalRequest.encode(voipSignalRequest).finish();
    let res = await fetchVoipCall(paramBuf, this.accessToken()!);
    const decodedRes = VoIPSignalResponse.decode(res);

    return JSON.stringify(decodedRes);
  }
}
