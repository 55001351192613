import React, { useState } from "react";
import {
  Proposal,
  RewardTransactionContentForApp,
  VoIPCallState,
  VoIPP2PMessageDirection,
  VoIPP2PMessageType,
  VoIPSignalAction,
} from "../protobuf/OpenApiServerV3DataTypes";
import ProposalItem from "./ProposalItem";
import { Myd3Blockchain } from "../authconnector/myd3";
import { MockMobileCore } from "./MockMobileCore";
import { getProtobufEnumKey } from "utils/storage";
import { JSONTree } from "react-json-tree";

type BoardProps = {
  core: MockMobileCore;
};

function decodeUtf8(uint8Array: any) {
  if (!(uint8Array instanceof Uint8Array)) {
    return "";
  }
  const decoder = new TextDecoder("utf-8");
  return decoder.decode(uint8Array);
}

export default function SignalRequestBoard({ core }: BoardProps) {
  let requestList = core.requestList !== undefined ? JSON.parse(JSON.stringify(core.requestList)) : undefined;
  let room = core.room !== undefined ? core.room : undefined;

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>Room Id</td>
            <td>Caller Did</td>
            <td>Callee Did</td>
            <td>Call State</td>
            <td>Call Title</td>
            <td>Caller Media Connected</td>
            <td>Callee Media Connected</td>
            <td>closed At</td>
          </tr>
          <tr>
            <td>{room === undefined ? "" : room.roomId}</td>
            <td>{room === undefined ? "" : room.callerDid}</td>
            <td>{room === undefined ? "" : room.calleeDid}</td>
            <td>{room === undefined ? "" : getProtobufEnumKey(VoIPCallState, room.callState)}</td>
            <td>{room === undefined ? "" : room.callTitle}</td>
            <td>{room === undefined ? "" : room.callerMediaConnected}</td>
            <td>{room === undefined ? "" : room.calleeMediaConnected}</td>
            <td>{room === undefined ? "" : room.closedAt}</td>
          </tr>
        </tbody>
      </table>
      &nbsp;
      <table>
        <tbody>
          <tr>
            <td>Room Id</td>
            <td>Action</td>
            <td>Seq</td>
            <td>Title</td>
            <td>Caller Did</td>
            <td>Callee Did</td>
            <td>Direction</td>
            <td>Msg Type</td>
          </tr>

          {requestList?.map((p: any) => (
            <React.Fragment key={p.seq}>
              <tr>
                <td>{`"${p.callRoomId}"`}</td>
                <td>{`${getProtobufEnumKey(VoIPSignalAction, p.action)}`}</td>
                <td>{`"${p.seq}"`}</td>
                <td>{`"${p.callTitle}"`}</td>
                <td>{`"${p.callerDid}"`}</td>
                <td>{`"${p.calleeDid}"`}</td>
                <td>{`${getProtobufEnumKey(VoIPP2PMessageDirection, p.direction)}`}</td>
                <td>{`${getProtobufEnumKey(VoIPP2PMessageType, p.p2pMessageType)}`}</td>
              </tr>
              <tr>
                <td colSpan={8}>
                  <details>
                    <summary>Msg</summary>
                    {decodeUtf8(p.p2pMessage)}
                  </details>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
