/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "io.snplab.myd.proto";

export enum Purpose {
  PURPOSE_BEGIN = 0,
  PURPOSE_DATA_MARKET = 100,
  PURPOSE_SDK_DATA_MARKET = 101,
  /** PURPOSE_RBAC - 300~399 : reserved for block chain */
  PURPOSE_RBAC = 301,
  PURPOSE_REWARD = 302,
  PURPOSE_DID = 303,
  /** PURPOSE_LOGIC - integrated logic */
  PURPOSE_LOGIC = 310,
  PURPOSE_PANEL = 600,
  PURPOSE_INVITE_FRIEND = 601,
  PURPOSE_ATTENDANCE = 602,
  PURPOSE_DID_FILTERING = 1002,
  UNRECOGNIZED = -1,
}

export function purposeFromJSON(object: any): Purpose {
  switch (object) {
    case 0:
    case "PURPOSE_BEGIN":
      return Purpose.PURPOSE_BEGIN;
    case 100:
    case "PURPOSE_DATA_MARKET":
      return Purpose.PURPOSE_DATA_MARKET;
    case 101:
    case "PURPOSE_SDK_DATA_MARKET":
      return Purpose.PURPOSE_SDK_DATA_MARKET;
    case 301:
    case "PURPOSE_RBAC":
      return Purpose.PURPOSE_RBAC;
    case 302:
    case "PURPOSE_REWARD":
      return Purpose.PURPOSE_REWARD;
    case 303:
    case "PURPOSE_DID":
      return Purpose.PURPOSE_DID;
    case 310:
    case "PURPOSE_LOGIC":
      return Purpose.PURPOSE_LOGIC;
    case 600:
    case "PURPOSE_PANEL":
      return Purpose.PURPOSE_PANEL;
    case 601:
    case "PURPOSE_INVITE_FRIEND":
      return Purpose.PURPOSE_INVITE_FRIEND;
    case 602:
    case "PURPOSE_ATTENDANCE":
      return Purpose.PURPOSE_ATTENDANCE;
    case 1002:
    case "PURPOSE_DID_FILTERING":
      return Purpose.PURPOSE_DID_FILTERING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Purpose.UNRECOGNIZED;
  }
}

export function purposeToJSON(object: Purpose): string {
  switch (object) {
    case Purpose.PURPOSE_BEGIN:
      return "PURPOSE_BEGIN";
    case Purpose.PURPOSE_DATA_MARKET:
      return "PURPOSE_DATA_MARKET";
    case Purpose.PURPOSE_SDK_DATA_MARKET:
      return "PURPOSE_SDK_DATA_MARKET";
    case Purpose.PURPOSE_RBAC:
      return "PURPOSE_RBAC";
    case Purpose.PURPOSE_REWARD:
      return "PURPOSE_REWARD";
    case Purpose.PURPOSE_DID:
      return "PURPOSE_DID";
    case Purpose.PURPOSE_LOGIC:
      return "PURPOSE_LOGIC";
    case Purpose.PURPOSE_PANEL:
      return "PURPOSE_PANEL";
    case Purpose.PURPOSE_INVITE_FRIEND:
      return "PURPOSE_INVITE_FRIEND";
    case Purpose.PURPOSE_ATTENDANCE:
      return "PURPOSE_ATTENDANCE";
    case Purpose.PURPOSE_DID_FILTERING:
      return "PURPOSE_DID_FILTERING";
    case Purpose.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum FunctionType {
  FUNCTION_BEGIN = 0,
  /** REGISTER_PROPOSAL - proposal */
  REGISTER_PROPOSAL = 1,
  /** DID_REGISTER - 303 did */
  DID_REGISTER = 10,
  GET_DID = 11,
  DID_REGISTER_BATCH = 12,
  /** RBAC_GRANT_ROLE - 301 RBAC */
  RBAC_GRANT_ROLE = 20,
  RBAC_REVOKE_ROLE = 21,
  RBAC_HAS_ROLE = 22,
  RBAC_DEFINE_ROLE = 23,
  /** REWARD_MINT - 302 reward */
  REWARD_MINT = 30,
  REWARD_TRANSFER = 31,
  REWARD_BURN = 32,
  /** TICKET_REWARD_BATCH - batch in one transaction */
  TICKET_REWARD_BATCH = 50,
  /** DEPLOY - deployment */
  DEPLOY = 60,
  UNRECOGNIZED = -1,
}

export function functionTypeFromJSON(object: any): FunctionType {
  switch (object) {
    case 0:
    case "FUNCTION_BEGIN":
      return FunctionType.FUNCTION_BEGIN;
    case 1:
    case "REGISTER_PROPOSAL":
      return FunctionType.REGISTER_PROPOSAL;
    case 10:
    case "DID_REGISTER":
      return FunctionType.DID_REGISTER;
    case 11:
    case "GET_DID":
      return FunctionType.GET_DID;
    case 12:
    case "DID_REGISTER_BATCH":
      return FunctionType.DID_REGISTER_BATCH;
    case 20:
    case "RBAC_GRANT_ROLE":
      return FunctionType.RBAC_GRANT_ROLE;
    case 21:
    case "RBAC_REVOKE_ROLE":
      return FunctionType.RBAC_REVOKE_ROLE;
    case 22:
    case "RBAC_HAS_ROLE":
      return FunctionType.RBAC_HAS_ROLE;
    case 23:
    case "RBAC_DEFINE_ROLE":
      return FunctionType.RBAC_DEFINE_ROLE;
    case 30:
    case "REWARD_MINT":
      return FunctionType.REWARD_MINT;
    case 31:
    case "REWARD_TRANSFER":
      return FunctionType.REWARD_TRANSFER;
    case 32:
    case "REWARD_BURN":
      return FunctionType.REWARD_BURN;
    case 50:
    case "TICKET_REWARD_BATCH":
      return FunctionType.TICKET_REWARD_BATCH;
    case 60:
    case "DEPLOY":
      return FunctionType.DEPLOY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FunctionType.UNRECOGNIZED;
  }
}

export function functionTypeToJSON(object: FunctionType): string {
  switch (object) {
    case FunctionType.FUNCTION_BEGIN:
      return "FUNCTION_BEGIN";
    case FunctionType.REGISTER_PROPOSAL:
      return "REGISTER_PROPOSAL";
    case FunctionType.DID_REGISTER:
      return "DID_REGISTER";
    case FunctionType.GET_DID:
      return "GET_DID";
    case FunctionType.DID_REGISTER_BATCH:
      return "DID_REGISTER_BATCH";
    case FunctionType.RBAC_GRANT_ROLE:
      return "RBAC_GRANT_ROLE";
    case FunctionType.RBAC_REVOKE_ROLE:
      return "RBAC_REVOKE_ROLE";
    case FunctionType.RBAC_HAS_ROLE:
      return "RBAC_HAS_ROLE";
    case FunctionType.RBAC_DEFINE_ROLE:
      return "RBAC_DEFINE_ROLE";
    case FunctionType.REWARD_MINT:
      return "REWARD_MINT";
    case FunctionType.REWARD_TRANSFER:
      return "REWARD_TRANSFER";
    case FunctionType.REWARD_BURN:
      return "REWARD_BURN";
    case FunctionType.TICKET_REWARD_BATCH:
      return "TICKET_REWARD_BATCH";
    case FunctionType.DEPLOY:
      return "DEPLOY";
    case FunctionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Role for RBAC */
export enum Role {
  ADMIN = 0,
  PLATFORM_MANAGER = 1,
  PLATFORM_OPERATOR = 2,
  ENDORSER = 3,
  ROLE_MAINTAINER = 4,
  UNRECOGNIZED = -1,
}

export function roleFromJSON(object: any): Role {
  switch (object) {
    case 0:
    case "ADMIN":
      return Role.ADMIN;
    case 1:
    case "PLATFORM_MANAGER":
      return Role.PLATFORM_MANAGER;
    case 2:
    case "PLATFORM_OPERATOR":
      return Role.PLATFORM_OPERATOR;
    case 3:
    case "ENDORSER":
      return Role.ENDORSER;
    case 4:
    case "ROLE_MAINTAINER":
      return Role.ROLE_MAINTAINER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Role.UNRECOGNIZED;
  }
}

export function roleToJSON(object: Role): string {
  switch (object) {
    case Role.ADMIN:
      return "ADMIN";
    case Role.PLATFORM_MANAGER:
      return "PLATFORM_MANAGER";
    case Role.PLATFORM_OPERATOR:
      return "PLATFORM_OPERATOR";
    case Role.ENDORSER:
      return "ENDORSER";
    case Role.ROLE_MAINTAINER:
      return "ROLE_MAINTAINER";
    case Role.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** ref: https://confluence.snplab.io/display/MIDD/Error+Codes */
export enum BCCommonErrorType {
  BEGIN = 0,
  OK = 1,
  ERROR_INVALID_ARGUMENT = 2,
  ERROR_NOT_IMPLEMENTED = 3,
  /** ERR_BLOCKCHAIN_GET_STATE_FAILED - Blockchain error */
  ERR_BLOCKCHAIN_GET_STATE_FAILED = 8001,
  ERR_BLOCKCHAIN_PUT_STATE_FAILED = 8002,
  /** ERR_BLOCKCHAIN_DEL_STATUS_SUCCESS - no 0 place holder for backward-compatibility */
  ERR_BLOCKCHAIN_DEL_STATUS_SUCCESS = 8006,
  ERR_NOT_FOR_YOUR_PROPOSAL = 2022,
  ERR_NOT_FOR_YOUR_TICKET = 2023,
  /** ERR_CANNOT_ACTIVATE_PROPOSAL - Proposal */
  ERR_CANNOT_ACTIVATE_PROPOSAL = 1001,
  ERR_CANNOT_REVOKE_PROPOSAL = 1002,
  ERR_ALREADY_REVOKED_PROPOSAL = 1003,
  ERR_EXPIRED_PROPOSAL = 1004,
  ERR_NOT_ENOUGH_DEPOSIT_FOR_PROPOSAL = 1005,
  ERR_INVALID_DEPOSIT_FOR_PROPOSAL = 1006,
  ERR_NOT_VALID_BEFORE_PROPOSAL = 1007,
  ERR_NOT_VALID_AFTER_PROPOSAL = 1008,
  ERR_INACTIVE_PROPOSAL = 1009,
  ERR_STILL_VALID_PROPOSAL = 1010,
  ERR_NOTHING_REFRESHED = 1011,
  ERR_DROUGHT_PROPOSAL = 1098,
  ERR_CORRUPTED_PROPOSAL = 1099,
  /** ERR_CANNOT_ISSUE_TICKET - Ticket */
  ERR_CANNOT_ISSUE_TICKET = 1501,
  ERR_CANNOT_REVOKE_TICKET = 1502,
  ERR_ALREADY_REVOKED_TICKET = 1503,
  ERR_EXPIRED_TICKET = 1504,
  ERR_NOT_ENOUGH_DEPOSIT_FOR_TICKET = 1505,
  ERR_INVALID_DEPOSIT_FOR_TICKET = 1506,
  ERR_ALREADY_ISSUED_BY_DID = 1507,
  ERR_CANNOT_CONSUME_BY_PERIOD = 1508,
  ERR_BEFORE_VALID_AT_TICKET = 1509,
  ERR_REFUNDED_TICKET = 1597,
  ERR_DROUGHT_TICKET = 1598,
  ERR_CORRUPTED_TICKET = 1599,
  /** ERR_SPENT_TOKEN - Mileage */
  ERR_SPENT_TOKEN = 2001,
  ERR_UNSPENT_TOKEN = 2002,
  ERR_EXPIRED_TOKEN = 2003,
  ERR_AMOUNT_DIFFER = 2004,
  ERR_INVALID_DUE_TO = 2005,
  ERR_INVALID_DUE_TO_TAG = 2006,
  ERR_INVALID_DUE_TO_VERB = 2007,
  ERR_INVALID_DUE_TO_OBJECT = 2008,
  ERR_INVALID_DURATION = 2009,
  ERR_NOT_CLAIMABLE_YET = 2010,
  ERR_NOT_REFUNDABLE = 2011,
  ERR_ALREADY_REWARDED = 2012,
  ERR_FAILED_TO_GET_TICKET = 2013,
  ERR_FAILED_TO_GET_PROPOSAL = 2014,
  ERR_PROPOSAL_NOT_DEPOSITABLE = 2015,
  ERR_CANNOT_SPEND = 2016,
  ERR_CANNOT_DEPOSIT = 2017,
  ERR_CANNOT_WITHDRAW = 2018,
  ERR_CANNOT_TRANSFER = 2019,
  ERR_NOT_ENOUGH_MONEY = 2020,
  ERR_DEPOSITED_ALREADY_SPENT = 2021,
  /** ERR_BLOCKCHAIN_NO_DATA_FOUND - TATE_FAILED = 8003; */
  ERR_BLOCKCHAIN_NO_DATA_FOUND = 8004,
  ERR_BLOCKCHAIN_ALREADY_EXISTS = 8005,
  /** ERR_RETRY_WITH_NEW_BLOCKCHAIN_NONCE - Blockchain ODM PASS */
  ERR_RETRY_WITH_NEW_BLOCKCHAIN_NONCE = 8100,
  ERR_BLOCKCHAIN_DID_MIGRATION_FAILED = 8101,
  ERR_BLOCKCHAIN_TRANSACTION_SIGNATURE_VERIFICATION_FAILURE = 8102,
  ERR_BLOCKCHAIN_NOT_AUTHORIZED = 8103,
  ERR_BLOCKCHAIN_CONTRACT_NOT_READY_YET = 8104,
  ERR_BLOCKCHAIN_DID_REGISTRATION_FAILED = 8105,
  ERR_BLOCKCHAIN_DID_ALREADY_IN_USE = 8106,
  ERR_BLOCKCHAIN_ADDRESS_ALREADY_IN_USE = 8107,
  ERR_BLOCKCHAIN_NOT_ALLOWED_LOGIC_CONTRACT = 8108,
  ERR_BLOCKCHAIN_UNKNOWN = 8109,
  UNRECOGNIZED = -1,
}

export function bCCommonErrorTypeFromJSON(object: any): BCCommonErrorType {
  switch (object) {
    case 0:
    case "BEGIN":
      return BCCommonErrorType.BEGIN;
    case 1:
    case "OK":
      return BCCommonErrorType.OK;
    case 2:
    case "ERROR_INVALID_ARGUMENT":
      return BCCommonErrorType.ERROR_INVALID_ARGUMENT;
    case 3:
    case "ERROR_NOT_IMPLEMENTED":
      return BCCommonErrorType.ERROR_NOT_IMPLEMENTED;
    case 8001:
    case "ERR_BLOCKCHAIN_GET_STATE_FAILED":
      return BCCommonErrorType.ERR_BLOCKCHAIN_GET_STATE_FAILED;
    case 8002:
    case "ERR_BLOCKCHAIN_PUT_STATE_FAILED":
      return BCCommonErrorType.ERR_BLOCKCHAIN_PUT_STATE_FAILED;
    case 8006:
    case "ERR_BLOCKCHAIN_DEL_STATUS_SUCCESS":
      return BCCommonErrorType.ERR_BLOCKCHAIN_DEL_STATUS_SUCCESS;
    case 2022:
    case "ERR_NOT_FOR_YOUR_PROPOSAL":
      return BCCommonErrorType.ERR_NOT_FOR_YOUR_PROPOSAL;
    case 2023:
    case "ERR_NOT_FOR_YOUR_TICKET":
      return BCCommonErrorType.ERR_NOT_FOR_YOUR_TICKET;
    case 1001:
    case "ERR_CANNOT_ACTIVATE_PROPOSAL":
      return BCCommonErrorType.ERR_CANNOT_ACTIVATE_PROPOSAL;
    case 1002:
    case "ERR_CANNOT_REVOKE_PROPOSAL":
      return BCCommonErrorType.ERR_CANNOT_REVOKE_PROPOSAL;
    case 1003:
    case "ERR_ALREADY_REVOKED_PROPOSAL":
      return BCCommonErrorType.ERR_ALREADY_REVOKED_PROPOSAL;
    case 1004:
    case "ERR_EXPIRED_PROPOSAL":
      return BCCommonErrorType.ERR_EXPIRED_PROPOSAL;
    case 1005:
    case "ERR_NOT_ENOUGH_DEPOSIT_FOR_PROPOSAL":
      return BCCommonErrorType.ERR_NOT_ENOUGH_DEPOSIT_FOR_PROPOSAL;
    case 1006:
    case "ERR_INVALID_DEPOSIT_FOR_PROPOSAL":
      return BCCommonErrorType.ERR_INVALID_DEPOSIT_FOR_PROPOSAL;
    case 1007:
    case "ERR_NOT_VALID_BEFORE_PROPOSAL":
      return BCCommonErrorType.ERR_NOT_VALID_BEFORE_PROPOSAL;
    case 1008:
    case "ERR_NOT_VALID_AFTER_PROPOSAL":
      return BCCommonErrorType.ERR_NOT_VALID_AFTER_PROPOSAL;
    case 1009:
    case "ERR_INACTIVE_PROPOSAL":
      return BCCommonErrorType.ERR_INACTIVE_PROPOSAL;
    case 1010:
    case "ERR_STILL_VALID_PROPOSAL":
      return BCCommonErrorType.ERR_STILL_VALID_PROPOSAL;
    case 1011:
    case "ERR_NOTHING_REFRESHED":
      return BCCommonErrorType.ERR_NOTHING_REFRESHED;
    case 1098:
    case "ERR_DROUGHT_PROPOSAL":
      return BCCommonErrorType.ERR_DROUGHT_PROPOSAL;
    case 1099:
    case "ERR_CORRUPTED_PROPOSAL":
      return BCCommonErrorType.ERR_CORRUPTED_PROPOSAL;
    case 1501:
    case "ERR_CANNOT_ISSUE_TICKET":
      return BCCommonErrorType.ERR_CANNOT_ISSUE_TICKET;
    case 1502:
    case "ERR_CANNOT_REVOKE_TICKET":
      return BCCommonErrorType.ERR_CANNOT_REVOKE_TICKET;
    case 1503:
    case "ERR_ALREADY_REVOKED_TICKET":
      return BCCommonErrorType.ERR_ALREADY_REVOKED_TICKET;
    case 1504:
    case "ERR_EXPIRED_TICKET":
      return BCCommonErrorType.ERR_EXPIRED_TICKET;
    case 1505:
    case "ERR_NOT_ENOUGH_DEPOSIT_FOR_TICKET":
      return BCCommonErrorType.ERR_NOT_ENOUGH_DEPOSIT_FOR_TICKET;
    case 1506:
    case "ERR_INVALID_DEPOSIT_FOR_TICKET":
      return BCCommonErrorType.ERR_INVALID_DEPOSIT_FOR_TICKET;
    case 1507:
    case "ERR_ALREADY_ISSUED_BY_DID":
      return BCCommonErrorType.ERR_ALREADY_ISSUED_BY_DID;
    case 1508:
    case "ERR_CANNOT_CONSUME_BY_PERIOD":
      return BCCommonErrorType.ERR_CANNOT_CONSUME_BY_PERIOD;
    case 1509:
    case "ERR_BEFORE_VALID_AT_TICKET":
      return BCCommonErrorType.ERR_BEFORE_VALID_AT_TICKET;
    case 1597:
    case "ERR_REFUNDED_TICKET":
      return BCCommonErrorType.ERR_REFUNDED_TICKET;
    case 1598:
    case "ERR_DROUGHT_TICKET":
      return BCCommonErrorType.ERR_DROUGHT_TICKET;
    case 1599:
    case "ERR_CORRUPTED_TICKET":
      return BCCommonErrorType.ERR_CORRUPTED_TICKET;
    case 2001:
    case "ERR_SPENT_TOKEN":
      return BCCommonErrorType.ERR_SPENT_TOKEN;
    case 2002:
    case "ERR_UNSPENT_TOKEN":
      return BCCommonErrorType.ERR_UNSPENT_TOKEN;
    case 2003:
    case "ERR_EXPIRED_TOKEN":
      return BCCommonErrorType.ERR_EXPIRED_TOKEN;
    case 2004:
    case "ERR_AMOUNT_DIFFER":
      return BCCommonErrorType.ERR_AMOUNT_DIFFER;
    case 2005:
    case "ERR_INVALID_DUE_TO":
      return BCCommonErrorType.ERR_INVALID_DUE_TO;
    case 2006:
    case "ERR_INVALID_DUE_TO_TAG":
      return BCCommonErrorType.ERR_INVALID_DUE_TO_TAG;
    case 2007:
    case "ERR_INVALID_DUE_TO_VERB":
      return BCCommonErrorType.ERR_INVALID_DUE_TO_VERB;
    case 2008:
    case "ERR_INVALID_DUE_TO_OBJECT":
      return BCCommonErrorType.ERR_INVALID_DUE_TO_OBJECT;
    case 2009:
    case "ERR_INVALID_DURATION":
      return BCCommonErrorType.ERR_INVALID_DURATION;
    case 2010:
    case "ERR_NOT_CLAIMABLE_YET":
      return BCCommonErrorType.ERR_NOT_CLAIMABLE_YET;
    case 2011:
    case "ERR_NOT_REFUNDABLE":
      return BCCommonErrorType.ERR_NOT_REFUNDABLE;
    case 2012:
    case "ERR_ALREADY_REWARDED":
      return BCCommonErrorType.ERR_ALREADY_REWARDED;
    case 2013:
    case "ERR_FAILED_TO_GET_TICKET":
      return BCCommonErrorType.ERR_FAILED_TO_GET_TICKET;
    case 2014:
    case "ERR_FAILED_TO_GET_PROPOSAL":
      return BCCommonErrorType.ERR_FAILED_TO_GET_PROPOSAL;
    case 2015:
    case "ERR_PROPOSAL_NOT_DEPOSITABLE":
      return BCCommonErrorType.ERR_PROPOSAL_NOT_DEPOSITABLE;
    case 2016:
    case "ERR_CANNOT_SPEND":
      return BCCommonErrorType.ERR_CANNOT_SPEND;
    case 2017:
    case "ERR_CANNOT_DEPOSIT":
      return BCCommonErrorType.ERR_CANNOT_DEPOSIT;
    case 2018:
    case "ERR_CANNOT_WITHDRAW":
      return BCCommonErrorType.ERR_CANNOT_WITHDRAW;
    case 2019:
    case "ERR_CANNOT_TRANSFER":
      return BCCommonErrorType.ERR_CANNOT_TRANSFER;
    case 2020:
    case "ERR_NOT_ENOUGH_MONEY":
      return BCCommonErrorType.ERR_NOT_ENOUGH_MONEY;
    case 2021:
    case "ERR_DEPOSITED_ALREADY_SPENT":
      return BCCommonErrorType.ERR_DEPOSITED_ALREADY_SPENT;
    case 8004:
    case "ERR_BLOCKCHAIN_NO_DATA_FOUND":
      return BCCommonErrorType.ERR_BLOCKCHAIN_NO_DATA_FOUND;
    case 8005:
    case "ERR_BLOCKCHAIN_ALREADY_EXISTS":
      return BCCommonErrorType.ERR_BLOCKCHAIN_ALREADY_EXISTS;
    case 8100:
    case "ERR_RETRY_WITH_NEW_BLOCKCHAIN_NONCE":
      return BCCommonErrorType.ERR_RETRY_WITH_NEW_BLOCKCHAIN_NONCE;
    case 8101:
    case "ERR_BLOCKCHAIN_DID_MIGRATION_FAILED":
      return BCCommonErrorType.ERR_BLOCKCHAIN_DID_MIGRATION_FAILED;
    case 8102:
    case "ERR_BLOCKCHAIN_TRANSACTION_SIGNATURE_VERIFICATION_FAILURE":
      return BCCommonErrorType.ERR_BLOCKCHAIN_TRANSACTION_SIGNATURE_VERIFICATION_FAILURE;
    case 8103:
    case "ERR_BLOCKCHAIN_NOT_AUTHORIZED":
      return BCCommonErrorType.ERR_BLOCKCHAIN_NOT_AUTHORIZED;
    case 8104:
    case "ERR_BLOCKCHAIN_CONTRACT_NOT_READY_YET":
      return BCCommonErrorType.ERR_BLOCKCHAIN_CONTRACT_NOT_READY_YET;
    case 8105:
    case "ERR_BLOCKCHAIN_DID_REGISTRATION_FAILED":
      return BCCommonErrorType.ERR_BLOCKCHAIN_DID_REGISTRATION_FAILED;
    case 8106:
    case "ERR_BLOCKCHAIN_DID_ALREADY_IN_USE":
      return BCCommonErrorType.ERR_BLOCKCHAIN_DID_ALREADY_IN_USE;
    case 8107:
    case "ERR_BLOCKCHAIN_ADDRESS_ALREADY_IN_USE":
      return BCCommonErrorType.ERR_BLOCKCHAIN_ADDRESS_ALREADY_IN_USE;
    case 8108:
    case "ERR_BLOCKCHAIN_NOT_ALLOWED_LOGIC_CONTRACT":
      return BCCommonErrorType.ERR_BLOCKCHAIN_NOT_ALLOWED_LOGIC_CONTRACT;
    case 8109:
    case "ERR_BLOCKCHAIN_UNKNOWN":
      return BCCommonErrorType.ERR_BLOCKCHAIN_UNKNOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BCCommonErrorType.UNRECOGNIZED;
  }
}

export function bCCommonErrorTypeToJSON(object: BCCommonErrorType): string {
  switch (object) {
    case BCCommonErrorType.BEGIN:
      return "BEGIN";
    case BCCommonErrorType.OK:
      return "OK";
    case BCCommonErrorType.ERROR_INVALID_ARGUMENT:
      return "ERROR_INVALID_ARGUMENT";
    case BCCommonErrorType.ERROR_NOT_IMPLEMENTED:
      return "ERROR_NOT_IMPLEMENTED";
    case BCCommonErrorType.ERR_BLOCKCHAIN_GET_STATE_FAILED:
      return "ERR_BLOCKCHAIN_GET_STATE_FAILED";
    case BCCommonErrorType.ERR_BLOCKCHAIN_PUT_STATE_FAILED:
      return "ERR_BLOCKCHAIN_PUT_STATE_FAILED";
    case BCCommonErrorType.ERR_BLOCKCHAIN_DEL_STATUS_SUCCESS:
      return "ERR_BLOCKCHAIN_DEL_STATUS_SUCCESS";
    case BCCommonErrorType.ERR_NOT_FOR_YOUR_PROPOSAL:
      return "ERR_NOT_FOR_YOUR_PROPOSAL";
    case BCCommonErrorType.ERR_NOT_FOR_YOUR_TICKET:
      return "ERR_NOT_FOR_YOUR_TICKET";
    case BCCommonErrorType.ERR_CANNOT_ACTIVATE_PROPOSAL:
      return "ERR_CANNOT_ACTIVATE_PROPOSAL";
    case BCCommonErrorType.ERR_CANNOT_REVOKE_PROPOSAL:
      return "ERR_CANNOT_REVOKE_PROPOSAL";
    case BCCommonErrorType.ERR_ALREADY_REVOKED_PROPOSAL:
      return "ERR_ALREADY_REVOKED_PROPOSAL";
    case BCCommonErrorType.ERR_EXPIRED_PROPOSAL:
      return "ERR_EXPIRED_PROPOSAL";
    case BCCommonErrorType.ERR_NOT_ENOUGH_DEPOSIT_FOR_PROPOSAL:
      return "ERR_NOT_ENOUGH_DEPOSIT_FOR_PROPOSAL";
    case BCCommonErrorType.ERR_INVALID_DEPOSIT_FOR_PROPOSAL:
      return "ERR_INVALID_DEPOSIT_FOR_PROPOSAL";
    case BCCommonErrorType.ERR_NOT_VALID_BEFORE_PROPOSAL:
      return "ERR_NOT_VALID_BEFORE_PROPOSAL";
    case BCCommonErrorType.ERR_NOT_VALID_AFTER_PROPOSAL:
      return "ERR_NOT_VALID_AFTER_PROPOSAL";
    case BCCommonErrorType.ERR_INACTIVE_PROPOSAL:
      return "ERR_INACTIVE_PROPOSAL";
    case BCCommonErrorType.ERR_STILL_VALID_PROPOSAL:
      return "ERR_STILL_VALID_PROPOSAL";
    case BCCommonErrorType.ERR_NOTHING_REFRESHED:
      return "ERR_NOTHING_REFRESHED";
    case BCCommonErrorType.ERR_DROUGHT_PROPOSAL:
      return "ERR_DROUGHT_PROPOSAL";
    case BCCommonErrorType.ERR_CORRUPTED_PROPOSAL:
      return "ERR_CORRUPTED_PROPOSAL";
    case BCCommonErrorType.ERR_CANNOT_ISSUE_TICKET:
      return "ERR_CANNOT_ISSUE_TICKET";
    case BCCommonErrorType.ERR_CANNOT_REVOKE_TICKET:
      return "ERR_CANNOT_REVOKE_TICKET";
    case BCCommonErrorType.ERR_ALREADY_REVOKED_TICKET:
      return "ERR_ALREADY_REVOKED_TICKET";
    case BCCommonErrorType.ERR_EXPIRED_TICKET:
      return "ERR_EXPIRED_TICKET";
    case BCCommonErrorType.ERR_NOT_ENOUGH_DEPOSIT_FOR_TICKET:
      return "ERR_NOT_ENOUGH_DEPOSIT_FOR_TICKET";
    case BCCommonErrorType.ERR_INVALID_DEPOSIT_FOR_TICKET:
      return "ERR_INVALID_DEPOSIT_FOR_TICKET";
    case BCCommonErrorType.ERR_ALREADY_ISSUED_BY_DID:
      return "ERR_ALREADY_ISSUED_BY_DID";
    case BCCommonErrorType.ERR_CANNOT_CONSUME_BY_PERIOD:
      return "ERR_CANNOT_CONSUME_BY_PERIOD";
    case BCCommonErrorType.ERR_BEFORE_VALID_AT_TICKET:
      return "ERR_BEFORE_VALID_AT_TICKET";
    case BCCommonErrorType.ERR_REFUNDED_TICKET:
      return "ERR_REFUNDED_TICKET";
    case BCCommonErrorType.ERR_DROUGHT_TICKET:
      return "ERR_DROUGHT_TICKET";
    case BCCommonErrorType.ERR_CORRUPTED_TICKET:
      return "ERR_CORRUPTED_TICKET";
    case BCCommonErrorType.ERR_SPENT_TOKEN:
      return "ERR_SPENT_TOKEN";
    case BCCommonErrorType.ERR_UNSPENT_TOKEN:
      return "ERR_UNSPENT_TOKEN";
    case BCCommonErrorType.ERR_EXPIRED_TOKEN:
      return "ERR_EXPIRED_TOKEN";
    case BCCommonErrorType.ERR_AMOUNT_DIFFER:
      return "ERR_AMOUNT_DIFFER";
    case BCCommonErrorType.ERR_INVALID_DUE_TO:
      return "ERR_INVALID_DUE_TO";
    case BCCommonErrorType.ERR_INVALID_DUE_TO_TAG:
      return "ERR_INVALID_DUE_TO_TAG";
    case BCCommonErrorType.ERR_INVALID_DUE_TO_VERB:
      return "ERR_INVALID_DUE_TO_VERB";
    case BCCommonErrorType.ERR_INVALID_DUE_TO_OBJECT:
      return "ERR_INVALID_DUE_TO_OBJECT";
    case BCCommonErrorType.ERR_INVALID_DURATION:
      return "ERR_INVALID_DURATION";
    case BCCommonErrorType.ERR_NOT_CLAIMABLE_YET:
      return "ERR_NOT_CLAIMABLE_YET";
    case BCCommonErrorType.ERR_NOT_REFUNDABLE:
      return "ERR_NOT_REFUNDABLE";
    case BCCommonErrorType.ERR_ALREADY_REWARDED:
      return "ERR_ALREADY_REWARDED";
    case BCCommonErrorType.ERR_FAILED_TO_GET_TICKET:
      return "ERR_FAILED_TO_GET_TICKET";
    case BCCommonErrorType.ERR_FAILED_TO_GET_PROPOSAL:
      return "ERR_FAILED_TO_GET_PROPOSAL";
    case BCCommonErrorType.ERR_PROPOSAL_NOT_DEPOSITABLE:
      return "ERR_PROPOSAL_NOT_DEPOSITABLE";
    case BCCommonErrorType.ERR_CANNOT_SPEND:
      return "ERR_CANNOT_SPEND";
    case BCCommonErrorType.ERR_CANNOT_DEPOSIT:
      return "ERR_CANNOT_DEPOSIT";
    case BCCommonErrorType.ERR_CANNOT_WITHDRAW:
      return "ERR_CANNOT_WITHDRAW";
    case BCCommonErrorType.ERR_CANNOT_TRANSFER:
      return "ERR_CANNOT_TRANSFER";
    case BCCommonErrorType.ERR_NOT_ENOUGH_MONEY:
      return "ERR_NOT_ENOUGH_MONEY";
    case BCCommonErrorType.ERR_DEPOSITED_ALREADY_SPENT:
      return "ERR_DEPOSITED_ALREADY_SPENT";
    case BCCommonErrorType.ERR_BLOCKCHAIN_NO_DATA_FOUND:
      return "ERR_BLOCKCHAIN_NO_DATA_FOUND";
    case BCCommonErrorType.ERR_BLOCKCHAIN_ALREADY_EXISTS:
      return "ERR_BLOCKCHAIN_ALREADY_EXISTS";
    case BCCommonErrorType.ERR_RETRY_WITH_NEW_BLOCKCHAIN_NONCE:
      return "ERR_RETRY_WITH_NEW_BLOCKCHAIN_NONCE";
    case BCCommonErrorType.ERR_BLOCKCHAIN_DID_MIGRATION_FAILED:
      return "ERR_BLOCKCHAIN_DID_MIGRATION_FAILED";
    case BCCommonErrorType.ERR_BLOCKCHAIN_TRANSACTION_SIGNATURE_VERIFICATION_FAILURE:
      return "ERR_BLOCKCHAIN_TRANSACTION_SIGNATURE_VERIFICATION_FAILURE";
    case BCCommonErrorType.ERR_BLOCKCHAIN_NOT_AUTHORIZED:
      return "ERR_BLOCKCHAIN_NOT_AUTHORIZED";
    case BCCommonErrorType.ERR_BLOCKCHAIN_CONTRACT_NOT_READY_YET:
      return "ERR_BLOCKCHAIN_CONTRACT_NOT_READY_YET";
    case BCCommonErrorType.ERR_BLOCKCHAIN_DID_REGISTRATION_FAILED:
      return "ERR_BLOCKCHAIN_DID_REGISTRATION_FAILED";
    case BCCommonErrorType.ERR_BLOCKCHAIN_DID_ALREADY_IN_USE:
      return "ERR_BLOCKCHAIN_DID_ALREADY_IN_USE";
    case BCCommonErrorType.ERR_BLOCKCHAIN_ADDRESS_ALREADY_IN_USE:
      return "ERR_BLOCKCHAIN_ADDRESS_ALREADY_IN_USE";
    case BCCommonErrorType.ERR_BLOCKCHAIN_NOT_ALLOWED_LOGIC_CONTRACT:
      return "ERR_BLOCKCHAIN_NOT_ALLOWED_LOGIC_CONTRACT";
    case BCCommonErrorType.ERR_BLOCKCHAIN_UNKNOWN:
      return "ERR_BLOCKCHAIN_UNKNOWN";
    case BCCommonErrorType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Proposal includes DataMarket, DailyQuestion, InviteFriend, TNK
 * A proposal is identified by proposalId which is a address
 * This includes the reward details for each proposal.
 */
export interface ProposalInfo {
  /** proposal address */
  proposalId: Uint8Array;
  /** owner address for proposal */
  companyId: Uint8Array;
  purpose: number;
  /** reward max amount for a ticket */
  rewardMaxAmount: number;
  /** epoch value when the ticket is valid. Must be after notBefore */
  ticketValidFrom: number;
  /** epoch value when the ticket expires. */
  ticketValidUntil: number;
  reservedForFuture: Uint8Array;
  /** hash of proposal static data (bytes32) */
  proposalHash: Uint8Array;
}

/** Used to keep did information in the solidity contract */
export interface DidInfo {
  did: string;
  /** DID document in JSON format */
  document: string;
  /** Besu address in lower case */
  besuAddress: Uint8Array;
}

export interface RegisterProposalReq {
  proposalInfo: ProposalInfo | undefined;
  allowance: number;
}

export interface RegisterProposalResp {
  statusCode: BCCommonErrorType;
  proposalHash: Uint8Array;
}

export interface ConsumeTicketReq {
  /** proposal address */
  proposalId: Uint8Array;
  /** string format ticket */
  ticketId: Uint8Array;
  /** address */
  receiverAddress: Uint8Array;
  /** reward amount. should be under */
  amount: number;
  /** bytes32 */
  ticketAgreementHash: Uint8Array;
  /** bytes32 */
  dataReceiptHash: Uint8Array;
  requestId: Uint8Array;
}

export interface ConsumeTicketResp {
  statusCode: BCCommonErrorType;
  ticketAgreementHash: Uint8Array;
  dataReceiptHash: Uint8Array;
  proposalBalance: number;
  recipientBalance: number;
  requestId: Uint8Array;
}

export interface DidRegisterReq {
  didInfo: DidInfo | undefined;
}

export interface DidRegisterResp {
  statusCode: BCCommonErrorType;
}

/** a batch DidRegisterReq */
export interface DidRegisterBatchReq {
  didRegisterReqs: DidRegisterReq[];
}

export interface DidRegisterBatchResp {
  didRegisterResps: DidRegisterResp[];
}

export interface DidGetReq {
  did: string;
}

export interface DidGetResp {
  statusCode: BCCommonErrorType;
  didInfo: DidInfo | undefined;
}

export interface RewardTransferFromReq {
  sender: Uint8Array;
  recipient: Uint8Array;
  amount: number;
  requestId: Uint8Array;
  /** run operatorTransfer instead of transferFrom */
  operatorTransfer: boolean;
}

export interface RewardTransferFromResp {
  statusCode: BCCommonErrorType;
  recipientBalance: number;
  requestId: Uint8Array;
}

export interface TicketRewardBatchReq {
  consumeTicketReqs: ConsumeTicketReq[];
  transferFromReqs: RewardTransferFromReq[];
}

export interface TicketRewardBatchResp {
  consumeTicketResps: ConsumeTicketResp[];
  transferFromResps: RewardTransferFromResp[];
}

export interface SolidityRequest {
  /** mandatory for all function call */
  functionType: FunctionType;
  requestId: Uint8Array;
  timestamp: number;
  registerProposalReq: RegisterProposalReq | undefined;
  didRegisterBatchReq: DidRegisterBatchReq | undefined;
  didGetReq: DidGetReq | undefined;
  ticketRewardBatchReq: TicketRewardBatchReq | undefined;
}

export interface SolidityResponse {
  functionType: FunctionType;
  requestId: Uint8Array;
  timestamp: number;
  registerProposalResp: RegisterProposalResp | undefined;
  didRegisterBatchResp: DidRegisterBatchResp | undefined;
  didGetResp: DidGetResp | undefined;
  ticketRewardBatchResp: TicketRewardBatchResp | undefined;
}

function createBaseProposalInfo(): ProposalInfo {
  return {
    proposalId: new Uint8Array(),
    companyId: new Uint8Array(),
    purpose: 0,
    rewardMaxAmount: 0,
    ticketValidFrom: 0,
    ticketValidUntil: 0,
    reservedForFuture: new Uint8Array(),
    proposalHash: new Uint8Array(),
  };
}

export const ProposalInfo = {
  encode(message: ProposalInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalId.length !== 0) {
      writer.uint32(10).bytes(message.proposalId);
    }
    if (message.companyId.length !== 0) {
      writer.uint32(18).bytes(message.companyId);
    }
    if (message.purpose !== 0) {
      writer.uint32(24).uint64(message.purpose);
    }
    if (message.rewardMaxAmount !== 0) {
      writer.uint32(32).uint64(message.rewardMaxAmount);
    }
    if (message.ticketValidFrom !== 0) {
      writer.uint32(40).uint64(message.ticketValidFrom);
    }
    if (message.ticketValidUntil !== 0) {
      writer.uint32(48).uint64(message.ticketValidUntil);
    }
    if (message.reservedForFuture.length !== 0) {
      writer.uint32(58).bytes(message.reservedForFuture);
    }
    if (message.proposalHash.length !== 0) {
      writer.uint32(66).bytes(message.proposalHash);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProposalInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposalInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.proposalId = reader.bytes();
          break;
        case 2:
          message.companyId = reader.bytes();
          break;
        case 3:
          message.purpose = longToNumber(reader.uint64() as Long);
          break;
        case 4:
          message.rewardMaxAmount = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.ticketValidFrom = longToNumber(reader.uint64() as Long);
          break;
        case 6:
          message.ticketValidUntil = longToNumber(reader.uint64() as Long);
          break;
        case 7:
          message.reservedForFuture = reader.bytes();
          break;
        case 8:
          message.proposalHash = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProposalInfo {
    return {
      proposalId: isSet(object.proposalId) ? bytesFromBase64(object.proposalId) : new Uint8Array(),
      companyId: isSet(object.companyId) ? bytesFromBase64(object.companyId) : new Uint8Array(),
      purpose: isSet(object.purpose) ? Number(object.purpose) : 0,
      rewardMaxAmount: isSet(object.rewardMaxAmount) ? Number(object.rewardMaxAmount) : 0,
      ticketValidFrom: isSet(object.ticketValidFrom) ? Number(object.ticketValidFrom) : 0,
      ticketValidUntil: isSet(object.ticketValidUntil) ? Number(object.ticketValidUntil) : 0,
      reservedForFuture: isSet(object.reservedForFuture) ? bytesFromBase64(object.reservedForFuture) : new Uint8Array(),
      proposalHash: isSet(object.proposalHash) ? bytesFromBase64(object.proposalHash) : new Uint8Array(),
    };
  },

  toJSON(message: ProposalInfo): unknown {
    const obj: any = {};
    message.proposalId !== undefined &&
      (obj.proposalId = base64FromBytes(message.proposalId !== undefined ? message.proposalId : new Uint8Array()));
    message.companyId !== undefined &&
      (obj.companyId = base64FromBytes(message.companyId !== undefined ? message.companyId : new Uint8Array()));
    message.purpose !== undefined && (obj.purpose = Math.round(message.purpose));
    message.rewardMaxAmount !== undefined && (obj.rewardMaxAmount = Math.round(message.rewardMaxAmount));
    message.ticketValidFrom !== undefined && (obj.ticketValidFrom = Math.round(message.ticketValidFrom));
    message.ticketValidUntil !== undefined && (obj.ticketValidUntil = Math.round(message.ticketValidUntil));
    message.reservedForFuture !== undefined &&
      (obj.reservedForFuture = base64FromBytes(
        message.reservedForFuture !== undefined ? message.reservedForFuture : new Uint8Array(),
      ));
    message.proposalHash !== undefined &&
      (obj.proposalHash = base64FromBytes(
        message.proposalHash !== undefined ? message.proposalHash : new Uint8Array(),
      ));
    return obj;
  },

  create<I extends Exact<DeepPartial<ProposalInfo>, I>>(base?: I): ProposalInfo {
    return ProposalInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProposalInfo>, I>>(object: I): ProposalInfo {
    const message = createBaseProposalInfo();
    message.proposalId = object.proposalId ?? new Uint8Array();
    message.companyId = object.companyId ?? new Uint8Array();
    message.purpose = object.purpose ?? 0;
    message.rewardMaxAmount = object.rewardMaxAmount ?? 0;
    message.ticketValidFrom = object.ticketValidFrom ?? 0;
    message.ticketValidUntil = object.ticketValidUntil ?? 0;
    message.reservedForFuture = object.reservedForFuture ?? new Uint8Array();
    message.proposalHash = object.proposalHash ?? new Uint8Array();
    return message;
  },
};

function createBaseDidInfo(): DidInfo {
  return { did: "", document: "", besuAddress: new Uint8Array() };
}

export const DidInfo = {
  encode(message: DidInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(18).string(message.did);
    }
    if (message.document !== "") {
      writer.uint32(26).string(message.document);
    }
    if (message.besuAddress.length !== 0) {
      writer.uint32(34).bytes(message.besuAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DidInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDidInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.did = reader.string();
          break;
        case 3:
          message.document = reader.string();
          break;
        case 4:
          message.besuAddress = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DidInfo {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      document: isSet(object.document) ? String(object.document) : "",
      besuAddress: isSet(object.besuAddress) ? bytesFromBase64(object.besuAddress) : new Uint8Array(),
    };
  },

  toJSON(message: DidInfo): unknown {
    const obj: any = {};
    message.did !== undefined && (obj.did = message.did);
    message.document !== undefined && (obj.document = message.document);
    message.besuAddress !== undefined &&
      (obj.besuAddress = base64FromBytes(message.besuAddress !== undefined ? message.besuAddress : new Uint8Array()));
    return obj;
  },

  create<I extends Exact<DeepPartial<DidInfo>, I>>(base?: I): DidInfo {
    return DidInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DidInfo>, I>>(object: I): DidInfo {
    const message = createBaseDidInfo();
    message.did = object.did ?? "";
    message.document = object.document ?? "";
    message.besuAddress = object.besuAddress ?? new Uint8Array();
    return message;
  },
};

function createBaseRegisterProposalReq(): RegisterProposalReq {
  return { proposalInfo: undefined, allowance: 0 };
}

export const RegisterProposalReq = {
  encode(message: RegisterProposalReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalInfo !== undefined) {
      ProposalInfo.encode(message.proposalInfo, writer.uint32(10).fork()).ldelim();
    }
    if (message.allowance !== 0) {
      writer.uint32(16).uint64(message.allowance);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterProposalReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterProposalReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.proposalInfo = ProposalInfo.decode(reader, reader.uint32());
          break;
        case 2:
          message.allowance = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RegisterProposalReq {
    return {
      proposalInfo: isSet(object.proposalInfo) ? ProposalInfo.fromJSON(object.proposalInfo) : undefined,
      allowance: isSet(object.allowance) ? Number(object.allowance) : 0,
    };
  },

  toJSON(message: RegisterProposalReq): unknown {
    const obj: any = {};
    message.proposalInfo !== undefined &&
      (obj.proposalInfo = message.proposalInfo ? ProposalInfo.toJSON(message.proposalInfo) : undefined);
    message.allowance !== undefined && (obj.allowance = Math.round(message.allowance));
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterProposalReq>, I>>(base?: I): RegisterProposalReq {
    return RegisterProposalReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegisterProposalReq>, I>>(object: I): RegisterProposalReq {
    const message = createBaseRegisterProposalReq();
    message.proposalInfo = (object.proposalInfo !== undefined && object.proposalInfo !== null)
      ? ProposalInfo.fromPartial(object.proposalInfo)
      : undefined;
    message.allowance = object.allowance ?? 0;
    return message;
  },
};

function createBaseRegisterProposalResp(): RegisterProposalResp {
  return { statusCode: 0, proposalHash: new Uint8Array() };
}

export const RegisterProposalResp = {
  encode(message: RegisterProposalResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.proposalHash.length !== 0) {
      writer.uint32(18).bytes(message.proposalHash);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterProposalResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterProposalResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.statusCode = reader.int32() as any;
          break;
        case 2:
          message.proposalHash = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RegisterProposalResp {
    return {
      statusCode: isSet(object.statusCode) ? bCCommonErrorTypeFromJSON(object.statusCode) : 0,
      proposalHash: isSet(object.proposalHash) ? bytesFromBase64(object.proposalHash) : new Uint8Array(),
    };
  },

  toJSON(message: RegisterProposalResp): unknown {
    const obj: any = {};
    message.statusCode !== undefined && (obj.statusCode = bCCommonErrorTypeToJSON(message.statusCode));
    message.proposalHash !== undefined &&
      (obj.proposalHash = base64FromBytes(
        message.proposalHash !== undefined ? message.proposalHash : new Uint8Array(),
      ));
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterProposalResp>, I>>(base?: I): RegisterProposalResp {
    return RegisterProposalResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegisterProposalResp>, I>>(object: I): RegisterProposalResp {
    const message = createBaseRegisterProposalResp();
    message.statusCode = object.statusCode ?? 0;
    message.proposalHash = object.proposalHash ?? new Uint8Array();
    return message;
  },
};

function createBaseConsumeTicketReq(): ConsumeTicketReq {
  return {
    proposalId: new Uint8Array(),
    ticketId: new Uint8Array(),
    receiverAddress: new Uint8Array(),
    amount: 0,
    ticketAgreementHash: new Uint8Array(),
    dataReceiptHash: new Uint8Array(),
    requestId: new Uint8Array(),
  };
}

export const ConsumeTicketReq = {
  encode(message: ConsumeTicketReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalId.length !== 0) {
      writer.uint32(10).bytes(message.proposalId);
    }
    if (message.ticketId.length !== 0) {
      writer.uint32(18).bytes(message.ticketId);
    }
    if (message.receiverAddress.length !== 0) {
      writer.uint32(26).bytes(message.receiverAddress);
    }
    if (message.amount !== 0) {
      writer.uint32(32).uint64(message.amount);
    }
    if (message.ticketAgreementHash.length !== 0) {
      writer.uint32(42).bytes(message.ticketAgreementHash);
    }
    if (message.dataReceiptHash.length !== 0) {
      writer.uint32(50).bytes(message.dataReceiptHash);
    }
    if (message.requestId.length !== 0) {
      writer.uint32(58).bytes(message.requestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConsumeTicketReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumeTicketReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.proposalId = reader.bytes();
          break;
        case 2:
          message.ticketId = reader.bytes();
          break;
        case 3:
          message.receiverAddress = reader.bytes();
          break;
        case 4:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.ticketAgreementHash = reader.bytes();
          break;
        case 6:
          message.dataReceiptHash = reader.bytes();
          break;
        case 7:
          message.requestId = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ConsumeTicketReq {
    return {
      proposalId: isSet(object.proposalId) ? bytesFromBase64(object.proposalId) : new Uint8Array(),
      ticketId: isSet(object.ticketId) ? bytesFromBase64(object.ticketId) : new Uint8Array(),
      receiverAddress: isSet(object.receiverAddress) ? bytesFromBase64(object.receiverAddress) : new Uint8Array(),
      amount: isSet(object.amount) ? Number(object.amount) : 0,
      ticketAgreementHash: isSet(object.ticketAgreementHash)
        ? bytesFromBase64(object.ticketAgreementHash)
        : new Uint8Array(),
      dataReceiptHash: isSet(object.dataReceiptHash) ? bytesFromBase64(object.dataReceiptHash) : new Uint8Array(),
      requestId: isSet(object.requestId) ? bytesFromBase64(object.requestId) : new Uint8Array(),
    };
  },

  toJSON(message: ConsumeTicketReq): unknown {
    const obj: any = {};
    message.proposalId !== undefined &&
      (obj.proposalId = base64FromBytes(message.proposalId !== undefined ? message.proposalId : new Uint8Array()));
    message.ticketId !== undefined &&
      (obj.ticketId = base64FromBytes(message.ticketId !== undefined ? message.ticketId : new Uint8Array()));
    message.receiverAddress !== undefined &&
      (obj.receiverAddress = base64FromBytes(
        message.receiverAddress !== undefined ? message.receiverAddress : new Uint8Array(),
      ));
    message.amount !== undefined && (obj.amount = Math.round(message.amount));
    message.ticketAgreementHash !== undefined &&
      (obj.ticketAgreementHash = base64FromBytes(
        message.ticketAgreementHash !== undefined ? message.ticketAgreementHash : new Uint8Array(),
      ));
    message.dataReceiptHash !== undefined &&
      (obj.dataReceiptHash = base64FromBytes(
        message.dataReceiptHash !== undefined ? message.dataReceiptHash : new Uint8Array(),
      ));
    message.requestId !== undefined &&
      (obj.requestId = base64FromBytes(message.requestId !== undefined ? message.requestId : new Uint8Array()));
    return obj;
  },

  create<I extends Exact<DeepPartial<ConsumeTicketReq>, I>>(base?: I): ConsumeTicketReq {
    return ConsumeTicketReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConsumeTicketReq>, I>>(object: I): ConsumeTicketReq {
    const message = createBaseConsumeTicketReq();
    message.proposalId = object.proposalId ?? new Uint8Array();
    message.ticketId = object.ticketId ?? new Uint8Array();
    message.receiverAddress = object.receiverAddress ?? new Uint8Array();
    message.amount = object.amount ?? 0;
    message.ticketAgreementHash = object.ticketAgreementHash ?? new Uint8Array();
    message.dataReceiptHash = object.dataReceiptHash ?? new Uint8Array();
    message.requestId = object.requestId ?? new Uint8Array();
    return message;
  },
};

function createBaseConsumeTicketResp(): ConsumeTicketResp {
  return {
    statusCode: 0,
    ticketAgreementHash: new Uint8Array(),
    dataReceiptHash: new Uint8Array(),
    proposalBalance: 0,
    recipientBalance: 0,
    requestId: new Uint8Array(),
  };
}

export const ConsumeTicketResp = {
  encode(message: ConsumeTicketResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.ticketAgreementHash.length !== 0) {
      writer.uint32(18).bytes(message.ticketAgreementHash);
    }
    if (message.dataReceiptHash.length !== 0) {
      writer.uint32(26).bytes(message.dataReceiptHash);
    }
    if (message.proposalBalance !== 0) {
      writer.uint32(32).uint64(message.proposalBalance);
    }
    if (message.recipientBalance !== 0) {
      writer.uint32(40).uint64(message.recipientBalance);
    }
    if (message.requestId.length !== 0) {
      writer.uint32(50).bytes(message.requestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConsumeTicketResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumeTicketResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.statusCode = reader.int32() as any;
          break;
        case 2:
          message.ticketAgreementHash = reader.bytes();
          break;
        case 3:
          message.dataReceiptHash = reader.bytes();
          break;
        case 4:
          message.proposalBalance = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.recipientBalance = longToNumber(reader.uint64() as Long);
          break;
        case 6:
          message.requestId = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ConsumeTicketResp {
    return {
      statusCode: isSet(object.statusCode) ? bCCommonErrorTypeFromJSON(object.statusCode) : 0,
      ticketAgreementHash: isSet(object.ticketAgreementHash)
        ? bytesFromBase64(object.ticketAgreementHash)
        : new Uint8Array(),
      dataReceiptHash: isSet(object.dataReceiptHash) ? bytesFromBase64(object.dataReceiptHash) : new Uint8Array(),
      proposalBalance: isSet(object.proposalBalance) ? Number(object.proposalBalance) : 0,
      recipientBalance: isSet(object.recipientBalance) ? Number(object.recipientBalance) : 0,
      requestId: isSet(object.requestId) ? bytesFromBase64(object.requestId) : new Uint8Array(),
    };
  },

  toJSON(message: ConsumeTicketResp): unknown {
    const obj: any = {};
    message.statusCode !== undefined && (obj.statusCode = bCCommonErrorTypeToJSON(message.statusCode));
    message.ticketAgreementHash !== undefined &&
      (obj.ticketAgreementHash = base64FromBytes(
        message.ticketAgreementHash !== undefined ? message.ticketAgreementHash : new Uint8Array(),
      ));
    message.dataReceiptHash !== undefined &&
      (obj.dataReceiptHash = base64FromBytes(
        message.dataReceiptHash !== undefined ? message.dataReceiptHash : new Uint8Array(),
      ));
    message.proposalBalance !== undefined && (obj.proposalBalance = Math.round(message.proposalBalance));
    message.recipientBalance !== undefined && (obj.recipientBalance = Math.round(message.recipientBalance));
    message.requestId !== undefined &&
      (obj.requestId = base64FromBytes(message.requestId !== undefined ? message.requestId : new Uint8Array()));
    return obj;
  },

  create<I extends Exact<DeepPartial<ConsumeTicketResp>, I>>(base?: I): ConsumeTicketResp {
    return ConsumeTicketResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConsumeTicketResp>, I>>(object: I): ConsumeTicketResp {
    const message = createBaseConsumeTicketResp();
    message.statusCode = object.statusCode ?? 0;
    message.ticketAgreementHash = object.ticketAgreementHash ?? new Uint8Array();
    message.dataReceiptHash = object.dataReceiptHash ?? new Uint8Array();
    message.proposalBalance = object.proposalBalance ?? 0;
    message.recipientBalance = object.recipientBalance ?? 0;
    message.requestId = object.requestId ?? new Uint8Array();
    return message;
  },
};

function createBaseDidRegisterReq(): DidRegisterReq {
  return { didInfo: undefined };
}

export const DidRegisterReq = {
  encode(message: DidRegisterReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.didInfo !== undefined) {
      DidInfo.encode(message.didInfo, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DidRegisterReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDidRegisterReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.didInfo = DidInfo.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DidRegisterReq {
    return { didInfo: isSet(object.didInfo) ? DidInfo.fromJSON(object.didInfo) : undefined };
  },

  toJSON(message: DidRegisterReq): unknown {
    const obj: any = {};
    message.didInfo !== undefined && (obj.didInfo = message.didInfo ? DidInfo.toJSON(message.didInfo) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DidRegisterReq>, I>>(base?: I): DidRegisterReq {
    return DidRegisterReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DidRegisterReq>, I>>(object: I): DidRegisterReq {
    const message = createBaseDidRegisterReq();
    message.didInfo = (object.didInfo !== undefined && object.didInfo !== null)
      ? DidInfo.fromPartial(object.didInfo)
      : undefined;
    return message;
  },
};

function createBaseDidRegisterResp(): DidRegisterResp {
  return { statusCode: 0 };
}

export const DidRegisterResp = {
  encode(message: DidRegisterResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DidRegisterResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDidRegisterResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.statusCode = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DidRegisterResp {
    return { statusCode: isSet(object.statusCode) ? bCCommonErrorTypeFromJSON(object.statusCode) : 0 };
  },

  toJSON(message: DidRegisterResp): unknown {
    const obj: any = {};
    message.statusCode !== undefined && (obj.statusCode = bCCommonErrorTypeToJSON(message.statusCode));
    return obj;
  },

  create<I extends Exact<DeepPartial<DidRegisterResp>, I>>(base?: I): DidRegisterResp {
    return DidRegisterResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DidRegisterResp>, I>>(object: I): DidRegisterResp {
    const message = createBaseDidRegisterResp();
    message.statusCode = object.statusCode ?? 0;
    return message;
  },
};

function createBaseDidRegisterBatchReq(): DidRegisterBatchReq {
  return { didRegisterReqs: [] };
}

export const DidRegisterBatchReq = {
  encode(message: DidRegisterBatchReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.didRegisterReqs) {
      DidRegisterReq.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DidRegisterBatchReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDidRegisterBatchReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.didRegisterReqs.push(DidRegisterReq.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DidRegisterBatchReq {
    return {
      didRegisterReqs: Array.isArray(object?.didRegisterReqs)
        ? object.didRegisterReqs.map((e: any) => DidRegisterReq.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DidRegisterBatchReq): unknown {
    const obj: any = {};
    if (message.didRegisterReqs) {
      obj.didRegisterReqs = message.didRegisterReqs.map((e) => e ? DidRegisterReq.toJSON(e) : undefined);
    } else {
      obj.didRegisterReqs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DidRegisterBatchReq>, I>>(base?: I): DidRegisterBatchReq {
    return DidRegisterBatchReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DidRegisterBatchReq>, I>>(object: I): DidRegisterBatchReq {
    const message = createBaseDidRegisterBatchReq();
    message.didRegisterReqs = object.didRegisterReqs?.map((e) => DidRegisterReq.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDidRegisterBatchResp(): DidRegisterBatchResp {
  return { didRegisterResps: [] };
}

export const DidRegisterBatchResp = {
  encode(message: DidRegisterBatchResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.didRegisterResps) {
      DidRegisterResp.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DidRegisterBatchResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDidRegisterBatchResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.didRegisterResps.push(DidRegisterResp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DidRegisterBatchResp {
    return {
      didRegisterResps: Array.isArray(object?.didRegisterResps)
        ? object.didRegisterResps.map((e: any) => DidRegisterResp.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DidRegisterBatchResp): unknown {
    const obj: any = {};
    if (message.didRegisterResps) {
      obj.didRegisterResps = message.didRegisterResps.map((e) => e ? DidRegisterResp.toJSON(e) : undefined);
    } else {
      obj.didRegisterResps = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DidRegisterBatchResp>, I>>(base?: I): DidRegisterBatchResp {
    return DidRegisterBatchResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DidRegisterBatchResp>, I>>(object: I): DidRegisterBatchResp {
    const message = createBaseDidRegisterBatchResp();
    message.didRegisterResps = object.didRegisterResps?.map((e) => DidRegisterResp.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDidGetReq(): DidGetReq {
  return { did: "" };
}

export const DidGetReq = {
  encode(message: DidGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DidGetReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDidGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.did = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DidGetReq {
    return { did: isSet(object.did) ? String(object.did) : "" };
  },

  toJSON(message: DidGetReq): unknown {
    const obj: any = {};
    message.did !== undefined && (obj.did = message.did);
    return obj;
  },

  create<I extends Exact<DeepPartial<DidGetReq>, I>>(base?: I): DidGetReq {
    return DidGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DidGetReq>, I>>(object: I): DidGetReq {
    const message = createBaseDidGetReq();
    message.did = object.did ?? "";
    return message;
  },
};

function createBaseDidGetResp(): DidGetResp {
  return { statusCode: 0, didInfo: undefined };
}

export const DidGetResp = {
  encode(message: DidGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.didInfo !== undefined) {
      DidInfo.encode(message.didInfo, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DidGetResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDidGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.statusCode = reader.int32() as any;
          break;
        case 2:
          message.didInfo = DidInfo.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DidGetResp {
    return {
      statusCode: isSet(object.statusCode) ? bCCommonErrorTypeFromJSON(object.statusCode) : 0,
      didInfo: isSet(object.didInfo) ? DidInfo.fromJSON(object.didInfo) : undefined,
    };
  },

  toJSON(message: DidGetResp): unknown {
    const obj: any = {};
    message.statusCode !== undefined && (obj.statusCode = bCCommonErrorTypeToJSON(message.statusCode));
    message.didInfo !== undefined && (obj.didInfo = message.didInfo ? DidInfo.toJSON(message.didInfo) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DidGetResp>, I>>(base?: I): DidGetResp {
    return DidGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DidGetResp>, I>>(object: I): DidGetResp {
    const message = createBaseDidGetResp();
    message.statusCode = object.statusCode ?? 0;
    message.didInfo = (object.didInfo !== undefined && object.didInfo !== null)
      ? DidInfo.fromPartial(object.didInfo)
      : undefined;
    return message;
  },
};

function createBaseRewardTransferFromReq(): RewardTransferFromReq {
  return {
    sender: new Uint8Array(),
    recipient: new Uint8Array(),
    amount: 0,
    requestId: new Uint8Array(),
    operatorTransfer: false,
  };
}

export const RewardTransferFromReq = {
  encode(message: RewardTransferFromReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sender.length !== 0) {
      writer.uint32(10).bytes(message.sender);
    }
    if (message.recipient.length !== 0) {
      writer.uint32(18).bytes(message.recipient);
    }
    if (message.amount !== 0) {
      writer.uint32(24).uint64(message.amount);
    }
    if (message.requestId.length !== 0) {
      writer.uint32(34).bytes(message.requestId);
    }
    if (message.operatorTransfer === true) {
      writer.uint32(40).bool(message.operatorTransfer);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RewardTransferFromReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRewardTransferFromReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.sender = reader.bytes();
          break;
        case 2:
          message.recipient = reader.bytes();
          break;
        case 3:
          message.amount = longToNumber(reader.uint64() as Long);
          break;
        case 4:
          message.requestId = reader.bytes();
          break;
        case 5:
          message.operatorTransfer = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RewardTransferFromReq {
    return {
      sender: isSet(object.sender) ? bytesFromBase64(object.sender) : new Uint8Array(),
      recipient: isSet(object.recipient) ? bytesFromBase64(object.recipient) : new Uint8Array(),
      amount: isSet(object.amount) ? Number(object.amount) : 0,
      requestId: isSet(object.requestId) ? bytesFromBase64(object.requestId) : new Uint8Array(),
      operatorTransfer: isSet(object.operatorTransfer) ? Boolean(object.operatorTransfer) : false,
    };
  },

  toJSON(message: RewardTransferFromReq): unknown {
    const obj: any = {};
    message.sender !== undefined &&
      (obj.sender = base64FromBytes(message.sender !== undefined ? message.sender : new Uint8Array()));
    message.recipient !== undefined &&
      (obj.recipient = base64FromBytes(message.recipient !== undefined ? message.recipient : new Uint8Array()));
    message.amount !== undefined && (obj.amount = Math.round(message.amount));
    message.requestId !== undefined &&
      (obj.requestId = base64FromBytes(message.requestId !== undefined ? message.requestId : new Uint8Array()));
    message.operatorTransfer !== undefined && (obj.operatorTransfer = message.operatorTransfer);
    return obj;
  },

  create<I extends Exact<DeepPartial<RewardTransferFromReq>, I>>(base?: I): RewardTransferFromReq {
    return RewardTransferFromReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RewardTransferFromReq>, I>>(object: I): RewardTransferFromReq {
    const message = createBaseRewardTransferFromReq();
    message.sender = object.sender ?? new Uint8Array();
    message.recipient = object.recipient ?? new Uint8Array();
    message.amount = object.amount ?? 0;
    message.requestId = object.requestId ?? new Uint8Array();
    message.operatorTransfer = object.operatorTransfer ?? false;
    return message;
  },
};

function createBaseRewardTransferFromResp(): RewardTransferFromResp {
  return { statusCode: 0, recipientBalance: 0, requestId: new Uint8Array() };
}

export const RewardTransferFromResp = {
  encode(message: RewardTransferFromResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.recipientBalance !== 0) {
      writer.uint32(16).uint64(message.recipientBalance);
    }
    if (message.requestId.length !== 0) {
      writer.uint32(26).bytes(message.requestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RewardTransferFromResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRewardTransferFromResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.statusCode = reader.int32() as any;
          break;
        case 2:
          message.recipientBalance = longToNumber(reader.uint64() as Long);
          break;
        case 3:
          message.requestId = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RewardTransferFromResp {
    return {
      statusCode: isSet(object.statusCode) ? bCCommonErrorTypeFromJSON(object.statusCode) : 0,
      recipientBalance: isSet(object.recipientBalance) ? Number(object.recipientBalance) : 0,
      requestId: isSet(object.requestId) ? bytesFromBase64(object.requestId) : new Uint8Array(),
    };
  },

  toJSON(message: RewardTransferFromResp): unknown {
    const obj: any = {};
    message.statusCode !== undefined && (obj.statusCode = bCCommonErrorTypeToJSON(message.statusCode));
    message.recipientBalance !== undefined && (obj.recipientBalance = Math.round(message.recipientBalance));
    message.requestId !== undefined &&
      (obj.requestId = base64FromBytes(message.requestId !== undefined ? message.requestId : new Uint8Array()));
    return obj;
  },

  create<I extends Exact<DeepPartial<RewardTransferFromResp>, I>>(base?: I): RewardTransferFromResp {
    return RewardTransferFromResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RewardTransferFromResp>, I>>(object: I): RewardTransferFromResp {
    const message = createBaseRewardTransferFromResp();
    message.statusCode = object.statusCode ?? 0;
    message.recipientBalance = object.recipientBalance ?? 0;
    message.requestId = object.requestId ?? new Uint8Array();
    return message;
  },
};

function createBaseTicketRewardBatchReq(): TicketRewardBatchReq {
  return { consumeTicketReqs: [], transferFromReqs: [] };
}

export const TicketRewardBatchReq = {
  encode(message: TicketRewardBatchReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.consumeTicketReqs) {
      ConsumeTicketReq.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.transferFromReqs) {
      RewardTransferFromReq.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TicketRewardBatchReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTicketRewardBatchReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.consumeTicketReqs.push(ConsumeTicketReq.decode(reader, reader.uint32()));
          break;
        case 2:
          message.transferFromReqs.push(RewardTransferFromReq.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TicketRewardBatchReq {
    return {
      consumeTicketReqs: Array.isArray(object?.consumeTicketReqs)
        ? object.consumeTicketReqs.map((e: any) => ConsumeTicketReq.fromJSON(e))
        : [],
      transferFromReqs: Array.isArray(object?.transferFromReqs)
        ? object.transferFromReqs.map((e: any) => RewardTransferFromReq.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TicketRewardBatchReq): unknown {
    const obj: any = {};
    if (message.consumeTicketReqs) {
      obj.consumeTicketReqs = message.consumeTicketReqs.map((e) => e ? ConsumeTicketReq.toJSON(e) : undefined);
    } else {
      obj.consumeTicketReqs = [];
    }
    if (message.transferFromReqs) {
      obj.transferFromReqs = message.transferFromReqs.map((e) => e ? RewardTransferFromReq.toJSON(e) : undefined);
    } else {
      obj.transferFromReqs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TicketRewardBatchReq>, I>>(base?: I): TicketRewardBatchReq {
    return TicketRewardBatchReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TicketRewardBatchReq>, I>>(object: I): TicketRewardBatchReq {
    const message = createBaseTicketRewardBatchReq();
    message.consumeTicketReqs = object.consumeTicketReqs?.map((e) => ConsumeTicketReq.fromPartial(e)) || [];
    message.transferFromReqs = object.transferFromReqs?.map((e) => RewardTransferFromReq.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTicketRewardBatchResp(): TicketRewardBatchResp {
  return { consumeTicketResps: [], transferFromResps: [] };
}

export const TicketRewardBatchResp = {
  encode(message: TicketRewardBatchResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.consumeTicketResps) {
      ConsumeTicketResp.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.transferFromResps) {
      RewardTransferFromResp.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TicketRewardBatchResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTicketRewardBatchResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.consumeTicketResps.push(ConsumeTicketResp.decode(reader, reader.uint32()));
          break;
        case 2:
          message.transferFromResps.push(RewardTransferFromResp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TicketRewardBatchResp {
    return {
      consumeTicketResps: Array.isArray(object?.consumeTicketResps)
        ? object.consumeTicketResps.map((e: any) => ConsumeTicketResp.fromJSON(e))
        : [],
      transferFromResps: Array.isArray(object?.transferFromResps)
        ? object.transferFromResps.map((e: any) => RewardTransferFromResp.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TicketRewardBatchResp): unknown {
    const obj: any = {};
    if (message.consumeTicketResps) {
      obj.consumeTicketResps = message.consumeTicketResps.map((e) => e ? ConsumeTicketResp.toJSON(e) : undefined);
    } else {
      obj.consumeTicketResps = [];
    }
    if (message.transferFromResps) {
      obj.transferFromResps = message.transferFromResps.map((e) => e ? RewardTransferFromResp.toJSON(e) : undefined);
    } else {
      obj.transferFromResps = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TicketRewardBatchResp>, I>>(base?: I): TicketRewardBatchResp {
    return TicketRewardBatchResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TicketRewardBatchResp>, I>>(object: I): TicketRewardBatchResp {
    const message = createBaseTicketRewardBatchResp();
    message.consumeTicketResps = object.consumeTicketResps?.map((e) => ConsumeTicketResp.fromPartial(e)) || [];
    message.transferFromResps = object.transferFromResps?.map((e) => RewardTransferFromResp.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSolidityRequest(): SolidityRequest {
  return {
    functionType: 0,
    requestId: new Uint8Array(),
    timestamp: 0,
    registerProposalReq: undefined,
    didRegisterBatchReq: undefined,
    didGetReq: undefined,
    ticketRewardBatchReq: undefined,
  };
}

export const SolidityRequest = {
  encode(message: SolidityRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.functionType !== 0) {
      writer.uint32(8).int32(message.functionType);
    }
    if (message.requestId.length !== 0) {
      writer.uint32(18).bytes(message.requestId);
    }
    if (message.timestamp !== 0) {
      writer.uint32(24).uint64(message.timestamp);
    }
    if (message.registerProposalReq !== undefined) {
      RegisterProposalReq.encode(message.registerProposalReq, writer.uint32(90).fork()).ldelim();
    }
    if (message.didRegisterBatchReq !== undefined) {
      DidRegisterBatchReq.encode(message.didRegisterBatchReq, writer.uint32(178).fork()).ldelim();
    }
    if (message.didGetReq !== undefined) {
      DidGetReq.encode(message.didGetReq, writer.uint32(186).fork()).ldelim();
    }
    if (message.ticketRewardBatchReq !== undefined) {
      TicketRewardBatchReq.encode(message.ticketRewardBatchReq, writer.uint32(402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SolidityRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSolidityRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.functionType = reader.int32() as any;
          break;
        case 2:
          message.requestId = reader.bytes();
          break;
        case 3:
          message.timestamp = longToNumber(reader.uint64() as Long);
          break;
        case 11:
          message.registerProposalReq = RegisterProposalReq.decode(reader, reader.uint32());
          break;
        case 22:
          message.didRegisterBatchReq = DidRegisterBatchReq.decode(reader, reader.uint32());
          break;
        case 23:
          message.didGetReq = DidGetReq.decode(reader, reader.uint32());
          break;
        case 50:
          message.ticketRewardBatchReq = TicketRewardBatchReq.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SolidityRequest {
    return {
      functionType: isSet(object.functionType) ? functionTypeFromJSON(object.functionType) : 0,
      requestId: isSet(object.requestId) ? bytesFromBase64(object.requestId) : new Uint8Array(),
      timestamp: isSet(object.timestamp) ? Number(object.timestamp) : 0,
      registerProposalReq: isSet(object.registerProposalReq)
        ? RegisterProposalReq.fromJSON(object.registerProposalReq)
        : undefined,
      didRegisterBatchReq: isSet(object.didRegisterBatchReq)
        ? DidRegisterBatchReq.fromJSON(object.didRegisterBatchReq)
        : undefined,
      didGetReq: isSet(object.didGetReq) ? DidGetReq.fromJSON(object.didGetReq) : undefined,
      ticketRewardBatchReq: isSet(object.ticketRewardBatchReq)
        ? TicketRewardBatchReq.fromJSON(object.ticketRewardBatchReq)
        : undefined,
    };
  },

  toJSON(message: SolidityRequest): unknown {
    const obj: any = {};
    message.functionType !== undefined && (obj.functionType = functionTypeToJSON(message.functionType));
    message.requestId !== undefined &&
      (obj.requestId = base64FromBytes(message.requestId !== undefined ? message.requestId : new Uint8Array()));
    message.timestamp !== undefined && (obj.timestamp = Math.round(message.timestamp));
    message.registerProposalReq !== undefined && (obj.registerProposalReq = message.registerProposalReq
      ? RegisterProposalReq.toJSON(message.registerProposalReq)
      : undefined);
    message.didRegisterBatchReq !== undefined && (obj.didRegisterBatchReq = message.didRegisterBatchReq
      ? DidRegisterBatchReq.toJSON(message.didRegisterBatchReq)
      : undefined);
    message.didGetReq !== undefined &&
      (obj.didGetReq = message.didGetReq ? DidGetReq.toJSON(message.didGetReq) : undefined);
    message.ticketRewardBatchReq !== undefined && (obj.ticketRewardBatchReq = message.ticketRewardBatchReq
      ? TicketRewardBatchReq.toJSON(message.ticketRewardBatchReq)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SolidityRequest>, I>>(base?: I): SolidityRequest {
    return SolidityRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SolidityRequest>, I>>(object: I): SolidityRequest {
    const message = createBaseSolidityRequest();
    message.functionType = object.functionType ?? 0;
    message.requestId = object.requestId ?? new Uint8Array();
    message.timestamp = object.timestamp ?? 0;
    message.registerProposalReq = (object.registerProposalReq !== undefined && object.registerProposalReq !== null)
      ? RegisterProposalReq.fromPartial(object.registerProposalReq)
      : undefined;
    message.didRegisterBatchReq = (object.didRegisterBatchReq !== undefined && object.didRegisterBatchReq !== null)
      ? DidRegisterBatchReq.fromPartial(object.didRegisterBatchReq)
      : undefined;
    message.didGetReq = (object.didGetReq !== undefined && object.didGetReq !== null)
      ? DidGetReq.fromPartial(object.didGetReq)
      : undefined;
    message.ticketRewardBatchReq = (object.ticketRewardBatchReq !== undefined && object.ticketRewardBatchReq !== null)
      ? TicketRewardBatchReq.fromPartial(object.ticketRewardBatchReq)
      : undefined;
    return message;
  },
};

function createBaseSolidityResponse(): SolidityResponse {
  return {
    functionType: 0,
    requestId: new Uint8Array(),
    timestamp: 0,
    registerProposalResp: undefined,
    didRegisterBatchResp: undefined,
    didGetResp: undefined,
    ticketRewardBatchResp: undefined,
  };
}

export const SolidityResponse = {
  encode(message: SolidityResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.functionType !== 0) {
      writer.uint32(8).int32(message.functionType);
    }
    if (message.requestId.length !== 0) {
      writer.uint32(18).bytes(message.requestId);
    }
    if (message.timestamp !== 0) {
      writer.uint32(24).uint64(message.timestamp);
    }
    if (message.registerProposalResp !== undefined) {
      RegisterProposalResp.encode(message.registerProposalResp, writer.uint32(90).fork()).ldelim();
    }
    if (message.didRegisterBatchResp !== undefined) {
      DidRegisterBatchResp.encode(message.didRegisterBatchResp, writer.uint32(178).fork()).ldelim();
    }
    if (message.didGetResp !== undefined) {
      DidGetResp.encode(message.didGetResp, writer.uint32(186).fork()).ldelim();
    }
    if (message.ticketRewardBatchResp !== undefined) {
      TicketRewardBatchResp.encode(message.ticketRewardBatchResp, writer.uint32(402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SolidityResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSolidityResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.functionType = reader.int32() as any;
          break;
        case 2:
          message.requestId = reader.bytes();
          break;
        case 3:
          message.timestamp = longToNumber(reader.uint64() as Long);
          break;
        case 11:
          message.registerProposalResp = RegisterProposalResp.decode(reader, reader.uint32());
          break;
        case 22:
          message.didRegisterBatchResp = DidRegisterBatchResp.decode(reader, reader.uint32());
          break;
        case 23:
          message.didGetResp = DidGetResp.decode(reader, reader.uint32());
          break;
        case 50:
          message.ticketRewardBatchResp = TicketRewardBatchResp.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SolidityResponse {
    return {
      functionType: isSet(object.functionType) ? functionTypeFromJSON(object.functionType) : 0,
      requestId: isSet(object.requestId) ? bytesFromBase64(object.requestId) : new Uint8Array(),
      timestamp: isSet(object.timestamp) ? Number(object.timestamp) : 0,
      registerProposalResp: isSet(object.registerProposalResp)
        ? RegisterProposalResp.fromJSON(object.registerProposalResp)
        : undefined,
      didRegisterBatchResp: isSet(object.didRegisterBatchResp)
        ? DidRegisterBatchResp.fromJSON(object.didRegisterBatchResp)
        : undefined,
      didGetResp: isSet(object.didGetResp) ? DidGetResp.fromJSON(object.didGetResp) : undefined,
      ticketRewardBatchResp: isSet(object.ticketRewardBatchResp)
        ? TicketRewardBatchResp.fromJSON(object.ticketRewardBatchResp)
        : undefined,
    };
  },

  toJSON(message: SolidityResponse): unknown {
    const obj: any = {};
    message.functionType !== undefined && (obj.functionType = functionTypeToJSON(message.functionType));
    message.requestId !== undefined &&
      (obj.requestId = base64FromBytes(message.requestId !== undefined ? message.requestId : new Uint8Array()));
    message.timestamp !== undefined && (obj.timestamp = Math.round(message.timestamp));
    message.registerProposalResp !== undefined && (obj.registerProposalResp = message.registerProposalResp
      ? RegisterProposalResp.toJSON(message.registerProposalResp)
      : undefined);
    message.didRegisterBatchResp !== undefined && (obj.didRegisterBatchResp = message.didRegisterBatchResp
      ? DidRegisterBatchResp.toJSON(message.didRegisterBatchResp)
      : undefined);
    message.didGetResp !== undefined &&
      (obj.didGetResp = message.didGetResp ? DidGetResp.toJSON(message.didGetResp) : undefined);
    message.ticketRewardBatchResp !== undefined && (obj.ticketRewardBatchResp = message.ticketRewardBatchResp
      ? TicketRewardBatchResp.toJSON(message.ticketRewardBatchResp)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SolidityResponse>, I>>(base?: I): SolidityResponse {
    return SolidityResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SolidityResponse>, I>>(object: I): SolidityResponse {
    const message = createBaseSolidityResponse();
    message.functionType = object.functionType ?? 0;
    message.requestId = object.requestId ?? new Uint8Array();
    message.timestamp = object.timestamp ?? 0;
    message.registerProposalResp = (object.registerProposalResp !== undefined && object.registerProposalResp !== null)
      ? RegisterProposalResp.fromPartial(object.registerProposalResp)
      : undefined;
    message.didRegisterBatchResp = (object.didRegisterBatchResp !== undefined && object.didRegisterBatchResp !== null)
      ? DidRegisterBatchResp.fromPartial(object.didRegisterBatchResp)
      : undefined;
    message.didGetResp = (object.didGetResp !== undefined && object.didGetResp !== null)
      ? DidGetResp.fromPartial(object.didGetResp)
      : undefined;
    message.ticketRewardBatchResp =
      (object.ticketRewardBatchResp !== undefined && object.ticketRewardBatchResp !== null)
        ? TicketRewardBatchResp.fromPartial(object.ticketRewardBatchResp)
        : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

function bytesFromBase64(b64: string): Uint8Array {
  if (tsProtoGlobalThis.Buffer) {
    return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = tsProtoGlobalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (tsProtoGlobalThis.Buffer) {
    return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(String.fromCharCode(byte));
    });
    return tsProtoGlobalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
