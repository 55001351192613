/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { RewardTransactionContentForApp } from "./OpenApiServerV3DataTypes";

export const protobufPackage = "io.snplab.myd.protocol.v3.schema";

/** The type of the DID */
export enum RewardTransactionDidType {
  /** REWARD_TRANSACTION_DID_TYPE_BEGIN - placeholder for 0 */
  REWARD_TRANSACTION_DID_TYPE_BEGIN = 0,
  /** REWARD_TRANSACTION_DID_USER - myd app or sdk user */
  REWARD_TRANSACTION_DID_USER = 1,
  REWARD_TRANSACTION_DID_B2B_COMPANY = 2,
  UNRECOGNIZED = -1,
}

export function rewardTransactionDidTypeFromJSON(object: any): RewardTransactionDidType {
  switch (object) {
    case 0:
    case "REWARD_TRANSACTION_DID_TYPE_BEGIN":
      return RewardTransactionDidType.REWARD_TRANSACTION_DID_TYPE_BEGIN;
    case 1:
    case "REWARD_TRANSACTION_DID_USER":
      return RewardTransactionDidType.REWARD_TRANSACTION_DID_USER;
    case 2:
    case "REWARD_TRANSACTION_DID_B2B_COMPANY":
      return RewardTransactionDidType.REWARD_TRANSACTION_DID_B2B_COMPANY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RewardTransactionDidType.UNRECOGNIZED;
  }
}

export function rewardTransactionDidTypeToJSON(object: RewardTransactionDidType): string {
  switch (object) {
    case RewardTransactionDidType.REWARD_TRANSACTION_DID_TYPE_BEGIN:
      return "REWARD_TRANSACTION_DID_TYPE_BEGIN";
    case RewardTransactionDidType.REWARD_TRANSACTION_DID_USER:
      return "REWARD_TRANSACTION_DID_USER";
    case RewardTransactionDidType.REWARD_TRANSACTION_DID_B2B_COMPANY:
      return "REWARD_TRANSACTION_DID_B2B_COMPANY";
    case RewardTransactionDidType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** The action for the proposal */
export enum RewardTransactionProposalActionType {
  /** REWARD_TRANSACTION_PROPOSAL_ACTION_BEGIN - placeholder for 0 */
  REWARD_TRANSACTION_PROPOSAL_ACTION_BEGIN = 0,
  REWARD_TRANSACTION_PROPOSAL_ACTION_ACTIVATE = 1,
  REWARD_TRANSACTION_PROPOSAL_ACTION_CLAIM = 2,
  REWARD_TRANSACTION_PROPOSAL_ACTION_REVOKE = 3,
  REWARD_TRANSACTION_PROPOSAL_ACTION_REFUND = 4,
  UNRECOGNIZED = -1,
}

export function rewardTransactionProposalActionTypeFromJSON(object: any): RewardTransactionProposalActionType {
  switch (object) {
    case 0:
    case "REWARD_TRANSACTION_PROPOSAL_ACTION_BEGIN":
      return RewardTransactionProposalActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_BEGIN;
    case 1:
    case "REWARD_TRANSACTION_PROPOSAL_ACTION_ACTIVATE":
      return RewardTransactionProposalActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_ACTIVATE;
    case 2:
    case "REWARD_TRANSACTION_PROPOSAL_ACTION_CLAIM":
      return RewardTransactionProposalActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_CLAIM;
    case 3:
    case "REWARD_TRANSACTION_PROPOSAL_ACTION_REVOKE":
      return RewardTransactionProposalActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_REVOKE;
    case 4:
    case "REWARD_TRANSACTION_PROPOSAL_ACTION_REFUND":
      return RewardTransactionProposalActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_REFUND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RewardTransactionProposalActionType.UNRECOGNIZED;
  }
}

export function rewardTransactionProposalActionTypeToJSON(object: RewardTransactionProposalActionType): string {
  switch (object) {
    case RewardTransactionProposalActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_BEGIN:
      return "REWARD_TRANSACTION_PROPOSAL_ACTION_BEGIN";
    case RewardTransactionProposalActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_ACTIVATE:
      return "REWARD_TRANSACTION_PROPOSAL_ACTION_ACTIVATE";
    case RewardTransactionProposalActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_CLAIM:
      return "REWARD_TRANSACTION_PROPOSAL_ACTION_CLAIM";
    case RewardTransactionProposalActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_REVOKE:
      return "REWARD_TRANSACTION_PROPOSAL_ACTION_REVOKE";
    case RewardTransactionProposalActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_REFUND:
      return "REWARD_TRANSACTION_PROPOSAL_ACTION_REFUND";
    case RewardTransactionProposalActionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Operation from the platform operators */
export enum RewardTransactionOperatorActionType {
  /** REWARD_TRANSACTION_OPERATOR_ACTION_BEGIN - placeholder for 0 */
  REWARD_TRANSACTION_OPERATOR_ACTION_BEGIN = 0,
  REWARD_TRANSACTION_OPERATOR_ACTION_MINT = 1,
  REWARD_TRANSACTION_OPERATOR_ACTION_RECHARGE = 2,
  REWARD_TRANSACTION_PROPOSAL_ACTION_DID_TRANSFER = 3,
  REWARD_TRANSACTION_PROPOSAL_ACTION_BURN = 4,
  REWARD_TRANSACTION_PROPOSAL_ACTION_BULK_MINT = 5,
  REWARD_TRANSACTION_PROPOSAL_ACTION_POINT_ADJUSTMENT = 6,
  REWARD_TRANSACTION_PROPOSAL_ACTION_MYD2_OTHERS = 10,
  UNRECOGNIZED = -1,
}

export function rewardTransactionOperatorActionTypeFromJSON(object: any): RewardTransactionOperatorActionType {
  switch (object) {
    case 0:
    case "REWARD_TRANSACTION_OPERATOR_ACTION_BEGIN":
      return RewardTransactionOperatorActionType.REWARD_TRANSACTION_OPERATOR_ACTION_BEGIN;
    case 1:
    case "REWARD_TRANSACTION_OPERATOR_ACTION_MINT":
      return RewardTransactionOperatorActionType.REWARD_TRANSACTION_OPERATOR_ACTION_MINT;
    case 2:
    case "REWARD_TRANSACTION_OPERATOR_ACTION_RECHARGE":
      return RewardTransactionOperatorActionType.REWARD_TRANSACTION_OPERATOR_ACTION_RECHARGE;
    case 3:
    case "REWARD_TRANSACTION_PROPOSAL_ACTION_DID_TRANSFER":
      return RewardTransactionOperatorActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_DID_TRANSFER;
    case 4:
    case "REWARD_TRANSACTION_PROPOSAL_ACTION_BURN":
      return RewardTransactionOperatorActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_BURN;
    case 5:
    case "REWARD_TRANSACTION_PROPOSAL_ACTION_BULK_MINT":
      return RewardTransactionOperatorActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_BULK_MINT;
    case 6:
    case "REWARD_TRANSACTION_PROPOSAL_ACTION_POINT_ADJUSTMENT":
      return RewardTransactionOperatorActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_POINT_ADJUSTMENT;
    case 10:
    case "REWARD_TRANSACTION_PROPOSAL_ACTION_MYD2_OTHERS":
      return RewardTransactionOperatorActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_MYD2_OTHERS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RewardTransactionOperatorActionType.UNRECOGNIZED;
  }
}

export function rewardTransactionOperatorActionTypeToJSON(object: RewardTransactionOperatorActionType): string {
  switch (object) {
    case RewardTransactionOperatorActionType.REWARD_TRANSACTION_OPERATOR_ACTION_BEGIN:
      return "REWARD_TRANSACTION_OPERATOR_ACTION_BEGIN";
    case RewardTransactionOperatorActionType.REWARD_TRANSACTION_OPERATOR_ACTION_MINT:
      return "REWARD_TRANSACTION_OPERATOR_ACTION_MINT";
    case RewardTransactionOperatorActionType.REWARD_TRANSACTION_OPERATOR_ACTION_RECHARGE:
      return "REWARD_TRANSACTION_OPERATOR_ACTION_RECHARGE";
    case RewardTransactionOperatorActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_DID_TRANSFER:
      return "REWARD_TRANSACTION_PROPOSAL_ACTION_DID_TRANSFER";
    case RewardTransactionOperatorActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_BURN:
      return "REWARD_TRANSACTION_PROPOSAL_ACTION_BURN";
    case RewardTransactionOperatorActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_BULK_MINT:
      return "REWARD_TRANSACTION_PROPOSAL_ACTION_BULK_MINT";
    case RewardTransactionOperatorActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_POINT_ADJUSTMENT:
      return "REWARD_TRANSACTION_PROPOSAL_ACTION_POINT_ADJUSTMENT";
    case RewardTransactionOperatorActionType.REWARD_TRANSACTION_PROPOSAL_ACTION_MYD2_OTHERS:
      return "REWARD_TRANSACTION_PROPOSAL_ACTION_MYD2_OTHERS";
    case RewardTransactionOperatorActionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Event from the platform operators */
export enum RewardTransactionEventActionType {
  /** REWARD_TRANSACTION_EVENT_ACTION_BEGIN - placeholder for 0 */
  REWARD_TRANSACTION_EVENT_ACTION_BEGIN = 0,
  /** REWARD_TRANSACTION_EVENT_ACTION_ATTENDANCE - 출석체크 */
  REWARD_TRANSACTION_EVENT_ACTION_ATTENDANCE = 1,
  /** REWARD_TRANSACTION_EVENT_ACTION_DAILYQUESTION - 오늘의 질문, 투표 */
  REWARD_TRANSACTION_EVENT_ACTION_DAILYQUESTION = 2,
  /** REWARD_TRANSACTION_EVENT_ACTION_INVITE_FRIEND_HOST - 친구 추천 (추천인) */
  REWARD_TRANSACTION_EVENT_ACTION_INVITE_FRIEND_HOST = 3,
  /** REWARD_TRANSACTION_EVENT_ACTION_INVITE_FRIEND_GUEST - 친구 추천 (신규가입자) */
  REWARD_TRANSACTION_EVENT_ACTION_INVITE_FRIEND_GUEST = 4,
  /** REWARD_TRANSACTION_EVENT_ACTION_EVENT - 그 외 Event */
  REWARD_TRANSACTION_EVENT_ACTION_EVENT = 5,
  /** REWARD_TRANSACTION_EVENT_ACTION_TNK - TNK (충전소) */
  REWARD_TRANSACTION_EVENT_ACTION_TNK = 6,
  UNRECOGNIZED = -1,
}

export function rewardTransactionEventActionTypeFromJSON(object: any): RewardTransactionEventActionType {
  switch (object) {
    case 0:
    case "REWARD_TRANSACTION_EVENT_ACTION_BEGIN":
      return RewardTransactionEventActionType.REWARD_TRANSACTION_EVENT_ACTION_BEGIN;
    case 1:
    case "REWARD_TRANSACTION_EVENT_ACTION_ATTENDANCE":
      return RewardTransactionEventActionType.REWARD_TRANSACTION_EVENT_ACTION_ATTENDANCE;
    case 2:
    case "REWARD_TRANSACTION_EVENT_ACTION_DAILYQUESTION":
      return RewardTransactionEventActionType.REWARD_TRANSACTION_EVENT_ACTION_DAILYQUESTION;
    case 3:
    case "REWARD_TRANSACTION_EVENT_ACTION_INVITE_FRIEND_HOST":
      return RewardTransactionEventActionType.REWARD_TRANSACTION_EVENT_ACTION_INVITE_FRIEND_HOST;
    case 4:
    case "REWARD_TRANSACTION_EVENT_ACTION_INVITE_FRIEND_GUEST":
      return RewardTransactionEventActionType.REWARD_TRANSACTION_EVENT_ACTION_INVITE_FRIEND_GUEST;
    case 5:
    case "REWARD_TRANSACTION_EVENT_ACTION_EVENT":
      return RewardTransactionEventActionType.REWARD_TRANSACTION_EVENT_ACTION_EVENT;
    case 6:
    case "REWARD_TRANSACTION_EVENT_ACTION_TNK":
      return RewardTransactionEventActionType.REWARD_TRANSACTION_EVENT_ACTION_TNK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RewardTransactionEventActionType.UNRECOGNIZED;
  }
}

export function rewardTransactionEventActionTypeToJSON(object: RewardTransactionEventActionType): string {
  switch (object) {
    case RewardTransactionEventActionType.REWARD_TRANSACTION_EVENT_ACTION_BEGIN:
      return "REWARD_TRANSACTION_EVENT_ACTION_BEGIN";
    case RewardTransactionEventActionType.REWARD_TRANSACTION_EVENT_ACTION_ATTENDANCE:
      return "REWARD_TRANSACTION_EVENT_ACTION_ATTENDANCE";
    case RewardTransactionEventActionType.REWARD_TRANSACTION_EVENT_ACTION_DAILYQUESTION:
      return "REWARD_TRANSACTION_EVENT_ACTION_DAILYQUESTION";
    case RewardTransactionEventActionType.REWARD_TRANSACTION_EVENT_ACTION_INVITE_FRIEND_HOST:
      return "REWARD_TRANSACTION_EVENT_ACTION_INVITE_FRIEND_HOST";
    case RewardTransactionEventActionType.REWARD_TRANSACTION_EVENT_ACTION_INVITE_FRIEND_GUEST:
      return "REWARD_TRANSACTION_EVENT_ACTION_INVITE_FRIEND_GUEST";
    case RewardTransactionEventActionType.REWARD_TRANSACTION_EVENT_ACTION_EVENT:
      return "REWARD_TRANSACTION_EVENT_ACTION_EVENT";
    case RewardTransactionEventActionType.REWARD_TRANSACTION_EVENT_ACTION_TNK:
      return "REWARD_TRANSACTION_EVENT_ACTION_TNK";
    case RewardTransactionEventActionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Raw transaction parameters to identify request-id mis-using */
export interface RawTransactionIssueParameters {
  proposalId: string;
  did: string;
}

/** Raw transaction parameters to identify request-id mis-using */
export interface RawTransactionConsumeParameters {
  pdsId: string;
  proposalId: string;
}

/** Raw transaction parameters to identify request-id mis-using */
export interface RawTransactionPurchaseParameters {
  goodsCode: string;
  did: string;
  transactionId: string;
}

/**
 * Format to serialize the raw transaction parameters without any nonce or request id
 * This is used to aware the case of mis-using requset ID
 */
export interface RawTransactionMethodMarker {
  issueParams?: RawTransactionIssueParameters | undefined;
  consumeParams?: RawTransactionConsumeParameters | undefined;
  purchaseParams?: RawTransactionPurchaseParameters | undefined;
}

/** Proposal related transaction details */
export interface RewardTransactionContentFromProposal {
  /** proposal owner did */
  proposalOwner: string;
  /** service ID for the proposal */
  serviceId: string;
  /** proposal purpose */
  purpose: number;
  /** proposal title */
  proposalTitle: string;
  /** proposal action */
  action: RewardTransactionProposalActionType;
  /** proposal id */
  proposalId: string;
}

/** Coupon purchase transaction details */
export interface RewardTransactionContentFromGoodsPurchase {
  /** Goods code for the coupon */
  goodsId: string;
  /** Goods transaction id */
  goodsTransactionId: string;
  /** Goods brand */
  goodsBrand: string;
  /** Goods name */
  goodsName: string;
  /** Coupon transaction ID */
  transactionId: string;
}

/** Platform operator direct operations */
export interface RewardTransactionContentFromPlatformOperator {
  /** Operator action type */
  action: RewardTransactionOperatorActionType;
  /** Besu address for the transaction owner */
  transactionOwnerBesuAddress: string;
  /** Besu address of the receiver */
  amountReceiverBesuAddress?:
    | string
    | undefined;
  /** Myd2 history */
  myd2DueTo: string;
  /** Besu address of the sender */
  amountSenderBesuAddress?: string | undefined;
}

/** Event reward from the platform owner */
export interface RewardTransactionContentFromEvent {
  /** Goods code for the coupon */
  action: RewardTransactionEventActionType;
  /** Event description in short */
  dueTo: string;
  /** Human readable title */
  title: string;
}

/** Reward from the web (MyD connect) */
export interface RewardTransactionContentFromWeb {
  staffApprovedRecharge: string;
}

/**
 * Reward transaction content for the aggregation
 * It targets multiple type of aggregation for each company, user, proposal, serviceId and goodsId
 * It should provide goodsTransactionId for the customer service as well.
 */
export interface RewardTransactionContent {
  /** limited fields for the app */
  app:
    | RewardTransactionContentForApp
    | undefined;
  /** DID whose account balance is changed */
  did: string;
  /** RFC3339 timestamp */
  didRegisteredAt: string;
  /** if it is a company account */
  didType: RewardTransactionDidType;
  /**
   * app version if the transaction is from app or sdk
   * valid only for REWARD_TRANSACTION_DID_USER
   */
  appVersion?:
    | string
    | undefined;
  /** balance at this timestamp */
  balance: number;
  /** could be null for the various reasons. just for the reference. */
  txHash: string;
  /** true if it is rewarded from myd2 */
  fromMyd2: boolean;
  reward?: RewardTransactionContentFromProposal | undefined;
  coupon?: RewardTransactionContentFromGoodsPurchase | undefined;
  operator?: RewardTransactionContentFromPlatformOperator | undefined;
  event?: RewardTransactionContentFromEvent | undefined;
  web?: RewardTransactionContentFromWeb | undefined;
}

function createBaseRawTransactionIssueParameters(): RawTransactionIssueParameters {
  return { proposalId: "", did: "" };
}

export const RawTransactionIssueParameters = {
  encode(message: RawTransactionIssueParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalId !== "") {
      writer.uint32(10).string(message.proposalId);
    }
    if (message.did !== "") {
      writer.uint32(18).string(message.did);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RawTransactionIssueParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRawTransactionIssueParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.did = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RawTransactionIssueParameters {
    return {
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
      did: isSet(object.did) ? globalThis.String(object.did) : "",
    };
  },

  toJSON(message: RawTransactionIssueParameters): unknown {
    const obj: any = {};
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    if (message.did !== "") {
      obj.did = message.did;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RawTransactionIssueParameters>, I>>(base?: I): RawTransactionIssueParameters {
    return RawTransactionIssueParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RawTransactionIssueParameters>, I>>(
    object: I,
  ): RawTransactionIssueParameters {
    const message = createBaseRawTransactionIssueParameters();
    message.proposalId = object.proposalId ?? "";
    message.did = object.did ?? "";
    return message;
  },
};

function createBaseRawTransactionConsumeParameters(): RawTransactionConsumeParameters {
  return { pdsId: "", proposalId: "" };
}

export const RawTransactionConsumeParameters = {
  encode(message: RawTransactionConsumeParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pdsId !== "") {
      writer.uint32(10).string(message.pdsId);
    }
    if (message.proposalId !== "") {
      writer.uint32(18).string(message.proposalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RawTransactionConsumeParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRawTransactionConsumeParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pdsId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RawTransactionConsumeParameters {
    return {
      pdsId: isSet(object.pdsId) ? globalThis.String(object.pdsId) : "",
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
    };
  },

  toJSON(message: RawTransactionConsumeParameters): unknown {
    const obj: any = {};
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RawTransactionConsumeParameters>, I>>(base?: I): RawTransactionConsumeParameters {
    return RawTransactionConsumeParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RawTransactionConsumeParameters>, I>>(
    object: I,
  ): RawTransactionConsumeParameters {
    const message = createBaseRawTransactionConsumeParameters();
    message.pdsId = object.pdsId ?? "";
    message.proposalId = object.proposalId ?? "";
    return message;
  },
};

function createBaseRawTransactionPurchaseParameters(): RawTransactionPurchaseParameters {
  return { goodsCode: "", did: "", transactionId: "" };
}

export const RawTransactionPurchaseParameters = {
  encode(message: RawTransactionPurchaseParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.goodsCode !== "") {
      writer.uint32(10).string(message.goodsCode);
    }
    if (message.did !== "") {
      writer.uint32(18).string(message.did);
    }
    if (message.transactionId !== "") {
      writer.uint32(26).string(message.transactionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RawTransactionPurchaseParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRawTransactionPurchaseParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.goodsCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.did = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.transactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RawTransactionPurchaseParameters {
    return {
      goodsCode: isSet(object.goodsCode) ? globalThis.String(object.goodsCode) : "",
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      transactionId: isSet(object.transactionId) ? globalThis.String(object.transactionId) : "",
    };
  },

  toJSON(message: RawTransactionPurchaseParameters): unknown {
    const obj: any = {};
    if (message.goodsCode !== "") {
      obj.goodsCode = message.goodsCode;
    }
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.transactionId !== "") {
      obj.transactionId = message.transactionId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RawTransactionPurchaseParameters>, I>>(
    base?: I,
  ): RawTransactionPurchaseParameters {
    return RawTransactionPurchaseParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RawTransactionPurchaseParameters>, I>>(
    object: I,
  ): RawTransactionPurchaseParameters {
    const message = createBaseRawTransactionPurchaseParameters();
    message.goodsCode = object.goodsCode ?? "";
    message.did = object.did ?? "";
    message.transactionId = object.transactionId ?? "";
    return message;
  },
};

function createBaseRawTransactionMethodMarker(): RawTransactionMethodMarker {
  return { issueParams: undefined, consumeParams: undefined, purchaseParams: undefined };
}

export const RawTransactionMethodMarker = {
  encode(message: RawTransactionMethodMarker, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.issueParams !== undefined) {
      RawTransactionIssueParameters.encode(message.issueParams, writer.uint32(10).fork()).ldelim();
    }
    if (message.consumeParams !== undefined) {
      RawTransactionConsumeParameters.encode(message.consumeParams, writer.uint32(18).fork()).ldelim();
    }
    if (message.purchaseParams !== undefined) {
      RawTransactionPurchaseParameters.encode(message.purchaseParams, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RawTransactionMethodMarker {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRawTransactionMethodMarker();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.issueParams = RawTransactionIssueParameters.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.consumeParams = RawTransactionConsumeParameters.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.purchaseParams = RawTransactionPurchaseParameters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RawTransactionMethodMarker {
    return {
      issueParams: isSet(object.issueParams) ? RawTransactionIssueParameters.fromJSON(object.issueParams) : undefined,
      consumeParams: isSet(object.consumeParams)
        ? RawTransactionConsumeParameters.fromJSON(object.consumeParams)
        : undefined,
      purchaseParams: isSet(object.purchaseParams)
        ? RawTransactionPurchaseParameters.fromJSON(object.purchaseParams)
        : undefined,
    };
  },

  toJSON(message: RawTransactionMethodMarker): unknown {
    const obj: any = {};
    if (message.issueParams !== undefined) {
      obj.issueParams = RawTransactionIssueParameters.toJSON(message.issueParams);
    }
    if (message.consumeParams !== undefined) {
      obj.consumeParams = RawTransactionConsumeParameters.toJSON(message.consumeParams);
    }
    if (message.purchaseParams !== undefined) {
      obj.purchaseParams = RawTransactionPurchaseParameters.toJSON(message.purchaseParams);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RawTransactionMethodMarker>, I>>(base?: I): RawTransactionMethodMarker {
    return RawTransactionMethodMarker.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RawTransactionMethodMarker>, I>>(object: I): RawTransactionMethodMarker {
    const message = createBaseRawTransactionMethodMarker();
    message.issueParams = (object.issueParams !== undefined && object.issueParams !== null)
      ? RawTransactionIssueParameters.fromPartial(object.issueParams)
      : undefined;
    message.consumeParams = (object.consumeParams !== undefined && object.consumeParams !== null)
      ? RawTransactionConsumeParameters.fromPartial(object.consumeParams)
      : undefined;
    message.purchaseParams = (object.purchaseParams !== undefined && object.purchaseParams !== null)
      ? RawTransactionPurchaseParameters.fromPartial(object.purchaseParams)
      : undefined;
    return message;
  },
};

function createBaseRewardTransactionContentFromProposal(): RewardTransactionContentFromProposal {
  return { proposalOwner: "", serviceId: "", purpose: 0, proposalTitle: "", action: 0, proposalId: "" };
}

export const RewardTransactionContentFromProposal = {
  encode(message: RewardTransactionContentFromProposal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalOwner !== "") {
      writer.uint32(10).string(message.proposalOwner);
    }
    if (message.serviceId !== "") {
      writer.uint32(18).string(message.serviceId);
    }
    if (message.purpose !== 0) {
      writer.uint32(24).uint64(message.purpose);
    }
    if (message.proposalTitle !== "") {
      writer.uint32(34).string(message.proposalTitle);
    }
    if (message.action !== 0) {
      writer.uint32(40).int32(message.action);
    }
    if (message.proposalId !== "") {
      writer.uint32(50).string(message.proposalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RewardTransactionContentFromProposal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRewardTransactionContentFromProposal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposalOwner = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serviceId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.purpose = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.proposalTitle = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.action = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.proposalId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RewardTransactionContentFromProposal {
    return {
      proposalOwner: isSet(object.proposalOwner) ? globalThis.String(object.proposalOwner) : "",
      serviceId: isSet(object.serviceId) ? globalThis.String(object.serviceId) : "",
      purpose: isSet(object.purpose) ? globalThis.Number(object.purpose) : 0,
      proposalTitle: isSet(object.proposalTitle) ? globalThis.String(object.proposalTitle) : "",
      action: isSet(object.action) ? rewardTransactionProposalActionTypeFromJSON(object.action) : 0,
      proposalId: isSet(object.proposalId) ? globalThis.String(object.proposalId) : "",
    };
  },

  toJSON(message: RewardTransactionContentFromProposal): unknown {
    const obj: any = {};
    if (message.proposalOwner !== "") {
      obj.proposalOwner = message.proposalOwner;
    }
    if (message.serviceId !== "") {
      obj.serviceId = message.serviceId;
    }
    if (message.purpose !== 0) {
      obj.purpose = Math.round(message.purpose);
    }
    if (message.proposalTitle !== "") {
      obj.proposalTitle = message.proposalTitle;
    }
    if (message.action !== 0) {
      obj.action = rewardTransactionProposalActionTypeToJSON(message.action);
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RewardTransactionContentFromProposal>, I>>(
    base?: I,
  ): RewardTransactionContentFromProposal {
    return RewardTransactionContentFromProposal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RewardTransactionContentFromProposal>, I>>(
    object: I,
  ): RewardTransactionContentFromProposal {
    const message = createBaseRewardTransactionContentFromProposal();
    message.proposalOwner = object.proposalOwner ?? "";
    message.serviceId = object.serviceId ?? "";
    message.purpose = object.purpose ?? 0;
    message.proposalTitle = object.proposalTitle ?? "";
    message.action = object.action ?? 0;
    message.proposalId = object.proposalId ?? "";
    return message;
  },
};

function createBaseRewardTransactionContentFromGoodsPurchase(): RewardTransactionContentFromGoodsPurchase {
  return { goodsId: "", goodsTransactionId: "", goodsBrand: "", goodsName: "", transactionId: "" };
}

export const RewardTransactionContentFromGoodsPurchase = {
  encode(message: RewardTransactionContentFromGoodsPurchase, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.goodsId !== "") {
      writer.uint32(10).string(message.goodsId);
    }
    if (message.goodsTransactionId !== "") {
      writer.uint32(18).string(message.goodsTransactionId);
    }
    if (message.goodsBrand !== "") {
      writer.uint32(26).string(message.goodsBrand);
    }
    if (message.goodsName !== "") {
      writer.uint32(34).string(message.goodsName);
    }
    if (message.transactionId !== "") {
      writer.uint32(42).string(message.transactionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RewardTransactionContentFromGoodsPurchase {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRewardTransactionContentFromGoodsPurchase();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.goodsId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.goodsTransactionId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.goodsBrand = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.goodsName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.transactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RewardTransactionContentFromGoodsPurchase {
    return {
      goodsId: isSet(object.goodsId) ? globalThis.String(object.goodsId) : "",
      goodsTransactionId: isSet(object.goodsTransactionId) ? globalThis.String(object.goodsTransactionId) : "",
      goodsBrand: isSet(object.goodsBrand) ? globalThis.String(object.goodsBrand) : "",
      goodsName: isSet(object.goodsName) ? globalThis.String(object.goodsName) : "",
      transactionId: isSet(object.transactionId) ? globalThis.String(object.transactionId) : "",
    };
  },

  toJSON(message: RewardTransactionContentFromGoodsPurchase): unknown {
    const obj: any = {};
    if (message.goodsId !== "") {
      obj.goodsId = message.goodsId;
    }
    if (message.goodsTransactionId !== "") {
      obj.goodsTransactionId = message.goodsTransactionId;
    }
    if (message.goodsBrand !== "") {
      obj.goodsBrand = message.goodsBrand;
    }
    if (message.goodsName !== "") {
      obj.goodsName = message.goodsName;
    }
    if (message.transactionId !== "") {
      obj.transactionId = message.transactionId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RewardTransactionContentFromGoodsPurchase>, I>>(
    base?: I,
  ): RewardTransactionContentFromGoodsPurchase {
    return RewardTransactionContentFromGoodsPurchase.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RewardTransactionContentFromGoodsPurchase>, I>>(
    object: I,
  ): RewardTransactionContentFromGoodsPurchase {
    const message = createBaseRewardTransactionContentFromGoodsPurchase();
    message.goodsId = object.goodsId ?? "";
    message.goodsTransactionId = object.goodsTransactionId ?? "";
    message.goodsBrand = object.goodsBrand ?? "";
    message.goodsName = object.goodsName ?? "";
    message.transactionId = object.transactionId ?? "";
    return message;
  },
};

function createBaseRewardTransactionContentFromPlatformOperator(): RewardTransactionContentFromPlatformOperator {
  return {
    action: 0,
    transactionOwnerBesuAddress: "",
    amountReceiverBesuAddress: undefined,
    myd2DueTo: "",
    amountSenderBesuAddress: undefined,
  };
}

export const RewardTransactionContentFromPlatformOperator = {
  encode(message: RewardTransactionContentFromPlatformOperator, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.action !== 0) {
      writer.uint32(8).int32(message.action);
    }
    if (message.transactionOwnerBesuAddress !== "") {
      writer.uint32(18).string(message.transactionOwnerBesuAddress);
    }
    if (message.amountReceiverBesuAddress !== undefined) {
      writer.uint32(26).string(message.amountReceiverBesuAddress);
    }
    if (message.myd2DueTo !== "") {
      writer.uint32(34).string(message.myd2DueTo);
    }
    if (message.amountSenderBesuAddress !== undefined) {
      writer.uint32(42).string(message.amountSenderBesuAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RewardTransactionContentFromPlatformOperator {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRewardTransactionContentFromPlatformOperator();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.action = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transactionOwnerBesuAddress = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.amountReceiverBesuAddress = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.myd2DueTo = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.amountSenderBesuAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RewardTransactionContentFromPlatformOperator {
    return {
      action: isSet(object.action) ? rewardTransactionOperatorActionTypeFromJSON(object.action) : 0,
      transactionOwnerBesuAddress: isSet(object.transactionOwnerBesuAddress)
        ? globalThis.String(object.transactionOwnerBesuAddress)
        : "",
      amountReceiverBesuAddress: isSet(object.amountReceiverBesuAddress)
        ? globalThis.String(object.amountReceiverBesuAddress)
        : undefined,
      myd2DueTo: isSet(object.myd2DueTo) ? globalThis.String(object.myd2DueTo) : "",
      amountSenderBesuAddress: isSet(object.amountSenderBesuAddress)
        ? globalThis.String(object.amountSenderBesuAddress)
        : undefined,
    };
  },

  toJSON(message: RewardTransactionContentFromPlatformOperator): unknown {
    const obj: any = {};
    if (message.action !== 0) {
      obj.action = rewardTransactionOperatorActionTypeToJSON(message.action);
    }
    if (message.transactionOwnerBesuAddress !== "") {
      obj.transactionOwnerBesuAddress = message.transactionOwnerBesuAddress;
    }
    if (message.amountReceiverBesuAddress !== undefined) {
      obj.amountReceiverBesuAddress = message.amountReceiverBesuAddress;
    }
    if (message.myd2DueTo !== "") {
      obj.myd2DueTo = message.myd2DueTo;
    }
    if (message.amountSenderBesuAddress !== undefined) {
      obj.amountSenderBesuAddress = message.amountSenderBesuAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RewardTransactionContentFromPlatformOperator>, I>>(
    base?: I,
  ): RewardTransactionContentFromPlatformOperator {
    return RewardTransactionContentFromPlatformOperator.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RewardTransactionContentFromPlatformOperator>, I>>(
    object: I,
  ): RewardTransactionContentFromPlatformOperator {
    const message = createBaseRewardTransactionContentFromPlatformOperator();
    message.action = object.action ?? 0;
    message.transactionOwnerBesuAddress = object.transactionOwnerBesuAddress ?? "";
    message.amountReceiverBesuAddress = object.amountReceiverBesuAddress ?? undefined;
    message.myd2DueTo = object.myd2DueTo ?? "";
    message.amountSenderBesuAddress = object.amountSenderBesuAddress ?? undefined;
    return message;
  },
};

function createBaseRewardTransactionContentFromEvent(): RewardTransactionContentFromEvent {
  return { action: 0, dueTo: "", title: "" };
}

export const RewardTransactionContentFromEvent = {
  encode(message: RewardTransactionContentFromEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.action !== 0) {
      writer.uint32(8).int32(message.action);
    }
    if (message.dueTo !== "") {
      writer.uint32(18).string(message.dueTo);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RewardTransactionContentFromEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRewardTransactionContentFromEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.action = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dueTo = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RewardTransactionContentFromEvent {
    return {
      action: isSet(object.action) ? rewardTransactionEventActionTypeFromJSON(object.action) : 0,
      dueTo: isSet(object.dueTo) ? globalThis.String(object.dueTo) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
    };
  },

  toJSON(message: RewardTransactionContentFromEvent): unknown {
    const obj: any = {};
    if (message.action !== 0) {
      obj.action = rewardTransactionEventActionTypeToJSON(message.action);
    }
    if (message.dueTo !== "") {
      obj.dueTo = message.dueTo;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RewardTransactionContentFromEvent>, I>>(
    base?: I,
  ): RewardTransactionContentFromEvent {
    return RewardTransactionContentFromEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RewardTransactionContentFromEvent>, I>>(
    object: I,
  ): RewardTransactionContentFromEvent {
    const message = createBaseRewardTransactionContentFromEvent();
    message.action = object.action ?? 0;
    message.dueTo = object.dueTo ?? "";
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseRewardTransactionContentFromWeb(): RewardTransactionContentFromWeb {
  return { staffApprovedRecharge: "" };
}

export const RewardTransactionContentFromWeb = {
  encode(message: RewardTransactionContentFromWeb, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.staffApprovedRecharge !== "") {
      writer.uint32(10).string(message.staffApprovedRecharge);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RewardTransactionContentFromWeb {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRewardTransactionContentFromWeb();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staffApprovedRecharge = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RewardTransactionContentFromWeb {
    return {
      staffApprovedRecharge: isSet(object.staffApprovedRecharge) ? globalThis.String(object.staffApprovedRecharge) : "",
    };
  },

  toJSON(message: RewardTransactionContentFromWeb): unknown {
    const obj: any = {};
    if (message.staffApprovedRecharge !== "") {
      obj.staffApprovedRecharge = message.staffApprovedRecharge;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RewardTransactionContentFromWeb>, I>>(base?: I): RewardTransactionContentFromWeb {
    return RewardTransactionContentFromWeb.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RewardTransactionContentFromWeb>, I>>(
    object: I,
  ): RewardTransactionContentFromWeb {
    const message = createBaseRewardTransactionContentFromWeb();
    message.staffApprovedRecharge = object.staffApprovedRecharge ?? "";
    return message;
  },
};

function createBaseRewardTransactionContent(): RewardTransactionContent {
  return {
    app: undefined,
    did: "",
    didRegisteredAt: "",
    didType: 0,
    appVersion: undefined,
    balance: 0,
    txHash: "",
    fromMyd2: false,
    reward: undefined,
    coupon: undefined,
    operator: undefined,
    event: undefined,
    web: undefined,
  };
}

export const RewardTransactionContent = {
  encode(message: RewardTransactionContent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.app !== undefined) {
      RewardTransactionContentForApp.encode(message.app, writer.uint32(10).fork()).ldelim();
    }
    if (message.did !== "") {
      writer.uint32(26).string(message.did);
    }
    if (message.didRegisteredAt !== "") {
      writer.uint32(34).string(message.didRegisteredAt);
    }
    if (message.didType !== 0) {
      writer.uint32(40).int32(message.didType);
    }
    if (message.appVersion !== undefined) {
      writer.uint32(50).string(message.appVersion);
    }
    if (message.balance !== 0) {
      writer.uint32(56).uint64(message.balance);
    }
    if (message.txHash !== "") {
      writer.uint32(82).string(message.txHash);
    }
    if (message.fromMyd2 === true) {
      writer.uint32(88).bool(message.fromMyd2);
    }
    if (message.reward !== undefined) {
      RewardTransactionContentFromProposal.encode(message.reward, writer.uint32(170).fork()).ldelim();
    }
    if (message.coupon !== undefined) {
      RewardTransactionContentFromGoodsPurchase.encode(message.coupon, writer.uint32(178).fork()).ldelim();
    }
    if (message.operator !== undefined) {
      RewardTransactionContentFromPlatformOperator.encode(message.operator, writer.uint32(186).fork()).ldelim();
    }
    if (message.event !== undefined) {
      RewardTransactionContentFromEvent.encode(message.event, writer.uint32(194).fork()).ldelim();
    }
    if (message.web !== undefined) {
      RewardTransactionContentFromWeb.encode(message.web, writer.uint32(202).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RewardTransactionContent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRewardTransactionContent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.app = RewardTransactionContentForApp.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.did = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.didRegisteredAt = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.didType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.appVersion = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.balance = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.txHash = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.fromMyd2 = reader.bool();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.reward = RewardTransactionContentFromProposal.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.coupon = RewardTransactionContentFromGoodsPurchase.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.operator = RewardTransactionContentFromPlatformOperator.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.event = RewardTransactionContentFromEvent.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.web = RewardTransactionContentFromWeb.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RewardTransactionContent {
    return {
      app: isSet(object.app) ? RewardTransactionContentForApp.fromJSON(object.app) : undefined,
      did: isSet(object.did) ? globalThis.String(object.did) : "",
      didRegisteredAt: isSet(object.didRegisteredAt) ? globalThis.String(object.didRegisteredAt) : "",
      didType: isSet(object.didType) ? rewardTransactionDidTypeFromJSON(object.didType) : 0,
      appVersion: isSet(object.appVersion) ? globalThis.String(object.appVersion) : undefined,
      balance: isSet(object.balance) ? globalThis.Number(object.balance) : 0,
      txHash: isSet(object.txHash) ? globalThis.String(object.txHash) : "",
      fromMyd2: isSet(object.fromMyd2) ? globalThis.Boolean(object.fromMyd2) : false,
      reward: isSet(object.reward) ? RewardTransactionContentFromProposal.fromJSON(object.reward) : undefined,
      coupon: isSet(object.coupon) ? RewardTransactionContentFromGoodsPurchase.fromJSON(object.coupon) : undefined,
      operator: isSet(object.operator)
        ? RewardTransactionContentFromPlatformOperator.fromJSON(object.operator)
        : undefined,
      event: isSet(object.event) ? RewardTransactionContentFromEvent.fromJSON(object.event) : undefined,
      web: isSet(object.web) ? RewardTransactionContentFromWeb.fromJSON(object.web) : undefined,
    };
  },

  toJSON(message: RewardTransactionContent): unknown {
    const obj: any = {};
    if (message.app !== undefined) {
      obj.app = RewardTransactionContentForApp.toJSON(message.app);
    }
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.didRegisteredAt !== "") {
      obj.didRegisteredAt = message.didRegisteredAt;
    }
    if (message.didType !== 0) {
      obj.didType = rewardTransactionDidTypeToJSON(message.didType);
    }
    if (message.appVersion !== undefined) {
      obj.appVersion = message.appVersion;
    }
    if (message.balance !== 0) {
      obj.balance = Math.round(message.balance);
    }
    if (message.txHash !== "") {
      obj.txHash = message.txHash;
    }
    if (message.fromMyd2 === true) {
      obj.fromMyd2 = message.fromMyd2;
    }
    if (message.reward !== undefined) {
      obj.reward = RewardTransactionContentFromProposal.toJSON(message.reward);
    }
    if (message.coupon !== undefined) {
      obj.coupon = RewardTransactionContentFromGoodsPurchase.toJSON(message.coupon);
    }
    if (message.operator !== undefined) {
      obj.operator = RewardTransactionContentFromPlatformOperator.toJSON(message.operator);
    }
    if (message.event !== undefined) {
      obj.event = RewardTransactionContentFromEvent.toJSON(message.event);
    }
    if (message.web !== undefined) {
      obj.web = RewardTransactionContentFromWeb.toJSON(message.web);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RewardTransactionContent>, I>>(base?: I): RewardTransactionContent {
    return RewardTransactionContent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RewardTransactionContent>, I>>(object: I): RewardTransactionContent {
    const message = createBaseRewardTransactionContent();
    message.app = (object.app !== undefined && object.app !== null)
      ? RewardTransactionContentForApp.fromPartial(object.app)
      : undefined;
    message.did = object.did ?? "";
    message.didRegisteredAt = object.didRegisteredAt ?? "";
    message.didType = object.didType ?? 0;
    message.appVersion = object.appVersion ?? undefined;
    message.balance = object.balance ?? 0;
    message.txHash = object.txHash ?? "";
    message.fromMyd2 = object.fromMyd2 ?? false;
    message.reward = (object.reward !== undefined && object.reward !== null)
      ? RewardTransactionContentFromProposal.fromPartial(object.reward)
      : undefined;
    message.coupon = (object.coupon !== undefined && object.coupon !== null)
      ? RewardTransactionContentFromGoodsPurchase.fromPartial(object.coupon)
      : undefined;
    message.operator = (object.operator !== undefined && object.operator !== null)
      ? RewardTransactionContentFromPlatformOperator.fromPartial(object.operator)
      : undefined;
    message.event = (object.event !== undefined && object.event !== null)
      ? RewardTransactionContentFromEvent.fromPartial(object.event)
      : undefined;
    message.web = (object.web !== undefined && object.web !== null)
      ? RewardTransactionContentFromWeb.fromPartial(object.web)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
