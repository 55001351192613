import { Link } from "react-router-dom";

function Links() {
  return (
    <div className="Links">
      <div className="outdiv">
        <div hidden>
          <br />
          <Link to="/auth">JS Bridge Test</Link>
          <br />
          <br />
          <Link to="/example">Single device example</Link>
          <br />
        </div>
        <br />
        <Link to="/multiclient_test">Multi client test board</Link>
        <br />
        <br />
        <Link to="/scenario_test">Scenario board</Link>
        <br />
        <br />
        <Link to="/protobuf_viewer">Protobuf viewer</Link>
        <br />
        <br />
        <Link to="/adminconsole">Admin console</Link>
      </div>
    </div>
  );
}

export default Links;
