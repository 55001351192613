import { BlockchainRole } from "protobuf/OpenApiServerV3";
import { OverviewItemProps } from "./MetamaskConnector";
import { getProtobufEnumKey } from "utils/storage";

type BoardProps = {
  items: OverviewItemProps[];
};

export default function SpecialUsersOverviewBoard({ items }: BoardProps) {
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>역할</td>
            <td>이름</td>
            <td>분류</td>
            <td>주소</td>
            <td>Point(잔고)</td>
            <td>Coin(gas)</td>
          </tr>
          {items?.map((p) => (
            <tr key={p.itemAddress} className={p.isDeleted ? "red-alert" : ""}>
              <td>{getProtobufEnumKey(BlockchainRole, p.role)}</td>
              <td>{p.title}</td>
              <td>{p.subTitle}</td>
              <td>{p.itemAddress}</td>
              <td>{p.netBalance}</td>
              <td>{p.coinBalance.toString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
