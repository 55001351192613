import { MockMobileCore } from "./MockMobileCore";

type BoardProps = {
  core: MockMobileCore;
};

export default function CouponListBoard({ core }: BoardProps) {
  let couponList = core.couponList !== undefined ? JSON.parse(JSON.stringify(core.couponList)) : undefined;
  // let couponList = core.couponList !== undefined ? undefined : undefined;

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>transaction id</td>
            <td>order no</td>
            <td>pin code</td>
            <td>image url</td>
            <td>coupon info API</td>
          </tr>

          {couponList?.map((p: any, index: Number) => (
            <tr key={`"${index}"`}>
              <td>{p.couponTransactionId ?? ""}</td>
              <td>{`"${p.orderNo}"`}</td>
              <td>{`"${p.pinCode}"`}</td>
              <td>{`"${p.imageUrl}"`}</td>
              <td>
                <button
                  type="button"
                  onClick={async () => {
                    core?.infoCoupon(p.couponTransactionId).then((result) => {
                      console.log(result);
                    });
                  }}
                >
                  coupon/info
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
