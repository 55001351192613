import { useState } from "react";
import { Proposal, ProposalStatus } from "../protobuf/OpenApiServerV3DataTypes";
import ProposalItem from "./ProposalItem";
import { Myd3Blockchain } from "../authconnector/myd3";
import { MockMobileCore } from "./MockMobileCore";

type BoardProps = {
  core: MockMobileCore;
  onlyActivated: boolean;
};

export default function ProposalBoard({ core, onlyActivated }: BoardProps) {
  const proposals = Array.from(core.proposalsMap.values());

  return (
    <div>
      <table>
        <tbody>
          <tr className="greydiv">
            <td>Title</td>
            <td>Purpose</td>
            <td>Company</td>
            <td>Status</td>
          </tr>
          {proposals
            .filter((p) => !onlyActivated || p.activeGlobal?.proposalStatus === ProposalStatus.PROPOSAL_ACTIVATED)
            .map((p) => (
              <ProposalItem key={p.proposalId} proposal={p} core={core} />
            ))}
        </tbody>
      </table>
    </div>
  );
}
