/* eslint-disable */

export const protobufPackage = "io.snplab.myd.protocol.v3.schema";

/**
 * Common status code
 * https://confluence.snplab.io/display/MIDD/Error+Codes
 */
export enum CommonStatusCode {
  /** STATUS_SUCCESS - no 0 place holder for backward-compatibility */
  STATUS_SUCCESS = 0,
  ERR_INVALID_FUNCTION = 1,
  ERR_INVALID_FORMAT = 2,
  ERR_INVALID_ARGUMENT = 3,
  ERR_INVALID_RANGE = 4,
  /** ERR_CANNOT_ACTIVATE_PROPOSAL - Proposal */
  ERR_CANNOT_ACTIVATE_PROPOSAL = 1001,
  ERR_CANNOT_REVOKE_PROPOSAL = 1002,
  ERR_ALREADY_REVOKED_PROPOSAL = 1003,
  ERR_EXPIRED_PROPOSAL = 1004,
  ERR_NOT_ENOUGH_DEPOSIT_FOR_PROPOSAL = 1005,
  ERR_INVALID_DEPOSIT_FOR_PROPOSAL = 1006,
  ERR_NOT_VALID_BEFORE_PROPOSAL = 1007,
  ERR_NOT_VALID_AFTER_PROPOSAL = 1008,
  ERR_INACTIVE_PROPOSAL = 1009,
  ERR_STILL_VALID_PROPOSAL = 1010,
  ERR_NOTHING_REFRESHED = 1011,
  ERR_DROUGHT_PROPOSAL = 1098,
  ERR_CORRUPTED_PROPOSAL = 1099,
  /** ERR_CANNOT_ISSUE_TICKET - Ticket */
  ERR_CANNOT_ISSUE_TICKET = 1501,
  ERR_CANNOT_REVOKE_TICKET = 1502,
  ERR_ALREADY_REVOKED_TICKET = 1503,
  ERR_EXPIRED_TICKET = 1504,
  ERR_NOT_ENOUGH_DEPOSIT_FOR_TICKET = 1505,
  ERR_INVALID_DEPOSIT_FOR_TICKET = 1506,
  ERR_ALREADY_ISSUED_BY_DID = 1507,
  ERR_CANNOT_CONSUME_BY_PERIOD = 1508,
  ERR_BEFORE_VALID_AT_TICKET = 1509,
  ERR_PROPOSAL_PURPOSE_TO_ISSUE_TICKET = 1510,
  ERR_REFUNDED_TICKET = 1597,
  ERR_DROUGHT_TICKET = 1598,
  ERR_CORRUPTED_TICKET = 1599,
  /** ERR_SPENT_TOKEN - Mileage */
  ERR_SPENT_TOKEN = 2001,
  ERR_UNSPENT_TOKEN = 2002,
  ERR_EXPIRED_TOKEN = 2003,
  ERR_AMOUNT_DIFFER = 2004,
  ERR_INVALID_DUE_TO = 2005,
  ERR_INVALID_DUE_TO_TAG = 2006,
  ERR_INVALID_DUE_TO_VERB = 2007,
  ERR_INVALID_DUE_TO_OBJECT = 2008,
  ERR_INVALID_DURATION = 2009,
  ERR_NOT_CLAIMABLE_YET = 2010,
  ERR_NOT_REFUNDABLE = 2011,
  ERR_ALREADY_REWARDED = 2012,
  ERR_FAILED_TO_GET_TICKET = 2013,
  ERR_FAILED_TO_GET_PROPOSAL = 2014,
  ERR_PROPOSAL_NOT_DEPOSITABLE = 2015,
  ERR_CANNOT_SPEND = 2016,
  ERR_CANNOT_DEPOSIT = 2017,
  ERR_CANNOT_WITHDRAW = 2018,
  ERR_CANNOT_TRANSFER = 2019,
  ERR_NOT_ENOUGH_MONEY = 2020,
  ERR_DEPOSITED_ALREADY_SPENT = 2021,
  ERR_NOT_FOR_YOUR_PROPOSAL = 2022,
  ERR_NOT_FOR_YOUR_TICKET = 2023,
  /** ERR_UNREGISTERED_QUESTION - Middleware */
  ERR_UNREGISTERED_QUESTION = 3001,
  ERR_ALREADY_REGISTERED_ANSWER = 3002,
  ERR_COMPLETE_REWARD = 3004,
  ERR_SAVE_ANSWER_STASTICS = 3005,
  ERR_REMOVE_ANSWER_STASTICS = 3006,
  ERR_FINISHED_EVENT = 3011,
  ERR_UNKNOWN_ERROR = 3012,
  ERR_OVERTIME_USED_CODE = 3013,
  ERR_NOT_CORRECT_CODE = 3014,
  ERR_REWARD_POINT = 3015,
  ERR_ALREADY_PARTICIPATED_EVENT = 3016,
  ERR_NOT_PROGRESS_EVENT = 3017,
  ERR_LACK_PARTICIPATED_DATA_TRANSACTION = 3018,
  ERR_UNMATCH_OWNER = 3021,
  ERR_NOT_FOUND_DATA = 3022,
  ERR_STORED_ELASTIC = 3023,
  ERR_STORED_ELASTIC_EACH = 3024,
  ERR_AUTHORIZED = 3025,
  ERR_NOT_ACTIVATE = 3031,
  ERR_NOT_DELETE = 3032,
  ERR_NOTICE_COUNT_EXCEED = 3033,
  ERR_EXCEED_NOT_AFTER = 3034,
  ERR_NOT_BETWEEN_INVISIBLE_PERIOD = 3035,
  ERR_EVENT_NOTICE_URL_FORMAT = 3036,
  ERR_NOT_UPDATE = 3037,
  ERR_PASSWORD_NOT_CHANGED = 3038,
  ERR_DATA_DUPLICATED = 3039,
  ERR_UPLOAD_IO_FAILED = 3040,
  ERR_NO_CODES_AVAILABLE = 3041,
  /** ERR_INVALID_PARAMETER_FORMAT - Middleware common error */
  ERR_INVALID_PARAMETER_FORMAT = 6001,
  ERR_INVALID_PARAMETER = 6002,
  ERR_MOBILE_AUTH_INVALID_TOKEN = 6003,
  ERR_MOBILE_AUTH_EXPIRED_TOKEN = 6004,
  ERR_URL_FORMAT = 6005,
  ERR_IO_EXCEPTION = 6006,
  ERR_FILE_UPLOAD = 6101,
  ERR_ALREADY_USED_CODE = 6310,
  /** ERR_PROTOBUF_PARSING - ODM PASS common error */
  ERR_PROTOBUF_PARSING = 7001,
  /** ERR_BLOCKCHAIN_GET_STATE_FAILED - Blockchain error */
  ERR_BLOCKCHAIN_GET_STATE_FAILED = 8001,
  ERR_BLOCKCHAIN_PUT_STATE_FAILED = 8002,
  ERR_BLOCKCHAIN_DEL_STATE_FAILED = 8003,
  ERR_BLOCKCHAIN_NO_DATA_FOUND = 8004,
  ERR_BLOCKCHAIN_ALREADY_EXISTS = 8005,
  /** ERR_RETRY_WITH_NEW_BLOCKCHAIN_NONCE - Blockchain ODM PASS */
  ERR_RETRY_WITH_NEW_BLOCKCHAIN_NONCE = 8100,
  ERR_BLOCKCHAIN_DID_MIGRATION_FAILED = 8101,
  /** ERR_BLOCKCHAIN_TRANSACTION_SIGNATURE_VERIFICATION_FAILURE - Invalid transaction signature to transfer token */
  ERR_BLOCKCHAIN_TRANSACTION_SIGNATURE_VERIFICATION_FAILURE = 8102,
  ERR_BLOCKCHAIN_NOT_AUTHORIZED = 8103,
  ERR_BLOCKCHAIN_CONTRACT_NOT_READY_YET = 8104,
  ERR_BLOCKCHAIN_DID_REGISTRATION_FAILED = 8105,
  ERR_BLOCKCHAIN_DID_ALREADY_IN_USE = 8106,
  ERR_BLOCKCHAIN_ADDRESS_ALREADY_IN_USE = 8107,
  ERR_BLOCKCHAIN_NOT_ALLOWED_LOGIC_CONTRACT = 8108,
  ERR_BLOCKCHAIN_UNKNOWN = 8109,
  ERR_BLOCKCHAIN_MISMATCH_FUNCTION_TYPE = 8110,
  ERR_BLOCKCHAIN_NOT_ENOUGH_GAS = 8111,
  ERR_BLOCKCHAIN_SEND_TRANSACTION_FAILURE = 8112,
  /** ERR_BLOCKCHAIN_INVALID_TRANSACTION_MATERIAL - Invalid transaction material to transfer token */
  ERR_BLOCKCHAIN_INVALID_TRANSACTION_MATERIAL = 8113,
  /** ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY - Myd3 auth connector (ODM PASS frontend) */
  ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY = 8200,
  /** ERR_M3CON_FAILED_TO_GET_NONCE - Failed to get new nonce (probably network failure) */
  ERR_M3CON_FAILED_TO_GET_NONCE = 8201,
  /** ERR_M3CON_ATTESTATION_FAILED_MYD3_DATA_MISSING - Attestation failed. Myd3 data is not initialized successfully. */
  ERR_M3CON_ATTESTATION_FAILED_MYD3_DATA_MISSING = 8202,
  /** ERR_M3CON_FAILED_TO_GET_ACCESS_TOKEN_APP_KEY_IS_MISSING - Failed to get access token because the app-key is missing */
  ERR_M3CON_FAILED_TO_GET_ACCESS_TOKEN_APP_KEY_IS_MISSING = 8203,
  /** ERR_M3CON_REQUEST_FAILED_ACCESS_TOKEN_MISSING - REST API request is failed because the access token is not ready. */
  ERR_M3CON_REQUEST_FAILED_ACCESS_TOKEN_MISSING = 8204,
  /** ERR_M3CON_HASH_FAILED - Hash function failed to make digest. (Not usual) */
  ERR_M3CON_HASH_FAILED = 8205,
  /** ERR_M3CON_DID_REGISTRATION_FAILED_ALREADY_REGISTERED - DID attestation or registration is failed because the did is already registered with another besu address */
  ERR_M3CON_DID_REGISTRATION_FAILED_ALREADY_REGISTERED = 8300,
  /** ERR_M3CON_DID_REGISTRATION_FAILED_INCORRECT_VERKEY - DID attestation or registration is failed because the did is already registered with another verkey (migration failure) */
  ERR_M3CON_DID_REGISTRATION_FAILED_INCORRECT_VERKEY = 8301,
  /** ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_NONCE - DID attestation or registration is failed because of invalid nonce */
  ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_NONCE = 8302,
  /** ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_APP_VERSION - DID attestation or registration is failed because the given app-version is not allowed */
  ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_APP_VERSION = 8303,
  /** ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_V1 - DID attestation or registration is failed because it's app version is not Android while Google-play v1 attestation is successful */
  ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_V1 = 8304,
  /** ERR_M3CON_DID_REGISTRATION_FAILED_IOS - DID attestation or registration is failed because it's app version is not iPhone while Apple attestation is successful */
  ERR_M3CON_DID_REGISTRATION_FAILED_IOS = 8305,
  /** ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_PLAY_INTEGRITY - DID attestation or registration is failed because it's app version is not Android while Google-play integrity attestation is successful */
  ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_PLAY_INTEGRITY = 8306,
  /** ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_GENERAL - DID attestation or registration is failed because it failed to verify Android attestation v1 and Google-play integrity attestation */
  ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_GENERAL = 8307,
  /** ERR_M3CON_DID_REGISTRATION_FAILED_iOS_GENERAL - DID attestation or registration is failed because it failed to verify Apple attestation */
  ERR_M3CON_DID_REGISTRATION_FAILED_iOS_GENERAL = 8308,
  /** ERR_M3CON_DID_REGISTRATION_FAILED_SERVER_ECDH_FAILURE - DID attestation or registration is failed because the client public key is not suitable to do ECDH */
  ERR_M3CON_DID_REGISTRATION_FAILED_SERVER_ECDH_FAILURE = 8310,
  /** ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_SIGNATURE - DID attestation or registration is failed because registrationSignature is invalid */
  ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_SIGNATURE = 8311,
  /** ERR_M3CON_DID_ACCESS_TOKEN_FAILED_INVALID_NONCE - DID attestation or registration is failed because of invalid nonce */
  ERR_M3CON_DID_ACCESS_TOKEN_FAILED_INVALID_NONCE = 8312,
  /** ERR_M3CON_DID_ACCESS_TOKEN_FAILED_EMPTY_MID - Access token request is failed because of empty mid */
  ERR_M3CON_DID_ACCESS_TOKEN_FAILED_EMPTY_MID = 8313,
  /** ERR_AUTHENTICATION_FAILED - AA and other errors */
  ERR_AUTHENTICATION_FAILED = 9001,
  ERR_VALIDATION_FAILED = 9002,
  ERR_NOT_SUPPORTED = 9003,
  ERR_INVALID_CODEC_VERSION = 9004,
  ERR_CANNOT_DECODE = 9005,
  ERR_CANNOT_GET_KEY = 9006,
  ERR_CANNOT_GET_INDEX = 9007,
  ERR_BIZ_REST_API = 9008,
  ERR_INVOCATION_CHAINCODE_GOT_ERROR = 9997,
  ERR_UNKNOWN = 9998,
  ERR_SYSTEM_ERROR = 9999,
  UNRECOGNIZED = -1,
}

export function commonStatusCodeFromJSON(object: any): CommonStatusCode {
  switch (object) {
    case 0:
    case "STATUS_SUCCESS":
      return CommonStatusCode.STATUS_SUCCESS;
    case 1:
    case "ERR_INVALID_FUNCTION":
      return CommonStatusCode.ERR_INVALID_FUNCTION;
    case 2:
    case "ERR_INVALID_FORMAT":
      return CommonStatusCode.ERR_INVALID_FORMAT;
    case 3:
    case "ERR_INVALID_ARGUMENT":
      return CommonStatusCode.ERR_INVALID_ARGUMENT;
    case 4:
    case "ERR_INVALID_RANGE":
      return CommonStatusCode.ERR_INVALID_RANGE;
    case 1001:
    case "ERR_CANNOT_ACTIVATE_PROPOSAL":
      return CommonStatusCode.ERR_CANNOT_ACTIVATE_PROPOSAL;
    case 1002:
    case "ERR_CANNOT_REVOKE_PROPOSAL":
      return CommonStatusCode.ERR_CANNOT_REVOKE_PROPOSAL;
    case 1003:
    case "ERR_ALREADY_REVOKED_PROPOSAL":
      return CommonStatusCode.ERR_ALREADY_REVOKED_PROPOSAL;
    case 1004:
    case "ERR_EXPIRED_PROPOSAL":
      return CommonStatusCode.ERR_EXPIRED_PROPOSAL;
    case 1005:
    case "ERR_NOT_ENOUGH_DEPOSIT_FOR_PROPOSAL":
      return CommonStatusCode.ERR_NOT_ENOUGH_DEPOSIT_FOR_PROPOSAL;
    case 1006:
    case "ERR_INVALID_DEPOSIT_FOR_PROPOSAL":
      return CommonStatusCode.ERR_INVALID_DEPOSIT_FOR_PROPOSAL;
    case 1007:
    case "ERR_NOT_VALID_BEFORE_PROPOSAL":
      return CommonStatusCode.ERR_NOT_VALID_BEFORE_PROPOSAL;
    case 1008:
    case "ERR_NOT_VALID_AFTER_PROPOSAL":
      return CommonStatusCode.ERR_NOT_VALID_AFTER_PROPOSAL;
    case 1009:
    case "ERR_INACTIVE_PROPOSAL":
      return CommonStatusCode.ERR_INACTIVE_PROPOSAL;
    case 1010:
    case "ERR_STILL_VALID_PROPOSAL":
      return CommonStatusCode.ERR_STILL_VALID_PROPOSAL;
    case 1011:
    case "ERR_NOTHING_REFRESHED":
      return CommonStatusCode.ERR_NOTHING_REFRESHED;
    case 1098:
    case "ERR_DROUGHT_PROPOSAL":
      return CommonStatusCode.ERR_DROUGHT_PROPOSAL;
    case 1099:
    case "ERR_CORRUPTED_PROPOSAL":
      return CommonStatusCode.ERR_CORRUPTED_PROPOSAL;
    case 1501:
    case "ERR_CANNOT_ISSUE_TICKET":
      return CommonStatusCode.ERR_CANNOT_ISSUE_TICKET;
    case 1502:
    case "ERR_CANNOT_REVOKE_TICKET":
      return CommonStatusCode.ERR_CANNOT_REVOKE_TICKET;
    case 1503:
    case "ERR_ALREADY_REVOKED_TICKET":
      return CommonStatusCode.ERR_ALREADY_REVOKED_TICKET;
    case 1504:
    case "ERR_EXPIRED_TICKET":
      return CommonStatusCode.ERR_EXPIRED_TICKET;
    case 1505:
    case "ERR_NOT_ENOUGH_DEPOSIT_FOR_TICKET":
      return CommonStatusCode.ERR_NOT_ENOUGH_DEPOSIT_FOR_TICKET;
    case 1506:
    case "ERR_INVALID_DEPOSIT_FOR_TICKET":
      return CommonStatusCode.ERR_INVALID_DEPOSIT_FOR_TICKET;
    case 1507:
    case "ERR_ALREADY_ISSUED_BY_DID":
      return CommonStatusCode.ERR_ALREADY_ISSUED_BY_DID;
    case 1508:
    case "ERR_CANNOT_CONSUME_BY_PERIOD":
      return CommonStatusCode.ERR_CANNOT_CONSUME_BY_PERIOD;
    case 1509:
    case "ERR_BEFORE_VALID_AT_TICKET":
      return CommonStatusCode.ERR_BEFORE_VALID_AT_TICKET;
    case 1510:
    case "ERR_PROPOSAL_PURPOSE_TO_ISSUE_TICKET":
      return CommonStatusCode.ERR_PROPOSAL_PURPOSE_TO_ISSUE_TICKET;
    case 1597:
    case "ERR_REFUNDED_TICKET":
      return CommonStatusCode.ERR_REFUNDED_TICKET;
    case 1598:
    case "ERR_DROUGHT_TICKET":
      return CommonStatusCode.ERR_DROUGHT_TICKET;
    case 1599:
    case "ERR_CORRUPTED_TICKET":
      return CommonStatusCode.ERR_CORRUPTED_TICKET;
    case 2001:
    case "ERR_SPENT_TOKEN":
      return CommonStatusCode.ERR_SPENT_TOKEN;
    case 2002:
    case "ERR_UNSPENT_TOKEN":
      return CommonStatusCode.ERR_UNSPENT_TOKEN;
    case 2003:
    case "ERR_EXPIRED_TOKEN":
      return CommonStatusCode.ERR_EXPIRED_TOKEN;
    case 2004:
    case "ERR_AMOUNT_DIFFER":
      return CommonStatusCode.ERR_AMOUNT_DIFFER;
    case 2005:
    case "ERR_INVALID_DUE_TO":
      return CommonStatusCode.ERR_INVALID_DUE_TO;
    case 2006:
    case "ERR_INVALID_DUE_TO_TAG":
      return CommonStatusCode.ERR_INVALID_DUE_TO_TAG;
    case 2007:
    case "ERR_INVALID_DUE_TO_VERB":
      return CommonStatusCode.ERR_INVALID_DUE_TO_VERB;
    case 2008:
    case "ERR_INVALID_DUE_TO_OBJECT":
      return CommonStatusCode.ERR_INVALID_DUE_TO_OBJECT;
    case 2009:
    case "ERR_INVALID_DURATION":
      return CommonStatusCode.ERR_INVALID_DURATION;
    case 2010:
    case "ERR_NOT_CLAIMABLE_YET":
      return CommonStatusCode.ERR_NOT_CLAIMABLE_YET;
    case 2011:
    case "ERR_NOT_REFUNDABLE":
      return CommonStatusCode.ERR_NOT_REFUNDABLE;
    case 2012:
    case "ERR_ALREADY_REWARDED":
      return CommonStatusCode.ERR_ALREADY_REWARDED;
    case 2013:
    case "ERR_FAILED_TO_GET_TICKET":
      return CommonStatusCode.ERR_FAILED_TO_GET_TICKET;
    case 2014:
    case "ERR_FAILED_TO_GET_PROPOSAL":
      return CommonStatusCode.ERR_FAILED_TO_GET_PROPOSAL;
    case 2015:
    case "ERR_PROPOSAL_NOT_DEPOSITABLE":
      return CommonStatusCode.ERR_PROPOSAL_NOT_DEPOSITABLE;
    case 2016:
    case "ERR_CANNOT_SPEND":
      return CommonStatusCode.ERR_CANNOT_SPEND;
    case 2017:
    case "ERR_CANNOT_DEPOSIT":
      return CommonStatusCode.ERR_CANNOT_DEPOSIT;
    case 2018:
    case "ERR_CANNOT_WITHDRAW":
      return CommonStatusCode.ERR_CANNOT_WITHDRAW;
    case 2019:
    case "ERR_CANNOT_TRANSFER":
      return CommonStatusCode.ERR_CANNOT_TRANSFER;
    case 2020:
    case "ERR_NOT_ENOUGH_MONEY":
      return CommonStatusCode.ERR_NOT_ENOUGH_MONEY;
    case 2021:
    case "ERR_DEPOSITED_ALREADY_SPENT":
      return CommonStatusCode.ERR_DEPOSITED_ALREADY_SPENT;
    case 2022:
    case "ERR_NOT_FOR_YOUR_PROPOSAL":
      return CommonStatusCode.ERR_NOT_FOR_YOUR_PROPOSAL;
    case 2023:
    case "ERR_NOT_FOR_YOUR_TICKET":
      return CommonStatusCode.ERR_NOT_FOR_YOUR_TICKET;
    case 3001:
    case "ERR_UNREGISTERED_QUESTION":
      return CommonStatusCode.ERR_UNREGISTERED_QUESTION;
    case 3002:
    case "ERR_ALREADY_REGISTERED_ANSWER":
      return CommonStatusCode.ERR_ALREADY_REGISTERED_ANSWER;
    case 3004:
    case "ERR_COMPLETE_REWARD":
      return CommonStatusCode.ERR_COMPLETE_REWARD;
    case 3005:
    case "ERR_SAVE_ANSWER_STASTICS":
      return CommonStatusCode.ERR_SAVE_ANSWER_STASTICS;
    case 3006:
    case "ERR_REMOVE_ANSWER_STASTICS":
      return CommonStatusCode.ERR_REMOVE_ANSWER_STASTICS;
    case 3011:
    case "ERR_FINISHED_EVENT":
      return CommonStatusCode.ERR_FINISHED_EVENT;
    case 3012:
    case "ERR_UNKNOWN_ERROR":
      return CommonStatusCode.ERR_UNKNOWN_ERROR;
    case 3013:
    case "ERR_OVERTIME_USED_CODE":
      return CommonStatusCode.ERR_OVERTIME_USED_CODE;
    case 3014:
    case "ERR_NOT_CORRECT_CODE":
      return CommonStatusCode.ERR_NOT_CORRECT_CODE;
    case 3015:
    case "ERR_REWARD_POINT":
      return CommonStatusCode.ERR_REWARD_POINT;
    case 3016:
    case "ERR_ALREADY_PARTICIPATED_EVENT":
      return CommonStatusCode.ERR_ALREADY_PARTICIPATED_EVENT;
    case 3017:
    case "ERR_NOT_PROGRESS_EVENT":
      return CommonStatusCode.ERR_NOT_PROGRESS_EVENT;
    case 3018:
    case "ERR_LACK_PARTICIPATED_DATA_TRANSACTION":
      return CommonStatusCode.ERR_LACK_PARTICIPATED_DATA_TRANSACTION;
    case 3021:
    case "ERR_UNMATCH_OWNER":
      return CommonStatusCode.ERR_UNMATCH_OWNER;
    case 3022:
    case "ERR_NOT_FOUND_DATA":
      return CommonStatusCode.ERR_NOT_FOUND_DATA;
    case 3023:
    case "ERR_STORED_ELASTIC":
      return CommonStatusCode.ERR_STORED_ELASTIC;
    case 3024:
    case "ERR_STORED_ELASTIC_EACH":
      return CommonStatusCode.ERR_STORED_ELASTIC_EACH;
    case 3025:
    case "ERR_AUTHORIZED":
      return CommonStatusCode.ERR_AUTHORIZED;
    case 3031:
    case "ERR_NOT_ACTIVATE":
      return CommonStatusCode.ERR_NOT_ACTIVATE;
    case 3032:
    case "ERR_NOT_DELETE":
      return CommonStatusCode.ERR_NOT_DELETE;
    case 3033:
    case "ERR_NOTICE_COUNT_EXCEED":
      return CommonStatusCode.ERR_NOTICE_COUNT_EXCEED;
    case 3034:
    case "ERR_EXCEED_NOT_AFTER":
      return CommonStatusCode.ERR_EXCEED_NOT_AFTER;
    case 3035:
    case "ERR_NOT_BETWEEN_INVISIBLE_PERIOD":
      return CommonStatusCode.ERR_NOT_BETWEEN_INVISIBLE_PERIOD;
    case 3036:
    case "ERR_EVENT_NOTICE_URL_FORMAT":
      return CommonStatusCode.ERR_EVENT_NOTICE_URL_FORMAT;
    case 3037:
    case "ERR_NOT_UPDATE":
      return CommonStatusCode.ERR_NOT_UPDATE;
    case 3038:
    case "ERR_PASSWORD_NOT_CHANGED":
      return CommonStatusCode.ERR_PASSWORD_NOT_CHANGED;
    case 3039:
    case "ERR_DATA_DUPLICATED":
      return CommonStatusCode.ERR_DATA_DUPLICATED;
    case 3040:
    case "ERR_UPLOAD_IO_FAILED":
      return CommonStatusCode.ERR_UPLOAD_IO_FAILED;
    case 3041:
    case "ERR_NO_CODES_AVAILABLE":
      return CommonStatusCode.ERR_NO_CODES_AVAILABLE;
    case 6001:
    case "ERR_INVALID_PARAMETER_FORMAT":
      return CommonStatusCode.ERR_INVALID_PARAMETER_FORMAT;
    case 6002:
    case "ERR_INVALID_PARAMETER":
      return CommonStatusCode.ERR_INVALID_PARAMETER;
    case 6003:
    case "ERR_MOBILE_AUTH_INVALID_TOKEN":
      return CommonStatusCode.ERR_MOBILE_AUTH_INVALID_TOKEN;
    case 6004:
    case "ERR_MOBILE_AUTH_EXPIRED_TOKEN":
      return CommonStatusCode.ERR_MOBILE_AUTH_EXPIRED_TOKEN;
    case 6005:
    case "ERR_URL_FORMAT":
      return CommonStatusCode.ERR_URL_FORMAT;
    case 6006:
    case "ERR_IO_EXCEPTION":
      return CommonStatusCode.ERR_IO_EXCEPTION;
    case 6101:
    case "ERR_FILE_UPLOAD":
      return CommonStatusCode.ERR_FILE_UPLOAD;
    case 6310:
    case "ERR_ALREADY_USED_CODE":
      return CommonStatusCode.ERR_ALREADY_USED_CODE;
    case 7001:
    case "ERR_PROTOBUF_PARSING":
      return CommonStatusCode.ERR_PROTOBUF_PARSING;
    case 8001:
    case "ERR_BLOCKCHAIN_GET_STATE_FAILED":
      return CommonStatusCode.ERR_BLOCKCHAIN_GET_STATE_FAILED;
    case 8002:
    case "ERR_BLOCKCHAIN_PUT_STATE_FAILED":
      return CommonStatusCode.ERR_BLOCKCHAIN_PUT_STATE_FAILED;
    case 8003:
    case "ERR_BLOCKCHAIN_DEL_STATE_FAILED":
      return CommonStatusCode.ERR_BLOCKCHAIN_DEL_STATE_FAILED;
    case 8004:
    case "ERR_BLOCKCHAIN_NO_DATA_FOUND":
      return CommonStatusCode.ERR_BLOCKCHAIN_NO_DATA_FOUND;
    case 8005:
    case "ERR_BLOCKCHAIN_ALREADY_EXISTS":
      return CommonStatusCode.ERR_BLOCKCHAIN_ALREADY_EXISTS;
    case 8100:
    case "ERR_RETRY_WITH_NEW_BLOCKCHAIN_NONCE":
      return CommonStatusCode.ERR_RETRY_WITH_NEW_BLOCKCHAIN_NONCE;
    case 8101:
    case "ERR_BLOCKCHAIN_DID_MIGRATION_FAILED":
      return CommonStatusCode.ERR_BLOCKCHAIN_DID_MIGRATION_FAILED;
    case 8102:
    case "ERR_BLOCKCHAIN_TRANSACTION_SIGNATURE_VERIFICATION_FAILURE":
      return CommonStatusCode.ERR_BLOCKCHAIN_TRANSACTION_SIGNATURE_VERIFICATION_FAILURE;
    case 8103:
    case "ERR_BLOCKCHAIN_NOT_AUTHORIZED":
      return CommonStatusCode.ERR_BLOCKCHAIN_NOT_AUTHORIZED;
    case 8104:
    case "ERR_BLOCKCHAIN_CONTRACT_NOT_READY_YET":
      return CommonStatusCode.ERR_BLOCKCHAIN_CONTRACT_NOT_READY_YET;
    case 8105:
    case "ERR_BLOCKCHAIN_DID_REGISTRATION_FAILED":
      return CommonStatusCode.ERR_BLOCKCHAIN_DID_REGISTRATION_FAILED;
    case 8106:
    case "ERR_BLOCKCHAIN_DID_ALREADY_IN_USE":
      return CommonStatusCode.ERR_BLOCKCHAIN_DID_ALREADY_IN_USE;
    case 8107:
    case "ERR_BLOCKCHAIN_ADDRESS_ALREADY_IN_USE":
      return CommonStatusCode.ERR_BLOCKCHAIN_ADDRESS_ALREADY_IN_USE;
    case 8108:
    case "ERR_BLOCKCHAIN_NOT_ALLOWED_LOGIC_CONTRACT":
      return CommonStatusCode.ERR_BLOCKCHAIN_NOT_ALLOWED_LOGIC_CONTRACT;
    case 8109:
    case "ERR_BLOCKCHAIN_UNKNOWN":
      return CommonStatusCode.ERR_BLOCKCHAIN_UNKNOWN;
    case 8110:
    case "ERR_BLOCKCHAIN_MISMATCH_FUNCTION_TYPE":
      return CommonStatusCode.ERR_BLOCKCHAIN_MISMATCH_FUNCTION_TYPE;
    case 8111:
    case "ERR_BLOCKCHAIN_NOT_ENOUGH_GAS":
      return CommonStatusCode.ERR_BLOCKCHAIN_NOT_ENOUGH_GAS;
    case 8112:
    case "ERR_BLOCKCHAIN_SEND_TRANSACTION_FAILURE":
      return CommonStatusCode.ERR_BLOCKCHAIN_SEND_TRANSACTION_FAILURE;
    case 8113:
    case "ERR_BLOCKCHAIN_INVALID_TRANSACTION_MATERIAL":
      return CommonStatusCode.ERR_BLOCKCHAIN_INVALID_TRANSACTION_MATERIAL;
    case 8200:
    case "ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY":
      return CommonStatusCode.ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY;
    case 8201:
    case "ERR_M3CON_FAILED_TO_GET_NONCE":
      return CommonStatusCode.ERR_M3CON_FAILED_TO_GET_NONCE;
    case 8202:
    case "ERR_M3CON_ATTESTATION_FAILED_MYD3_DATA_MISSING":
      return CommonStatusCode.ERR_M3CON_ATTESTATION_FAILED_MYD3_DATA_MISSING;
    case 8203:
    case "ERR_M3CON_FAILED_TO_GET_ACCESS_TOKEN_APP_KEY_IS_MISSING":
      return CommonStatusCode.ERR_M3CON_FAILED_TO_GET_ACCESS_TOKEN_APP_KEY_IS_MISSING;
    case 8204:
    case "ERR_M3CON_REQUEST_FAILED_ACCESS_TOKEN_MISSING":
      return CommonStatusCode.ERR_M3CON_REQUEST_FAILED_ACCESS_TOKEN_MISSING;
    case 8205:
    case "ERR_M3CON_HASH_FAILED":
      return CommonStatusCode.ERR_M3CON_HASH_FAILED;
    case 8300:
    case "ERR_M3CON_DID_REGISTRATION_FAILED_ALREADY_REGISTERED":
      return CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_ALREADY_REGISTERED;
    case 8301:
    case "ERR_M3CON_DID_REGISTRATION_FAILED_INCORRECT_VERKEY":
      return CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_INCORRECT_VERKEY;
    case 8302:
    case "ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_NONCE":
      return CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_NONCE;
    case 8303:
    case "ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_APP_VERSION":
      return CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_APP_VERSION;
    case 8304:
    case "ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_V1":
      return CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_V1;
    case 8305:
    case "ERR_M3CON_DID_REGISTRATION_FAILED_IOS":
      return CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_IOS;
    case 8306:
    case "ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_PLAY_INTEGRITY":
      return CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_PLAY_INTEGRITY;
    case 8307:
    case "ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_GENERAL":
      return CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_GENERAL;
    case 8308:
    case "ERR_M3CON_DID_REGISTRATION_FAILED_iOS_GENERAL":
      return CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_iOS_GENERAL;
    case 8310:
    case "ERR_M3CON_DID_REGISTRATION_FAILED_SERVER_ECDH_FAILURE":
      return CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_SERVER_ECDH_FAILURE;
    case 8311:
    case "ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_SIGNATURE":
      return CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_SIGNATURE;
    case 8312:
    case "ERR_M3CON_DID_ACCESS_TOKEN_FAILED_INVALID_NONCE":
      return CommonStatusCode.ERR_M3CON_DID_ACCESS_TOKEN_FAILED_INVALID_NONCE;
    case 8313:
    case "ERR_M3CON_DID_ACCESS_TOKEN_FAILED_EMPTY_MID":
      return CommonStatusCode.ERR_M3CON_DID_ACCESS_TOKEN_FAILED_EMPTY_MID;
    case 9001:
    case "ERR_AUTHENTICATION_FAILED":
      return CommonStatusCode.ERR_AUTHENTICATION_FAILED;
    case 9002:
    case "ERR_VALIDATION_FAILED":
      return CommonStatusCode.ERR_VALIDATION_FAILED;
    case 9003:
    case "ERR_NOT_SUPPORTED":
      return CommonStatusCode.ERR_NOT_SUPPORTED;
    case 9004:
    case "ERR_INVALID_CODEC_VERSION":
      return CommonStatusCode.ERR_INVALID_CODEC_VERSION;
    case 9005:
    case "ERR_CANNOT_DECODE":
      return CommonStatusCode.ERR_CANNOT_DECODE;
    case 9006:
    case "ERR_CANNOT_GET_KEY":
      return CommonStatusCode.ERR_CANNOT_GET_KEY;
    case 9007:
    case "ERR_CANNOT_GET_INDEX":
      return CommonStatusCode.ERR_CANNOT_GET_INDEX;
    case 9008:
    case "ERR_BIZ_REST_API":
      return CommonStatusCode.ERR_BIZ_REST_API;
    case 9997:
    case "ERR_INVOCATION_CHAINCODE_GOT_ERROR":
      return CommonStatusCode.ERR_INVOCATION_CHAINCODE_GOT_ERROR;
    case 9998:
    case "ERR_UNKNOWN":
      return CommonStatusCode.ERR_UNKNOWN;
    case 9999:
    case "ERR_SYSTEM_ERROR":
      return CommonStatusCode.ERR_SYSTEM_ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CommonStatusCode.UNRECOGNIZED;
  }
}

export function commonStatusCodeToJSON(object: CommonStatusCode): string {
  switch (object) {
    case CommonStatusCode.STATUS_SUCCESS:
      return "STATUS_SUCCESS";
    case CommonStatusCode.ERR_INVALID_FUNCTION:
      return "ERR_INVALID_FUNCTION";
    case CommonStatusCode.ERR_INVALID_FORMAT:
      return "ERR_INVALID_FORMAT";
    case CommonStatusCode.ERR_INVALID_ARGUMENT:
      return "ERR_INVALID_ARGUMENT";
    case CommonStatusCode.ERR_INVALID_RANGE:
      return "ERR_INVALID_RANGE";
    case CommonStatusCode.ERR_CANNOT_ACTIVATE_PROPOSAL:
      return "ERR_CANNOT_ACTIVATE_PROPOSAL";
    case CommonStatusCode.ERR_CANNOT_REVOKE_PROPOSAL:
      return "ERR_CANNOT_REVOKE_PROPOSAL";
    case CommonStatusCode.ERR_ALREADY_REVOKED_PROPOSAL:
      return "ERR_ALREADY_REVOKED_PROPOSAL";
    case CommonStatusCode.ERR_EXPIRED_PROPOSAL:
      return "ERR_EXPIRED_PROPOSAL";
    case CommonStatusCode.ERR_NOT_ENOUGH_DEPOSIT_FOR_PROPOSAL:
      return "ERR_NOT_ENOUGH_DEPOSIT_FOR_PROPOSAL";
    case CommonStatusCode.ERR_INVALID_DEPOSIT_FOR_PROPOSAL:
      return "ERR_INVALID_DEPOSIT_FOR_PROPOSAL";
    case CommonStatusCode.ERR_NOT_VALID_BEFORE_PROPOSAL:
      return "ERR_NOT_VALID_BEFORE_PROPOSAL";
    case CommonStatusCode.ERR_NOT_VALID_AFTER_PROPOSAL:
      return "ERR_NOT_VALID_AFTER_PROPOSAL";
    case CommonStatusCode.ERR_INACTIVE_PROPOSAL:
      return "ERR_INACTIVE_PROPOSAL";
    case CommonStatusCode.ERR_STILL_VALID_PROPOSAL:
      return "ERR_STILL_VALID_PROPOSAL";
    case CommonStatusCode.ERR_NOTHING_REFRESHED:
      return "ERR_NOTHING_REFRESHED";
    case CommonStatusCode.ERR_DROUGHT_PROPOSAL:
      return "ERR_DROUGHT_PROPOSAL";
    case CommonStatusCode.ERR_CORRUPTED_PROPOSAL:
      return "ERR_CORRUPTED_PROPOSAL";
    case CommonStatusCode.ERR_CANNOT_ISSUE_TICKET:
      return "ERR_CANNOT_ISSUE_TICKET";
    case CommonStatusCode.ERR_CANNOT_REVOKE_TICKET:
      return "ERR_CANNOT_REVOKE_TICKET";
    case CommonStatusCode.ERR_ALREADY_REVOKED_TICKET:
      return "ERR_ALREADY_REVOKED_TICKET";
    case CommonStatusCode.ERR_EXPIRED_TICKET:
      return "ERR_EXPIRED_TICKET";
    case CommonStatusCode.ERR_NOT_ENOUGH_DEPOSIT_FOR_TICKET:
      return "ERR_NOT_ENOUGH_DEPOSIT_FOR_TICKET";
    case CommonStatusCode.ERR_INVALID_DEPOSIT_FOR_TICKET:
      return "ERR_INVALID_DEPOSIT_FOR_TICKET";
    case CommonStatusCode.ERR_ALREADY_ISSUED_BY_DID:
      return "ERR_ALREADY_ISSUED_BY_DID";
    case CommonStatusCode.ERR_CANNOT_CONSUME_BY_PERIOD:
      return "ERR_CANNOT_CONSUME_BY_PERIOD";
    case CommonStatusCode.ERR_BEFORE_VALID_AT_TICKET:
      return "ERR_BEFORE_VALID_AT_TICKET";
    case CommonStatusCode.ERR_PROPOSAL_PURPOSE_TO_ISSUE_TICKET:
      return "ERR_PROPOSAL_PURPOSE_TO_ISSUE_TICKET";
    case CommonStatusCode.ERR_REFUNDED_TICKET:
      return "ERR_REFUNDED_TICKET";
    case CommonStatusCode.ERR_DROUGHT_TICKET:
      return "ERR_DROUGHT_TICKET";
    case CommonStatusCode.ERR_CORRUPTED_TICKET:
      return "ERR_CORRUPTED_TICKET";
    case CommonStatusCode.ERR_SPENT_TOKEN:
      return "ERR_SPENT_TOKEN";
    case CommonStatusCode.ERR_UNSPENT_TOKEN:
      return "ERR_UNSPENT_TOKEN";
    case CommonStatusCode.ERR_EXPIRED_TOKEN:
      return "ERR_EXPIRED_TOKEN";
    case CommonStatusCode.ERR_AMOUNT_DIFFER:
      return "ERR_AMOUNT_DIFFER";
    case CommonStatusCode.ERR_INVALID_DUE_TO:
      return "ERR_INVALID_DUE_TO";
    case CommonStatusCode.ERR_INVALID_DUE_TO_TAG:
      return "ERR_INVALID_DUE_TO_TAG";
    case CommonStatusCode.ERR_INVALID_DUE_TO_VERB:
      return "ERR_INVALID_DUE_TO_VERB";
    case CommonStatusCode.ERR_INVALID_DUE_TO_OBJECT:
      return "ERR_INVALID_DUE_TO_OBJECT";
    case CommonStatusCode.ERR_INVALID_DURATION:
      return "ERR_INVALID_DURATION";
    case CommonStatusCode.ERR_NOT_CLAIMABLE_YET:
      return "ERR_NOT_CLAIMABLE_YET";
    case CommonStatusCode.ERR_NOT_REFUNDABLE:
      return "ERR_NOT_REFUNDABLE";
    case CommonStatusCode.ERR_ALREADY_REWARDED:
      return "ERR_ALREADY_REWARDED";
    case CommonStatusCode.ERR_FAILED_TO_GET_TICKET:
      return "ERR_FAILED_TO_GET_TICKET";
    case CommonStatusCode.ERR_FAILED_TO_GET_PROPOSAL:
      return "ERR_FAILED_TO_GET_PROPOSAL";
    case CommonStatusCode.ERR_PROPOSAL_NOT_DEPOSITABLE:
      return "ERR_PROPOSAL_NOT_DEPOSITABLE";
    case CommonStatusCode.ERR_CANNOT_SPEND:
      return "ERR_CANNOT_SPEND";
    case CommonStatusCode.ERR_CANNOT_DEPOSIT:
      return "ERR_CANNOT_DEPOSIT";
    case CommonStatusCode.ERR_CANNOT_WITHDRAW:
      return "ERR_CANNOT_WITHDRAW";
    case CommonStatusCode.ERR_CANNOT_TRANSFER:
      return "ERR_CANNOT_TRANSFER";
    case CommonStatusCode.ERR_NOT_ENOUGH_MONEY:
      return "ERR_NOT_ENOUGH_MONEY";
    case CommonStatusCode.ERR_DEPOSITED_ALREADY_SPENT:
      return "ERR_DEPOSITED_ALREADY_SPENT";
    case CommonStatusCode.ERR_NOT_FOR_YOUR_PROPOSAL:
      return "ERR_NOT_FOR_YOUR_PROPOSAL";
    case CommonStatusCode.ERR_NOT_FOR_YOUR_TICKET:
      return "ERR_NOT_FOR_YOUR_TICKET";
    case CommonStatusCode.ERR_UNREGISTERED_QUESTION:
      return "ERR_UNREGISTERED_QUESTION";
    case CommonStatusCode.ERR_ALREADY_REGISTERED_ANSWER:
      return "ERR_ALREADY_REGISTERED_ANSWER";
    case CommonStatusCode.ERR_COMPLETE_REWARD:
      return "ERR_COMPLETE_REWARD";
    case CommonStatusCode.ERR_SAVE_ANSWER_STASTICS:
      return "ERR_SAVE_ANSWER_STASTICS";
    case CommonStatusCode.ERR_REMOVE_ANSWER_STASTICS:
      return "ERR_REMOVE_ANSWER_STASTICS";
    case CommonStatusCode.ERR_FINISHED_EVENT:
      return "ERR_FINISHED_EVENT";
    case CommonStatusCode.ERR_UNKNOWN_ERROR:
      return "ERR_UNKNOWN_ERROR";
    case CommonStatusCode.ERR_OVERTIME_USED_CODE:
      return "ERR_OVERTIME_USED_CODE";
    case CommonStatusCode.ERR_NOT_CORRECT_CODE:
      return "ERR_NOT_CORRECT_CODE";
    case CommonStatusCode.ERR_REWARD_POINT:
      return "ERR_REWARD_POINT";
    case CommonStatusCode.ERR_ALREADY_PARTICIPATED_EVENT:
      return "ERR_ALREADY_PARTICIPATED_EVENT";
    case CommonStatusCode.ERR_NOT_PROGRESS_EVENT:
      return "ERR_NOT_PROGRESS_EVENT";
    case CommonStatusCode.ERR_LACK_PARTICIPATED_DATA_TRANSACTION:
      return "ERR_LACK_PARTICIPATED_DATA_TRANSACTION";
    case CommonStatusCode.ERR_UNMATCH_OWNER:
      return "ERR_UNMATCH_OWNER";
    case CommonStatusCode.ERR_NOT_FOUND_DATA:
      return "ERR_NOT_FOUND_DATA";
    case CommonStatusCode.ERR_STORED_ELASTIC:
      return "ERR_STORED_ELASTIC";
    case CommonStatusCode.ERR_STORED_ELASTIC_EACH:
      return "ERR_STORED_ELASTIC_EACH";
    case CommonStatusCode.ERR_AUTHORIZED:
      return "ERR_AUTHORIZED";
    case CommonStatusCode.ERR_NOT_ACTIVATE:
      return "ERR_NOT_ACTIVATE";
    case CommonStatusCode.ERR_NOT_DELETE:
      return "ERR_NOT_DELETE";
    case CommonStatusCode.ERR_NOTICE_COUNT_EXCEED:
      return "ERR_NOTICE_COUNT_EXCEED";
    case CommonStatusCode.ERR_EXCEED_NOT_AFTER:
      return "ERR_EXCEED_NOT_AFTER";
    case CommonStatusCode.ERR_NOT_BETWEEN_INVISIBLE_PERIOD:
      return "ERR_NOT_BETWEEN_INVISIBLE_PERIOD";
    case CommonStatusCode.ERR_EVENT_NOTICE_URL_FORMAT:
      return "ERR_EVENT_NOTICE_URL_FORMAT";
    case CommonStatusCode.ERR_NOT_UPDATE:
      return "ERR_NOT_UPDATE";
    case CommonStatusCode.ERR_PASSWORD_NOT_CHANGED:
      return "ERR_PASSWORD_NOT_CHANGED";
    case CommonStatusCode.ERR_DATA_DUPLICATED:
      return "ERR_DATA_DUPLICATED";
    case CommonStatusCode.ERR_UPLOAD_IO_FAILED:
      return "ERR_UPLOAD_IO_FAILED";
    case CommonStatusCode.ERR_NO_CODES_AVAILABLE:
      return "ERR_NO_CODES_AVAILABLE";
    case CommonStatusCode.ERR_INVALID_PARAMETER_FORMAT:
      return "ERR_INVALID_PARAMETER_FORMAT";
    case CommonStatusCode.ERR_INVALID_PARAMETER:
      return "ERR_INVALID_PARAMETER";
    case CommonStatusCode.ERR_MOBILE_AUTH_INVALID_TOKEN:
      return "ERR_MOBILE_AUTH_INVALID_TOKEN";
    case CommonStatusCode.ERR_MOBILE_AUTH_EXPIRED_TOKEN:
      return "ERR_MOBILE_AUTH_EXPIRED_TOKEN";
    case CommonStatusCode.ERR_URL_FORMAT:
      return "ERR_URL_FORMAT";
    case CommonStatusCode.ERR_IO_EXCEPTION:
      return "ERR_IO_EXCEPTION";
    case CommonStatusCode.ERR_FILE_UPLOAD:
      return "ERR_FILE_UPLOAD";
    case CommonStatusCode.ERR_ALREADY_USED_CODE:
      return "ERR_ALREADY_USED_CODE";
    case CommonStatusCode.ERR_PROTOBUF_PARSING:
      return "ERR_PROTOBUF_PARSING";
    case CommonStatusCode.ERR_BLOCKCHAIN_GET_STATE_FAILED:
      return "ERR_BLOCKCHAIN_GET_STATE_FAILED";
    case CommonStatusCode.ERR_BLOCKCHAIN_PUT_STATE_FAILED:
      return "ERR_BLOCKCHAIN_PUT_STATE_FAILED";
    case CommonStatusCode.ERR_BLOCKCHAIN_DEL_STATE_FAILED:
      return "ERR_BLOCKCHAIN_DEL_STATE_FAILED";
    case CommonStatusCode.ERR_BLOCKCHAIN_NO_DATA_FOUND:
      return "ERR_BLOCKCHAIN_NO_DATA_FOUND";
    case CommonStatusCode.ERR_BLOCKCHAIN_ALREADY_EXISTS:
      return "ERR_BLOCKCHAIN_ALREADY_EXISTS";
    case CommonStatusCode.ERR_RETRY_WITH_NEW_BLOCKCHAIN_NONCE:
      return "ERR_RETRY_WITH_NEW_BLOCKCHAIN_NONCE";
    case CommonStatusCode.ERR_BLOCKCHAIN_DID_MIGRATION_FAILED:
      return "ERR_BLOCKCHAIN_DID_MIGRATION_FAILED";
    case CommonStatusCode.ERR_BLOCKCHAIN_TRANSACTION_SIGNATURE_VERIFICATION_FAILURE:
      return "ERR_BLOCKCHAIN_TRANSACTION_SIGNATURE_VERIFICATION_FAILURE";
    case CommonStatusCode.ERR_BLOCKCHAIN_NOT_AUTHORIZED:
      return "ERR_BLOCKCHAIN_NOT_AUTHORIZED";
    case CommonStatusCode.ERR_BLOCKCHAIN_CONTRACT_NOT_READY_YET:
      return "ERR_BLOCKCHAIN_CONTRACT_NOT_READY_YET";
    case CommonStatusCode.ERR_BLOCKCHAIN_DID_REGISTRATION_FAILED:
      return "ERR_BLOCKCHAIN_DID_REGISTRATION_FAILED";
    case CommonStatusCode.ERR_BLOCKCHAIN_DID_ALREADY_IN_USE:
      return "ERR_BLOCKCHAIN_DID_ALREADY_IN_USE";
    case CommonStatusCode.ERR_BLOCKCHAIN_ADDRESS_ALREADY_IN_USE:
      return "ERR_BLOCKCHAIN_ADDRESS_ALREADY_IN_USE";
    case CommonStatusCode.ERR_BLOCKCHAIN_NOT_ALLOWED_LOGIC_CONTRACT:
      return "ERR_BLOCKCHAIN_NOT_ALLOWED_LOGIC_CONTRACT";
    case CommonStatusCode.ERR_BLOCKCHAIN_UNKNOWN:
      return "ERR_BLOCKCHAIN_UNKNOWN";
    case CommonStatusCode.ERR_BLOCKCHAIN_MISMATCH_FUNCTION_TYPE:
      return "ERR_BLOCKCHAIN_MISMATCH_FUNCTION_TYPE";
    case CommonStatusCode.ERR_BLOCKCHAIN_NOT_ENOUGH_GAS:
      return "ERR_BLOCKCHAIN_NOT_ENOUGH_GAS";
    case CommonStatusCode.ERR_BLOCKCHAIN_SEND_TRANSACTION_FAILURE:
      return "ERR_BLOCKCHAIN_SEND_TRANSACTION_FAILURE";
    case CommonStatusCode.ERR_BLOCKCHAIN_INVALID_TRANSACTION_MATERIAL:
      return "ERR_BLOCKCHAIN_INVALID_TRANSACTION_MATERIAL";
    case CommonStatusCode.ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY:
      return "ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY";
    case CommonStatusCode.ERR_M3CON_FAILED_TO_GET_NONCE:
      return "ERR_M3CON_FAILED_TO_GET_NONCE";
    case CommonStatusCode.ERR_M3CON_ATTESTATION_FAILED_MYD3_DATA_MISSING:
      return "ERR_M3CON_ATTESTATION_FAILED_MYD3_DATA_MISSING";
    case CommonStatusCode.ERR_M3CON_FAILED_TO_GET_ACCESS_TOKEN_APP_KEY_IS_MISSING:
      return "ERR_M3CON_FAILED_TO_GET_ACCESS_TOKEN_APP_KEY_IS_MISSING";
    case CommonStatusCode.ERR_M3CON_REQUEST_FAILED_ACCESS_TOKEN_MISSING:
      return "ERR_M3CON_REQUEST_FAILED_ACCESS_TOKEN_MISSING";
    case CommonStatusCode.ERR_M3CON_HASH_FAILED:
      return "ERR_M3CON_HASH_FAILED";
    case CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_ALREADY_REGISTERED:
      return "ERR_M3CON_DID_REGISTRATION_FAILED_ALREADY_REGISTERED";
    case CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_INCORRECT_VERKEY:
      return "ERR_M3CON_DID_REGISTRATION_FAILED_INCORRECT_VERKEY";
    case CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_NONCE:
      return "ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_NONCE";
    case CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_APP_VERSION:
      return "ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_APP_VERSION";
    case CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_V1:
      return "ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_V1";
    case CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_IOS:
      return "ERR_M3CON_DID_REGISTRATION_FAILED_IOS";
    case CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_PLAY_INTEGRITY:
      return "ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_PLAY_INTEGRITY";
    case CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_GENERAL:
      return "ERR_M3CON_DID_REGISTRATION_FAILED_ANDROID_GENERAL";
    case CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_iOS_GENERAL:
      return "ERR_M3CON_DID_REGISTRATION_FAILED_iOS_GENERAL";
    case CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_SERVER_ECDH_FAILURE:
      return "ERR_M3CON_DID_REGISTRATION_FAILED_SERVER_ECDH_FAILURE";
    case CommonStatusCode.ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_SIGNATURE:
      return "ERR_M3CON_DID_REGISTRATION_FAILED_INVALID_SIGNATURE";
    case CommonStatusCode.ERR_M3CON_DID_ACCESS_TOKEN_FAILED_INVALID_NONCE:
      return "ERR_M3CON_DID_ACCESS_TOKEN_FAILED_INVALID_NONCE";
    case CommonStatusCode.ERR_M3CON_DID_ACCESS_TOKEN_FAILED_EMPTY_MID:
      return "ERR_M3CON_DID_ACCESS_TOKEN_FAILED_EMPTY_MID";
    case CommonStatusCode.ERR_AUTHENTICATION_FAILED:
      return "ERR_AUTHENTICATION_FAILED";
    case CommonStatusCode.ERR_VALIDATION_FAILED:
      return "ERR_VALIDATION_FAILED";
    case CommonStatusCode.ERR_NOT_SUPPORTED:
      return "ERR_NOT_SUPPORTED";
    case CommonStatusCode.ERR_INVALID_CODEC_VERSION:
      return "ERR_INVALID_CODEC_VERSION";
    case CommonStatusCode.ERR_CANNOT_DECODE:
      return "ERR_CANNOT_DECODE";
    case CommonStatusCode.ERR_CANNOT_GET_KEY:
      return "ERR_CANNOT_GET_KEY";
    case CommonStatusCode.ERR_CANNOT_GET_INDEX:
      return "ERR_CANNOT_GET_INDEX";
    case CommonStatusCode.ERR_BIZ_REST_API:
      return "ERR_BIZ_REST_API";
    case CommonStatusCode.ERR_INVOCATION_CHAINCODE_GOT_ERROR:
      return "ERR_INVOCATION_CHAINCODE_GOT_ERROR";
    case CommonStatusCode.ERR_UNKNOWN:
      return "ERR_UNKNOWN";
    case CommonStatusCode.ERR_SYSTEM_ERROR:
      return "ERR_SYSTEM_ERROR";
    case CommonStatusCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
