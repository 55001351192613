import Web3 from "web3";
import BN from "bn.js";
import { keccak256 } from "ethereumjs-util";
import bs58 from "bs58";
import {
  fetchAttestation,
  fetchChallengeDetails,
  fetchChallengeId,
  fetchChallenges,
  fetchCouponId,
  fetchCouponIssue,
  fetchCouponList,
  fetchIssue,
  fetchMyd2CouponList,
  fetchNonce,
  fetchPiToken,
  fetchRegister,
  fetchToken,
} from "api";
import {
  AsyncRequestStatus,
  CouponInfo,
  DeviceAttestationRequest,
  DeviceAttestationResponse,
  GetAuthAccessTokenRequest,
  GetAuthAccessTokenResponse,
  GetAuthNonceRequest,
  GetAuthNonceResponse,
  GetCouponIdRequest,
  GetCouponIdResponse,
  GetPiAccessTokenRequest,
  GetPiAccessTokenResponse,
  IssueCouponRequest,
  IssueCouponResponse,
  IssueTicketRequest,
  IssueTicketRequestMaterial,
  IssueTicketResponse,
  ListCouponRequest,
  ListCouponResponse,
  ListMyd2CouponRequest,
  ListMyd2CouponResponse,
  Myd2CouponInfo,
  RegisterDeviceRequest,
  RegisterDeviceResponse,
  RegisterRequestMaterial,
} from "protobuf/OpenApiServerV3";
import { Proposal } from "protobuf/OpenApiServerV3DataTypes";
import { ISSUE_TICKET_PREFIX, REGISTER_REQUEST_PREFIX } from "./constants";
import { Myd3Blockchain } from "./myd3";
import {
  base64ToUint8Array,
  byteArrayToHexString,
  convertUint8ArrayToWordArray,
  convertWordArrayToUint8Array,
  fromHexString,
  toHexString,
  uint8ArrayToBase64,
} from "./blockchainutil";
import { Myd2Blockchain } from "./myd2";
import { CommonStatusCode } from "protobuf/CommonStatusCode";
import CryptoJS from "crypto-js";
import { decodeBase64 } from "ethers";

type DataFromMyd2 = {
  did: string;
  myd2Verkey: string; // base58
  myd2PrivateKey: string; // base58
};

type Myd3ApplicationExtraData = {
  lastListedTime: string | undefined;
  jsonProposals: string[];
};

type Myd3ApplicationInfo = {
  appVersion: string;
  appKey: string | undefined;
};

export type Myd3AuthConnectorState = {
  did: string;
  dataFromMyd2: DataFromMyd2 | null;
  myd3PublicKey: string; // base58
  myd3PrivateKey: string; // base58
  myd3BesuAddress: string; // hex string starts with "0x"
  accessToken: string;
  applicationInfo: Myd3ApplicationInfo;

  // extra data for react-testbed
  applicationExtraData: Myd3ApplicationExtraData | null;
};

type saveStateFun = (myd3AuthConnectorState: string) => boolean;

type GetAttestationParcelFunction = (nonce: string) => string;

export type GetNonceResult = {
  status: CommonStatusCode;
  nonce: string; // nonce
};

export type DeviceRegistrationResult = {
  status: CommonStatusCode;
  appKey: string; // base64 encoded; only valid for attestation
  secretKey: string; // base64 encoded
  iv: string; // base64 encoded
};

export type GetAccessTokenResult = {
  status: CommonStatusCode;
  accessToken: string;
};

export type GetPiAccessTokenResult = {
  status: CommonStatusCode;
  piAccessToken: string;
};

export type IssueTicketResult = {
  status: CommonStatusCode;
  issueTicketResponse: IssueTicketResponse;
};

export type CouponIssueResult = {
  status: CommonStatusCode;
  orderNo: string;
  pinCode: string;
  imageUrl: string;
  info: string;
  couponTransactionId?: string;
};

export type CouponListResult = {
  status: CommonStatusCode;
  coupons?: CouponIssueResult[];
};

export type Myd2CouponListResult = {
  status: CommonStatusCode;
  coupons?: Myd2CouponInfo[];
};

export type ChallengeRequestResult = Record<string, any>;

export class Myd3AuthConnector {
  state: Myd3AuthConnectorState = {
    did: "",
    dataFromMyd2: null,
    myd3PublicKey: "",
    myd3PrivateKey: "",
    myd3BesuAddress: "",
    accessToken: "",
    applicationInfo: { appVersion: "", appKey: undefined },

    // extra data for react-testbed
    applicationExtraData: null, // TODO: remove applicationExtraData
  };

  myd3: Myd3Blockchain;
  saveState: saveStateFun;

  /**
   * Constructor of Myd3AuthConnector
   * Note, data should be undefined when dataFromMyd2 is delivered.
   *
   * @param myd3ApplicationInfo application info
   * @param saveState save state callback function
   * @param data stored state of the Myd3AuthConnector in json text format
   * @param dataFromMyd2 myd2 did and keys to migrate to myd3
   */
  constructor(
    applicationInfo: Myd3ApplicationInfo,
    saveState: saveStateFun,
    data?: string,
    dataFromMyd2?: DataFromMyd2
  ) {
    this.saveState = saveState;

    if (data && dataFromMyd2) {
      throw new Error("myd3 state cannot exist with myd2 state.");
    }

    if (dataFromMyd2) {
      this.state.dataFromMyd2 = dataFromMyd2;
      const myd2 = new Myd2Blockchain(dataFromMyd2.did, dataFromMyd2.myd2Verkey, dataFromMyd2.myd2PrivateKey);

      this.myd3 = new Myd3Blockchain(undefined, dataFromMyd2.did);
      this.myd3.setMyd2(myd2);
    } else if (data) {
      const parsedData = JSON.parse(data);
      this.myd3 = new Myd3Blockchain(undefined, parsedData.did, parsedData.myd3PrivateKey);

      // if (parsedData.dataFromMyd2) {
      //   this.state.dataFromMyd2 = parsedData.dataFromMyd2;
      // }
    } else {
      this.myd3 = new Myd3Blockchain();
    }

    this.state.applicationInfo.appVersion = applicationInfo.appVersion;
    if (applicationInfo.appKey) {
      this.state.applicationInfo.appKey = applicationInfo.appKey;
    }

    this.state = {
      ...this.state,
      did: this.myd3.did,
      myd3PublicKey: this.myd3.publicKey,
      myd3PrivateKey: this.myd3.privateKey,
      myd3BesuAddress: this.myd3.account.address.toLowerCase(),
    };

    this.saveState(JSON.stringify(this.state));
  }

  async getNonce(): Promise<GetNonceResult> {
    if (this.state.myd3BesuAddress === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_FAILED_TO_GET_NONCE));
    }

    const getAuthNonceRequest: GetAuthNonceRequest = GetAuthNonceRequest.create();
    getAuthNonceRequest.besuAddress = this.state.myd3BesuAddress;

    const paramBuf = GetAuthNonceRequest.encode(getAuthNonceRequest).finish();
    const res = await fetchNonce(paramBuf);

    const nonceResponse = GetAuthNonceResponse.decode(res);

    return {
      status: CommonStatusCode.STATUS_SUCCESS,
      nonce: nonceResponse.nonce,
    };
  }

  private randomBytes(count: number): Uint8Array {
    const result = new Uint8Array(count);
    for (let i = 0; i < count; ++i) {
      result[i] = Math.floor(256 * Math.random());
    }
    return result;
  }

  /**
   * Run the device attestation
   *
   * @param getParcelFn a function collect attestation parcel which is generated with the nonce requested
   * @return
   */
  async deviceAttestation(parcel: string, nonce: string): Promise<DeviceRegistrationResult> {
    const { did, myd3PublicKey, myd3BesuAddress, dataFromMyd2 } = this.state;

    if (did === "" || myd3PublicKey === "" || myd3BesuAddress === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY));
    }

    const clientRandom: Uint8Array = this.randomBytes(16);
    // const nonce = await this.getNonce();

    // get parcel from the native layer
    // const parcel = await getParcelFn(nonce);

    const attestDeviceRequest: DeviceAttestationRequest = DeviceAttestationRequest.create();
    attestDeviceRequest.appVersion = this.state.applicationInfo.appVersion;
    attestDeviceRequest.clientKeyMaterial = clientRandom;
    attestDeviceRequest.parcel = parcel;
    attestDeviceRequest.registrationMessage = RegisterRequestMaterial.create();
    attestDeviceRequest.registrationMessage.materialPrefix = REGISTER_REQUEST_PREFIX;
    attestDeviceRequest.registrationMessage.besuAddress = myd3BesuAddress;
    attestDeviceRequest.registrationMessage.did = did;
    attestDeviceRequest.registrationMessage.nonce = nonce;
    attestDeviceRequest.registrationMessage.publicKey = bs58.decode(myd3PublicKey);

    if (dataFromMyd2?.myd2Verkey) {
      attestDeviceRequest.registrationMessage.verkey = bs58.decode(dataFromMyd2.myd2Verkey);
    }

    const registrationRequestMaterial: Uint8Array = RegisterRequestMaterial.encode(
      attestDeviceRequest.registrationMessage
    ).finish();
    attestDeviceRequest.registrationSignature = this.myd3.generateMyd3Signature(registrationRequestMaterial);

    if (dataFromMyd2?.myd2Verkey) {
      attestDeviceRequest.migrationSignature = this.myd3.generateMyd2Signature(registrationRequestMaterial);
    }

    const paramBuf2 = DeviceAttestationRequest.encode(attestDeviceRequest).finish();
    const res2 = await fetchAttestation(paramBuf2);
    const registerDeviceResponse = DeviceAttestationResponse.decode(res2);
    if (registerDeviceResponse.code !== CommonStatusCode.STATUS_SUCCESS) {
      // console.log(`registerDeviceResponse.code ${registerDeviceResponse.code}`);
      throw new Error(String(registerDeviceResponse.code));
    }

    const dhResult: Uint8Array = this.myd3.generateSharedKey(
      registerDeviceResponse.ephemeralPublicKey,
      clientRandom,
      registerDeviceResponse.serverKeyMaterial
    );

    const sharedKey: Uint8Array = dhResult.subarray(0, 32);
    const iv: Uint8Array = dhResult.subarray(32, 44);

    const appKey = this.myd3.decryptAes(sharedKey, iv, registerDeviceResponse.encryptedAppKey);
    const secretKey = this.myd3.decryptAes(sharedKey, iv, registerDeviceResponse.encryptedSecretKey);
    const initialVector = this.myd3.decryptAes(sharedKey, iv, registerDeviceResponse.encryptedInitialVector);

    return {
      status: CommonStatusCode.STATUS_SUCCESS,
      appKey: uint8ArrayToBase64(appKey),
      secretKey: uint8ArrayToBase64(secretKey),
      iv: uint8ArrayToBase64(initialVector),
    };
  }

  /**
   * Run the device registration
   *
   * @returns device registration result
   */
  async deviceRegistration(): Promise<DeviceRegistrationResult> {
    const { did, myd3PublicKey, myd3BesuAddress, dataFromMyd2 } = this.state;

    if (did === "" || myd3PublicKey === "" || myd3BesuAddress === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY));
    }

    const clientRandom: Uint8Array = this.randomBytes(16);
    const nonceResult: GetNonceResult = await this.getNonce();
    if (nonceResult.status !== CommonStatusCode.STATUS_SUCCESS) {
      throw new Error(String(nonceResult.status));
    }

    const registerDeviceRequest: RegisterDeviceRequest = RegisterDeviceRequest.create();
    registerDeviceRequest.appVersion = this.state.applicationInfo.appVersion;
    registerDeviceRequest.clientKeyMaterial = clientRandom;
    registerDeviceRequest.registrationMessage = RegisterRequestMaterial.create();
    registerDeviceRequest.registrationMessage.materialPrefix = REGISTER_REQUEST_PREFIX;
    registerDeviceRequest.registrationMessage.besuAddress = myd3BesuAddress;
    registerDeviceRequest.registrationMessage.did = did;
    registerDeviceRequest.registrationMessage.nonce = nonceResult.nonce!;
    registerDeviceRequest.registrationMessage.publicKey = bs58.decode(myd3PublicKey);

    if (this.myd3.myd2) {
      registerDeviceRequest.registrationMessage.verkey = bs58.decode(this.myd3.myd2.verkey);
    }

    const registrationRequestMaterial: Uint8Array = RegisterRequestMaterial.encode(
      registerDeviceRequest.registrationMessage
    ).finish();
    registerDeviceRequest.registrationSignature = this.myd3.generateMyd3Signature(registrationRequestMaterial);

    if (this.myd3.myd2) {
      registerDeviceRequest.migrationSignature = this.myd3.generateMyd2Signature(registrationRequestMaterial);
    }

    const paramBuf2 = RegisterDeviceRequest.encode(registerDeviceRequest).finish();
    const res2 = await fetchRegister(paramBuf2);
    const registerDeviceResponse = RegisterDeviceResponse.decode(res2);

    if (registerDeviceResponse.code !== CommonStatusCode.STATUS_SUCCESS) {
      // console.log(`registerDeviceResponse.code ${registerDeviceResponse.code}`);
      throw new Error(String(registerDeviceResponse.code));
    }

    const dhResult: Uint8Array = this.myd3.generateSharedKey(
      registerDeviceResponse.ephemeralPublicKey,
      clientRandom,
      registerDeviceResponse.serverKeyMaterial
    );
    const sharedKey: Uint8Array = dhResult.subarray(0, 32);
    const iv: Uint8Array = dhResult.subarray(32, 44);

    // const appKey = this.myd3.decryptAes(sharedKey, iv, registerDeviceResponse.encryptedAppKey);
    const secretKey = this.myd3.decryptAes(sharedKey, iv, registerDeviceResponse.encryptedSecretKey);
    const initialVector = this.myd3.decryptAes(sharedKey, iv, registerDeviceResponse.encryptedInitialVector);

    this.saveState(JSON.stringify(this.state));

    return {
      status: CommonStatusCode.STATUS_SUCCESS,
      appKey: "", // base64 encoded; only valid for attestation
      secretKey: uint8ArrayToBase64(secretKey),
      iv: uint8ArrayToBase64(initialVector),
    };
  }

  /**
   * Get access token from the server requested
   */
  async getAccessToken(mid: string | undefined): Promise<GetAccessTokenResult> {
    const { did, myd3BesuAddress } = this.state;

    if (did === "" || myd3BesuAddress === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY));
    }

    if (this.state.applicationInfo.appKey === undefined) {
      throw new Error(String(CommonStatusCode.ERR_M3CON_FAILED_TO_GET_ACCESS_TOKEN_APP_KEY_IS_MISSING));
    }

    const nonceResult = await this.getNonce();
    if (nonceResult.status !== CommonStatusCode.STATUS_SUCCESS) {
      throw new Error(String(nonceResult.status));
    }

    const getAuthAccessTokenRequest: GetAuthAccessTokenRequest = GetAuthAccessTokenRequest.create();
    getAuthAccessTokenRequest.besuAddress = myd3BesuAddress;
    getAuthAccessTokenRequest.appVersion = this.state.applicationInfo.appVersion;
    getAuthAccessTokenRequest.did = did;
    getAuthAccessTokenRequest.mid = mid;
    getAuthAccessTokenRequest.nonce = nonceResult.nonce!;

    const generatedVerifyCode: { verifyCode: string; signature: string } = this.myd3.generateAuthVerifyCode(
      this.state.applicationInfo.appKey,
      getAuthAccessTokenRequest.appVersion,
      getAuthAccessTokenRequest.nonce
    );
    getAuthAccessTokenRequest.verifyCode = generatedVerifyCode.verifyCode;
    getAuthAccessTokenRequest.signature = generatedVerifyCode.signature;

    const tokenParamBuf = GetAuthAccessTokenRequest.encode(getAuthAccessTokenRequest).finish();
    const res2 = await fetchToken(tokenParamBuf);
    const decodedRes2 = GetAuthAccessTokenResponse.decode(res2);
    this.state.accessToken = decodedRes2.accessToken;

    this.saveState(JSON.stringify(this.state));

    return {
      status: decodedRes2.statusCode,
      accessToken: this.state.accessToken,
    };
  }

  /**
   * Get PI access token from the middleware to upload data to the data server
   */
  async getPiAccessToken(pdsId: string): Promise<GetPiAccessTokenResult> {
    if (!this.state.accessToken) {
      throw new Error(String(CommonStatusCode.ERR_M3CON_REQUEST_FAILED_ACCESS_TOKEN_MISSING));
    }

    const getPiAccessTokenRequest: GetPiAccessTokenRequest = GetPiAccessTokenRequest.create();
    getPiAccessTokenRequest.pdsId = pdsId;

    const paramBuf = GetPiAccessTokenRequest.encode(getPiAccessTokenRequest).finish();
    const res = await fetchPiToken(paramBuf, this.state.accessToken);
    const decodedRes = GetPiAccessTokenResponse.decode(res);

    console.log(decodedRes);
    return {
      status: CommonStatusCode.STATUS_SUCCESS,
      piAccessToken: decodedRes.piAccessToken,
    };
  }

  /**
   * Issue a ticket
   * @param proposalId proposal ID to issue a ticket
   */
  async issueTicket(proposalId: string): Promise<IssueTicketResult> {
    const { did, accessToken } = this.state;

    if (did === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY));
    }

    if (accessToken === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_REQUEST_FAILED_ACCESS_TOKEN_MISSING));
    }

    const requestedDate = new Date();
    const issueTicketRequest: IssueTicketRequest = IssueTicketRequest.create();

    issueTicketRequest.issueTicketRequestMaterial = IssueTicketRequestMaterial.create();
    issueTicketRequest.issueTicketRequestMaterial.materialPrefix = ISSUE_TICKET_PREFIX;
    issueTicketRequest.issueTicketRequestMaterial.createdAt = requestedDate.toISOString();
    issueTicketRequest.issueTicketRequestMaterial.proposalId = proposalId;
    issueTicketRequest.issueTicketRequestMaterial.did = did;
    issueTicketRequest.signature = this.myd3.generateMyd3Signature(
      IssueTicketRequestMaterial.encode(issueTicketRequest.issueTicketRequestMaterial).finish()
    );

    console.log(IssueTicketRequest.toJSON(issueTicketRequest));

    const paramBuf = IssueTicketRequest.encode(issueTicketRequest).finish();
    const res = await fetchIssue(paramBuf, accessToken);
    const decodedRes = IssueTicketResponse.decode(res);

    console.log(decodedRes);
    return {
      status: decodedRes.code === undefined ? CommonStatusCode.STATUS_SUCCESS : decodedRes.code,
      issueTicketResponse: decodedRes,
    };
  }

  /**
   * Issue a coupon
   * @param goodsCode goods code to issue a coupon
   * @param salePrice sale price to issue a coupon
   */
  async issueCoupon(goodsCode: string, salePrice: number): Promise<CouponIssueResult> {
    const { did, accessToken } = this.state;

    if (did === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY));
    }

    if (accessToken === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_REQUEST_FAILED_ACCESS_TOKEN_MISSING));
    }

    const getCouponIdRequest: GetCouponIdRequest = GetCouponIdRequest.create();
    getCouponIdRequest.did = did!;

    const couponIdParam = GetCouponIdRequest.encode(getCouponIdRequest).finish();
    const idsRes = await fetchCouponId(couponIdParam, accessToken!);
    const couponIdResponse = GetCouponIdResponse.decode(idsRes);
    if (couponIdResponse.code !== CommonStatusCode.STATUS_SUCCESS) {
      throw new Error(String(couponIdResponse.code));
    }

    const clientRandom: Uint8Array = this.randomBytes(16);

    // abi.encode("world.myd.purchase-coupon", blockchainRewardContractAddress, besuAddress, blockchainRewardReceiverAddress, amount, blockchainRewardTransferNonce, blockchainRequestId)
    console.log("ccccccc", "0x".concat(toHexString(couponIdResponse.blockchainRequestId)));
    console.log("cccccc2", couponIdResponse.blockchainRequestId);

    let material: Uint8Array = fromHexString(
      this.myd3.web3.eth.abi.encodeParameters(
        ["bytes32", "address", "address", "address", "uint", "uint", "bytes32"],
        [
          "0x776f726c642e6d79642e70757263686173652d636f75706f6e00000000000000",
          couponIdResponse.blockchainRewardContractAddress,
          this.myd3.account.address,
          couponIdResponse.blockchainRewardReceiverAddress,
          salePrice,
          couponIdResponse.blockchainRewardTransferNonce,
          "0x".concat(toHexString(couponIdResponse.blockchainRequestId)),
        ]
      )
    );
    material = material.subarray(1);

    const messageHashHex = toHexString(keccak256(Buffer.from(material)));
    if (messageHashHex == null) {
      throw new Error(String(CommonStatusCode.ERR_M3CON_HASH_FAILED));
    }

    const sign = this.myd3.web3.eth.accounts.sign(messageHashHex, toHexString(bs58.decode(this.myd3.privateKey)));

    const issueCouponRequest: IssueCouponRequest = IssueCouponRequest.create();
    issueCouponRequest.did = did!;
    issueCouponRequest.purchaseRequestMaterial = material;
    issueCouponRequest.purchaseRequestMaterialSignature = fromHexString(sign.signature.substring(2));
    issueCouponRequest.goodsCode = goodsCode;
    issueCouponRequest.clientKeyMaterial = clientRandom;

    const paramBuf = IssueCouponRequest.encode(issueCouponRequest).finish();
    const res = await fetchCouponIssue(paramBuf, accessToken!);
    const issueCouponResponse = IssueCouponResponse.decode(res);

    if (issueCouponResponse.code !== 0) {
      throw new Error(String(issueCouponResponse.code));
    }

    const dhResult: Uint8Array = this.myd3.generateSharedKey(
      issueCouponResponse.ephemeralPublicKey,
      clientRandom,
      issueCouponResponse.serverKeyMaterial
    );
    const sharedKey: Uint8Array = dhResult.subarray(0, 32);
    const iv: Uint8Array = dhResult.subarray(32, 44);

    const couponData = CouponInfo.decode(Uint8Array.from(issueCouponResponse.encryptedCouponData));
    const pinCode = this.myd3.decryptAes(sharedKey, iv, fromHexString(couponData.encryptedPinCode));
    const imageUrl = this.myd3.decryptAes(sharedKey, iv, fromHexString(couponData.encryptedImageUrl));
    const info = this.myd3.decryptAes(sharedKey, iv, fromHexString(couponData.encryptedInfo));

    return {
      orderNo: couponData.orderNo,
      pinCode: new TextDecoder().decode(pinCode),
      imageUrl: new TextDecoder().decode(imageUrl),
      info: new TextDecoder().decode(info),
      status: CommonStatusCode.STATUS_SUCCESS,
    };
  }

  /**
   * Coupon list
   */
  async listCoupon(): Promise<CouponListResult> {
    const { did, accessToken } = this.state;

    if (did === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY));
    }

    if (accessToken === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_REQUEST_FAILED_ACCESS_TOKEN_MISSING));
    }

    const listCouponRequest: ListCouponRequest = ListCouponRequest.create();
    listCouponRequest.did = did;
    // listCouponRequest.couponTransactionIds = ?
    const paramBuf = ListCouponRequest.encode(listCouponRequest).finish();
    const res = await fetchCouponList(paramBuf, accessToken!);

    const listCouponResponse = ListCouponResponse.decode(res);

    console.log("listCouponResponse", listCouponResponse);

    const decryptedCouponList = listCouponResponse.coupons.map((couponInfo): CouponIssueResult => {
      const dhResult: Uint8Array = this.myd3.generateSharedKey(
        couponInfo.ephemeralPublicKey,
        couponInfo.clientKeyMaterial,
        couponInfo.serverKeyMaterial
      );

      const sharedKey: Uint8Array = dhResult.subarray(0, 32);
      const iv: Uint8Array = dhResult.subarray(32, 44);

      // eslint-disable-next-line prefer-destructuring
      const orderNo = couponInfo.orderNo;
      const pinCode = this.myd3.decryptAes(sharedKey, iv, fromHexString(couponInfo.encryptedPinCode));
      const imageUrl = this.myd3.decryptAes(sharedKey, iv, fromHexString(couponInfo.encryptedImageUrl));
      const info = this.myd3.decryptAes(sharedKey, iv, fromHexString(couponInfo.encryptedInfo));
      // eslint-disable-next-line prefer-destructuring
      const couponTransactionId = couponInfo.couponTransactionId;

      return {
        status: CommonStatusCode.STATUS_SUCCESS,
        orderNo,
        pinCode: new TextDecoder().decode(pinCode),
        imageUrl: new TextDecoder().decode(imageUrl),
        info: new TextDecoder().decode(info),
        couponTransactionId,
      };
    });

    return { status: CommonStatusCode.STATUS_SUCCESS, coupons: decryptedCouponList };
  }

  /**
   * Myd2 coupon list
   */
  async listMyd2Coupon(): Promise<Myd2CouponListResult> {
    const { did, accessToken } = this.state;

    if (did === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY));
    }

    if (accessToken === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_REQUEST_FAILED_ACCESS_TOKEN_MISSING));
    }

    const listCouponRequest: ListMyd2CouponRequest = ListMyd2CouponRequest.create();
    listCouponRequest.did = did;
    // listCouponRequest.couponTransactionIds = ?
    const paramBuf = ListCouponRequest.encode(listCouponRequest).finish();
    const res = await fetchMyd2CouponList(paramBuf, accessToken!);

    const listCouponResponse = ListMyd2CouponResponse.decode(res);

    console.log("listCouponResponse", listCouponResponse);

    return { status: listCouponResponse.code, coupons: listCouponResponse.coupons };
  }

  async getChallenge(challengeId: number): Promise<ChallengeRequestResult> {
    const { did, accessToken } = this.state;

    if (did === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY));
    }

    if (accessToken === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_REQUEST_FAILED_ACCESS_TOKEN_MISSING));
    }

    const challengeDetails = await fetchChallengeDetails("", accessToken!, challengeId!);

    return challengeDetails;
  }

  async requestChallenge(challengeId: number, challengeAmount: number): Promise<ChallengeRequestResult> {
    const { did, accessToken } = this.state;

    if (did === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_MYD3_NOT_INITIALIZED_PROPERLY));
    }

    if (accessToken === "") {
      throw new Error(String(CommonStatusCode.ERR_M3CON_REQUEST_FAILED_ACCESS_TOKEN_MISSING));
    }

    const idsRes = await fetchChallengeId("", accessToken!, challengeId!);

    // abi.encode("world.myd.purchase-coupon", blockchainRewardContractAddress, besuAddress, blockchainRewardReceiverAddress, amount, blockchainRewardTransferNonce, blockchainRequestId)
    let material: Uint8Array = fromHexString(
      this.myd3.web3.eth.abi.encodeParameters(
        ["bytes32", "address", "address", "address", "uint", "uint", "bytes32"],
        [
          "0x776f726c642e6d79642e70757263686173652d636f75706f6e00000000000000",
          idsRes.data.blockchainRewardContractAddress,
          this.myd3.account.address,
          idsRes.data.blockchainRewardReceiverAddress,
          challengeAmount,
          idsRes.data.blockchainRewardTransferNonce,
          idsRes.data.encodedBlockChainRequestId,
        ]
      )
    );

    material = material.subarray(1);
    const messageHashHex = toHexString(keccak256(Buffer.from(material)));
    if (messageHashHex == null) {
      throw new Error(String(CommonStatusCode.ERR_M3CON_HASH_FAILED));
    }

    const sign = this.myd3.web3.eth.accounts.sign(messageHashHex, toHexString(bs58.decode(this.myd3.privateKey)));

    const param = {
      challengeMaterials: uint8ArrayToBase64(material),
      challengeMaterialSignature: uint8ArrayToBase64(fromHexString(sign.signature.substring(2))),
      nickName: "admin console user",
    };

    const challengesRes = await fetchChallenges(JSON.stringify(param), accessToken!, challengeId!);

    return {};
  }
}
