import { useState } from "react";
import { MetamaskConnector } from "./MetamaskConnector";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function NonceQueryBoard({ metamaskConnector }: BoardProps) {
  const [pwnerAddress, setOwnerAddress] = useState("");
  const [nonce, setNonce] = useState(0);

  return (
    <div>
      Address <input type="text" name="owner" value={pwnerAddress} onChange={(e) => setOwnerAddress(e.target.value)} />
      <button
        onClick={async () => {
          setNonce(await metamaskConnector.getBlockchainNonce(pwnerAddress));
        }}
      >
        Get blockchain nonce
      </button>
      <br />
      {nonce}
    </div>
  );
}
