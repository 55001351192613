import React from "react";
import logo from "./logo.svg";
import "./App.css";
import MockMobileUi from "./clientsample/MockMobileUi";
import ClientBoard from "./clientsample/ClientBoard";
import { Routes, Route, Link } from "react-router-dom";
import Board from "new-bridge/Board";
import Links from "Links";
import ScenarioBoard from "scenarioboard/ScenarioBoard";
import ProtobufViewer from "protobufviewer/ProtobufViewer";
import AdminConsoleUi from "adminconsole/AdminConsoleUi";

function App() {
  return (
    <div className="App">
      <div className="outdiv">
        <Routes>
          <Route path="/" element={<Links />} />
          <Route path="multiclient_test" element={<ClientBoard />} />
          <Route path="scenario_test" element={<ScenarioBoard />} />
          <Route path="protobuf_viewer" element={<ProtobufViewer />} />
          <Route path="auth" element={<Board />} />
          <Route path="adminconsole" element={<AdminConsoleUi />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
