import { setServerMode } from "api/base";
import { MockMobileCore } from "clientsample/MockMobileCore";
import { MetamaskConnector } from "adminconsole/MetamaskConnector";
import { ChangeEvent, useState } from "react";
import { Gzip } from "zlibt2";

type BoardProps = {};

export default function ScenarioBoard(data: BoardProps) {
  //   const initialCurrentCore: MockMobileCore = new MockMobileCore("empty");

  const outputBufferList: string[] = [];
  const initailList: string[] = [];
  const [serverModeSelected, setServerModeSelected] = useState("dev");
  const [proposalIdsToUse, setProposalIdsToUse] = useState("");
  const [numberOfDevices, setNumberOfDevices] = useState(1);
  const [output, setOutput] = useState(initailList);
  const [metamaskConnector, setMetamaskConnector] = useState(new MetamaskConnector());
  const [loggedIn, setLoggedIn] = useState(false);

  function handleServerChange(event: ChangeEvent<HTMLSelectElement>): void {
    setServerMode(event.target.value);
    setServerModeSelected(event.target.value);
  }

  const exampleData = {
    payload: [
      [
        {
          identifier: {
            scheme: "KeywordEngine",
            category: "Life",
          },
          keywords: [
            {
              dateTime: "2023-01-13 22:17:00",
              identifier: {
                scheme: "Keyword",
                category: "Life",
              },
              name: "검색해요",
            },
            {
              dateTime: "2023-01-14 00:38:00",
              identifier: {
                scheme: "Keyword",
                category: "Life",
              },
              name: "가짜검색어",
            },
            {
              dateTime: "2023-01-14 00:38:00",
              identifier: {
                scheme: "Keyword",
                category: "Life",
              },
              name: "가짜검색어2",
            },
          ],
        },
      ],
    ],
  };

  function runSceanrio(): void {
    for (let i = 0; i < numberOfDevices; i++) {
      runOneSceanrio(i);
    }
  }

  function printOutput(id: Number, message: string) {
    let formattedOutput = `#${id} ${message}`;
    outputBufferList.push(formattedOutput);

    // deep copy and send
    const outputList: string[] = [];
    outputBufferList.forEach((item) => outputList.push(item));
    setOutput(outputList);
  }

  async function runOneSceanrio(id: Number): Promise<void> {
    const oneCore: MockMobileCore = new MockMobileCore();

    let result = await oneCore.deviceRegistration();
    printOutput(id, "registered");

    let mid = oneCore.myd3AuthConnector?.myd3.publicKey.substring(10);
    result = await oneCore.getAccessToken(mid);
    console.log(`runOneSceanrio jwt mid ${mid} result ${result}`);
    printOutput(id, "access token");

    result = await oneCore.listProposals();
    console.log(`runOneSceanrio list ${result}`);
    printOutput(id, "list proposal");

    let proposalIds: string[] = splitProposalIds(proposalIdsToUse);
    await proposalIds.forEach(async (proposalId) => {
      let proposal = oneCore.getProposal(proposalId);

      try {
        await oneCore.issueTicket(proposalId);
        printOutput(id, "issue ticket");
      } catch (error) {
        printOutput(id, `issue ticket failed ${error}`);
        return;
      }

      const pdsId = proposal?.activeClient?.uploadStatus?.at(0)?.oneTimeId;
      if (pdsId === undefined) {
        printOutput(id, `issue ticket failed (no pds id)`);
        return;
      }

      try {
        await oneCore.getPiAccessToken(pdsId);
        printOutput(id, "pi access token");
      } catch (error) {
        printOutput(id, `pi access token failed ${error}`);
        return;
      }
      const utf8Encode: TextEncoder = new TextEncoder();
      const exampleString: string = JSON.stringify(exampleData);
      const gzippedPayload: Uint8Array | any = new Gzip(utf8Encode.encode(exampleString)).compress();

      try {
        await oneCore?.uploadPi(
          proposal?.static?.content?.contentPurchase?.uploadUrl!,
          pdsId,
          proposalId,
          gzippedPayload
        );
        printOutput(id, "pi uploaded");
      } catch (error) {
        printOutput(id, `pi upload failed ${error}`);
      }
    });
  }

  function splitProposalIds(proposalIds: string): string[] {
    let result: string[] = [];
    const didArray = splitTextList(proposalIds);

    didArray.forEach((v) => {
      if (v !== "") {
        result.push(v);
      }
    });
    return result;
  }

  function splitTextList(str: string): string[] {
    const arr = str.split(",");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].replace(/^\s*/, "").replace(/\s*$/, "");
    }
    return arr;
  }

  return (
    <div>
      <select onChange={handleServerChange} value={serverModeSelected}>
        <option value="dev">DEV server</option>
        <option value="stag">STAG server</option>
        <option value="gscert">GS CERT server</option>
        <option value="gscert2">GS CERT 2 server</option>
        <option value="local">LOCAL server</option>
      </select>
      <button
        onClick={async () => {
          console.log("Metamask connecting...");
          await metamaskConnector.connectWallet();
          console.log("Metamask connected");
          console.log("Trying to login...");
          await metamaskConnector.login();
          console.log("Trying to logged in");
          if (metamaskConnector.accessToken !== undefined && metamaskConnector.accessToken !== "") {
            setLoggedIn(true);
          }
        }}
      >
        Metamask login
      </button>
      <br />
      <div style={{ display: loggedIn ? "block" : "none" }}>
        <br />
        Scenario board
        <br />
        <br />
        <details open>
          <summary>Register, issue and upload</summary>
          <table>
            <thead>
              <tr>
                <td>Item</td>
                <td>Value</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Number of devices</td>
                <td>
                  <input
                    type="text"
                    value={numberOfDevices}
                    onChange={(e) => {
                      setNumberOfDevices(Number(e.target.value));
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>Proposals to issue (Data market)</td>
                <td>
                  <textarea
                    minLength={3000}
                    value={proposalIdsToUse}
                    onChange={(e) => {
                      setProposalIdsToUse(e.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>Start test</td>
                <td>
                  {" "}
                  <button type="button" onClick={runSceanrio}>
                    start
                  </button>
                </td>
              </tr>
              <tr>
                <td>Output</td>
                <td>
                  {" "}
                  {output.map((p: any) => (
                    <>
                      <span>{p}</span>
                      <br />
                    </>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </details>
      </div>
    </div>
  );
}
