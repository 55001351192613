import { useState } from "react";
import { MetamaskConnector } from "./MetamaskConnector";
import { UserMigrationReportResponse, UserMigrationReportUnit } from "protobuf/OpenApiServerV3";
import { CSVLink } from "react-csv";
import { Data } from "react-csv/lib/core";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function MigrationReportBoard({ metamaskConnector }: BoardProps) {
  const [loaded, setLoaded] = useState(false);
  const [daysToQuery, setDaysToQuery] = useState("1");
  const [activeUserCriteria, setActiveUserCriteria] = useState("30");
  const [migrationReports, setMigrationReports] = useState<UserMigrationReportUnit[]>([]);
  const [migrationReportCsvData, setMigrationReportCsvData] = useState<string | Data | (() => string | Data)>("");

  async function queryMigration(): Promise<void> {
    setLoaded(true);
    console.log("Trying to get report...");
    let result: UserMigrationReportResponse = await metamaskConnector.migrationReport(
      Number(daysToQuery),
      Number(activeUserCriteria)
    );
    console.log(`proposal report result ${result}`);

    if (result !== undefined) {
      setMigrationReports(result.reports);
      let buffer = `Date, Myd2 active (original), Myd2 active (adjusted), Myd3 active, Ratio, Myd2 new, Myd3 new, Myd2 to Myd3, Myd2 to Myd3 accumulated`;
      for (const migrationReport of result.reports) {
        buffer += `\n${migrationReport.startTime}, ${migrationReport.activeMyd2Users}, ${
          migrationReport.activeMyd2Users - migrationReport.accumulatedMigratedUsers
        }, ${migrationReport.activeMyd3Users}, ${(
          (migrationReport.activeMyd3Users /
            (migrationReport.activeMyd2Users -
              migrationReport.accumulatedMigratedUsers +
              migrationReport.activeMyd3Users)) *
          100
        ).toFixed(2)}%,${migrationReport.newMyd2Users},${migrationReport.newMyd3Users},${
          migrationReport.migratedUsers
        },${migrationReport.accumulatedMigratedUsers}`;
      }
      setMigrationReportCsvData(buffer);
    }
  }

  return (
    <div className="rcdiv">
      <input
        type="text"
        value={daysToQuery}
        onChange={(e) => {
          setDaysToQuery(e.target.value);
        }}
      />
      days &nbsp;
      <button
        onClick={async () => {
          queryMigration();
        }}
      >
        make a migration report
      </button>{" "}
      활성 사용자 :{" "}
      <input
        type="text"
        value={activeUserCriteria}
        onChange={(e) => {
          setActiveUserCriteria(e.target.value);
        }}
      />
      일 이내에 로그인한 이력이 있는 사용자
      <br />
      <div
        style={{
          display: loaded ? "block" : "none",
        }}
      >
        Migration 현황을 확인하기 위한 테이블입니다.
        <br />
        활성 사용자 수는 조회하는 시점에 따라 달라질 수 있습니다. (조회 직전에 오래된 사용자가 로그인하면 수치 변경됨)
        <br />
        <table className="bluetable">
          <thead>
            <tr>
              <th>Date</th>
              <th>마이디3 활성</th>
              <th>신규 마이디3</th>
              <th>마이디2-&gt;마이디3 이동 (당일)</th>
              <th>마이디2-&gt;마이디3 이동 (누적)</th>
            </tr>
          </thead>
          {migrationReports.map((migrationReport, index) => (
            <tbody key={`rep-${index}`}>
              <tr>
                <td>{migrationReport.startTime}</td>
                <td>{migrationReport.activeMyd3Users}</td>
                <td>{migrationReport.newMyd3Users}</td>
                <td>{migrationReport.migratedUsers}</td>
                <td>{migrationReport.accumulatedMigratedUsers}</td>
              </tr>
            </tbody>
          ))}
        </table>
        <br />
        &nbsp;&nbsp;
        <CSVLink data={migrationReportCsvData} filename="migrationReport.csv" target="_blank">
          Export Excel
        </CSVLink>
      </div>
    </div>
  );
}
