import { OverviewItemProps } from "adminconsole/MetamaskConnector";
import { Proposal, ProposalStatus } from "protobuf/OpenApiServerV3DataTypes";
import { useState } from "react";
import { JSONTree } from "react-json-tree";
import { getProtobufEnumKey } from "utils/storage";

type BoardProps = {
  items: OverviewItemProps[];
};

export default function ProposalOverviewBoard({ items }: BoardProps) {
  const [proposal, setProposal] = useState(Proposal.create());
  return (
    <div>
      <table>
        <thead>
          <tr>
            <td rowSpan={2}>제목</td>
            <td colSpan={2}>부제목</td>
            <td colSpan={2}>Proposal ID</td>
            <td colSpan={3}>Proposal Address</td>
          </tr>
          <tr>
            <td>회사명</td>
            <td>Company ID</td>
            <td>시작일</td>
            <td>종료일</td>
            <td>잔액</td>
            <td>Coin (gas)</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          {items?.map((p) => (
            <>
              <tr key={p.itemId}>
                <td rowSpan={3}>{p.title}</td>
                <td colSpan={2}>{p.subTitle}</td>
                <td colSpan={2}>
                  <button type="button" onClick={() => copyToClipboard(p.itemId)}>
                    DID 복사
                  </button>
                  {p.itemId}
                </td>
                <td colSpan={3}>{p.itemAddress}</td>
              </tr>
              <tr>
                <td>{p.ownerName}</td>
                <td>{p.ownerId}</td>
                <td>{p.notBefore}</td>
                <td>{p.notAfter}</td>
                <td>{p.netBalance.toString()}</td>
                <td>{p.coinBalance.toString()}</td>
                <td>{getProtobufEnumKey(ProposalStatus, p.status)}</td>
              </tr>
              <tr>
                <td colSpan={7}>
                  <details>
                    <summary> JSON 보기 </summary>
                    <JSONTree data={p.proposal!} />
                  </details>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
      <br />
    </div>
  );
}

export async function copyToClipboard(text: string): Promise<void> {
  try {
    await navigator.clipboard.writeText(text);
    console.log(`copied ${text}`);
  } catch (err) {
    console.error(`failed to copy ${text}`);
  }
}
