import { useState } from "react";
import { MetamaskConnector } from "./MetamaskConnector";
import { CouponManagerResponse, CouponStatus, ListMyd2CouponResponse } from "protobuf/OpenApiServerV3";
import { CommonStatusCode } from "protobuf/CommonStatusCode";
import { JSONTree } from "react-json-tree";
import { getProtobufEnumKey } from "utils/storage";
import { toHexString, uint8ArrayToBase64 } from "authconnector/blockchainutil";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
  queryOnly?: boolean;
};

export default function CouponManagerBoard({ metamaskConnector, queryOnly }: BoardProps) {
  const [couponOwnerDid, setCouponOwnerDid] = useState<string>("");
  const [couponTransactionId, setCouponTransactionId] = useState<string>("");
  const [couponTransactionIdToRevoke, setCouponTransactionIdToRevoke] = useState<string>("");
  const [singleCouponData, setSingleCouponData] = useState<CouponManagerResponse>(CouponManagerResponse.create());
  const [couponData, setCouponData] = useState<CouponManagerResponse>(CouponManagerResponse.create());
  const [myd2CouponData, setMyd2CouponData] = useState<ListMyd2CouponResponse>(ListMyd2CouponResponse.create());

  const [listByDidResult, setListByDidResult] = useState<string>("");
  const [getByTransactionIdResult, setGetByTransactionIdResult] = useState<string>("");
  const [revokeResult, setRevokeResult] = useState<string>("");

  async function copyToClipboard(text: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(text);
      console.log(`copied ${text}`);
    } catch (err) {
      console.error(`failed to copy ${text}`);
    }
  }

  return (
    <div>
      <br />
      Search by coupon transaction ID &nbsp;
      <input
        type="text"
        value={couponTransactionId}
        onChange={(e) => {
          setCouponTransactionId(e.target.value);
        }}
      />
      &nbsp;
      <button
        onClick={async () => {
          setSingleCouponData(await metamaskConnector.getCouponsByTransactionId(couponTransactionId));
          setGetByTransactionIdResult(getProtobufEnumKey(CommonStatusCode, couponData.code) ?? "");
        }}
      >
        search
      </button>
      <br />
      <br />
      <table className="bluetable">
        <thead>
          <tr>
            <th>Brand name</th>
            <th>Goods code</th>
            <th>PIN status</th>
            <th>Send status</th>
          </tr>
        </thead>
        {singleCouponData.couponDetails.map((entity, index) => (
          <tbody key={`details-${index}`}>
            <tr>
              <td>{entity.brandName}</td>
              <td>{entity.goodsCode}</td>
              <td>{getProtobufEnumKey(CouponStatus, Number(entity.pinStatusCode))}</td>
              <td>{entity.sendStatusCode}</td>
            </tr>
            <tr>
              <td colSpan={4}>
                <JSONTree data={entity} />
              </td>
            </tr>
          </tbody>
        ))}
      </table>
      <div className="rcdiv">{getByTransactionIdResult}</div>
      <br />
      <br />
      Search by DID &nbsp;
      <input
        type="text"
        value={couponOwnerDid}
        onChange={(e) => {
          setCouponOwnerDid(e.target.value);
        }}
      />
      &nbsp;
      <button
        onClick={async () => {
          setCouponData(await metamaskConnector.listCouponsByDid(couponOwnerDid));
          setMyd2CouponData(await metamaskConnector.listMyd2CouponsByDid(couponOwnerDid));
          setListByDidResult(
            `${getProtobufEnumKey(CommonStatusCode, couponData.code) ?? ""}/${
              getProtobufEnumKey(CommonStatusCode, myd2CouponData.code) ?? ""
            }`
          );
        }}
      >
        search
      </button>
      <br />
      <br />
      <table className="bluetable">
        <thead>
          <tr>
            <th>Version</th>
            <th>Transaction ID</th>
            <th>Order no</th>
            <th>Goods code</th>
            <th>Price</th>
            <th>Published at</th>
            <th>Status</th>
          </tr>
        </thead>
        {couponData.couponInfoList.map((entity, index) => (
          <tbody key={`details-${index}`}>
            <tr>
              <td>MYD3</td>
              <td>{entity.couponTransactionId}</td>
              <td>{entity.orderNo}</td>
              <td>{entity.goodsCode}</td>
              <td>{entity.price}</td>
              <td>{entity.publishedAt}</td>
              <td>{getProtobufEnumKey(CouponStatus, Number(entity.status))}</td>
            </tr>
            <tr>
              <td colSpan={7}>
                <details>
                  <summary>raw</summary>
                  <JSONTree data={entity} />
                </details>
              </td>
            </tr>
          </tbody>
        ))}
        {myd2CouponData.coupons.map((entity, index) => (
          <tbody key={`details-${index}`}>
            <tr>
              <td>MYD2</td>
              <td>{entity.couponTransactionId}</td>
              <td />
              <td>{entity.goodsCode}</td>
              <td />
              <td>{entity.issuedAt}</td>
              <td>{entity.status}</td>
            </tr>
            <tr>
              <td>HEX</td>
              <td>
                {" "}
                <button type="button" onClick={() => copyToClipboard(toHexString(entity.encryptedInfo))}>
                  복사
                </button>{" "}
                {toHexString(entity.encryptedInfo).substring(0, 10)}{" "}
                {entity.encryptedInfo !== undefined && entity.encryptedInfo.length > 10 ? "..." : ""}
              </td>
              <td>Base64</td>
              <td>
                {" "}
                <button type="button" onClick={() => copyToClipboard(uint8ArrayToBase64(entity.encryptedInfo))}>
                  복사
                </button>{" "}
                {uint8ArrayToBase64(entity.encryptedInfo).substring(0, 10)}
                {entity.encryptedInfo !== undefined && entity.encryptedInfo.length > 10 ? "..." : ""}
              </td>
            </tr>

            <tr>
              <td colSpan={7}>
                <details>
                  <summary>raw</summary>
                  <JSONTree data={entity} />
                </details>
              </td>
            </tr>
          </tbody>
        ))}
      </table>
      <div className="rcdiv">{listByDidResult}</div>
      <br />
      <br />
      <div style={{ display: queryOnly === false ? "block" : "none" }}>
        Revoke coupon by transaction ID
        <input
          type="text"
          value={couponTransactionIdToRevoke}
          onChange={(e) => {
            setCouponTransactionIdToRevoke(e.target.value);
          }}
        />
        &nbsp;
        <button
          onClick={async () => {
            let response = await metamaskConnector.revokeCouponsByTransactionId(couponTransactionIdToRevoke);
            setRevokeResult(getProtobufEnumKey(CommonStatusCode, response) ?? "");
          }}
        >
          revoke
        </button>
        <br />
        <div className="rcdiv">{revokeResult}</div>
        <br />
      </div>
    </div>
  );
}
