/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */

// app에서 web api를 호출하는 함수 key
// do nothing

// web에서 app으로 값을 전달하는 함수 key
// web의 입장에서 네이티브 앱에 필요한 정보를 요청할 때 REQ_*, 필요한 정보를 전달할 때 RES_*
export const INITIALIZE = "initialize";
export const RES_MYD3_STATE = "res_myd3_state";
export const REQ_ATTESTATION = "req_attestation";
export const RES_REGISTERED = "res_registered";

const webApi: { [key: string]: any } = {};

// for invoke_myd_web_callback
const interfaceMap: {
  [key: string]: (...args: any[]) => void;
} = {};

let sequence_id = "0";

/**
 * APP 정보 요청 전 result handling 등록
 * @param {String} function_name api name
 * @param {Object} parameter api param
 * @param {Function} callback callback
 */

// web -> app, invoke myd web callback
function invoke_app_function(function_name: string, parameter: Object, callback: (...args: any[]) => void) {
  try {
    interfaceMap[sequence_id] = callback;

    const overallParams = {
      function: function_name,
      sequence_id,
      parameter,
    };
    if (
      typeof (window as any).webkit !== "undefined" &&
      typeof (window as any).webkit.messageHandlers.odmpass_app !== "undefined"
    ) {
      typeof (window as any).webkit.messageHandlers.odmpass_app.postMessage(JSON.stringify(overallParams));
    } else {
      typeof (window as any).odmpass_app.post(JSON.stringify(overallParams));
    }

    sequence_id = (Number(sequence_id) + 1).toString();
  } catch (err) {
    throw Error(`Callback Error: ${err}`);
  }
}

/**
 * WEB -> APP 호출 시 사용될 interface (APP 정보 요청)
 * @param {String} function_name app api name
 * @param {String} seqence_id interface map sequence_id
 * @param {Object} return_app_to_web api param
 */
// @ts-ignore

// web -> app
window.invoke_odmpass_web_callback = function invoke_sdk_web_callback(
  function_name: string,
  seq_id: string,
  return_app_to_web: object
) {
  const current_callback = interfaceMap[seq_id];
  current_callback(return_app_to_web);

  delete interfaceMap[seq_id];
};

/**
 * APP -> WEB 호출 시 사용될 interface (WEB 정보 요청)
 * @param {String} function_name api name
 * @param {Object} parameter  api param
 */
// @ts-ignore
window.invoke_odmpass_web_api = function invoke_sdk_web_api(function_name: string, parameter: object) {
  if (webApi[function_name] === undefined) {
    return {};
    // return error.NotFoundException;
  }
  console.log(function_name);
  const current_callback = webApi[function_name];
  return current_callback(parameter);
};

// main method
export const invokeInterface = async (
  functionName: string,
  param: object,
  callback: (...args: any[]) => any
): Promise<any> => {
  if (window && document) {
    return new Promise((resolve) => {
      invoke_app_function(functionName, param, (...args: any[]) => {
        resolve(callback(...args));
      });
    });
  }

  return null;
};
