import { useState } from "react";
import { MetamaskConnector } from "./MetamaskConnector";

type BoardProps = {
  metamaskConnector: MetamaskConnector;
};

export default function RefundSpecialProposal({ metamaskConnector }: BoardProps) {
  const [proposalIdToRefund, setProposalIdToRefund] = useState("");

  return (
    <div>
      <input
        type="text"
        value={proposalIdToRefund}
        onChange={(e) => {
          setProposalIdToRefund(e.target.value);
        }}
      />
      <button
        onClick={async () => {
          console.log("Trying to refund...");
          let result = await metamaskConnector.refundSpecialProposal(proposalIdToRefund);
          console.log(`result ${result}`);
        }}
      >
        Refund the proposal now!
      </button>
      <br />
      <br />
    </div>
  );
}
