import { ChangeEvent, useState } from "react";
import { MetamaskConnector } from "./MetamaskConnector";
import CompanyOverviewBoard from "./CompanyOverviewBoard";
import { setServerMode } from "api/base";
import SpecialUsersOverviewBoard from "./SpecialUsersOverviewBoard";
import ProposalOverviewBoard from "./ProposalOverviewBoard";
import SmartContractOverviewBoard from "./SmartContractOverviewBoard";
import RegisterInviteFriendProposal from "./RegisterInviteFriendProposal";
import DidFilteringBoard from "./DidFilteringBoard";
import RegisterDidFilteringProposal from "./RegisterDidFilteringProposal";
import RegisterAttendanceProposal from "./RegisterAttendanceProposal";
import RevokeSpecialProposal from "./RevokeSpecialProposal";
import HomeBannerBoard from "./HomeBannerBoard";
import AppVersionBoard from "./AppVersionBoard";
import PointExpiryBoard from "./PointExpiryBoard";
import SmartContractDirectCallBoard from "./SmartContractDirectCallBoard";
import RefundSpecialProposal from "./RefundSpecialProposal";
import WebResourceUploadBoard from "./WebResourceUploadBoard";
import ApplicationResourceBoard from "./ApplicationResourceBoard";
import CouponManagerBoard from "./CouponManagerBoard";
import DailyReportBoard from "./DailyReportBoard";
import DidPointAllowanceBoard from "./DidPointAllowanceBoard";
import QueryBoard from "./QueryBoard";
import AdminProposalUpdate from "./AdminProposalUpdate";
import ManualMyd2MigrationBoard from "./ManulaMyd2Migration";
import MigrationReportBoard from "./MigrationReportBoard";
import NonceQueryBoard from "./NonceQueryBoard";

type AdminConsoleUiProps = {};

export default function AdminConsoleUi(props: AdminConsoleUiProps) {
  const [serverModeSelected, setServerModeSelected] = useState("dev");

  const [tabSelected, setTabSelected] = useState("query");

  const [metamaskConnector, setMetamaskConnector] = useState(new MetamaskConnector());
  const [loggedIn, setLoggedIn] = useState(false);
  const [specialUsersOverviewItems, setSpecialUsersOverviewItems] = useState(
    metamaskConnector.specialUsersOverviewItems
  );
  const [companyOverviewItems, setCompanyOverviewItems] = useState(metamaskConnector.companyOverviewItems);
  const [proposalOverviewItems, setProposalOverviewItems] = useState(metamaskConnector.proposalOverviewItems);
  const [smartContractItemLinks, setSmartContractItemLinks] = useState(metamaskConnector.smartContractItemLinks);
  const [didForCreateKeyPair, setDidForCreateKeyPair] = useState("");

  const [middlewareRoleCheckOwnerAddress, setMiddlewareRoleCheckOwnerAddress] = useState("");
  const [middlewareRoleCheckRole, setMiddlewareRoleCheckRole] = useState("");
  const [middlewareRoleCheckUserName, setMiddlewareRoleCheckUserName] = useState("");
  const [middlewareRoleCheckTeam, setMiddlewareRoleCheckTeam] = useState("");
  const [middlewareRoleCheckResult, setMiddlewareRoleCheckResult] = useState("");

  const [roleCheckOwnerAddress, setRoleCheckOwnerAddress] = useState("");
  const [roleCheckRole, setRoleCheckRole] = useState("");
  const [roleCheckResult, setRoleCheckResult] = useState("");

  const [didToBreakAway, setDidToBreakAway] = useState("");

  const [proposalAddressToCheck, setProposalAddressToCheck] = useState("");
  const [proposalAddressCheckResult, setProposalAddressCheckResult] = useState("");

  function handleServerChange(event: ChangeEvent<HTMLSelectElement>): void {
    setServerMode(event.target.value);
    setServerModeSelected(event.target.value);
  }

  return (
    <div>
      <details open>
        <summary>Blockchain admin console</summary>
        <select onChange={handleServerChange} value={serverModeSelected}>
          <option value="dev">DEV server</option>
          <option value="stag">STAG server</option>
          <option value="prod">PROD server</option>
          <option value="gscert">GS CERT server</option>
          <option value="gscert2">GS CERT 2 server</option>
          <option value="local">LOCAL server</option>
        </select>
        &nbsp;&nbsp;
        <button
          onClick={async () => {
            console.log("Metamask connecting...");
            await metamaskConnector.connectWallet();
            console.log("Metamask connected");
            console.log("Trying to login...");
            await metamaskConnector.login();
            console.log("Trying to logged in");
            if (metamaskConnector.accessToken !== undefined && metamaskConnector.accessToken !== "") {
              setLoggedIn(true);
            }
          }}
        >
          Metamask login
        </button>
        <div style={{ display: loggedIn ? "block" : "none" }}>
          <br />
          <br />
          <input
            id="queryTab"
            value="query"
            name="tab"
            type="radio"
            checked={tabSelected === "query"}
            onChange={(e) => setTabSelected("query")}
          />{" "}
          Query{" "}
          <input
            id="overviewTab"
            value="overview"
            name="tab"
            type="radio"
            checked={tabSelected === "overview"}
            onChange={(e) => setTabSelected("overview")}
          />{" "}
          Overview
          <input
            id="overviewTab"
            value="point"
            name="tab"
            type="radio"
            checked={tabSelected === "point"}
            onChange={(e) => setTabSelected("point")}
          />{" "}
          Point
          <input
            id="overviewTab"
            value="management"
            name="tab"
            type="radio"
            checked={tabSelected === "management"}
            onChange={(e) => setTabSelected("management")}
          />{" "}
          Management
          <input
            id="overviewTab"
            value="adminonly"
            name="tab"
            type="radio"
            checked={tabSelected === "adminonly"}
            onChange={(e) => setTabSelected("adminonly")}
          />{" "}
          Admin only
          <br />
          <br />
          <br />
          <div style={{ display: tabSelected === "query" ? "block" : "none" }}>
            <QueryBoard metamaskConnector={metamaskConnector} />
          </div>
          <div style={{ display: tabSelected === "overview" ? "block" : "none" }}>
            <details open>
              <summary>Daily report</summary>
              <DailyReportBoard metamaskConnector={metamaskConnector} queryOnly={false} />
            </details>
            <br />
            <details open>
              <summary>Migration report</summary>
              <MigrationReportBoard metamaskConnector={metamaskConnector} />
            </details>
            <br />
            <details open>
              <summary>Special users</summary>
              <button
                onClick={async () => {
                  await metamaskConnector.loadSpecialUsersOverview();
                  console.log("loaded");
                  setSpecialUsersOverviewItems(metamaskConnector.specialUsersOverviewItems);
                }}
              >
                Load overview
              </button>
              <br />
              <SpecialUsersOverviewBoard items={specialUsersOverviewItems} />
            </details>
            <br />
            <br />
            <details open>
              <summary>Companies and proposals</summary>
              <button
                onClick={async () => {
                  await metamaskConnector.loadCompanyProposalOverview();
                  console.log("loaded");
                  setCompanyOverviewItems(metamaskConnector.companyOverviewItems);
                  setProposalOverviewItems(metamaskConnector.proposalOverviewItems);
                }}
              >
                Get company and proposal list
              </button>
              <br />
              <b>Create Key Pair</b>&nbsp; DID{" "}
              <input
                type="text"
                name="did"
                value={didForCreateKeyPair}
                onChange={(e) => setDidForCreateKeyPair(e.target.value)}
              />
              &nbsp;
              <button
                onClick={async () => {
                  let address = await metamaskConnector.createKeyPair(didForCreateKeyPair);
                  await metamaskConnector.loadCompanyProposalOverview();
                  console.log("loaded");
                  setCompanyOverviewItems(metamaskConnector.companyOverviewItems);
                }}
              >
                키 생성
              </button>
              <br />
              Company list <br />
              <CompanyOverviewBoard items={companyOverviewItems} />
              Proposal list <br />
              <ProposalOverviewBoard items={proposalOverviewItems} />
            </details>
            <br />
            <br />
            <details open>
              <summary>Smart contracts overview</summary>
              <button
                onClick={async () => {
                  await metamaskConnector.loadContractRelations();
                  setSmartContractItemLinks(metamaskConnector.smartContractItemLinks);
                }}
              >
                Get smart contract links
              </button>
              <SmartContractOverviewBoard items={smartContractItemLinks} />
            </details>
          </div>
          <div style={{ display: tabSelected === "point" ? "block" : "none" }}>
            <DidPointAllowanceBoard metamaskConnector={metamaskConnector} />
          </div>
          <div style={{ display: tabSelected === "management" ? "block" : "none" }}>
            <details open>
              <summary>Home banner</summary>
              <HomeBannerBoard metamaskConnector={metamaskConnector} />
            </details>
            <br />
            <br />
            <details open>
              <summary>App version config</summary>
              <AppVersionBoard metamaskConnector={metamaskConnector} />
            </details>
            <br />
            <br />
            <details open>
              <summary>Web resources</summary>
              <WebResourceUploadBoard metamaskConnector={metamaskConnector} />
            </details>
            <br />
            <br />
            <details>
              <summary>Application resource</summary>
              <ApplicationResourceBoard metamaskConnector={metamaskConnector} />
            </details>
            <br />
            <br />
            <details>
              <summary>DID filtering</summary>
              <DidFilteringBoard metamaskConnector={metamaskConnector} />
            </details>{" "}
          </div>
          <div style={{ display: tabSelected === "adminonly" ? "block" : "none" }}>
            <details>
              <summary>Coupon manager</summary>
              <CouponManagerBoard metamaskConnector={metamaskConnector} queryOnly={false} />
            </details>
            <br />
            <details>
              <summary>Attendance proposal registration</summary>
              <RegisterAttendanceProposal metamaskConnector={metamaskConnector} />
            </details>
            <br />
            <details>
              <summary>Invite friend proposal registration</summary>
              <RegisterInviteFriendProposal metamaskConnector={metamaskConnector} />
            </details>
            <br />
            <details>
              <summary>(CAUTION!!!) DID filtering proposal registration</summary>
              <RegisterDidFilteringProposal metamaskConnector={metamaskConnector} />
            </details>
            <br />
            <details>
              <summary>(CAUTION!!!) Revoking a proposal - no refund</summary>
              <RevokeSpecialProposal metamaskConnector={metamaskConnector} />
            </details>
            <br />
            <details>
              <summary>(CAUTION!!!) Refund a proposal - should be done after revoke</summary>
              <RefundSpecialProposal metamaskConnector={metamaskConnector} />
            </details>
            <br />
            <details>
              <summary>(CAUTION!!!) Point burn</summary>
              <PointExpiryBoard metamaskConnector={metamaskConnector} />
            </details>
            <br />
            <details>
              <summary>(CAUTION!!!) Get blockchain nonce</summary>
              <NonceQueryBoard metamaskConnector={metamaskConnector} />
            </details>
            <br />
            <details open>
              <summary>Middleware role management</summary>
              Owner address{" "}
              <input
                type="text"
                name="mw-role-check-owner"
                value={middlewareRoleCheckOwnerAddress}
                onChange={(e) => setMiddlewareRoleCheckOwnerAddress(e.target.value)}
              />
              &nbsp; Role number &nbsp;
              <input
                type="text"
                name="mw-role-check-spender"
                value={middlewareRoleCheckRole}
                onChange={(e) => setMiddlewareRoleCheckRole(e.target.value)}
              />
              &nbsp; User name &nbsp;
              <input
                type="text"
                name="mw-role-check-username"
                value={middlewareRoleCheckUserName}
                onChange={(e) => setMiddlewareRoleCheckUserName(e.target.value)}
              />
              &nbsp; Team &nbsp;
              <input
                type="text"
                name="mw-role-check-team"
                value={middlewareRoleCheckTeam}
                onChange={(e) => setMiddlewareRoleCheckTeam(e.target.value)}
              />
              &nbsp;
              <button
                onClick={async () => {
                  if (window.confirm("정말 grant 하시겠습니까?")) {
                    let granted = await metamaskConnector.grantMiddlewareRole(
                      middlewareRoleCheckOwnerAddress,
                      Number(middlewareRoleCheckRole),
                      middlewareRoleCheckUserName,
                      middlewareRoleCheckTeam
                    );
                    setMiddlewareRoleCheckResult(granted ? "granted" : "not allowed");
                  }
                }}
              >
                Grant role
              </button>
              &nbsp;&nbsp;
              <button
                onClick={async () => {
                  if (window.confirm("정말 revoke 하시겠습니까?")) {
                    let revoked = await metamaskConnector.revokeMiddlewareRole(middlewareRoleCheckOwnerAddress);
                    setMiddlewareRoleCheckResult(revoked ? "revoked" : "denied");
                  }
                }}
              >
                Revoke role
              </button>
              <br />
              {middlewareRoleCheckResult}
            </details>
            <br />
            <br />
            <details open>
              <summary>Role check and management</summary>
              Owner address{" "}
              <input
                type="text"
                name="role-check-owner"
                value={roleCheckOwnerAddress}
                onChange={(e) => setRoleCheckOwnerAddress(e.target.value)}
              />
              Role number &nbsp;
              <input
                type="text"
                name="role-check-spender"
                value={roleCheckRole}
                onChange={(e) => setRoleCheckRole(e.target.value)}
              />
              <button
                onClick={async () => {
                  let granted = await metamaskConnector.hasRole(roleCheckOwnerAddress, Number(roleCheckRole));
                  setRoleCheckResult(granted ? "granted" : "not allowed");
                }}
              >
                Has role?
              </button>
              &nbsp;&nbsp;
              <button
                onClick={async () => {
                  if (window.confirm("정말 grant 하시겠습니까?")) {
                    let granted = await metamaskConnector.grantRole(roleCheckOwnerAddress, Number(roleCheckRole));
                    setRoleCheckResult(granted ? "granted" : "not allowed");
                  }
                }}
              >
                Grant role
              </button>
              &nbsp;&nbsp;
              <button
                onClick={async () => {
                  if (window.confirm("정말 revoke 하시겠습니까?")) {
                    let granted = await metamaskConnector.revokeRole(roleCheckOwnerAddress, Number(roleCheckRole));
                    setRoleCheckResult(granted ? "granted" : "not allowed");
                  }
                }}
              >
                Revoke role
              </button>
              <br />
              {roleCheckResult}
            </details>
            <br />
            <details open>
              <summary>Proposal in smart contract (raw data) retrieving</summary>
              Proposal address{" "}
              <input
                type="text"
                name="proposal-address-to-check"
                value={proposalAddressToCheck}
                onChange={(e) => setProposalAddressToCheck(e.target.value)}
              />
              &nbsp;&nbsp;
              <button
                onClick={async () => {
                  let result = await metamaskConnector.getProposalInSmartContract(proposalAddressToCheck);
                  setProposalAddressCheckResult(result);
                }}
              >
                Check
              </button>
              <br />
              <div className="rcdiv">{proposalAddressCheckResult}</div>
            </details>
            <br />
            <details open>
              <summary>Break away DID</summary>
              DID{" "}
              <input
                type="text"
                name="did-to-break-away"
                value={didToBreakAway}
                onChange={(e) => setDidToBreakAway(e.target.value)}
              />
              &nbsp;&nbsp;
              <button
                onClick={async () => {
                  if (window.confirm("정말 DID를 파기하시겠습니까? 이 작업은 되돌릴 수 없습니다!")) {
                    await metamaskConnector.breakAwayDid(didToBreakAway);
                  }
                }}
              >
                Break-away
              </button>
              <br />
              <div className="rcdiv">{proposalAddressCheckResult}</div>
            </details>
            <br />
            <br />
            <SmartContractDirectCallBoard metamaskConnector={metamaskConnector} />
            <br />
            <br />
            <AdminProposalUpdate metamaskConnector={metamaskConnector} />
            <br />
            <br />
            <ManualMyd2MigrationBoard metamaskConnector={metamaskConnector} />
          </div>
        </div>
      </details>
    </div>
  );
}
